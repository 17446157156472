/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {

  /*      AD FUNCTION CRUD      */
  getAdFunctions({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/ad-functions/select-list`)
        .then(response => {
          commit('SET_AD_FUNCTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAdFunction({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/ad-functions/${id}`)
        .then(response => {
          commit('SET_AD_FUNCTION', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  postAdFunction({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/projects/${project.state.activeProjectId}/ad-functions`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putAdFunction({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/projects/${project.state.activeProjectId}/ad-functions`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  /*      AD WORKFLOW     */
  // postAdWorkflow({ commit }, adScheduleId) {
  //   return new Promise(resolve => {
  //     axios.get(`/api/projects/${project.state.activeProjectId}/ad-schedules/${adScheduleId}/ad-workflows`)
  //       .then(response => {
  //         resolve(response)
  //       })
  //   })
  // },
  // postAdWorkflowChangePositions({ commit }, adScheduleId) {
  //   return new Promise(resolve => {
  //     axios.get(`/api/projects/${project.state.activeProjectId}/ad-schedules/${adScheduleId}/ad-workflows`)
  //       .then(response => {
  //         resolve(response)
  //       })
  //   })
  // },
}
