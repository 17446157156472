export default {
  getIsLoading: state => state.isLoading,
  getApiKeyRoleSelected: state => state.apiKey.roleId,
  getApiKeyName: state => state.apiKey.userApiKey.apiName,
  getApiKeyHasExpireDate: state => state.apiKey.userApiKey.hasExpireTime,
  getApiKeyStartDateTime: state => state.apiKey.userApiKey.startDateTime,
  getApiKeyExpireDateTime: state => state.apiKey.userApiKey.expireDateTime,
  getApiKeyKey: state => state.apiKey.userApiKey.apiKey,
  // getApiKeyRoleOptions: state => state.apiKeyRoleOptions,
  getApiKeysProjectIdsSelected: state => state.apiKey.listProjectsId,
}
