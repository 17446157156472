<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.788 9.627a2.6 2.6 0 010 4.285l-4.316 2.967c-1.725 1.186-4.073-.05-4.073-2.143V8.802c0-2.093 2.348-3.328 4.073-2.142l4.316 2.967zm-.68 3.296a1.4 1.4 0 000-2.307L7.793 7.648c-.93-.638-2.194.027-2.194 1.154v5.934c0 1.128 1.265 1.793 2.194 1.154l4.315-2.967z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.627 6.66c-1.725-1.186-4.073.049-4.073 2.142V9a.6.6 0 001.2 0v-.198c0-1.127 1.264-1.792 2.193-1.154l4.316 2.968a1.4 1.4 0 010 2.307l-4.316 2.967c-.929.639-2.193-.026-2.193-1.154v-.198a.6.6 0 00-1.2 0v.198c0 2.094 2.348 3.329 4.073 2.143l4.316-2.967a2.6 2.6 0 000-4.285L14.627 6.66z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
