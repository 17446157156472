<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.29 6.404c1.99-2.078 5.35-2.215 7.518-.314l.192.169.193-.17c2.167-1.9 5.528-1.763 7.518.315 2.005 2.093 1.854 5.353-.329 7.268l-6.92 6.071a.7.7 0 01-.923 0l-6.92-6.071c-2.183-1.915-2.334-5.175-.33-7.268zm6.726.588c-1.687-1.48-4.317-1.369-5.86.242-1.529 1.597-1.419 4.069.253 5.536L12 18.552l6.591-5.782c1.672-1.467 1.782-3.94.253-5.536-1.543-1.61-4.172-1.722-5.86-.242L12 7.855l-.984-.863z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
