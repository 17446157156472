<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.971 16.029H5.143c-.963 0-1.743-.78-1.743-1.743V5.143c0-.963.78-1.743 1.743-1.743h9.143c.962 0 1.743.78 1.743 1.743V7.97h2.828c.963 0 1.743.78 1.743 1.743v9.143c0 .963-.78 1.743-1.743 1.743H9.714c-.962 0-1.743-.78-1.743-1.743V16.03zm1.2 0h5.115c.962 0 1.743-.78 1.743-1.743V9.17h2.828c.3 0 .543.244.543.543v9.143c0 .3-.243.543-.543.543H9.714a.543.543 0 01-.543-.543V16.03z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
