<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.229 4.732a.6.6 0 01-.269.805L6.034 12l12.926 6.463a.6.6 0 01-.536 1.074L5.318 12.984a1.1 1.1 0 010-1.968l13.106-6.553a.6.6 0 01.805.269z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
