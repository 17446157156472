import User from '@/models/users/User'
import UserOnAdd from '@/models/users/UserOnAdd'

export default {
  isLoading: true,
  users: [],
  usersOnAdd: [new UserOnAdd()],
  usersOfProject: [],
  usersForProject: [],
  user: {},
  userDefaultOrganization: null,
  userDefaultProject: null,
  userEdited: new User(),
  userProjectsOptions: [],

  filters: {
    params: {
      organizationId: null,
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },

  filtersOfProject: {
    params: {
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },
  // filtersForProject: {
  //   params: {
  //     page: 1,
  //     take: 10,
  //     search: '',
  //     order: 'desc',
  //   },
  // },

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },
  paginationOfProject: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

}
