<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.423 7.576a.6.6 0 010 .848L3.847 12l3.576 3.576a.6.6 0 11-.849.848L2.15 12l4.424-4.424a.6.6 0 01.849 0zm9.151 0a.6.6 0 01.849 0L21.847 12l-4.424 4.424a.6.6 0 01-.849-.848L20.15 12l-3.576-3.576a.6.6 0 010-.848z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.15 12a.6.6 0 01.6-.6h7.304a.6.6 0 010 1.2H8.75a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
