<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.257 12a5.743 5.743 0 1111.486 0 5.743 5.743 0 01-11.486 0zM12 7.457a4.543 4.543 0 100 9.086 4.543 4.543 0 000-9.086z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 12a8.6 8.6 0 1117.2 0 8.6 8.6 0 01-17.2 0zM12 4.6a7.4 7.4 0 100 14.8 7.4 7.4 0 000-14.8z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 12a.6.6 0 01.6-.6h4.571a.6.6 0 010 1.2H4a.6.6 0 01-.6-.6zM14.829 12a.6.6 0 01.6-.6H20a.6.6 0 010 1.2h-4.571a.6.6 0 01-.6-.6zM12 3.4a.6.6 0 01.6.6v4.571a.6.6 0 11-1.2 0V4a.6.6 0 01.6-.6zM12 14.829a.6.6 0 01.6.6V20a.6.6 0 11-1.2 0v-4.571a.6.6 0 01.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
