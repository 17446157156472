<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.94 10.093a.547.547 0 010-.83L12 5.666l4.06 3.597a.547.547 0 010 .83.647.647 0 01-.847 0L12 7.245l-3.213 2.848a.647.647 0 01-.846 0zM7.94 13.907a.547.547 0 000 .83L12 18.334l4.06-3.597a.547.547 0 000-.83.647.647 0 00-.847 0L12 16.755l-3.213-2.848a.647.647 0 00-.846 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
