<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.4a1.754 1.754 0 00-1.122 3.102L6.58 10.323H6.5a2.6 2.6 0 00-2.6 2.6V17a2.6 2.6 0 002.6 2.6h11a2.6 2.6 0 002.6-2.6v-4.077a2.6 2.6 0 00-2.6-2.6h-.08l-4.298-3.82A1.754 1.754 0 0012 3.4zm-.554 1.754a.554.554 0 111.108 0 .554.554 0 01-1.108 0zm-3.06 5.17L12 7.11l3.614 3.212H8.386z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
