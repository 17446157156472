<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.148 17.044a1.178 1.178 0 100 2.356 1.178 1.178 0 000-2.355zm-2.377 1.178a2.378 2.378 0 114.755 0 2.378 2.378 0 01-4.755 0zM16.445 17.044a1.178 1.178 0 100 2.356 1.178 1.178 0 000-2.355zm-2.378 1.178a2.378 2.378 0 114.755 0 2.378 2.378 0 01-4.755 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.251 9.333a.6.6 0 01.6-.6h1.61a2.6 2.6 0 012.413 1.635l.54 1.348a2.6 2.6 0 01.185.966v3.17a2.378 2.378 0 01-2.377 2.378v-1.2c.65 0 1.177-.528 1.177-1.178v-3.17a1.4 1.4 0 00-.1-.52l-.539-1.349a1.4 1.4 0 00-1.3-.88h-1.609a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.918 4.593a.6.6 0 01.6-.6h3.334a2.6 2.6 0 012.6 2.6v5.481a2.6 2.6 0 01-2.6 2.6H6.37a1.178 1.178 0 100 2.356v1.2a2.378 2.378 0 010-4.756h7.482a1.4 1.4 0 001.4-1.4V6.593a1.4 1.4 0 00-1.4-1.4h-3.334a.6.6 0 01-.6-.6zM9.326 17.63a.6.6 0 01.6-.6h4.74a.6.6 0 110 1.2h-4.74a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.148 4.6a3.548 3.548 0 100 7.096 3.548 3.548 0 000-7.096zM3.4 8.148a4.748 4.748 0 119.496 0 4.748 4.748 0 01-9.496 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.149 5.77a.6.6 0 01.6.6v1.178h1.178a.6.6 0 110 1.2H8.249a.7.7 0 01-.7-.7V6.37a.6.6 0 01.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
