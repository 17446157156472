import ApiKeyUser from './ApiKeyUser'

class ApiKey {
  constructor() {
    // this.projectId = ''
    this.listProjectsId = []
    this.roleId = null
    this.userApiKey = new ApiKeyUser()
  }

  fromData(data, toGet = false) {
    // this.projectId = data.projectId
    this.roleId = data.roleId ? data.roleId : null
    if (toGet) {
      this.listProjectsId = data.listProjectsId
    } else {
      // this.roleId = data.roleId.id
      this.listProjectsId = data.listProjectsId?.map(s => s.id)
    }

    const userApiKey = new ApiKeyUser()
    userApiKey.fromData(data.userApiKey, toGet)
    this.userApiKey = userApiKey
  }
}

export default ApiKey
