// import Video from '@/models/videos/video'

export default {
  SET_VIDEOTAGS: (state, v) => { state.videoTags = v },
  SET_VIDEOTITLES: (state, v) => { state.videoTitles = v },
  SET_VIDEOAUTHORS: (state, v) => { state.videoAuthors = v },
  SET_VIDEOSOURCE: (state, v) => { state.videoSource = v },
  SET_PROJECTDOMAINS: (state, v) => { state.projectDomains = v },
  SET_ORGANIZATIONUSERS: (state, v) => { state.organizationUsers = v },
  UPDATE_FILTER: (state, { type, search }) => {
    state.filters[type].params.search = search
  },
  CLEAR_VIDEOTAGS: state => { state.videoTags = [] },
  CLEAR_VIDEOTITLES: state => { state.videoTitles = [] },
  CLEAR_VIDEOAUTHORS: state => { state.videoAuthors = [] },
  CLEAR_VIDEOSOURCE: state => { state.videoSource = [] },
  CLEAR_PROJECTDOMAINS: state => { state.projectDomains = [] },
  CLEAR_ORGANIZATIONUSERS: state => { state.organizationUsers = [] },
}
