<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.08316 37.5862L36.1659 9.31907C37.0544 8.45552 38.4747 8.47572 39.3383 9.36419L47.0839 17.3334C47.9475 18.2218 47.9273 19.6421 47.0388 20.5057L17.956 48.7728C14.8669 51.7753 9.92875 51.705 6.92629 48.6159C3.92383 45.5268 3.99406 40.5886 7.08316 37.5862Z"
      fill="#F6F8F9"
      stroke="black"
      stroke-width="1.4"
    />
    <line
      x1="11.0215"
      y1="34.1769"
      x2="21.4762"
      y2="44.9333"
      stroke="black"
      stroke-width="1.4"
    />
    <line
      x1="19.626"
      y1="25.8134"
      x2="30.0807"
      y2="36.5698"
      stroke="black"
      stroke-width="1.4"
    />
    <line
      x1="28.2315"
      y1="17.4496"
      x2="38.6861"
      y2="28.206"
      stroke="black"
      stroke-width="1.4"
    />
    <path
      d="M4.2 44.2656L4.2 3.70902C4.2 2.47003 5.2044 1.46563 6.4434 1.46563H17.5566C18.7956 1.46563 19.8 2.47003 19.8 3.70902L19.8 44.2656C19.8 48.5734 16.3078 52.0656 12 52.0656C7.69218 52.0656 4.2 48.5734 4.2 44.2656Z"
      fill="#F6F8F9"
      stroke="black"
      stroke-width="1.4"
    />
    <line
      x1="4.5"
      y1="39.0656"
      x2="19.5"
      y2="39.0656"
      stroke="black"
      stroke-width="1.4"
    />
    <line
      x1="4.5"
      y1="27.0656"
      x2="19.5"
      y2="27.0656"
      stroke="black"
      stroke-width="1.4"
    />
    <line
      x1="4.5"
      y1="15.0656"
      x2="19.5"
      y2="15.0656"
      stroke="black"
      stroke-width="1.4"
    />
    <path
      d="M12 36.4656H52.5566C53.7956 36.4656 54.8 37.47 54.8 38.709V49.8222C54.8 51.0612 53.7956 52.0656 52.5566 52.0656H12C7.69218 52.0656 4.2 48.5734 4.2 44.2656C4.2 39.9578 7.69218 36.4656 12 36.4656Z"
      fill="#F6F8F9"
      stroke="black"
      stroke-width="1.4"
    />
    <line
      x1="17.2"
      y1="36.7656"
      x2="17.2"
      y2="51.7656"
      stroke="black"
      stroke-width="1.4"
    />
    <line
      x1="29.2"
      y1="36.7656"
      x2="29.2"
      y2="51.7656"
      stroke="black"
      stroke-width="1.4"
    />
    <line
      x1="41.2"
      y1="36.7656"
      x2="41.2"
      y2="51.7656"
      stroke="black"
      stroke-width="1.4"
    />
    <circle
      cx="11"
      cy="44.2656"
      r="2.8"
      fill="#F6F8F9"
      stroke="black"
      stroke-width="1.4"
    />
  </svg>
</template>
