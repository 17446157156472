<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.4 11.704a8.304 8.304 0 0116.607 0v4.111a3.6 3.6 0 01-3.6 3.6h-3.021a1.786 1.786 0 110-1.2h3.021a2.4 2.4 0 002.11-1.256 2.082 2.082 0 01-2.673-1.996v-.415a3.445 3.445 0 012.941-3.408 7.104 7.104 0 00-14.163 0 3.445 3.445 0 012.94 3.408v.415a2.081 2.081 0 11-4.162 0v-3.26z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
