/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {

  // getVideo({ commit, state }, id) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`/api/projects/${project.state.activeProjectId}/videos/${id}`)
  //       .then(async response => {
  //         commit('SET_VIDEO', response.data.result)
  //         resolve(response)
  //       })
  //       .catch(error => { reject(error) })
  //   })
  // },
  postFolders({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/folders`, data)
        .then(response => {
          commit('ADD_FOLDER', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putFolders({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/folders`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getFolders({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/folders`)
        .then(response => {
          commit('SET_FOLDERS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getFoldersSelectList({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/folders/select-list`)
        .then(response => {
          commit('SET_FOLDER_SELECTLIST', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getFolderById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/folders/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteFolderById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/projects/${project.state.activeProjectId}/folders/${id}`)
        .then(response => {
          commit('REMOVE_FOLDER_FROM_FOLDERS', parseInt(id, 10))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getFoldersChildren({ commit, state }, folderId) {
    commit('LOADING_FOLDERS_CHILDREN', true)
    if (!folderId) folderId = 0
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/folders/${folderId}/children`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => {
          commit('LOADING_FOLDERS_CHILDREN', false)
        })
    })
  },
  putFolderVideos({ commit, state, rootStore }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/folders/${data.folderId}/videos`, {
          videoIdsList: data.videoIds,
        })
        .then(response => {
          // videos.mutations.REMOVE_VIDEOS_BY_ID(videos, data.videoIds)
          commit('videosModule/REMOVE_VIDEOS_BY_ID', data.videoIds, { root: true })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putFolderFolders({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/folders/${data.folderId}?parentId=${data.parentId}`)
        .then(response => {
          commit('REMOVE_FOLDER_FROM_FOLDERS', data.folderId)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
