<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.271 7.182a4.09 4.09 0 00-1.968 7.678 2.636 2.636 0 002.26 3.995h4.073a.273.273 0 01-.001.545h-1.454a.6.6 0 100 1.2h1.454a1.473 1.473 0 00.001-2.946h-4.074a1.436 1.436 0 01-.102-2.869c.117.327.281.631.484.905a.6.6 0 00.964-.715 2.31 2.31 0 114.165-1.393h-1.438a.6.6 0 000 1.2h2.91a1.436 1.436 0 010 2.872.6.6 0 000 1.2 2.636 2.636 0 100-5.272h-.273a3.51 3.51 0 00-3.075-3.465 4.092 4.092 0 00-3.926-2.935zM3.69 11.273a.6.6 0 01.6-.6h1.164a.6.6 0 010 1.2H4.29a.6.6 0 01-.6-.6zM4.705 15.064a.6.6 0 01.22-.82l1.008-.582a.6.6 0 11.6 1.04l-1.008.581a.6.6 0 01-.82-.22zM15.79 8.664a.6.6 0 01.22-.82l1.008-.582a.6.6 0 11.6 1.04l-1.008.581a.6.6 0 01-.82-.22zM13.88 6.754a.6.6 0 01-.219-.82l.582-1.008a.6.6 0 111.04.6L14.7 6.534a.6.6 0 01-.82.22zM11.272 6.055a.6.6 0 01-.6-.6V4.29a.6.6 0 011.2 0v1.164a.6.6 0 01-.6.6zM8.663 6.754a.6.6 0 01-.82-.22l-.581-1.008a.6.6 0 011.039-.6l.582 1.008a.6.6 0 01-.22.82zM6.753 8.664a.6.6 0 01-.82.22L4.927 8.3a.6.6 0 01.6-1.039l1.008.582a.6.6 0 01.22.82z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
