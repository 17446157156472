import CustomParameter from './CustomParameters'
import Chapters from './Chapters'

class Audio {
  constructor() {
    this.publicId = ''
    this.title = ''
    this.description = ''
    this.canEmbed = true
    this.source = ''
    this.link = ''
    this.author = ''
    this.thumbnail = ''
    this.insertDate = ''
    this.publishStartDate = null
    this.publishEndDate = null
    this.customParameters = []
    this.publishStatusName = ''
    this.chapterState = false
    this.duration = null
    this.chapters = []
  }

  fromData(data) {
    this.publicId = data.publicId || ''
    this.title = data.title || ''
    this.description = data.description || ''
    this.canEmbed = data.canEmbed || false
    this.source = data.source || ''
    this.link = data.link || ''
    this.author = data.author || ''
    this.publishStatusName = data.publishStatusName || ''
    this.thumbnail = data.thumbnail || ''
    this.insertDate = data.insertDate || ''
    this.publishStartDate = data.publishStartDate || null
    this.publishEndDate = data.publishEndDate || null
    this.chapterState = data.chapterState || false
    this.duration = data.duration || null

    this.chapters = data.chapters.length > 0 ? data.chapters.map(cp => {
      const ncp = new Chapters()
      ncp.fromData(cp)
      return ncp
    }) : []
    this.customParameters = data.customParameters.length > 0 ? data.customParameters.map(cp => {
      const ncp = new CustomParameter()
      ncp.fromData(cp)
      return ncp
    }) : []
  }
}

export default Audio
