<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.598 4a.6.6 0 10-1.2 0v1.143a.6.6 0 101.2 0V4zM7.398 12a4.6 4.6 0 119.2 0 4.6 4.6 0 01-9.2 0zM11.998 18.257a.6.6 0 01.6.6V20a.6.6 0 11-1.2 0v-1.143a.6.6 0 01.6-.6zM8.869 17.419a.6.6 0 01.22.82l-.572.99a.6.6 0 01-1.04-.6l.572-.99a.6.6 0 01.82-.22zM16.52 5.372a.6.6 0 10-1.04-.6l-.571.99a.6.6 0 101.039.6l.571-.99zM6.58 15.129a.6.6 0 01-.22.82l-.99.57a.6.6 0 01-.6-1.039l.99-.571a.6.6 0 01.82.22zM19.23 8.52a.6.6 0 00-.6-1.04l-.989.572a.6.6 0 10.6 1.039l.99-.571zM5.743 12a.6.6 0 01-.6.6H4a.6.6 0 110-1.2h1.143a.6.6 0 01.6.6zM20 12.6a.6.6 0 000-1.2h-1.143a.6.6 0 000 1.2H20zM6.58 8.871a.6.6 0 01-.82.22l-.99-.571a.6.6 0 01.6-1.04l.99.572a.6.6 0 01.22.82zM18.626 16.52a.6.6 0 10.6-1.04l-.99-.571a.6.6 0 10-.6 1.04l.99.57zM8.874 6.581a.6.6 0 01-.82-.22l-.57-.99a.6.6 0 011.038-.6l.572.99a.6.6 0 01-.22.82zM15.48 19.228a.6.6 0 001.04-.6l-.571-.99a.6.6 0 00-1.04.6l.572.99z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
