<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.252 8.542l.851.851a9.11 9.11 0 00-.808.767 10.27 10.27 0 00-1.633 2.34c.068.137.168.328.301.555a10.27 10.27 0 001.332 1.785C7.505 16.134 9.357 17.4 12 17.4a7.49 7.49 0 002.643-.467l.92.92A8.617 8.617 0 0112 18.6c-3.072 0-5.22-1.484-6.581-2.94a11.471 11.471 0 01-1.49-1.996 9.945 9.945 0 01-.473-.91l-.007-.016-.002-.005v-.002h-.001a.6.6 0 010-.461L4 12.5c-.554-.23-.554-.232-.554-.232l.001-.001.002-.005.007-.016A4.276 4.276 0 013.571 12c.08-.163.198-.391.358-.664.32-.544.81-1.269 1.49-1.996.25-.268.528-.537.833-.798zm11.548 7.87c.284-.246.545-.5.781-.752.68-.727 1.17-1.452 1.49-1.996a9.993 9.993 0 00.449-.856l.024-.054.007-.016.002-.005v-.002L20 12.5l.554.23a.602.602 0 000-.46l-.001-.003-.002-.005-.007-.016A3.973 3.973 0 0020.43 12a9.965 9.965 0 00-.36-.664c-.32-.544-.81-1.269-1.489-1.996C17.22 7.884 15.071 6.4 12 6.4c-1.336 0-2.497.28-3.495.718l.923.923A7.501 7.501 0 0112 7.6c2.643 0 4.494 1.266 5.705 2.56a10.268 10.268 0 011.633 2.34 10.268 10.268 0 01-1.634 2.34c-.228.245-.48.488-.755.722l.851.85zM20 12.5l.554-.23-.554.23zm-5.252.86c.09-.27.138-.559.138-.86 0-1.568-1.314-2.8-2.886-2.8-.277 0-.547.038-.803.11l1.118 1.117c.718.128 1.258.685 1.355 1.356l1.078 1.078zm-2.95.729l1.08 1.079A2.96 2.96 0 0112 15.3c-1.572 0-2.886-1.232-2.886-2.8 0-.328.058-.642.163-.933l1.041 1.041c.054.754.667 1.39 1.48 1.48zM3.424 3.876a.6.6 0 01.849 0L19.688 19.29a.6.6 0 01-.849.848L3.424 4.724a.6.6 0 010-.848z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
