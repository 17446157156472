<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.4 7A2.6 2.6 0 015 4.4h11.273a2.6 2.6 0 012.6 2.6v6.182a2.6 2.6 0 01-2.6 2.6H5a2.6 2.6 0 01-2.6-2.6V7zM5 5.6A1.4 1.4 0 003.6 7v6.182a1.4 1.4 0 001.4 1.4h11.273a1.4 1.4 0 001.4-1.4V7a1.4 1.4 0 00-1.4-1.4H5z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.4 6.946h16.473v3.745H2.4V6.945zm1.2 1.2V9.49h14.073V8.146H3.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.99 8.818a.6.6 0 01.6-.6h.228a2.6 2.6 0 012.6 2.6V16a3.6 3.6 0 01-3.6 3.6H7.545a2.6 2.6 0 01-2.6-2.6v-1.818a.6.6 0 011.2 0V17a1.4 1.4 0 001.4 1.4h10.273a2.4 2.4 0 002.4-2.4v-5.182a1.4 1.4 0 00-1.4-1.4h-.227a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
