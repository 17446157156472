/* eslint-disable no-unused-vars */
export default [

  // Flags Routing
  {
    path: '/:projectId/flags',
    name: 'flags',
    component: () => import('@/views/properties/flags/Flags.vue'),
    meta: {
      titleText: 'Flags',
      parentText: 'Dashboard',
      contentClass: 'flags',
      navActiveLink: 'flags',
      gjIcon: 'Flag',
      action: 'read',
      resource: 'flag',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Flags',
          to: 'flags',
          active: true,
        },
      ],
    },
  },

  // Geoblocking Rules Routing
  {
    path: '/:projectId/geo-blocking',
    name: 'geo-blocking',
    component: () => import('@/views/properties/geo-blocking/GeoblockingRules.vue'),
    meta: {
      titleText: 'Geo Blocking',
      parentText: 'Dashboard',
      contentClass: 'geo-blocking',
      navActiveLink: 'geo-blocking',
      gjIcon: 'Settings',
      action: 'read',
      resource: 'flag',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Geo Blocking',
          to: 'geo-blocking',
          active: true,
        },
      ],
    },
  },

  // Webhooks Routing
  {
    path: '/:projectId/webhooks',
    name: 'webhooks',
    component: () => import('@/views/account/webhooks/Webhooks.vue'),
    meta: {
      titleText: 'Webhooks',
      parentText: 'Dashboard',
      contentClass: 'webhooks',
      navActiveLink: 'webhooks',
      gjIcon: 'Settings',
      action: 'read',
      resource: 'project',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Webhooks',
          to: 'webhooks',
          active: true,
        },
      ],
    },
  },
]
