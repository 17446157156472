<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 7.25A1.6 1.6 0 015 5.65h14a1.6 1.6 0 011.6 1.6v5a1.6 1.6 0 01-1.6 1.6H5a1.6 1.6 0 01-1.6-1.6v-5zm1.6-.4a.4.4 0 00-.4.4v5c0 .22.18.4.4.4h14a.4.4 0 00.4-.4v-5a.4.4 0 00-.4-.4H5zM3.4 15.75a.6.6 0 01.6-.6h10a.6.6 0 010 1.2H4a.6.6 0 01-.6-.6zM3.4 17.75a.6.6 0 01.6-.6h5a.6.6 0 010 1.2H4a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>
