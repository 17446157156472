import Player from '@/models/players/audioPlayer'

export default {
  ADD_PLAYER: (state, data) => state.players.unshift(data),
  // SET_PLAYER: (state, data) => { state.player = data },
  SET_PLAYERS: (state, data) => { state.players = data },
  SET_DEFAULT_PLAYERS: (state, data) => { state.defaultPlayers = data || [] },
  SET_PLAYERS_SELECTLIST: (state, data) => { state.playersSelectList = data.map(p => { p.id = p.value; return p }) },
  SET_PLAYER: (state, data) => {
    const player = new Player()
    player.fromData(data)
    state.player = player
  },
  SET_PLAYER_RELEASECHANNELS: (state, data) => { state.player.releaseChannels = data },
  SET_PLAYER_RELEASECHANNEL: (state, data) => { state.player.releaseChannel = data },
  SET_PLAYER_PLAYERSIZETYPE: (state, data) => { state.player.playerSizeTypeId = data.id; state.player.playerSizeType = data },
  SET_PLAYER_PRELOADOPTION: (state, data) => { state.player.preloadOptionId = data.id; state.player.preloadOption = data },
  SET_PLAYER_DEFAULTBANDWIDTHESTIMATEOPTION: (state, data) => { state.player.defaultBandwidthEstimateOptionId = data.id; state.player.defaultBandwidthEstimateOption = data },
  SET_PLAYER_RESPONSIVESIZE: (state, data) => {
    state.player.responsiveSize = data
  },
  SET_PLAYER_LOGOPOSITIONOPTION: (state, data) => { state.player.logoPositionOptionId = data.id; state.player.logoPositionOption = data },
  SET_PLAYER_FLOATPOSITIONOPTION: (state, data) => { state.player.floatPositionOptionId = data.id; state.player.floatPositionOption = data },
  // SET_PLAYER_: (state, data) => { state.player = data },
  SET_PLAYER_BACKGROUND_DEFAULT_COLORS: (state, data) => {
    state.player.backgroundPrimaryColor = `#${data['audioplayer.color.backgroundprimarycolor']}`
    state.player.backgroundSecondaryColor = `#${data['audioplayer.color.backgroundsecondarycolor']}`
    state.player.backgroundBorderColor = `#${data['audioplayer.color.backgroundbordercolor']}`
  },
  SET_PLAYER_MENU_DEFAULT_COLORS: (state, data) => {
    state.player.menuBackgroundDefaultColor = `#${data['audioplayer.color.menubackgrounddefaultcolor']}`
    state.player.menuTextDefaultColor = `#${data['audioplayer.color.menutextdefaultcolor']}`
    state.player.menuToggleDefaultColor = `#${data['audioplayer.color.menutoggledefaultcolor']}`
    state.player.menuToggleOnColor = `#${data['audioplayer.color.menutoggleoncolor']}`
    state.player.menuToggleOffColor = `#${data['audioplayer.color.menutoggleoffcolor']}`
  },
  SET_PLAYER_ELEMENTS_DEFAULT_COLORS: (state, data) => {
    state.player.elementsPrimaryColor = `#${data['audioplayer.color.elementsprimarycolor']}`
    state.player.elementsSecondaryColor = `#${data['audioplayer.color.elementssecondarycolor']}`
    state.player.elementsPlaylistColor = `#${data['audioplayer.color.elementsplaylistcolor']}`
    state.player.elementsPlaybackButtonColor = `#${data['audioplayer.color.elementsplaybackbuttoncolor']}`
    state.player.elementsVolumeButtonColor = `#${data['audioplayer.color.elementsvolumebuttoncolor']}`
    state.player.elementsSkipButtonColor = `#${data['audioplayer.color.elementsskipbuttoncolor']}`
    state.player.elementsSettingsButtonColor = `#${data['audioplayer.color.elementssettinsbuttoncolor']}`
    state.player.elementsNextPrevButtonColor = `#${data['audioplayer.color.elementsnextprevbuttoncolor']}`
    state.player.elementsListButtonColor = `#${data['audioplayer.color.elementsplaylistbuttoncolor']}`
    state.player.elementsTimeInfoColor = `#${data['audioplayer.color.elementstimeinfocolor']}`
    state.player.elementsTitleColor = `#${data['audioplayer.color.elementstitlecolor']}`
    state.player.elementsAuthorColor = `#${data['audioplayer.color.elementsauthorcolor']}`
    state.player.elementsDescriptionColor = `#${data['audioplayer.color.elementsdescriptioncolor']}`
    state.player.elementsDefaultColor = `#${data['audioplayer.color.elementsdefaultcolor']}`
    state.player.elementsDividersColor = `#${data['audioplayer.color.elementsdividerscolor']}`
    state.player.elementsSliderRailColor = `#${data['audioplayer.color.elementssliderrailcolor']}`
    state.player.elementsSliderProgressBarColor = `#${data['audioplayer.color.elementssliderprogressbarcolor']}`
    state.player.elementsDraggerColor = `#${data['audioplayer.color.elementsdraggercolor']}`
    state.player.elementsItemColor = `#${data['audioplayer.color.elementsitemsprogresscolor']}`
  },
  SET_PLAYER_DEFAULT_COLORS: (state, data) => {
    // Object.keys(data).forEach(x => {
    //   state.player[x] = `#${data[x]}`
    // })
    state.player.backgroundPrimaryColor = `#${data['audioplayer.color.backgroundprimarycolor']}`
    state.player.backgroundSecondaryColor = `#${data['audioplayer.color.backgroundsecondarycolor']}`
    state.player.backgroundBorderColor = `#${data['audioplayer.color.backgroundbordercolor']}`
    state.player.menuBackgroundDefaultColor = `#${data['audioplayer.color.menubackgrounddefaultcolor']}`
    state.player.menuTextDefaultColor = `#${data['audioplayer.color.menutextdefaultcolor']}`
    state.player.menuToggleDefaultColor = `#${data['audioplayer.color.menutoggledefaultcolor']}`
    state.player.menuToggleOnColor = `#${data['audioplayer.color.menutoggleoncolor']}`
    state.player.menuToggleOffColor = `#${data['audioplayer.color.menutoggleoffcolor']}`
    state.player.elementsPrimaryColor = `#${data['audioplayer.color.elementsprimarycolor']}`
    state.player.elementsSecondaryColor = `#${data['audioplayer.color.elementssecondarycolor']}`
    state.player.elementsPlaylistColor = `#${data['audioplayer.color.elementsplaylistcolor']}`
    state.player.elementsPlaybackButtonColor = `#${data['audioplayer.color.elementsplaybackbuttoncolor']}`
    state.player.elementsVolumeButtonColor = `#${data['audioplayer.color.elementsvolumebuttoncolor']}`
    state.player.elementsSkipButtonColor = `#${data['audioplayer.color.elementsskipbuttoncolor']}`
    state.player.elementsSettingsButtonColor = `#${data['audioplayer.color.elementssettinsbuttoncolor']}`
    state.player.elementsNextPrevButtonColor = `#${data['audioplayer.color.elementsnextprevbuttoncolor']}`
    state.player.elementsListButtonColor = `#${data['audioplayer.color.elementsplaylistbuttoncolor']}`
    state.player.elementsTimeInfoColor = `#${data['audioplayer.color.elementstimeinfocolor']}`
    state.player.elementsTitleColor = `#${data['audioplayer.color.elementstitlecolor']}`
    state.player.elementsAuthorColor = `#${data['audioplayer.color.elementsauthorcolor']}`
    state.player.elementsDescriptionColor = `#${data['audioplayer.color.elementsdescriptioncolor']}`
    state.player.elementsDefaultColor = `#${data['audioplayer.color.elementsdefaultcolor']}`
    state.player.elementsDividersColor = `#${data['audioplayer.color.elementsdividerscolor']}`
    state.player.elementsSliderRailColor = `#${data['audioplayer.color.elementssliderrailcolor']}`
    state.player.elementsSliderProgressBarColor = `#${data['audioplayer.color.elementssliderprogressbarcolor']}`
    state.player.elementsDraggerColor = `#${data['audioplayer.color.elementsdraggercolor']}`
    state.player.elementsItemColor = `#${data['audioplayer.color.elementsitemsprogresscolor']}`
  },
  SET_PLAYER_LOCALIZATION_DEFAULT: (state, data) => {
    Object.keys(data).forEach(x => {
      const y = x.split('.')[1]
      state.player[y] = data[x]
    })
  },
  SET_PAGINATION: (state, data) => {
    const { items, ...p } = data
    state.pagination = p
  },
  SET_OPTIONS: (state, data) => { state.options = data.data.result },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  SCRIPT_IS_LOADED: (state, data) => { state.playerLoaded = data },
  CLEAR_PLAYERS: state => { state.players = [] },
  CLEAR_PLAYERS_SELECTLIST: state => { state.playersSelectList = [] },
  CLEAR_PLAYER: state => { state.player = new Player() },
  UPDATE_PLAYER: (state, data) => {
    const playerIndex = state.players.findIndex(p => p.id === data.id)
    Object.assign(state.players[playerIndex], data)
  },
  UPDATE_PLAYER_PROP: (state, { key, value }) => {
    state.player[key] = value
  },
  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
}
