<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 7.5A2.5 2.5 0 014.5 5h15A2.5 2.5 0 0122 7.5v9a2.5 2.5 0 01-2.5 2.5h-15A2.5 2.5 0 012 16.5v-9zM4.5 6A1.5 1.5 0 003 7.5v9A1.5 1.5 0 004.5 18h15a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0019.5 6h-15z"
    />
    <path
      d="M6.505 11.309c-.442-.565-1.103-1.012-1.912-1.294a.136.136 0 01-.09-.152.14.14 0 01.138-.113l1.326.002h.006c.194.008.461.054.546.361l.002.007.235 1.078a.136.136 0 01-.08.152.145.145 0 01-.17-.041z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.216 14.25a.142.142 0 01-.118-.062.134.134 0 01-.011-.13l1.788-3.923c.118-.26.316-.372.661-.372h.863a.14.14 0 01.138.107l.96 4.214a.134.134 0 01-.028.115.143.143 0 01-.11.051h-.883a.14.14 0 01-.138-.107l-.002-.012-.127-.553-.888-.001h-.019l-.711-.001c-.027.065-.106.268-.23.586a.141.141 0 01-.133.088h-1.012zm2.496-2.889l-.086-.388-.672 1.684h1.05l-.293-1.296z"
    />
    <path
      d="M11.661 14.044c.333.113.798.183 1.244.188h.002c1.279 0 2.11-.58 2.12-1.48.005-.493-.32-.867-1.02-1.173-.428-.202-.69-.335-.688-.54 0-.184.24-.37.701-.371h.037c.383 0 .653.084.823.147.04.015.084.011.12-.009a.137.137 0 00.07-.096l.114-.65a.136.136 0 00-.096-.153 3.322 3.322 0 00-.975-.143c-1.205 0-2.052.587-2.058 1.428-.008.623.605.97 1.067 1.177.473.212.633.347.63.537-.003.29-.38.422-.728.422-.496 0-.763-.072-1.141-.225a.144.144 0 00-.122.007.137.137 0 00-.071.097l-.121.685a.136.136 0 00.092.152zM9.487 14.2c.027.03.066.048.108.048h.976a.14.14 0 00.14-.114l.742-4.216a.134.134 0 00-.031-.111.143.143 0 00-.108-.049h-.978a.14.14 0 00-.139.114l-.741 4.216c-.007.04.004.08.03.112zM5.419 10.725a.145.145 0 01.165.004c.712.53 1.222 1.197 1.411 1.624a.125.125 0 01.007.018l.13.453L8.332 9.85a.141.141 0 01.132-.087h1.027c.047 0 .092.023.118.061.026.039.03.087.012.13L7.754 14.16a.141.141 0 01-.13.082l-1.137.002a.14.14 0 01-.136-.098l-.988-3.271a.135.135 0 01.056-.151z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
