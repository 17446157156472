import state from './moduleSocialDistributionState'
import mutations from './moduleSocialDistributionMutations'
import actions from './moduleSocialDistributionActions'
import getters from './moduleSocialDistributionGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
