<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.8125 4.5C2.8125 3.56802 3.56802 2.8125 4.5 2.8125H13.5C14.432 2.8125 15.1875 3.56802 15.1875 4.5V13.5C15.1875 14.432 14.432 15.1875 13.5 15.1875H4.5C3.56802 15.1875 2.8125 14.432 2.8125 13.5V12.75C2.8125 12.4393 2.56066 12.1875 2.25 12.1875C1.93934 12.1875 1.6875 12.4393 1.6875 12.75V13.5C1.6875 15.0533 2.9467 16.3125 4.5 16.3125H13.5C15.0533 16.3125 16.3125 15.0533 16.3125 13.5V4.5C16.3125 2.9467 15.0533 1.6875 13.5 1.6875H4.5C2.9467 1.6875 1.6875 2.9467 1.6875 4.5V5.25C1.6875 5.56066 1.93934 5.8125 2.25 5.8125C2.56066 5.8125 2.8125 5.56066 2.8125 5.25V4.5Z"
      fill="#36435E"
    />
    <path
      d="M9.39775 4.85225C9.17808 4.63258 8.82193 4.63258 8.60226 4.85225C8.38259 5.07192 8.38259 5.42808 8.60226 5.64775L11.392 8.4375H2.25001C1.93935 8.4375 1.68751 8.68934 1.68751 9C1.68751 9.31066 1.93935 9.5625 2.25001 9.5625H11.392L8.60226 12.3523C8.38259 12.5719 8.38259 12.9281 8.60226 13.1477C8.82193 13.3674 9.17808 13.3674 9.39775 13.1477L13.5455 9L9.39775 4.85225Z"
      fill="#36435E"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
