<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.58 9.857a2.6 2.6 0 010 4.286l-6.107 4.197C8.748 19.526 6.4 18.291 6.4 16.198V7.802c0-2.093 2.348-3.328 4.073-2.142l6.106 4.197zm-.68 3.297a1.4 1.4 0 000-2.308L9.792 6.648C8.864 6.01 7.6 6.675 7.6 7.802v8.396c0 1.127 1.264 1.792 2.193 1.154l6.106-4.198z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
