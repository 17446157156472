import encode from './encode'
import { actions, state } from './state/store'
import reencode from './reencode'

async function multipartComplete(file, requestKey, videoId, isForReencode) {
  file.status = 'multipartComplete'

  state.eventBus.$emit(state.eventBusName, state.uploads)
  actions.multipartCompleteAction({
    requestKey,
    parts: file.uploadedParts,
  })
    .then(async () => {
      if (!isForReencode) {
        encode(file, file.requestKey)
      } else {
        reencode(videoId, file, file.requestKey)
      }
    })
    .catch(() => {
      file.status = 'failed'
      file.failedStatus = 'complete'

      state.eventBus.$emit(state.eventBusName, state.uploads)
    })
}

export default multipartComplete
