<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 8 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.6464 3.64645L0.853504 0.853553C0.538521 0.538571 0.761605 0 1.20706 0H6.79284C7.2383 0 7.46138 0.53857 7.1464 0.853553L4.3535 3.64645C4.15824 3.84171 3.84166 3.84171 3.6464 3.64645Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
