<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.875 15.8125C6.875 16.1922 7.1828 16.5 7.5625 16.5C7.9422 16.5 8.25 16.1922 8.25 15.8125L8.25 6.1875C8.25 5.8078 7.9422 5.5 7.5625 5.5C7.1828 5.5 6.875 5.8078 6.875 6.1875V15.8125Z"
      fill="currentColor"
    />
    <path
      d="M16.0695 7.81947C16.338 7.55098 16.338 7.11568 16.0695 6.8472C15.801 6.57871 15.3657 6.57871 15.0972 6.8472L11.7546 10.1898C11.3071 10.6373 11.3071 11.3628 11.7546 11.8102L15.0972 15.1528C15.3657 15.4213 15.801 15.4213 16.0695 15.1528C16.338 14.8843 16.338 14.449 16.0695 14.1805L12.8889 11L16.0695 7.81947Z"
      fill="currentColor"
    />
  </svg>
</template>
