export default {
  isLoading: true,
  isUploading: false,
  geoBlockingRules: [],
  geoBlockingRule: null,
  geoBlockingRuleTypes: [],

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

  filters: {
    params: {
      geoBlockingRuleTypeId: null,
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },
}
