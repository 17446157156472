<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 12.5l.554-.23-.001-.003-.002-.005-.007-.016-.024-.054a9.965 9.965 0 00-.45-.856c-.32-.544-.81-1.269-1.489-1.996C17.22 7.884 15.071 6.4 12 6.4c-3.072 0-5.22 1.484-6.581 2.94a11.47 11.47 0 00-1.49 1.996 9.918 9.918 0 00-.473.91l-.007.016-.002.005v.002L4 12.5l-.554-.23a.6.6 0 000 .46L4 12.5l-.554.23v.002l.001.001.002.005.007.016.024.054a9.945 9.945 0 00.45.856c.32.544.81 1.269 1.489 1.996 1.361 1.456 3.51 2.94 6.58 2.94 3.072 0 5.22-1.484 6.582-2.94.68-.727 1.17-1.452 1.49-1.996a9.993 9.993 0 00.449-.856l.024-.054.007-.016.002-.005v-.002L20 12.5zm0 0l.554.23a.602.602 0 000-.46L20 12.5zm-10.886 0c0-1.568 1.314-2.8 2.886-2.8s2.886 1.232 2.886 2.8c0 1.568-1.314 2.8-2.886 2.8s-2.886-1.232-2.886-2.8zM12 10.9c-.953 0-1.686.738-1.686 1.6 0 .862.733 1.6 1.686 1.6s1.686-.738 1.686-1.6c0-.862-.733-1.6-1.686-1.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
