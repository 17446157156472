export default {
  getIsLoading: state => state.isLoading,
  getOrganizations: state => state.organizations,
  getPinnedOrganizations: state => state.pinnedOrganizations,
  getUnpinnedOrganizations: state => state.unpinnedOrganizations,
  getActiveOrganizationId: state => state.activeOrganizationId,
  getActiveOrganization: state => state.activeOrganization,
  getActiveOrganizationPage: state => state.activeOrganizationPage,
  // getOptions: state => state.project.options,
  getOrganizationsOfUser: state => state.projectsOfUser,
  getCountries: state => state.countries,
  getVideoCatalogUrl: state => `${process.env.VUE_APP_API_BASE_URL}api/organizations/${state.activeOrganizationId}/videos/catalog`,
}
