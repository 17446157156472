<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.4 11.714a2.6 2.6 0 012.6-2.6h8.57a2.6 2.6 0 012.6 2.6V18a2.6 2.6 0 01-2.6 2.6H8A2.6 2.6 0 015.4 18v-6.286zm2.6-1.4a1.4 1.4 0 00-1.4 1.4V18A1.4 1.4 0 008 19.4h8.57a1.4 1.4 0 001.4-1.4v-6.286a1.4 1.4 0 00-1.4-1.4H8z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.285 4.6a3.4 3.4 0 00-3.4 3.4v1.714a.6.6 0 11-1.2 0V8a4.6 4.6 0 119.2 0v1.714a.6.6 0 11-1.2 0V8a3.4 3.4 0 00-3.4-3.4zM12.285 12.543a.6.6 0 01.6.6v3.428a.6.6 0 11-1.2 0v-3.428a.6.6 0 01.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
