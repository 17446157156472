import state from './moduleAdsProgrammabilityState'
import mutations from './moduleAdsProgrammabilityMutations'
import actions from './moduleAdsProgrammabilityActions'
import getters from './moduleAdsProgrammabilityGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
