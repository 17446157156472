import CustomParameter from './CustomParameters'

class Podcast {
  constructor() {
    this.publicId = ''
    this.title = ''
    this.description = ''
    this.link = ''
    this.author = ''
    this.ownerName = ''
    this.ownerEmail = ''
    this.thumbnailUrl = ''
    this.thumbnailPath = ''
    this.canEmbed = true
    this.copyright = ''
    this.languageId = 1
    this.explicitOptionId = 1
    this.sortTypeId = null
    this.rssFeedUrl = ''
    this.categories = []
    this.customParameters = []
    this.customParameters.push(new CustomParameter())
  }

  fromData(data) {
    this.publicId = data.publicId ? data.publicId : ''
    this.title = data.title ? data.title : ''
    this.description = data.description ? data.description : ''
    this.link = data.link ? data.link : ''
    this.author = data.author ? data.author : ''
    this.copyright = data.copyright ? data.copyright : ''
    this.ownerName = data.ownerName ? data.ownerName : ''
    this.ownerEmail = data.ownerEmail ? data.ownerEmail : ''
    this.thumbnailUrl = data.thumbnailUrl ? data.thumbnailUrl : ''
    this.thumbnailPath = data.thumbnailPath ? data.thumbnailPath : ''
    this.canEmbed = data.canEmbed || false
    this.languageId = data.languageId ? data.languageId : null
    this.explicitOptionId = data.explicitOptionId ? data.explicitOptionId : 2
    this.rssFeedUrl = data.rssFeedUrl ? data.rssFeedUrl : null
    this.sortTypeId = data.sortTypeId ? data.sortTypeId : null
    this.categories = data.categories
      ? data.categories.map(c => ({
        ...c,
        subcategories: c?.subcategories.length > 0 ? c.subcategories : null,
      }))
      : []
    this.customParameters = data.customParameters && data.customParameters.length > 0
      ? data.customParameters.map(cp => {
        const ncp = new CustomParameter()
        ncp.fromData(cp)
        return ncp
      })
      : [new CustomParameter()]
  }
}

export default Podcast
