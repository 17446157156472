<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.886 12a.6.6 0 01-.6.6H9.714a.6.6 0 110-1.2h4.572a.6.6 0 01.6.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 12a8.6 8.6 0 1117.2 0 8.6 8.6 0 01-17.2 0zM12 4.6a7.4 7.4 0 100 14.8 7.4 7.4 0 000-14.8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
