export default {
  isLoading: true,
  isDropdownLoading: true,
  organizations: [],
  pinnedOrganizations: [],
  unpinnedOrganizations: [],
  organizationsOfUser: [],
  project: {
    // id: 1,
    name: '',
    // organizationId: 3,
  },

  // videoCatalogUrl: '',
  activeOrganizationId: '',
  activeOrganization: null,
  activeOrganizationPage: 1,

  countries: [],

  filters: {
    params: {
      page: 1,
      take: 5,
      search: '',
      order: 'asc',
    },
  },
  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },
}
