<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3333 9.00007C11.3333 10.5649 10.0648 11.8334 8.5 11.8334C6.93519 11.8334 5.66667 10.5649 5.66667 9.00007C5.66667 7.43526 6.93519 6.16674 8.5 6.16674C10.0648 6.16674 11.3333 7.43526 11.3333 9.00007ZM10.2708 9.00007C10.2708 9.97808 9.478 10.7709 8.5 10.7709C7.522 10.7709 6.72917 9.97808 6.72917 9.00007C6.72917 8.02207 7.522 7.22924 8.5 7.22924C9.478 7.22924 10.2708 8.02207 10.2708 9.00007Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.46801 2.49007C8.10981 2.13352 8.8902 2.13352 9.532 2.49007L13.782 4.85118C14.4566 5.22597 14.875 5.93704 14.875 6.70877V11.2914C14.875 12.0631 14.4566 12.7742 13.782 13.149L9.532 15.5101C8.8902 15.8666 8.10981 15.8666 7.46801 15.5101L3.21801 13.149C2.5434 12.7742 2.125 12.0631 2.125 11.2914V6.70877C2.125 5.93704 2.5434 5.22597 3.21801 4.85118L7.46801 2.49007ZM9.016 3.41886L13.266 5.77997C13.6033 5.96737 13.8125 6.3229 13.8125 6.70877V11.2914C13.8125 11.6773 13.6033 12.0328 13.266 12.2202L9.016 14.5813C8.6951 14.7596 8.30491 14.7596 7.98401 14.5813L3.73401 12.2202C3.3967 12.0328 3.1875 11.6773 3.1875 11.2914V6.70877C3.1875 6.3229 3.3967 5.96737 3.734 5.77998L7.98401 3.41886C8.30491 3.24059 8.6951 3.24059 9.016 3.41886Z"
      fill="currentColor"
    />
  </svg>
</template>
