/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {

  // ////////// //
  /* Flag CRUD */
  getFlags({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/flags`, state.filters)
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_FLAGS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  postFlag({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/flags`, data)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putFlags({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/flags`, data)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getFlagById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/flags/${id}`)
        .then(response => {
          commit('SET_CATEGORY', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteFlagById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${project.state.activeProjectId}/flags/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // ////////// //
  /* FlagCustomSetting CRUD */
  postFlagCustomSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/flags/custom-settings`, data)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putFlagCustomSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/flags/custom-settings`, data)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getFlagCustomSettingById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/flags/custom-settings/${id}`)
        .then(response => {
          commit('SET_FLAG_CUSTOMSETTING', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteFlagCustomSettingById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${project.state.activeProjectId}/flags/custom-settings/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // ////////// //
  /* Flag OTHERS */
  getFlagsSelectList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/flags/select-list`)
        .then(async response => {
          commit('SET_FLAGS', response.data.result.map(x => { x.enabled = true; return x }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateFlagStatus({ commit, state }, { id, status }) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/projects/${project.state.activeProjectId}/flags/${id}/status?active=${status}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getFlagTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/flag-types/select-list')
        .then(response => {
          commit('SET_FLAG_TYPES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
