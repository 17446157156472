<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.947 5.046c0-.368-.3-.671-.665-.62A4.788 4.788 0 007.164 8.99l-.083 2.24a6.688 6.688 0 01-1.827 4.333c-.574.607-.24 1.661.65 1.74 1.22.107 3.566.277 6.176.277 2.56 0 4.797-.164 5.99-.272.91-.082 1.248-1.146.69-1.776a7.325 7.325 0 01-.874-1.215.656.656 0 00-.564-.337c-.446 0-.745.454-.528.843.259.464.561.904.903 1.314-1.22.104-3.283.243-5.617.243-2.335 0-4.459-.14-5.726-.244a7.885 7.885 0 001.926-4.861l.083-2.24a3.588 3.588 0 013.053-3.416c.291-.043.531-.278.531-.573zM14.08 19.58a.6.6 0 000-1.2h-4a.6.6 0 100 1.2h4zM15.519 7.73h2.03c.259 0 .414-.147.414-.397s-.155-.397-.415-.397h-1.077v-.021l1.241-1.6a.587.587 0 00.147-.39c0-.272-.182-.445-.469-.445h-1.898c-.26 0-.412.146-.412.397 0 .25.152.397.412.397h1.131v.02l-1.388 1.702c-.101.122-.14.217-.14.34 0 .238.164.394.424.394z"
    />
    <path
      d="M12.154 12.984h2.657c.34 0 .543-.192.543-.52s-.204-.52-.543-.52H13.4v-.027l1.624-2.094c.137-.175.192-.32.192-.511 0-.356-.238-.582-.613-.582h-2.485c-.34 0-.539.191-.539.52 0 .327.2.519.54.519h1.48v.027l-1.817 2.227c-.133.16-.183.285-.183.445 0 .312.215.516.554.516z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
