export default {
  videoTags: [],
  videoTitles: [],
  videoAuthors: [],
  videoSource: [],
  projectDomains: [],
  organizationUsers: [],
  filters: {
    tags: {
      params: {
        search: '',
      },
    },

    titles: {
      params: {
        search: '',
      },
    },

    authors: {
      params: {
        search: '',
      },
    },
    source: {
      params: {
        search: '',
      },
    },
    domains: {
      params: {
        search: '',
      },
    },
    organizationUsers: {
      params: {
        search: '',
        take: 99,
      },
    },
  },
}
