<template>
  <svg
    viewBox="0 0 21 21"
    :width="size"
    :height="size"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="currentColor"
      d="M3.4 12a8.6 8.6 0 1117.2 0 8.6 8.6 0 01-17.2 0zM12 7.96a.6.6 0 01.6.6v.011a.6.6 0 11-1.2 0V8.56a.6.6 0 01.6-.6zm-2.314 3.469a.6.6 0 01.6-.6H12.6v4h1.114a.6.6 0 110 1.2h-3.428a.6.6 0 010-1.2H11.4v-2.8h-1.114a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
