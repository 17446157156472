class Notification {
  constructor() {
    this.id = ''
    this.title = ''
    this.description = ''
    this.userNotificationTypeId = 1
    this.userNotificationDateId = 1
    this.time = '00:00'
    this.periodic = true
    this.message = ''
    this.active = false
    this.organizationId = ''
    this.recipients = []
    this.usageVolumeTypes = [
      {
        usageNotificationVolumeId: 1,
        threshold: 80,
      },
    ]
    this.threshold = 80
  }

  fromData(data) {
    this.id = data.id || ''
    this.title = data.title || ''
    this.userNotificationTypeId = data.id || 1
    this.description = data.description || ''
    this.userNotificationDateId = data.userNotificationDateId || 1
    this.time = data.time || '00:00'
    this.periodic = data.periodic
    this.message = data.message || ''
    this.active = data.active
    this.organizationId = data.organizationId || ''
    // this.recipients = data.recipients.length ? data.recipients.map(r => r.email) : []
    this.recipients = data.recipients || []
    // this.recipients = []
    this.usageVolumeTypes = data.usageVolumeTypes || [
      {
        usageNotificationVolumeId: 1,
        threshold: 0,
      },
    ]
    this.threshold = data.threshold || 80
  }
}

export default Notification
