<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.114 6a2.6 2.6 0 012.6-2.6h8.572a2.6 2.6 0 012.6 2.6v12a2.6 2.6 0 01-2.6 2.6H7.714a2.6 2.6 0 01-2.6-2.6V6zm11.172 11.172c.47 0 .91-.125 1.29-.343a1.4 1.4 0 01-1.29.857H7.714a1.4 1.4 0 01-1.29-.857c.38.218.82.343 1.29.343h8.572zm-9.862 1.371a1.4 1.4 0 001.29.857h8.572a1.4 1.4 0 001.29-.857c-.38.218-.82.343-1.29.343H7.714c-.47 0-.91-.125-1.29-.343zm7.61-9.4a.857.857 0 100-1.714.857.857 0 000 1.714zm-2.57.787a.5.5 0 01.796-.026l1.97 2.424a.5.5 0 01-.389.815h-3.694a.5.5 0 01-.407-.79l1.724-2.423z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
