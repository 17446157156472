<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.983 3.168L16.74 7.56a.6.6 0 11-.814.882L12.6 5.37v11.296a.6.6 0 01-1.2 0V5.449L8.424 8.424a.6.6 0 11-.848-.848l4.407-4.408zM4 10.733a.6.6 0 01.6.6V18A1.4 1.4 0 006 19.4h12a1.4 1.4 0 001.4-1.4v-6.667a.6.6 0 011.2 0V18a2.6 2.6 0 01-2.6 2.6H6A2.6 2.6 0 013.4 18v-6.667a.6.6 0 01.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
