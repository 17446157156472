<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.398 19.818a.6.6 0 01.6-.6h16a.6.6 0 110 1.2h-16a.6.6 0 01-.6-.6zM7.18 17.145a1.6 1.6 0 01-1.6-1.6V11a1.6 1.6 0 011.6-1.6h2.364a1.6 1.6 0 011.6 1.6v4.546a1.6 1.6 0 01-1.6 1.6H7.18zm-.4-1.6c0 .221.18.4.4.4h2.364a.4.4 0 00.4-.4V11a.4.4 0 00-.4-.4H7.18a.4.4 0 00-.4.4v4.546zM14.453 17.145a1.6 1.6 0 01-1.6-1.6V5.183a1.6 1.6 0 011.6-1.6h2.363a1.6 1.6 0 011.6 1.6v10.363a1.6 1.6 0 01-1.6 1.6h-2.363zm-.4-1.6c0 .221.179.4.4.4h2.363a.4.4 0 00.4-.4V5.183a.4.4 0 00-.4-.4h-2.363a.4.4 0 00-.4.4v10.363z"
    />
  </svg>
</template>
