export default {
  getIsLoading: state => state.isLoading,
  getAdSchedule: state => state.adSchedule,
  getAdSchedules: state => state.adSchedules,
  getDefaultAdSchedules: state => state.defaultAdSchedules,
  getName: state => state.adSchedule.name,
  getAdManager: state => state.adSchedule.adManager,
  getAdManagerId: state => state.adSchedule.adManagerId,
  getVmapAdTag: state => state.adSchedule.vmapAdTag,
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,

  getSkipableAfterSeconds: state => (adBreakType, index, property) => {
    const { adBreaks } = state.adSchedule
    const arrayOfTypeName = adBreaks.filter(x => x.adBreakType.name === adBreakType)
    const value = arrayOfTypeName[index][property]
    return value
  },
  getStartOnPlaylistItem: state => state.adSchedule.startOnPlaylistItem,
  getRepeatOnInterval: state => state.adSchedule.repeatOnInterval,
  getTimeBetweenAds: state => state.adSchedule.timeBetweenAds,
  getAdSeenOnSubsequentVisitId: state => state.adSchedule.adSeenOnSubsequentVisitOptionId,
  getEnableBidding: state => state.adSchedule.enableBidding,
  getBiddingTemplatePublicId: state => state.adSchedule.biddingTemplatePublicId,
  getAdScheduleType: state => state.adSchedule?.adScheduleTypeId,

  /*      OPTIONS GETTERS AD SCHEDULE     */
  getAdManagerOptions: state => state.options?.adManagerOptions || [],
  getAdScheduleBreakTimingTypeOptions: state => state.options?.breakTimingTypeOptions,
  getAdScheduleTypesOptions: state => state.options?.adScheduleTypeOptions,
  getAdScheduleDistributionMethodTypeOptions: state => state.options?.distributionMethodTypeOptions,
  getAdScheduleMidrollTypeOptions: state => state.options?.midrollTypeOptions,
  getAdScheduleTypeId: state => state.adSchedule?.adScheduleTypeId,

  /*      AD FUNCTIONS AD SCHEDULE     */
  // getAdFunction: state => state.adFunction,
  getAdScheduleWorkflows: state => state.adSchedule.adWorkflows,
  getAdScheduleAdFunctions: state => state.adFunctions,
}
