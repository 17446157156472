import state from './moduleVideoPlayersState'
import mutations from './moduleVideoPlayersMutations'
import actions from './moduleVideoPlayersActions'
import getters from './moduleVideoPlayersGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
