import CustomReport from '@/models/custom-reports/CustomReport'
import CustomReportFilter from '@/models/custom-reports/CustomReportFilter'

export default {
  SET_IS_LOADING: (state, data) => { state.isLoading = data },

  // # LIVE VIEWERS
  SET_LIVE_VIEWERS: (state, data) => { state.liveViewers = data },
  SET_LIVE_VIEWERS_IS_LOADING: (state, data) => { state.liveViewersIsLoading = data },
  UPDATE_LIVE_VIEWERS_FILTER: (state, data) => {
    state.liveViewersFilters.params[data.propKey] = data.value
  },
  RESET_LIVE_VIEWERS_FILTER: state => {
    const filteri = {
      params: {
        videoId: '',
        endDate: '',
        seconds: null,
        intervalLength: null,
      },
    }
    state.liveViewersFilters = filteri
  },

  // # CONTENT
  SET_TOP_CONTENT: (state, data) => { state.topContent = data },
  SET_TOP_CONTENT_IS_LOADING: (state, data) => { state.topContentIsLoading = data },
  UPDATE_TOP_CONTENT_FILTER: (state, data) => {
    state.topContentFilters.params[data.propKey] = data.value
  },
  RESET_TOP_CONTENT_FILTER: state => {
    const filteri = {
      params: {
        videoId: '',
        endDate: '',
        seconds: null,
        intervalLength: null,
      },
    }
    state.topContentFilters = filteri
  },

  // # VIDEO ANALYTICS
  SET_VIDEO_ANALYTICS: (state, data) => { state.videoAnalytics = data },
  SET_TIME_WATCHED_PER_VIEWER: (state, data) => { state.videoAnalytics.timeWatchedPerViewer = data.timeWatchedPerViewer },
  CLEAR_VIDEO_ANALYTICS: state => {
    state.videoAnalytics = {
      playsNumber: 0,
      embedNumber: 0,
      completesNumber: 0,
      uniqueViewersNumber: 0,
      timeWatchedPerViewer: 0,
    }
  },
  SET_VIDEO_ANALYTICS_LOADING: (state, data) => { state.videoAnalyticsIsLoading = data },

  // # DEVICES
  SET_TOP_DEVICES: (state, data) => { state.topDevices = data },
  SET_TOP_DEVICES_IS_LOADING: (state, data) => { state.topDevicesIsLoading = data },

  // # BROWSERS
  SET_TOP_BROWSERS: (state, data) => { state.topBrowsers = data },
  SET_TOP_BROWSERS_IS_LOADING: (state, data) => { state.topBrowsersIsLoading = data },

  // # DOMAINS
  SET_TOP_DOMAINS: (state, data) => { state.topDomains = data },
  SET_TOP_DOMAINS_IS_LOADING: (state, data) => { state.topDomainsIsLoading = data },

  // # OPERATING SYSTEMS
  SET_TOP_OPERATING_SYSTEMS: (state, data) => { state.topOperatingSystems = data },
  SET_TOP_OPERATING_SYSTEMS_IS_LOADING: (state, data) => { state.topOperatingSystemsIsLoading = data },

  // # COUNTRIES
  SET_TOP_COUNTRIES: (state, data) => { state.topCountries = data },
  SET_TOP_COUNTRIES_IS_LOADING: (state, data) => { state.topCountriesIsLoading = data },

  // # STREAM DATA
  SET_STREAM_DATA: (state, data) => {
    state.streamDataNumberOfJobs = data[0].numberOfJobs
    state.streamDataFileSize = data[0].fileSize
    state.streamDataDuration = data[0].fileDuration
  },

  // # STORAGE
  SET_USAGE_DATA: (state, data) => {
    state.usageDatas = data
  },
  CLEAR_USAGE_DATA: state => {
    state.usageDatas = {}
  },

  LOADING_USAGE_DATA: (state, value) => {
    state.loadingUsageData = value
  },

  // # ANALYTICS CONTENT MUTTATIONS
  // #
  SET_CONTENT: (state, data) => { state.content = data },

  // # ANALYTICS CUSTOM REPORTS MUTTATIONS
  // #
  SET_CUSTOMREPORTS: (state, data) => { state.customReports = data },
  SET_CUSTOMREPORT: (state, data) => {
    const cR = new CustomReport()
    cR.fromData(data)
    state.customReport = cR
  },
  SET_CUSTOMREPORTS_IS_LOADING: (state, data) => { state.customReportsIsLoading = data },
  SET_CUSTOMREPORT_IS_LOADING: (state, data) => { state.customReportIsLoading = data },
  SET_CUSTOMREPORTS_NAME: (state, data) => { state.customReport.name = data },
  SET_CUSTOMREPORTS_SELECTED_METRICS: (state, data) => { state.customReport.reportMetricKeys = data },
  SET_CUSTOMREPORTS_SELECTED_DIMENSIONS: (state, data) => { state.customReport.reportDimensionKeys = data },
  SET_CUSTOMREPORTS_SELECTED_FILTERS: (state, data) => { state.customReport.reportFilters = data },
  SET_CUSTOMREPORTS_DATE_RANGE_ID: (state, data) => { state.customReport.customReportDateRangeId = data },
  SET_CUSTOMREPORTS_DATE_FROM: (state, data) => { state.customReport.dateFrom = data },
  SET_CUSTOMREPORTS_DATE_TO: (state, data) => { state.customReport.dateTo = data },
  SET_CUSTOMREPORTS_ENTITY_TYPE_ID: (state, data) => { state.customReport.reportEntityTypeId = data },
  SET_CUSTOMREPORTS_OPTIONS: (state, data) => { state.customReportsOptions = data },

  ADD_NEW_FILTER: state => { const ncrf = new CustomReportFilter(); state.customReport.reportFilters.push(ncrf) },
  CLEAR_FILTER: (state, index) => { state.customReport.reportFilters.splice(index, 1) },

  SET_CUSTOMREPORT_VIEW_TABLE: (state, data) => { state.customReportViewTable = data },
  SET_CUSTOMREPORT_VIEW_TABLE_QUERY: (state, data) => { state.customReportViewTableQuery = data },
  SET_CUSTOMREPORT_VIEW_TABLE_ISLOADING: (state, data) => { state.isLoadingTable = data },
  SET_CUSTOMREPORT_VIEW_CHART: (state, data) => { state.customReportViewChart = data },
  SET_CUSTOMREPORT_VIEW_CHART_QUERY: (state, data) => { state.customReportViewChartQuery = data },
  SET_CUSTOMREPORT_VIEW_CHART_ISLOADING: (state, data) => { state.isLoadingChart = data },

  // # ANALYTICS USAGE HISTORY MUTTATIONS
  // #
  SET_USAGEHISTORY: (state, data) => { state.usageHistory = data },
  SET_USAGEHISTORY_ISLOADING: (state, data) => { state.usageHistoryIsLoading = data },
  SET_USAGEHISTORY_TOTAL: (state, data) => {
    state.usageHistoryTotal = {
      ...data,
      organizationId: 0,
      projectId: 0,
      identifier: 'Total',
      // projectName: '',
    }
  },
  CLEAR_USAGEHISTORY: state => { state.usageHistory = [] },
  CLEAR_USAGEHISTORYTOTAL: state => { state.usageHistoryTotal = null },

  // # ANALYTICS CONTRACT MUTTATIONS
  // #
  SET_CONTRACT_ISLOADING: (state, data) => { state.contractIsLoading = data },
  SET_CONTRACT_TOTAL: (state, data) => { state.contractTotal = data },
  SET_CONTRACT_USAGE: (state, data) => { state.contractUsage = data },
  CLEAR_CONTRACT: state => { state.contractTotal = {}; state.contractUsage = {} },

  // # ANALYTICS CUSTOM PARAMETERS MUTTATIONS
  // #

  SET_PAGINATION: (state, data) => {
    const {
      endDate, items, rowsMetadata, startDate, ...p
    } = data
    state.pagination = p
  },
  SET_CUSTOMREPORTS_TABLE_PAGINATION: (state, data) => {
    const {
      endDate, items, rowsMetadata, startDate, ...p
    } = data
    state.customReportViewTablePagination = p
  },
  CLEAR_CUSTOMREPORTS: state => { state.customReports = [] },
  CLEAR_CUSTOMREPORT: state => { state.customReport = new CustomReport() },
  CLEAR_CUSTOMREPORT_OPTIONS: state => { state.customReportsOptions = null },
  CLEAR_CUSTOMREPORT_VIEW_TABLE: state => { state.customReportViewTable = {} },
  CLEAR_CUSTOMREPORT_VIEW_TABLE_QUERY: state => { state.customReportViewTableQuery = {} },
  CLEAR_CUSTOMREPORT_VIEW_CHART: state => { state.customReportViewChart = {} },
  CLEAR_CUSTOMREPORT_VIEW_CHART_QUERY: state => { state.customReportViewChartQuery = {} },
  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params.take = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  UPDATE_CUSTOMREPORTS_TABLE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.customReportViewTableFilters.params.take = data.value
      state.customReportViewTableFilters.params.page = '1'
    } else {
      state.customReportViewTableFilters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
  RESET_CUSTOMREPORTS_TABLE_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.customReportViewTableFilters = filteri
  },
}
