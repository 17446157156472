<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.853 6.69a1.851 1.851 0 00-2.632 0l-7.437 7.48a1.061 1.061 0 000 1.492c.407.41 1.065.41 1.471 0l4.022-4.046a.6.6 0 01.851.846l-4.021 4.045a2.234 2.234 0 01-3.174 0 2.261 2.261 0 010-3.183l7.437-7.48a3.051 3.051 0 014.334 0 3.092 3.092 0 010 4.353l-7.672 7.72a3.868 3.868 0 01-5.496 0 3.923 3.923 0 010-5.522l4.838-4.87a.6.6 0 11.852.845L6.388 13.24a2.723 2.723 0 000 3.83 2.668 2.668 0 003.793 0l7.672-7.72c.73-.735.73-1.927 0-2.662z"
    />
  </svg>
</template>
