<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.685 8v1.133a2.6 2.6 0 00-2.286 2.581V18A2.6 2.6 0 008 20.6h8.572a2.6 2.6 0 002.6-2.6v-6.286a2.6 2.6 0 00-2.286-2.58V8a4.6 4.6 0 00-9.2 0zm4.6-3.4a3.4 3.4 0 00-3.4 3.4v1.114h6.8V8a3.4 3.4 0 00-3.4-3.4zm.6 8.543a.6.6 0 00-1.2 0v3.428a.6.6 0 101.2 0v-3.428z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
