<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.124 9.858a2.6 2.6 0 010 4.284L9.473 17.34C7.748 18.526 5.4 17.291 5.4 15.198V8.802c0-2.093 2.348-3.328 4.073-2.142l4.651 3.198zM17.455 5.673a.6.6 0 01.6.6v11.454a.6.6 0 01-1.2 0V6.273a.6.6 0 01.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
