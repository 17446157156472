class UserOnAdd {
  constructor() {
    this.projectId = ''
    this.roleId = null
    this.user = null
  }

  fromData(data) {
    this.projectId = data.projectId
    this.roleId = data.roleId
    this.user = {
      email: data.user.email,
      publicId: data.user.publicId,
    }
  }
}

export default UserOnAdd
