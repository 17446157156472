<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 7A2.6 2.6 0 016 4.4h9.714a2.6 2.6 0 012.6 2.6v3.686h.186c1.16 0 2.1.94 2.1 2.1v5a2.1 2.1 0 01-2.1 2.1h-1.571a2.1 2.1 0 01-2.065-1.715H6a2.6 2.6 0 01-2.6-2.6V7zm14.314 4.886h-.785a.9.9 0 00-.9.9v5a.9.9 0 00.9.9H18.5a.9.9 0 00.9-.9v-5a.9.9 0 00-.9-.9h-.786z"
    />
    <path d="M8.572 18.686a.6.6 0 100 1.2h4.57a.6.6 0 100-1.2h-4.57z" />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
