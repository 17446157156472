import Wrapper from '@/models/ad-schedules/wrapper'

export default {
  wrappers: [],
  wrapper: new Wrapper(),
  currencies: [],
  bidOptions: [],
  isLoading: true,
  wrapperOptions: [],
  filters: {
    params: {
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },
  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

  options: {
    adManagerOptions: null,
    vpaidModeOptions: [],
    adSeenOnSubsequentVisitOptions: [],
    adBreakTypeOptions: [],
    breakTimingTypeOptions: [],
  },
}
