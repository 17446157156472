<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.972 5.363a.6.6 0 00-.848.03L5.442 15.774l-.21 3.746 3.724-.47L18.637 8.67a.6.6 0 00-.03-.849l-2.635-2.457zm-1.727-.79a1.802 1.802 0 012.547-.089l2.635 2.458c.728.678.768 1.818.089 2.546L9.834 19.871c-.192.205-.45.337-.728.372l-4.295.542A.72.72 0 014 20.03l.241-4.322c.016-.28.13-.547.321-.752l9.682-10.383z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
