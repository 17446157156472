<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.612 4.5A2.802 2.802 0 003.81 7.302v6.466a2.802 2.802 0 002.802 2.802H7.69c.238 0 .43.193.43.431v1.635c0 .768.93 1.153 1.473.61l2.233-2.234a1.509 1.509 0 011.067-.442h4.496a2.802 2.802 0 002.802-2.802V7.302A2.802 2.802 0 0017.389 4.5H6.612zm3.068 6a1 1 0 11-2 0 1 1 0 012 0zm3.333 0a1 1 0 11-2 0 1 1 0 012 0zm2.334 1a1 1 0 100-2 1 1 0 000 2z"
    />
  </svg>
</template>
