export default {
  isLoading: true,
  roles: [],
  role: [],
  permissions: {
    all: [],
    ofUser: [],
    ofRole: [],
    default: [
      { type: 'dashboard.read' },
    ],
  },
  activeRoleId: null,

  filters: {
    params: {
      // organizationId: null,
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },
}
