<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.833 6a1.6 1.6 0 013.2 0v4.5a.6.6 0 01-1.2 0V6a.4.4 0 10-.8 0v6.44a.832.832 0 01-1.422.588l-1.83-1.83a.398.398 0 00-.587.026.407.407 0 00.007.528c.785.902 2.243 2.611 2.744 3.437.364.599.756 1.52 1.048 2.258.227.575.77.953 1.362.953h3.569c.545 0 1.001-.309 1.166-.768.271-.757.59-1.816.749-2.964.19-1.367.146-2.794.083-3.696a.409.409 0 00-.424-.372.466.466 0 00-.465.466v1.184a.6.6 0 01-1.2 0v-1.184c0-.92.745-1.666 1.665-1.666.831 0 1.56.622 1.62 1.488.066.938.116 2.46-.09 3.945a16.38 16.38 0 01-.808 3.204c-.355.989-1.303 1.563-2.296 1.563h-3.569c-1.11 0-2.08-.703-2.478-1.712-.293-.74-.653-1.575-.957-2.076-.436-.717-1.81-2.335-2.624-3.273a1.607 1.607 0 01-.024-2.083 1.598 1.598 0 012.357-.107l1.204 1.203V6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.433 9.35a.4.4 0 00-.4.4v1.75a.6.6 0 01-1.2 0V9.75a1.6 1.6 0 013.2 0V12a.6.6 0 11-1.2 0V9.75a.4.4 0 00-.4-.4z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.433 8.35a.4.4 0 00-.4.4v1.75a.6.6 0 01-1.2 0V8.75a1.6 1.6 0 013.2 0v2.75a.6.6 0 11-1.2 0V8.75a.4.4 0 00-.4-.4z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
