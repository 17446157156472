import state from './moduleApiKeyState'
import mutations from './moduleApiKeyMutations'
import actions from './moduleApiKeyActions'
import getters from './moduleApiKeyGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
