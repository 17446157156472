import User from '@/models/users/User'
import UserOnAdd from '@/models/users/UserOnAdd'

export default {
  ADD_USER: (state, data) => state.users.unshift(data),
  SET_USERS: (state, data) => { state.users = data },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },
  CLEAR_USERS: state => { state.users = [] },
  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },

  SET_USERS_FOR_PROJECT: (state, data) => { state.usersForProject = data },
  SET_USERS_ORGANIZATION_PROJECTS: (state, data) => { state.userProjectsOptions = data },
  CLEAR_USERS_FOR_PROJECT: state => { state.users = [] },
  UPDATE_FILTER_FOR_PROJECT: (state, data) => { state.filtersForProject.params[data.propKey] = data.value },

  REMOVE_PROJECT_FROM_USER: (state, { userIndex, projectIndex }) => { state.users[userIndex].projects.splice(projectIndex, 1) },

  // SET_USERS_OF_PROJECT: (state, data) => { state.usersOfProject = data },
  // CLEAR_USERS_OF_PROJECT: state => { state.users = [] },
  UPDATE_FILTER_OF_PROJECT: (state, data) => { state.filtersOfProject.params[data.propKey] = data.value },
  // SET_PAGINATION_OF_PROJECT: (state, data) => { const { items, ...p } = data; state.paginationOfProject = p },
  RESET_FILTER_OF_PROJECT: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filtersOfProject = filteri
  },

  SET_USER: (state, data) => { state.user = data },
  SET_USER_ROLE: (state, data) => { state.user.role = data },
  SET_USER_DEFAULT_ORGANIZATION: (state, data) => { state.userDefaultOrganization = data },
  SET_USER_DEFAULT_PROJECT: (state, data) => { state.userDefaultProject = data },
  SET_USER_EDITED: (state, data) => { const u = new User(); u.fromData(data); state.userEdited = u },
  SET_OPTIONS: (state, data) => { state.options = data.data.result },

  CLEAR_USER: state => { state.user = [] },
  CLEAR_USERS_ONADD: state => { state.usersOnAdd = [new UserOnAdd()] },
  CLEAR_USER_EDITED: state => { state.userEdited = new User() },

  SCRIPT_IS_LOADED: (state, data) => { state.userLoaded = data },
  UPDATE_USER: (state, data) => { const userIndex = state.users.findIndex(p => p.id === data.id); Object.assign(state.users[userIndex], data) },
  UPDATE_PROJECT_ID: (state, data) => { state.filters.params.ProjectId = data },
  UPDATE_ROLE: (state, data) => { state.userEdited.role = data },
  UPDATE_EMAIL: (state, data) => { state.userEdited.email = data },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },

  ADD_USER_ON_ADD: (state, data) => {
    const uoa = new UserOnAdd()
    uoa.fromData(data)
    state.usersOnAdd.push(uoa)
  },
  ADD_USERONADD_SEGMENT: state => {
    // const uoa = new UserOnAdd()
    // uoa.fromData(data)
    state.usersOnAdd.push(new UserOnAdd())
  },
  // ADD_USER_ON_ADD: (state, data) => {
  //   const uoa = new UserOnAdd()
  //   uoa.fromData(data)
  //   state.usersOnAdd.push(uoa)
  // },
  REMOVE_USERSONADD_SEGMENT: (state, index) => {
    state.usersOnAdd.splice(index, 1)
  },
}
