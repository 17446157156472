import BiddingTemplateBidders from './biddingTemplateBidders'

class BiddingTemplate {
  constructor() {
    this.name = ''
    this.publicId = null
    this.mediationOptionId = null
    this.biddingTemplateBidders = []
    // this.biddingTemplateBidders.push(new BiddingTemplateBidders())
  }

  fromData(data) {
    this.name = data.name ? data.name : ''
    this.publicId = data.publicId ? data.publicId : null
    this.mediationOptionId = data.mediationOptionId ? data.mediationOptionId : null
    this.biddingTemplateBidders = data.biddingTemplateBidders ? data.biddingTemplateBidders.map(x => {
      const biddingTemplateBidders = new BiddingTemplateBidders()
      biddingTemplateBidders.fromData(x)
      return biddingTemplateBidders
    }) : null
  }
}

export default BiddingTemplate
