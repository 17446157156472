/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {

  getPreSigned({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/uploads/url`, data.params, data.headers)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getMultipartPreSigned({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/uploads/multipart`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getPreSignedUrl({ commit }, requestKey) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/uploads/url/${requestKey}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getMultipartUploadedParts({ commit }, requestKey) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/uploads/url/multipart/${requestKey}/parts`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  multipartComplete({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/uploads/multipart-complete`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  multipartAbort({ commit }, requestKey) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/uploads/multipart-abort`, { requestKey })
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  encodesVideo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/encodes`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
