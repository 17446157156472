<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.4 18A2.6 2.6 0 006 20.6h3.218V3.4H6A2.6 2.6 0 003.4 6v12zM10.418 3.4v17.2H18a2.6 2.6 0 002.6-2.6V6A2.6 2.6 0 0018 3.4h-7.582z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
