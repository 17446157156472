import Video from '@/models/videos/video'
import CustomParameter from '@/models/videos/CustomParameters'
import Subtitle from '@/models/videos/Subtitles'
import CustomOverlays from '@/models/videos/CustomOverlays'
import Chapters from '@/models/videos/Chapters'

export default {
  SET_VIDEO: (state, data) => {
    const fd = new Video()
    fd.fromData(data)
    state.video = fd
  },
  SET_VIDEOS: (state, data) => {
    state.videos = data
  },
  SET_RECENTLY_DELETED_VIDEOS: (state, data) => {
    state.recentlyDeletedVideos = data
  },
  ADD_VIDEO: (state, data) => {
    state.videos.unshift(data)
  },
  // ADD_VIDEOS: (state, data) => { [].push.apply(state.videos, data) },
  ADD_VIDEOS: (state, data) => {
    [...state.videos] = [...state.videos, ...data]
  },
  SET_VIDEO_ASSETS: (state, data) => {
    state.streams = data
      ? data.streamResponse?.streams.map(d => ({
        mode: 'span', // span means this header will span all columns
        label: d.streamType, // this is the label that'll be used for the header
        labelType: d.assetType, // this is the label type
        html: false, // if this is true, label will be rendered as html
        root: d.root,
        children: d.qualityTypes,
      }))
      : null
    state.playBackUrl = data ? data.playBackUrl : ''
    state.customThumbnail = data ? data.customThumbnail : ''
    state.originalFile = data ? data.originalFile : ''
  },
  SET_THUMBNAIL_SECOND: (state, data) => {
    state.thumbnailSecond = data ? data.thumbnailSecond : null
  },
  SET_PAGINATION: (state, data) => {
    const { items, ...p } = data
    state.pagination = p
  },
  SET_OPTIONS: (state, data) => {
    state.options = data.data.result
  },
  SET_STREAMS: (state, data) => {
    state.streams = data
  },
  SET_PUBLIC_ID: (state, data) => {
    state.video.publicId = data
  },
  SET_NAME: (state, data) => {
    state.video.name = data
  },
  SET_TITLE: (state, data) => {
    state.video.title = data
  },
  SET_DESCRIPTION: (state, data) => {
    state.video.description = data
  },
  SET_VIDEO_THUMBNAILURL: (state, data) => {
    state.video.thumbnailUrl = data
  },
  SET_PUBLISH_DATE: (state, data) => {
    state.video.publishDate = data
  },
  SET_PUBLISH_END_DATE: (state, data) => {
    state.video.publishEndDate = data
  },
  SET_PUBLISH_STATUS_ID: (state, data) => {
    state.video.publishStatusId = data
  },
  SET_DURATION: (state, data) => {
    state.video.duration = data
  },
  SET_ENTITY_ID: (state, data) => {
    state.video.entityId = data
  },
  SET_VIDEO_PAGE_PERMALINK: (state, data) => {
    state.video.videoPagePermalink = data
  },
  SET_USERNAME: (state, data) => {
    state.video.userName = data
  },
  SET_TAGS: (state, data) => {
    state.video.tagNames = data
  },
  SET_CATEGORY: (state, data) => {
    state.video.categoryId = data
  },
  SET_FLAGS: (state, data) => {
    state.video.flagIds = data
  },
  SET_GEOBLOCKING: (state, data) => {
    state.video.geoBlockingRuleId = data
  },
  SET_RESTRICTION: (state, data) => {
    state.video.restriction = data
  },
  SET_SKIP_INTRO_STATE: (state, data) => {
    state.video.skipIntroState = data
  },
  SET_CUSTOM_OVERLAY_STATE: (state, data) => {
    state.video.customOverlayState = data
  },
  SET_CHAPTER_STATE: (state, data) => {
    state.video.chapterState = data
  },
  SET_SKIP_INTRO_FROM: (state, data) => {
    state.video.skipIntroFrom = data
  },
  SET_SKIP_INTRO_TO: (state, data) => {
    state.video.skipIntroTo = data
  },
  SET_AUTHOR: (state, data) => {
    state.video.author = data
  },
  SET_SOURCE: (state, data) => {
    state.video.source = data
  },
  SET_VISIBILITY_OPTIONS: (state, data) => {
    state.visibilityOptions = data
  },
  SET_PASSWORD_PROTECTED_TITLE: (state, data) => {
    state.video.passwordProtectedTitle = data
  },
  SET_PASSWORD_PROTECTED_DESCRIPTION: (state, data) => {
    state.video.passwordProtectedDescription = data
  },
  SET_PASSWORD: (state, data) => {
    state.video.password = data
  },
  SET_CUSTOM_OVERLAYS_POSITIONS: (state, data) => {
    state.customOverlaysPositions = data
  },
  SET_AD_SCHEDULE: (state, data) => {
    state.video.publicAdScheduleId = data
  },
  SET_SHARED_HISTORY: (state, data) => {
    state.video.sharedHistory = data
  },
  ADD_CUSTOM_PARAMETER: state => {
    const ncp = new CustomParameter()
    state.video.customParameters.push(ncp)
  },
  ADD_CUSTOM_OVERLAY: state => {
    const ncp = new CustomOverlays()
    state.video.customOverlays.push(ncp)
  },
  ADD_CHAPTER: (state, time = null) => {
    const ncp = new Chapters('', time)
    state.video.chapters.push(ncp)
  },
  SET_ANALYTICS(state, payload) {
    const { result, intervalType, differenceInDays } = payload
    state.intervalType = intervalType
    state.diffInTimeCustomDate = differenceInDays
    state.videoAnalytics = result
    state.videoAnalyticsGropuedData = {
      result: result?.graphResult?.map(entry => entry?.result),
      date: result?.graphResult?.map(entry => entry?.date),
    }
    state.videoAnalyticsTotal = result?.total
  },
  SET_LIVE_VIEWERS(state, data) {
    state.liveViewers = data?.liveViewers
    state.liveViewersGroupedData = {
      uniqueViewers: data?.liveViewers?.map(entry => entry?.uniqueViewers),
      date: data?.liveViewers?.map(entry => entry?.date),
    }
    state.liveViewersTotal = data?.total
  },
  SET_KEY_MOMENTS(state, data) {
    state.keyMoments = data
    state.keyMomentsGroupedData = {
      videoSeconds: data.map(entry => entry?.videoSeconds),
      views: data.map(entry => entry?.views),
    }
  },
  LOADING_CUSTOM_ANALYTICS(state, value) {
    state.loadingCustomAnalytics = value
  },
  SET_KEY_MOMENTS_CARDS(state, data) {
    state.keyMomentsCards = data
  },
  SET_CUSTOM_DATE(state, { e, dateStr }) {
    state.customDate = e
    state.dateStr = dateStr
  },
  DESTROY_CUSTOM_DATE(state) {
    state.customDate = []
    state.dateStr = ''
  },
  LOADING_VIDEOS(state, value) {
    state.loadingVideos = value
  },
  LOADING_ANALYTICS(state, value) {
    state.loadingAnalytics = value
  },
  LOADING_ANALYTICS_TOP_COUNTRIES(state, value) {
    state.loadingTopCountries = value
  },
  LOADING_ANALYTICS_TOP_BROWSERS(state, value) {
    state.loadingTopBrowsers = value
  },
  LOADING_ANALYTICS_TOP_DEVICES(state, value) {
    state.loadingTopDevices = value
  },
  LOADING_ANALYTICS_KEY_MOMENTS(state, value) {
    state.loadingKeyMoments = value
  },
  LOADING_ANALYTICS_KEY_MOMENTS_CARDS(state, value) {
    state.loadingKeyMomentsCards = value
  },
  LOADING_ANALYTICS_LIVE_VIEWERS(state, value) {
    state.loadingLiveViewers = value
  },
  SET_TOP_COUNTRIES(state, data) {
    state.topCountries = data
    state.topCountriesGroupedData = data.map(entry => ({
      names: entry?.countryName,
      values: entry?.countryNr,
    }))
  },
  SET_TOP_BROWSERS(state, data) {
    state.topBrowsers = data
    state.topBrowsersGroupedData = data.map(entry => ({
      names: entry?.browser,
      values: entry?.browserTypeNr,
    }))
  },
  SET_TOP_DEVICES(state, data) {
    state.topDevices = data
    state.topDevicesGroupedData = {
      deviceType: data.map(entry => entry?.deviceType),
      deviceTypeNr: data.map(entry => entry?.deviceTypeNr),
    }
  },
  DESTROY_VIDEO_ANALYTICS(state) {
    state.videoAnalytics = []
    state.videoAnalyticsGropuedData = {}
    state.videoAnalyticsTotal = 0
    state.liveViewersTotal = 0
    state.topDevices = []
    state.topCountries = []
    state.topBrowsers = []
    state.totalClips = []
    state.keyMomentsCards = {}
    state.keyMoments = []
    state.keyMomentsGroupedData = {}
    state.totalClipsPagination = {}
    state.topBrowsersGroupedData = {}
    state.topCountriesGroupedData = {}
    state.topDevicesGroupedData = {}
    state.liveViewersGroupedData = {}
  },
  CLEAR_VIDEO: state => {
    state.video = new Video()
  },
  CLEAR_VIDEOS: state => {
    state.videos = []
  },
  REMOVE_VIDEOS_BY_ID: (state, data) => {
    data.forEach(d => {
      state.videos.splice(
        state.videos.findIndex(x => x.mediaId === d),
        1,
      )
    })
  },
  REMOVE_CUSTOM_PARAMETER: (state, index) => {
    state.video.customParameters.splice(index, 1)
  },
  REMOVE_CUSTOM_OVERLAY: (state, index) => {
    state.video.customOverlays.splice(index, 1)
  },
  REMOVE_CHAPTER: (state, index) => {
    state.video.chapters.splice(index, 1)
  },
  REMOVE_CHAPTERS: state => {
    state.video.chapters.length = 0
  },
  UPDATE_PROJECT_ID: (state, data) => {
    state.filters.params.ProjectId = data
  },
  UPDATE_ISLOADING: (state, data) => {
    state.isLoading = data
  },
  UPDATE_ISUPLOADING: (state, data) => {
    state.isUploading = data
  },
  NEW_VIDEO_UPLOADED: (state, data) => {
    state.newVideo = data
  },
  UPDATE_CUSTOM_PARAMETER_KEY: (state, data) => {
    state.video.customParameters[data.index].key = data.key
  },
  UPDATE_CUSTOM_PARAMETER_VALUE: (state, data) => {
    state.video.customParameters[data.index].value = data.value
  },
  UPDATE_CUSTOM_OVERLAY_CAPTION: (state, data) => {
    state.video.customOverlays[data.index].caption = data.caption
  },
  UPDATE_CUSTOM_OVERLAY_POSITION: (state, data) => {
    state.video.customOverlays[data.index].position = data.position
  },
  UPDATE_CUSTOM_OVERLAY_SECOND_FROM: (state, data) => {
    state.video.customOverlays[data.index].secondsFrom = data.secondsFrom
  },
  UPDATE_CUSTOM_OVERLAY_SECOND_TO: (state, data) => {
    state.video.customOverlays[data.index].secondsTo = data.secondsTo
  },
  UPDATE_CHAPTER_TITLE: (state, data) => {
    state.video.chapters[data.index].title = data.title
  },
  UPDATE_CHAPTER_TIME: (state, data) => {
    state.video.chapters[data.index].time = data.time
  },
  UPDATE_VIDEO: (state, data) => {
    const videoIndex = state.videos.findIndex(p => p.id === data.id)
    Object.assign(state.videos[videoIndex], data)
  },
  UPDATE_STATUS_BY_ID: (state, data) => {
    const videoIndex = state.videos.findIndex(v => v.mediaId === data.id)
    state.videos[videoIndex].status = data.status
  },
  UPDATE_FILTER: (state, data) => {
    state.filters.params[data.propKey] = data.value
  },
  UPDATE_PAGINATION: (state, data) => {
    state.pagination[data.propKey] = data.value
  },

  RESET_FILTER: state => {
    const filteri = {
      params: {
        folderView: false,
        folderId: null,
        insertedDate: null,
        key: null,
        value: null,
        author: null,
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
        onlyReadyVideos: false,
        isLive: false,
      },
    }
    state.filters = filteri
  },
  RESET_FILTER_LIVE: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
        onlyReadyVideos: false,
        isLive: true,
      },
    }
    state.filters = filteri
  },
  RESET_PAGINATION: state => {
    const pagination = {
      totalPages: null,
      totalCount: null,
      currentPage: null,
    }
    state.pagination = pagination
  },

  /*    SUBTITLES   */
  SET_SUBTITLES: (state, data) => {
    state.subtitles = data
  },
  ADD_SUBTITLES_ROW: (state, languageId) => {
    const subtitle = new Subtitle()
    subtitle.fromData({ languageId })
    state.subtitles.push(subtitle)
  },
  UPDATE_SUBTITLES_ROW: (state, { data, index }) => {
    const subtitle = new Subtitle()
    subtitle.fromData(data)
    state.subtitles.splice(index, 1, subtitle)
  },
  REMOVE_SUBTITLE_ROW: (state, index) => {
    state.subtitles.splice(index, 1)
  },
  SET_VIDEO_PLAYLISTS: (state, data) => {
    state.playlists = data
  },
  LOADING_ANALYTICS_REPORT(state, value) {
    state.loadingAnalyticsReport = value
  },
  SET_ANALYTICS_REPORT(state, data) {
    state.analyticsReport = data
  },
}
