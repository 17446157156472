<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.46c-1.612 0-2.924 1.284-2.924 2.873 0 1.382.991 2.533 2.309 2.81v1.684h-6.81c-.974 0-1.765.778-1.765 1.74v2.28c-1.34.26-2.35 1.423-2.35 2.82 0 1.587 1.306 2.873 2.915 2.873 1.61 0 2.915-1.286 2.915-2.873 0-1.363-.962-2.503-2.251-2.799v-2.301a.53.53 0 01.536-.527h6.81v2.793a.54.54 0 000 .022c-1.318.275-2.309 1.43-2.309 2.812 0 1.587 1.306 2.873 2.915 2.873 1.61 0 2.915-1.286 2.915-2.873 0-1.377-.981-2.527-2.292-2.808l.001-.026V12.04h6.892a.53.53 0 01.535.527v2.284c-1.33.266-2.332 1.425-2.332 2.816 0 1.587 1.306 2.873 2.915 2.873 1.61 0 2.915-1.286 2.915-2.873 0-1.369-.97-2.513-2.268-2.803V12.567c0-.962-.79-1.74-1.765-1.74h-6.892V9.143c1.318-.277 2.309-1.428 2.309-2.81 0-1.59-1.312-2.873-2.924-2.873zm-1.694 2.873c0-.914.756-1.66 1.694-1.66s1.694.746 1.694 1.66c0 .914-.756 1.66-1.694 1.66s-1.694-.746-1.694-1.66zM1.69 17.667c0-.917.754-1.66 1.685-1.66s1.685.743 1.685 1.66c0 .916-.754 1.66-1.685 1.66a1.673 1.673 0 01-1.685-1.66zm8.616 0c0-.917.754-1.66 1.685-1.66.932 0 1.685.743 1.685 1.66 0 .916-.753 1.66-1.685 1.66a1.673 1.673 0 01-1.685-1.66zm8.634 0c0-.917.754-1.66 1.685-1.66s1.685.743 1.685 1.66c0 .916-.754 1.66-1.685 1.66a1.673 1.673 0 01-1.685-1.66z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
