<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.76 10.908a3.64 3.64 0 00-2.793-3.54 4.47 4.47 0 00-8.909.487 3.64 3.64 0 001.982 6.692h2.065l-.634 1.827a.2.2 0 00.189.266h.948a.2.2 0 01.198.226l-.305 2.283c-.028.21.247.314.365.138l2.246-3.35a.2.2 0 00-.152-.31l-1.339-.099.559-.98h1.94a3.64 3.64 0 003.64-3.64z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
