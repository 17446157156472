export default {
  getIsLoading: state => state.isLoading,
  getVideoArrayIsLoading: state => state.videoArrayIsLoading,
  getIsUploading: state => state.isUploading,
  getVideos: state => state.videos,
  getVideosPagination: state => state.videosPagination,
  getPlaylistVideos: state => state.playlistVideos,
  getPlaylists: state => state.playlists,
  getOptions: state => state.options,
  getSelectedType: state => state.selectedType,
  getFoldersOptions: (state, getters, rootState, rootGetters) => rootGetters['foldersModule/getFolders'],
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,

  getPlaylist: state => state.playlist,
  getPublicId: state => state.playlist.publicId,
  getPlaylistTitle: state => state.playlist.title,
  getPlaylistDescription: state => state.playlist.description,
  getPlaylistCanEmbed: state => state.playlist.canEmbed,
  getPlaylistType: state => state.playlist.playlistType,
  getPlaylistCustomParameters: state => state.playlist.customParameters,
  getPlaylistIncludeTags: state => state.playlist.includeTags,
  getPlaylistExcludeTags: state => state.playlist.excludeTags,
  getPlaylistIncludeTagsState: state => state.playlist.includeTagsState,
  getPlaylistExcludeTagsState: state => state.playlist.excludeTagsState,
  getPlaylistIncludeTagsModeId: state => state.playlist.includeTagModeId,
  getPlaylistExcludeTagsModeId: state => state.playlist.excludeTagModeId,
  getPlaylistIncludeFlags: state => state.playlist.includeFlags,
  getPlaylistExcludeFlags: state => state.playlist.excludeFlags,
  getPlaylistIncludeFlagsState: state => state.playlist.includeFlagsState,
  getPlaylistExcludeFlagsState: state => state.playlist.excludeFlagsState,
  getPlaylistIncludeFlagModeId: state => state.playlist.includeFlagModeId,
  getPlaylistExcludeFlagModeId: state => state.playlist.excludeFlagModeId,
  getPlaylistFolders: state => state.playlist.folders,
  getPlaylistAspectRatios: state => state.aspectRatios,
  getPlaylistAspectRatioTypeId: state => state.playlist.aspectRatioTypeId,
  getPlaylistMaxVideos: state => state.playlist.maxVideos,
  getPlaylistDateFrom: state => state.playlist.dateFrom,
  getPlaylistDateTo: state => state.playlist.dateTo,
  getPlaylistSortOptionId: state => state.playlist.playlistSortOptionId,
  getPlaylistIncludeAllProjects: state => state.playlist.includeAllProjects,
  getPlaylistIncludeProjects: state => state.playlist.includeProjects,
  getCurrentItemId: state => state.playlist.currentItemId,
  getPlaylistUserId: state => state.playlist.userId,
  getPlaylistDisplayTypeId: state => state.playlist.playlistDisplayTypeId,
  getPlaylistDisplayType: state => state.playlist.playlistDisplayType,
  getPlaylistDisplayTypes: state => state.playlistDisplayTypes,
}
