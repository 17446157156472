<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.65625 2.625C2.65625 2.3316 2.4184 2.09375 2.125 2.09375C1.8316 2.09375 1.59375 2.3316 1.59375 2.625V13.25C1.59375 14.717 2.78299 15.9062 4.25 15.9062H14.875C15.1684 15.9062 15.4062 15.6684 15.4062 15.375C15.4062 15.0816 15.1684 14.8437 14.875 14.8437H4.25C3.3698 14.8437 2.65625 14.1302 2.65625 13.25L2.65625 2.625Z"
      fill="currentColor"
    />
    <path
      d="M15.3501 2.8627C15.4813 2.60027 15.375 2.28117 15.1125 2.14995C14.8501 2.01874 14.531 2.12511 14.3998 2.38753L11.9511 7.28496C11.5756 8.03601 10.5746 8.19845 9.98083 7.60469C8.84311 6.46697 6.91799 6.82442 6.26452 8.29473L4.47283 12.326C4.35367 12.5941 4.47442 12.9081 4.74253 13.0272C5.01065 13.1464 5.32459 13.0257 5.44376 12.7575L7.23544 8.72625C7.58608 7.93732 8.61906 7.74551 9.22953 8.35599C10.3361 9.46256 12.2016 9.15983 12.9014 7.76013L15.3501 2.8627Z"
      fill="currentColor"
    />
  </svg>
</template>
