<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.25 3.75C2.25 3.33579 2.58579 3 3 3H6C6.41421 3 6.75 3.33579 6.75 3.75V6.75C6.75 7.16421 6.41421 7.5 6 7.5H3C2.58579 7.5 2.25 7.16421 2.25 6.75V3.75ZM3.375 6.375V4.125H5.625V6.375H3.375Z"
      fill="currentColor"
    />
    <path
      d="M8.25 3.1875C7.93934 3.1875 7.6875 3.43934 7.6875 3.75C7.6875 4.06066 7.93934 4.3125 8.25 4.3125H15.75C16.0607 4.3125 16.3125 4.06066 16.3125 3.75C16.3125 3.43934 16.0607 3.1875 15.75 3.1875H8.25Z"
      fill="currentColor"
    />
    <path
      d="M7.6875 6C7.6875 5.68934 7.93934 5.4375 8.25 5.4375H12C12.3107 5.4375 12.5625 5.68934 12.5625 6C12.5625 6.31066 12.3107 6.5625 12 6.5625H8.25C7.93934 6.5625 7.6875 6.31066 7.6875 6Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.25 11.25C2.25 10.8358 2.58579 10.5 3 10.5H6C6.41421 10.5 6.75 10.8358 6.75 11.25V14.25C6.75 14.6642 6.41421 15 6 15H3C2.58579 15 2.25 14.6642 2.25 14.25V11.25ZM3.375 13.875V11.625H5.625V13.875H3.375Z"
      fill="currentColor"
    />
    <path
      d="M7.6875 11.25C7.6875 10.9393 7.93934 10.6875 8.25 10.6875H15.75C16.0607 10.6875 16.3125 10.9393 16.3125 11.25C16.3125 11.5607 16.0607 11.8125 15.75 11.8125H8.25C7.93934 11.8125 7.6875 11.5607 7.6875 11.25Z"
      fill="currentColor"
    />
    <path
      d="M8.25 12.9375C7.93934 12.9375 7.6875 13.1893 7.6875 13.5C7.6875 13.8107 7.93934 14.0625 8.25 14.0625H12C12.3107 14.0625 12.5625 13.8107 12.5625 13.5C12.5625 13.1893 12.3107 12.9375 12 12.9375H8.25Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
