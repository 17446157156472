<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.033 7.869a.6.6 0 01.6-.6h2.487a3.64 3.64 0 110 7.279H9.04a3.64 3.64 0 01-1.84-6.78.6.6 0 11.607 1.034 2.44 2.44 0 001.233 4.545h6.08a2.44 2.44 0 100-4.878h-2.487a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.058 7.869a4.469 4.469 0 018.937 0 .6.6 0 01-1.2 0 3.269 3.269 0 10-5.94 1.883.6.6 0 01-.98.693 4.451 4.451 0 01-.817-2.576zM11.687 13.688a.5.5 0 00-.398.33l-.818 2.356a.2.2 0 00.189.266h.948a.2.2 0 01.198.226l-.305 2.283c-.028.21.247.314.365.138l2.246-3.35a.2.2 0 00-.152-.31l-1.339-.099.724-1.27a.5.5 0 00-.509-.742l-1.15.172z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
