export function addHash(v) {
  if (v === undefined || v === '') return '#000000'
  return `#${v}`
}
export function replaceHash(v) {
  return v.replace('#', '')
}
// new addHash function: null, undefined and '' will return null
// all color strings can be handled as empty strings on player side, player will use its default color
export function newAddHash(v) {
  if (v === undefined || v === null || v === '') return null
  return `#${v}`
}
