/* eslint-disable no-unused-vars */
export default [

  // Analytics Routing
  // Real Time Routing
  {
    path: '',
    component: () => import('@/views/analytics/analytics/Analytics.vue'),
    children: [
      {
        path: '/:projectId/analytics',
        name: 'advanced-analytics',
        component: () => import('@/views/analytics/analytics/tabs/Videos.vue'),
        meta: {
          titleText: 'Analytics',
          parentText: 'Dashboard',
          contentClass: 'advanced-analytics',
          navActiveLink: 'advanced-analytics',
          gjIcon: 'Analytics',
          action: 'read',
          resource: 'real-time-analytics',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Analytics',
              to: 'advanced-analytics',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/analytics',
        name: 'analytics-podcasts',
        component: () => import('@/views/analytics/analytics/tabs/Podcasts.vue'),
        meta: {
          titleText: 'Analytics',
          parentText: 'Dashboard',
          contentClass: 'advanced-analytics',
          navActiveLink: 'advanced-analytics',
          gjIcon: 'Analytics',
          action: 'read',
          resource: 'real-time-analytics',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Analytics',
              to: 'advanced-analytics',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/analytics',
        name: 'analytics-live-videos',
        component: () => import('@/views/analytics/analytics/tabs/LiveVideos.vue'),
        meta: {
          titleText: 'Analytics',
          parentText: 'Dashboard',
          contentClass: 'advanced-analytics',
          navActiveLink: 'advanced-analytics',
          gjIcon: 'Analytics',
          action: 'read',
          resource: 'real-time-analytics',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Analytics',
              to: 'advanced-analytics',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/analytics',
        name: 'analytics-audios',
        component: () => import('@/views/analytics/analytics/tabs/Audios.vue'),
        meta: {
          titleText: 'Analytics',
          parentText: 'Dashboard',
          contentClass: 'advanced-analytics',
          navActiveLink: 'advanced-analytics',
          gjIcon: 'Analytics',
          action: 'read',
          resource: 'real-time-analytics',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Analytics',
              to: 'advanced-analytics',
              active: true,
            },
          ],
        },
      },
    ],

  },

  // Content Routing
  {
    path: '/:projectId/content',
    name: 'content',
    component: () => import('@/views/analytics/content/AnalyticsContent.vue'),
    meta: {
      titleText: 'Content Analytics',
      parentText: 'Dashboard',
      contentClass: 'content',
      navActiveLink: 'content',
      gjIcon: 'Article',
      action: 'read',
      resource: 'user',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Content',
          to: 'content',
          active: true,
        },
      ],
    },
  },

  // Custom Reports Routing
  {
    path: '/:projectId/custom-reports',
    component: () => import('@/views/analytics/custom-reports/CustomReport.vue'),
    meta: {
      titleText: 'Custom Reports',
      parentText: 'Dashboard',
      contentClass: 'custom-reports',
      navActiveLink: 'custom-reports',
      gjIcon: 'Note',
      action: 'read',
      resource: 'custom-report',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Custom reports',
          to: 'custom-reports',
          active: true,
        },
      ],
    },
    children: [
      {
        path: '/',
        name: 'custom-reports',
        component: () => import('@/views/analytics/custom-reports/CustomReports.vue'),
        meta: {
          titleText: 'Custom Reports',
          parentText: 'Dashboard',
          contentClass: 'custom-reports',
          navActiveLink: 'custom-reports',
          gjIcon: 'Note',
          action: 'read',
          resource: 'custom-report',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Custom reports',
              to: 'custom-reports',
              active: true,
            },
          ],
        },
      },
      {
        path: 'add/:tab',
        name: 'custom-reports-add',
        component: () => import('@/views/analytics/custom-reports/Add.vue'),
        meta: {
          titleText: 'Custom Reports',
          parentText: 'Dashboard',
          contentClass: 'custom-report',
          navActiveLink: 'custom-reports',
          gjIcon: 'Note',
          action: 'write',
          resource: 'custom-report',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Custom reports',
              to: 'custom-reports',
            },
            {
              text: 'New report',
              active: true,
            },
          ],
        },
      },
      {
        path: ':tab/:id',
        name: 'custom-reports-edit',
        component: () => import('@/views/analytics/custom-reports/Add.vue'),
        meta: {
          title: '',
          titleText: 'Custom Reports',
          parentText: 'Dashboard',
          contentClass: 'custom-report',
          navActiveLink: 'custom-reports',
          gjIcon: 'Note',
          action: 'execute',
          resource: 'custom-report',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Custom reports',
              to: 'custom-reports',
            },
            {
              dynamic: true,
              text: 'Edit Custom Report',
              active: true,
            },
          ],
        },
      },
      {
        path: 'view/:tab/:id',
        name: 'custom-reports-view',
        component: () => import('@/views/analytics/custom-reports/View.vue'),
        meta: {
          title: '',
          titleText: 'Custom Reports',
          parentText: 'Dashboard',
          contentClass: 'custom-report',
          navActiveLink: 'custom-reports',
          gjIcon: 'Note',
          action: 'execute',
          resource: 'custom-report',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Custom reports',
              to: 'custom-reports',
            },
            {
              dynamic: true,
              text: 'Edit Custom Report',
              active: true,
            },
          ],
        },
      },
    ],
  },

  // Usage History Routing
  {
    path: '/:projectId/usage',
    name: 'usage',
    component: () => import('@/views/analytics/usage-history/UsageHistory.vue'),
    meta: {
      titleText: 'Usage History',
      parentText: 'Dashboard',
      contentClass: 'usage',
      navActiveLink: 'usage',
      gjIcon: 'Storage',
      action: 'read',
      resource: 'usage',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Usage',
          to: 'usage',
          active: true,
        },
      ],
    },
  },

]
