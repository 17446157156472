<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.4a8.6 8.6 0 100 17.2 8.6 8.6 0 000-17.2zm0 5.714a.6.6 0 01.6.6V11.4h1.686a.6.6 0 010 1.2H12.6v1.686a.6.6 0 01-1.2 0V12.6H9.714a.6.6 0 110-1.2H11.4V9.714a.6.6 0 01.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
