<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.999 3c-.82 0-1.565.472-1.917 1.21h-2.8A2.054 2.054 0 005.23 6.26V18.95A2.054 2.054 0 007.281 21h9.436a2.054 2.054 0 002.05-2.051V6.26a2.054 2.054 0 00-2.05-2.05h-2.801A2.123 2.123 0 0011.998 3zm0 .89c-.57 0-1.072.391-1.192.935a.35.35 0 01-.341.275h-.979v.486c0 .64.52 1.16 1.161 1.16h2.702c.64 0 1.16-.52 1.16-1.16V5.1h-.978a.35.35 0 01-.341-.275c-.12-.544-.622-.935-1.192-.935zm2.903 7.37a.546.546 0 00-.77-.01l-2.853 2.786-1.414-1.38a.545.545 0 00-.77.772l1.803 1.76c.212.207.55.207.762 0l3.234-3.158a.545.545 0 00.008-.77z"
    />
  </svg>
</template>
