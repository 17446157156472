<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.054 5.23a.63.63 0 01.63-.63h1.231a.63.63 0 01.631.63v.71l.437.123c.466.132.91.317 1.325.55l.396.22.501-.5a.63.63 0 01.893 0l.87.87a.63.63 0 01.078.797h1.273a1.829 1.829 0 00-.503-1.646l-.87-.87a1.831 1.831 0 00-2.45-.126 7.325 7.325 0 00-.76-.315 1.831 1.831 0 00-1.82-1.643H9.684c-.948 0-1.728.72-1.822 1.643-.26.091-.513.197-.759.315a1.831 1.831 0 00-2.45.126l-.87.87a1.831 1.831 0 00-.126 2.45c-.118.246-.224.5-.315.76a1.831 1.831 0 00-1.643 1.82v1.231c0 .948.72 1.728 1.643 1.822.091.26.197.513.315.759-.586.719-.545 1.78.126 2.45l.87.87a1.83 1.83 0 002.45.126c.246.118.5.224.76.315a1.83 1.83 0 001.82 1.643h1.231c.948 0 1.728-.72 1.822-1.643a7.32 7.32 0 00.759-.315 1.83 1.83 0 002.45-.126l.87-.87A1.829 1.829 0 0017.32 16h-1.273a.631.631 0 01-.078.797l-.87.87a.63.63 0 01-.893 0l-.501-.5-.396.22a6.132 6.132 0 01-1.325.55l-.437.124v.708a.63.63 0 01-.63.631H9.684a.63.63 0 01-.631-.63v-.71l-.437-.123a6.132 6.132 0 01-1.325-.55l-.396-.22-.501.5a.63.63 0 01-.892 0l-.87-.87a.63.63 0 010-.892l.5-.501-.22-.396a6.127 6.127 0 01-.55-1.325l-.124-.437h-.708a.63.63 0 01-.631-.63v-1.231a.63.63 0 01.63-.631h.71l.123-.437c.132-.466.317-.91.55-1.325l.22-.396-.5-.501a.63.63 0 010-.892l.87-.87a.63.63 0 01.892 0l.501.5.396-.22a6.128 6.128 0 011.325-.55l.437-.124v-.708z"
    />
    <path
      d="M11.35 10.704h.056l.446 1.594h-.947l.446-1.594zM15.728 10.596c.457 0 .726.234.726.674v.005c0 .44-.27.677-.726.677h-.487v-1.356h.487z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3 8a4 4 0 100 8h8a4 4 0 000-8h-8zm-1 6h1.128l.26-.929h1.38l.261.929h1.128l-1.42-4.228H10.72L9.3 14zm4.866 0h1.075v-1.24h.745c.946 0 1.555-.58 1.555-1.485v-.005c0-.906-.61-1.498-1.556-1.498h-1.819V14zm5.241 0h-1.075V9.772h1.075V14z"
    />
  </svg>
</template>
