import project from '@/store/account/project/moduleProject'

export default {
  SET_ORGANIZATION: (state, data) => { state.organization = data },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  SET_ORGANIZATIONS: (state, data) => { state.organizations = data },
  SET_PINNED_ORGANIZATIONS: (state, data) => { state.pinnedOrganizations = data },
  SET_UNPINNED_ORGANIZATIONS: (state, data) => { state.unpinnedOrganizations = data },
  SET_ORGANIZATIONS_OF_USER: (state, data) => { state.organizationsOfUser = data },
  SET_ACTIVE_ORGANIZATION: (state, data) => {
    state.activeOrganization = data
    state.activeOrganizationId = data.publicId
    project.state.filters.params.organizationId = data.publicId
    project.state.dropdownFilters.params.organizationId = data.publicId
    project.state.filters.params.organizationId = data.publicId
  },
  SET_ACTIVE_ORGANIZATION_ID: (state, data) => { state.activeOrganizationId = data },
  SET_ACTIVE_ORGANIZATION_PAGE: (state, data) => { state.activeOrganizationPage = data },
  UPDATE_ORGANIZATIONS_FOR_ACTIVE: (state, publicId) => {
    state.organizations.map(x => {
      if (x.publicId === publicId) { x.isDefault = true; return x }
      x.isDefault = false; return x
    })
  },
  SET_COUNTRIES: (state, data) => { state.countries = data },
  // SET_ORGANIZATION_VIDEOCATALOG_URL: (state, data) => { state.videoCatalogUrl = data },

  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },
  UPDATE_FILTER: (state, data) => {
    state.filters.params[data.propKey] = data.value
  },
}
