<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.18 3.4a.6.6 0 01.6.6v16a.6.6 0 11-1.2 0V4a.6.6 0 01.6-.6zM6.853 7.182a1.6 1.6 0 011.6-1.6h4.545a1.6 1.6 0 011.6 1.6v2.363a1.6 1.6 0 01-1.6 1.6H8.453a1.6 1.6 0 01-1.6-1.6V7.182zm1.6-.4a.4.4 0 00-.4.4v2.363c0 .221.179.4.4.4h4.545a.4.4 0 00.4-.4V7.182a.4.4 0 00-.4-.4H8.453zM6.853 14.455a1.6 1.6 0 011.6-1.6h10.363a1.6 1.6 0 011.6 1.6v2.363a1.6 1.6 0 01-1.6 1.6H8.453a1.6 1.6 0 01-1.6-1.6v-2.363zm1.6-.4a.4.4 0 00-.4.4v2.363c0 .221.179.4.4.4h10.363a.4.4 0 00.4-.4v-2.363a.4.4 0 00-.4-.4H8.453z"
    />
  </svg>
</template>
