<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.761 8.73509C14.7294 8.70351 14.7104 8.67193 14.6852 8.63404L14.0788 7.52252L12.9673 6.91614C12.822 6.83403 12.7399 6.68878 12.7399 6.52458C12.7399 6.36039 12.8283 6.20884 12.9672 6.13308L14.0786 5.52696L14.6847 4.41558C14.7668 4.27034 14.9183 4.18195 15.0762 4.18828C15.2341 4.19462 15.392 4.27673 15.4678 4.41567L16.0741 5.52719L17.1856 6.13356C17.3246 6.20936 17.413 6.36093 17.413 6.52512C17.4131 6.68932 17.3247 6.84087 17.1857 6.91663L16.0744 7.52275L15.4682 8.63413C15.3862 8.77937 15.2346 8.86776 15.0767 8.86143C14.9567 8.86773 14.8431 8.81719 14.761 8.73509Z"
      fill="currentColor"
    />
    <path
      d="M7.81345 17.3135C7.74467 17.2447 7.70339 17.176 7.64837 17.0934L6.32762 14.6724L3.90661 13.3517C3.59022 13.1728 3.41137 12.8564 3.41133 12.4988C3.41129 12.1412 3.60382 11.8111 3.90641 11.6461L6.32711 10.3259L7.6473 7.90518C7.82608 7.58884 8.15617 7.39631 8.50005 7.4101C8.84392 7.4239 9.18781 7.60275 9.35291 7.90538L10.6737 10.3264L13.0947 11.6471C13.3973 11.8122 13.5899 12.1424 13.5899 12.5C13.59 12.8576 13.3975 13.1877 13.0949 13.3527L10.6742 14.6729L9.35397 17.0936C9.17519 17.41 8.8451 17.6025 8.50122 17.5887C8.23988 17.6024 7.99228 17.4924 7.81345 17.3135Z"
      fill="currentColor"
    />
    <path
      d="M16.6381 19.6103C16.594 19.5662 16.5676 19.5221 16.5323 19.4692L15.6858 17.9175L14.1341 17.071C13.9313 16.9564 13.8167 16.7536 13.8166 16.5244C13.8166 16.2951 13.94 16.0836 14.134 15.9778L15.6855 15.1317L16.5316 13.5802C16.6462 13.3774 16.8578 13.254 17.0782 13.2628C17.2986 13.2717 17.519 13.3863 17.6248 13.5803L18.4713 15.132L20.023 15.9785C20.217 16.0843 20.3404 16.2959 20.3404 16.5251C20.3405 16.7543 20.2171 16.9659 20.0231 17.0717L18.4716 17.9178L17.6255 19.4693C17.5109 19.6721 17.2993 19.7955 17.0789 19.7866C16.9114 19.7954 16.7527 19.7249 16.6381 19.6103Z"
      fill="currentColor"
    />
  </svg>

</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
