<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.033 7.174a4.99 4.99 0 011.518.657l3.118-3.343.006-.006a2.533 2.533 0 013.583 3.583l-.004.004-3.74 3.653c.392 1.04.482 2.017.321 2.915-.184 1.03-.69 1.92-1.382 2.663-1.371 1.474-3.498 2.402-5.486 2.771-2 .372-4.221.196-5.966-.797C3.224 18.262 2 16.433 2 13.657v-.729l.68.262c1.241.479 2.103.533 2.723.379.607-.15 1.053-.519 1.431-1.043.389-.537.682-1.203.996-1.919l.01-.022c.3-.685.624-1.425 1.067-2.01.936-1.237 2.514-1.79 4.126-1.4zm4.655-1.24l.7-.75a1.533 1.533 0 012.165 2.172l-.717.7-.004-.005-2.034-2.033a.5.5 0 00-.11-.084zm-.662.71l-1.678 1.8c.638.578 1.227 1.344 1.728 2.308l2.045-1.998-2.03-2.029a.5.5 0 01-.065-.08zm-4.228 1.502c-1.245-.3-2.41.129-3.094 1.033-.362.479-.643 1.111-.958 1.83l-.015.034c-.3.684-.631 1.44-1.086 2.069-.472.653-1.1 1.204-2.002 1.427-.727.181-1.584.136-2.611-.171.184 2.017 1.16 3.295 2.464 4.037a6.23 6.23 0 001.672.64c3.54-.901 4.332-3.297 4.332-4.255a.5.5 0 111 0c0 1.135-.71 3.172-3.073 4.453a9.69 9.69 0 001.358-.155c1.851-.344 3.755-1.2 4.936-2.47.585-.628.985-1.35 1.13-2.157.144-.805.04-1.734-.441-2.792-.99-2.172-2.368-3.223-3.612-3.523z"
    />
  </svg>
</template>
