<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.11668 18.875C8.71668 18.875 8.32501 18.775 7.95834 18.575C7.22501 18.1667 6.78334 17.425 6.78334 16.5917V12.175C6.78334 11.7583 6.50834 11.1333 6.25001 10.8167L3.13334 7.51667C2.60834 6.99167 2.20834 6.09167 2.20834 5.41667V3.5C2.20834 2.16667 3.21668 1.125 4.50001 1.125H15.5C16.7667 1.125 17.7917 2.15 17.7917 3.41667V5.25C17.7917 6.125 17.2667 7.11667 16.775 7.60833L13.1667 10.8C12.8167 11.0917 12.5417 11.7333 12.5417 12.25V15.8333C12.5417 16.575 12.075 17.4333 11.4917 17.7833L10.3417 18.525C9.96668 18.7583 9.54168 18.875 9.11668 18.875ZM4.50001 2.375C3.91668 2.375 3.45834 2.86667 3.45834 3.5V5.41667C3.45834 5.725 3.70834 6.325 4.02501 6.64167L7.20001 9.98333C7.62501 10.5083 8.04168 11.3833 8.04168 12.1667V16.5833C8.04168 17.125 8.41668 17.3917 8.57501 17.475C8.92501 17.6667 9.35001 17.6667 9.67501 17.4667L10.8333 16.725C11.0667 16.5833 11.3 16.1333 11.3 15.8333V12.25C11.3 11.3583 11.7333 10.375 12.3583 9.85L15.925 6.69167C16.2083 6.40833 16.55 5.73333 16.55 5.24167V3.41667C16.55 2.84167 16.0833 2.375 15.5083 2.375H4.50001Z"
      fill="currentColor "
    />
    <path
      d="M5 8.95833C4.88333 8.95833 4.775 8.925 4.66666 8.86666C4.375 8.68333 4.28333 8.29166 4.46666 8L8.575 1.41666C8.75833 1.125 9.14166 1.03333 9.43333 1.21666C9.725 1.4 9.81666 1.78333 9.63333 2.075L5.525 8.65833C5.40833 8.85 5.20833 8.95833 5 8.95833Z"
      fill="currentColor "
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
