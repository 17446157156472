export default {
  getIsLoading: state => state.isLoading,
  getIsBiddingAdd: state => state.isAdd,
  getBidding: state => state.bidding,
  getBiddings: state => state.biddings,
  getName: state => state.bidding.name,
  getMediationOptions: state => state.options.mediationOptions,
  // getBidderOptions: state => state.options.bidderOptions.filter(el => state.bidding.biddingTemplateBidders.filter(y => y.isDeleted === false).map(x => x.bidderId).indexOf(el.id) < 0),
  getBidderOptions: state => state.options.bidderOptions,

  // // Values
  getAdPartnerInputValue: state => (partnerIndex, inputIndex) => state.bidding.biddingTemplateBidders[partnerIndex].bidParamValues[inputIndex].value,
}
