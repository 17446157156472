<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.257 8a.6.6 0 01.6-.6h2.572a3.743 3.743 0 012.545 6.487.6.6 0 11-.816-.88 2.543 2.543 0 00-1.73-4.407h-2.57a.6.6 0 01-.6-.6zM8.071 8.127a.6.6 0 01-.213.821 2.543 2.543 0 001.285 4.738h5.143a.257.257 0 100-.515h-.858a.6.6 0 010-1.2h.857a1.457 1.457 0 010 2.915H9.143A3.743 3.743 0 017.25 7.913a.6.6 0 01.821.214z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.113 8a4.6 4.6 0 019.2 0 .6.6 0 01-1.2 0 3.4 3.4 0 10-6.18 1.96.6.6 0 01-.98.692A4.582 4.582 0 017.114 8zM5.4 16a.6.6 0 01.6-.6h5.714a.6.6 0 010 1.2H6a.6.6 0 01-.6-.6zM7.686 17.714a.6.6 0 01.6-.6h9.143a1.743 1.743 0 110 3.486h-1.715a.6.6 0 010-1.2h1.714a.543.543 0 100-1.086H8.287a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
