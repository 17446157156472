<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.4a8.6 8.6 0 100 17.2 8.6 8.6 0 000-17.2zm.6 3.743a.6.6 0 10-1.2 0v5.106l3.607 3.604a.6.6 0 10.848-.849L12.6 11.751V7.143z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
