import axios from 'axios'
// import store from '@/store'
import { actions, state } from './state/store'
import encode from './encode'
import reencode from './reencode'

async function uploadPart(file, requestKey, videoId, isForReencode) {
  file.uploadType = 'single'

  state.eventBus.$emit(state.eventBusName, state.uploads)
  const params = {
    fileName: file.name,
    mimeType: file.type,
  }

  const headers = {
    'Access-Control-Allow-Origin': '*',
  }
  let one = true
  await actions.getPreSigned({ params, headers })
    .then(response => {
      file.cancelToken = axios.CancelToken.source()
      file.requestKey = response.data.result.requestKey
      const options = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/octet-stream',
          ACL: 'public-read',
        },
        onUploadProgress: progressEvent => {
          const { total } = progressEvent
          file.progress = progressEvent.lengthComputable ? total : null
          if (file.progress !== null) {
            file.progress = Math.round((progressEvent.loaded * 100) / total)
          }
          if (one) file.status = 'uploading'; one = false

          state.eventBus.$emit(state.eventBusName, state.uploads)
        },
        cancelToken: file.cancelToken.token,
      }

      state.eventBus.$emit(state.eventBusName, state.uploads)
      // response.data.result.url => presigned url from response,
      // file => file file to upload,
      // options => headers, params, onUploadProgress, cancelToken
      return axios.put(response.data.result.url, file.file, options)

      // test with hardcoded presigned url
      // return axios.put('https://', video, options)
        .then(async () => {
          if (!isForReencode) {
            encode(file, file.requestKey)
          } else {
            reencode(videoId, file, file.requestKey)
          }
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            file.status = 'canceled'
          } else file.status = 'failed'; file.failedStatus = 'upload'

          state.eventBus.$emit(state.eventBusName, state.uploads)
        })
    })
    .catch(() => {
      file.status = 'failed'

      state.eventBus.$emit(state.eventBusName, state.uploads)
    })
}

export default uploadPart
