<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.093 16.06a.547.547 0 01-.83 0L5.666 12l3.597-4.06a.547.547 0 01.83 0c.21.237.21.61 0 .847L7.245 12l2.848 3.213c.21.237.21.61 0 .846zM13.907 16.06a.547.547 0 00.83 0L18.334 12l-3.597-4.06a.547.547 0 00-.83 0 .647.647 0 000 .847L16.755 12l-2.848 3.213a.647.647 0 000 .846z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
