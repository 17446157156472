<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2348 1.73483C10.3813 1.58839 10.6187 1.58839 10.7652 1.73483C10.9116 1.88128 10.9116 2.11872 10.7652 2.26517L8.53033 4.5H9.125C9.33211 4.5 9.5 4.66789 9.5 4.875C9.5 5.08211 9.33211 5.25 9.125 5.25H7.78033L6.03033 7H9.125C9.33211 7 9.5 7.16789 9.5 7.375C9.5 7.58211 9.33211 7.75 9.125 7.75H5.28033L2.76517 10.2652C2.61872 10.4116 2.38128 10.4116 2.23483 10.2652C2.08839 10.1187 2.08839 9.88128 2.23483 9.73483L10.2348 1.73483Z"
      fill="currentColor"
    />
    <path
      d="M3.96967 7L3.21967 7.75H2.875C2.66789 7.75 2.5 7.58211 2.5 7.375C2.5 7.16789 2.66789 7 2.875 7H3.96967Z"
      fill="currentColor"
    />
    <path
      d="M6.46967 4.5L5.71967 5.25H2.875C2.66789 5.25 2.5 5.08211 2.5 4.875C2.5 4.66789 2.66789 4.5 2.875 4.5H6.46967Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
