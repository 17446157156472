<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>

<template>
  <svg
    style="fill: currentColor"
    :width="size"
    :height="size"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.25153 1.64688L0.650559 13.5286C-0.0900317 14.8617 0.873904 16.4999 2.39887 16.4999H15.6008C17.1258 16.4999 18.0897 14.8617 17.3491 13.5286L10.7482 1.64688C9.98615 0.275261 8.01354 0.275258 7.25153 1.64688ZM9.99984 5.49991C9.99984 4.94763 9.55213 4.49991 8.99984 4.49991C8.44756 4.49991 7.99984 4.94763 7.99984 5.49991V9.49991C7.99984 10.0522 8.44756 10.4999 8.99984 10.4999C9.55213 10.4999 9.99984 10.0522 9.99984 9.49991V5.49991ZM8.99984 14.4999C9.55213 14.4999 9.99984 14.0522 9.99984 13.4999C9.99984 12.9476 9.55213 12.4999 8.99984 12.4999C8.44756 12.4999 7.99984 12.9476 7.99984 13.4999C7.99984 14.0522 8.44756 14.4999 8.99984 14.4999Z"
    />
  </svg>
</template>
