<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.75 8.942a.75.75 0 00-1.5 0v5a.75.75 0 001.5 0v-5zM13 16.942a1 1 0 11-2 0 1 1 0 012 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.273 4.797c-.99-1.783-3.555-1.783-4.546 0l-6.6 11.882c-.964 1.733.29 3.863 2.272 3.863h13.202c1.982 0 3.236-2.13 2.273-3.863L14.273 4.797zm-3.497.583a1.4 1.4 0 012.448 0l6.6 11.882a1.4 1.4 0 01-1.223 2.08H5.399a1.4 1.4 0 01-1.224-2.08L10.776 5.38z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
