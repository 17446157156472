/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {

  getCategories({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/categories`)
        .then(async response => {
          commit('SET_CATEGORIES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getCategoriesById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/categories/${id}`)
        .then(response => {
          commit('SET_CATEGORY', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
