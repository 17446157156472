class CustomReportFilter {
  constructor() {
    this.id = 0
    this.filterId = null
    this.filterOperatorId = 0
    this.customReportId = 0
    this.filterOperatorDisplay = ''
    this.filterDisplay = ''
    this.value = ''
  }

  fromData(data) {
    this.id = data.id
    this.filterId = data.filterId || null
    this.filterOperatorId = data.filterOperatorId
    this.customReportId = data.customReportId
    this.filterOperatorDisplay = data.filterOperatorDisplay
    this.filterDisplay = data.filterDisplay
    this.value = data.value ? this.checkVal(data) : ''
  }

  checkVal(d) {
    this.data = d
    if (d.filterOperatorId === 29 || d.filterOperatorId === 30) {
      return d.value.split(',')
    }
    return d.value
  }
}

export default CustomReportFilter
