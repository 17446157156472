<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.778 4.31A3.432 3.432 0 004.35 7.74a3.432 3.432 0 003.428 3.427 3.432 3.432 0 003.428-3.427A3.433 3.433 0 007.778 4.31zM12.795 16.808a3.432 3.432 0 013.427-3.427 3.432 3.432 0 013.428 3.427 3.432 3.432 0 01-3.428 3.428 3.432 3.432 0 01-3.427-3.428zM15.261 5.01L7.549 18.856a.667.667 0 001.166.649l7.712-13.848a.667.667 0 00-1.166-.649z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
