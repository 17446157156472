import state from './moduleVerticalPlayersState'
import mutations from './moduleVerticalPlayersMutations'
import actions from './moduleVerticalPlayersActions'
import getters from './moduleVerticalPlayersGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
