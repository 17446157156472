<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.20005 7.425C7.57284 7.425 7.87505 7.72721 7.87505 8.1V12.6C7.87505 12.9728 7.57284 13.275 7.20005 13.275C6.82726 13.275 6.52505 12.9728 6.52505 12.6V8.1C6.52505 7.72721 6.82726 7.425 7.20005 7.425Z"
      fill="currentColor"
    />
    <path
      d="M11.475 8.1C11.475 7.72721 11.1728 7.425 10.8 7.425C10.4273 7.425 10.125 7.72721 10.125 8.1V12.6C10.125 12.9728 10.4273 13.275 10.8 13.275C11.1728 13.275 11.475 12.9728 11.475 12.6V8.1Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.52505 3.6C6.52505 2.2331 7.63314 1.125 9.00005 1.125C10.367 1.125 11.475 2.2331 11.475 3.6V4.05H15.525C15.8978 4.05 16.2 4.35221 16.2 4.725C16.2 5.09779 15.8978 5.4 15.525 5.4H14.4V13.5C14.4 14.9912 13.1912 16.2 11.7 16.2H6.30005C4.80888 16.2 3.60005 14.9912 3.60005 13.5V5.4H2.47505C2.10226 5.4 1.80005 5.09779 1.80005 4.725C1.80005 4.35221 2.10226 4.05 2.47505 4.05H6.52505V3.6ZM10.125 3.6V4.05H7.87505V3.6C7.87505 2.97868 8.37873 2.475 9.00005 2.475C9.62137 2.475 10.125 2.97868 10.125 3.6ZM4.95005 13.5V5.85H13.05V13.5C13.05 14.2456 12.4456 14.85 11.7 14.85H6.30005C5.55446 14.85 4.95005 14.2456 4.95005 13.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
