<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 17.554a.6.6 0 010 1.2H8.218a2.447 2.447 0 110-1.2H20zm-12.908.603v-.006a1.246 1.246 0 100 .006zM17.526 12.6a2.447 2.447 0 01-4.744 0H4a.6.6 0 110-1.2h8.782a2.447 2.447 0 014.744 0H20a.6.6 0 010 1.2h-2.474zM16.4 12a1.246 1.246 0 10-2.493 0 1.246 1.246 0 002.493 0zM9.846 8.292a2.447 2.447 0 002.372-1.846H20a.6.6 0 000-1.2h-7.782a2.447 2.447 0 00-4.743-.004H4a.6.6 0 000 1.2h3.473a2.447 2.447 0 002.373 1.85zm0-1.2a1.246 1.246 0 100-2.492 1.246 1.246 0 000 2.492z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
