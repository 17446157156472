<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.35 6a2.6 2.6 0 012.6-2.6h8.571a2.6 2.6 0 012.6 2.6v7.217c.067.043.13.093.19.152l1.006 1.007a1.1 1.1 0 01.023 1.531l-3.943 4.19a1.6 1.6 0 01-1.165.503H13.15a.8.8 0 01-.8-.8v-1.118c0-.407.155-.8.435-1.096l3.946-4.193c.059-.062.122-.116.19-.162V6a1.4 1.4 0 00-1.4-1.4H6.95A1.4 1.4 0 005.55 6v12a1.4 1.4 0 001.4 1.4h4a.6.6 0 010 1.2h-4a2.6 2.6 0 01-2.6-2.6V6zm13.162 8.314l-3.853 4.094a.4.4 0 00-.109.274v.718h.682a.4.4 0 00.292-.126l3.876-4.119-.842-.842a.622.622 0 01-.037.001h-.01z"
    />
    <path
      d="M8.35 9.714a.6.6 0 01.6-.6h2.857a.6.6 0 110 1.2H8.95a.6.6 0 01-.6-.6zM8.95 11.4a.6.6 0 000 1.2h4.571a.6.6 0 100-1.2H8.95zM8.35 14.286a.6.6 0 01.6-.6h4.571a.6.6 0 010 1.2H8.95a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
