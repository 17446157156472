<template>
  <component
    :is="icons[name]"
    :size="size"
  />
</template>

<script>
const requireIcon = require.context('./vue-icons', false, /\.vue$/)
const icons = {}
requireIcon.keys().forEach(fileName => {
  const icon = requireIcon(fileName).default
  const iconName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
  icons[iconName] = icon
})
export default {
  props: {
    name: {
      type: String,
      required: true,
      default: 'Article',
    },
    size: {
      type: String,
      default: '24',
    },
  },
  data() {
    return {
      icons,
    }
  },
  created() {

  },
}
</script>
