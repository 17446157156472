<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.142 15.172a1.4 1.4 0 000 2.8h6.857a1.4 1.4 0 000-2.8h-2.857a.6.6 0 110-1.2h2.857a2.6 2.6 0 110 5.2h-6.857a2.6 2.6 0 010-5.2h.285a.6.6 0 110 1.2h-.285z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.83 14a3.457 3.457 0 016.915 0 .6.6 0 11-1.2 0 2.257 2.257 0 10-4.071 1.344.6.6 0 01-.964.715A3.444 3.444 0 0110.83 14z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.4 9.429a.6.6 0 01.6-.6h2.856a2.6 2.6 0 012.559 2.135.6.6 0 01-1.18.213 1.4 1.4 0 00-1.379-1.148H10a.6.6 0 01-.6-.6zM6 10.029a1.4 1.4 0 100 2.8h5.429a.6.6 0 010 1.2H6a2.6 2.6 0 110-5.2h.286a.6.6 0 010 1.2H6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.685 8.857a3.457 3.457 0 016.915 0 .6.6 0 01-1.2 0 2.257 2.257 0 10-4.072 1.344.6.6 0 01-.963.715 3.445 3.445 0 01-.68-2.059z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
