/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'
import organization from '@/store/account/organization/moduleOrganization'

export default {
  getSocialDistributions({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/organizations/${organization.state.activeOrganizationId}/social-distribution-connections`,
          state.filters,
        )
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_SOCIAL_DISTRIBUTIONS', response.data.result.items)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getSocialDistribution({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/organizations/${organization.state.activeOrganizationId}/social-distribution-connections/${id}`,
        )
        .then(async response => {
          commit('SET_SOCIAL_DISTRIBUTION', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getAuthorizeUrl({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/organizations/${organization.state.activeOrganizationId}/social-distribution-connections/authorize`,
          { params: data },
        )
        .then(async response => {
          commit('SET_AUTHORIZE_URL', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  callbackUrl({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          '/api/social-distribution-connections/authorize',
          { params: data },
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log('error-here', error)
          reject(error)
        })
    })
  },

  getCategories({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/organizations/${organization.state.activeOrganizationId}/social-distribution-connections/${id}/categories`,
        )
        .then(async response => {
          commit('SET_CATEGORIES', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getPrivacyStatuses({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        // eslint-disable-next-line no-irregular-whitespace
        .get(`/api/social-medias/${id}/privacy-statuses`)
        .then(async response => {
          commit('SET_PRICACY_STATUSES', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getFacebookTags({ commit, state }, socialDistributionsId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/organizations/${organization.state.activeOrganizationId}/social-distributions/${socialDistributionsId}/facebook-tags`,
        )
        .then(async response => {
          commit('SET_FACEBOOK_TAGS', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getSocialMedias({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/social-medias')
        .then(async response => {
          commit('SET_SOCIAL_MEDIAS', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteSocialDistibution({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `/api/organizations/${organization.state.activeOrganizationId}/social-distribution-connections/${id}`,
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  shareVideo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/organizations/${organization.state.activeOrganizationId}/social-distribution-connections/share-video`,
          data,
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getSelectList({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/organizations/${organization.state.activeOrganizationId}/social-distribution-connections/select-list`,
        )
        .then(async response => {
          commit('SET_SELECT_LIST', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getMediaHubs({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/organizations/${organization.state.activeOrganizationId}/social-distribution-connections/${id}/media-hubs`,
        )
        .then(async response => {
          commit('SET_MEDIA_HUBS', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  reconnectSocialDistribution({ commit, state }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/api/organizations/${organization.state.activeOrganizationId}/social-distribution-connections/${id}/refresh-token`,
          data,
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  disconnectConnection({ commit, state }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/organizations/${organization.state.activeOrganizationId}/social-distribution-connections/${id}/deactivate`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

}
