<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.716 4.305h-2.862a2.028 2.028 0 00-3.71 0H7.28A1.958 1.958 0 005.325 6.26v12.688a1.958 1.958 0 001.956 1.956h9.436a1.958 1.958 0 001.955-1.956V6.26a1.958 1.958 0 00-1.956-1.956zm-6.25.89c.209 0 .39-.145.434-.35.11-.498.572-.86 1.099-.86s.99.362 1.098.86c.045.205.226.35.435.35h.883v.39c0 .589-.477 1.065-1.065 1.066h-2.702a1.067 1.067 0 01-1.066-1.065v-.39h.884zm7.316 13.754c0 .588-.477 1.065-1.066 1.065H7.281a1.066 1.066 0 01-1.065-1.065V6.26c0-.589.477-1.065 1.065-1.066h1.411v.39a1.958 1.958 0 001.956 1.957h2.702a1.958 1.958 0 001.956-1.956v-.39h1.41c.589 0 1.065.476 1.066 1.065v12.688z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.082 4.21a2.124 2.124 0 013.833 0h2.802a2.054 2.054 0 012.05 2.05V18.95A2.054 2.054 0 0116.718 21H7.28a2.054 2.054 0 01-2.051-2.051V6.26a2.054 2.054 0 012.051-2.052h2.801zM12 3.19c-.766 0-1.46.453-1.768 1.153l-.025.057H7.28a1.863 1.863 0 00-1.86 1.86V18.95a1.863 1.863 0 001.86 1.86h9.435a1.863 1.863 0 001.861-1.86V6.26a1.863 1.863 0 00-1.86-1.861h-2.925l-.025-.057a1.933 1.933 0 00-1.768-1.152zm0 .891c-.485 0-.906.333-1.006.785a.54.54 0 01-.527.424h-.788v.296c0 .535.434.97.97.97h2.702c.535 0 .97-.435.97-.97V5.29h-.788a.54.54 0 01-.528-.424c-.099-.452-.52-.785-1.005-.785zm-1.192.744c.12-.544.622-.935 1.192-.935s1.072.391 1.192.935a.35.35 0 00.341.275h.979v.486c-.001.64-.52 1.16-1.161 1.16h-2.702c-.64 0-1.16-.52-1.16-1.16V5.1h.978a.35.35 0 00.341-.275zM7.281 5.1h1.506v.486a1.863 1.863 0 001.861 1.86h2.702a1.863 1.863 0 001.86-1.86V5.1h1.506c.641 0 1.16.52 1.161 1.16V18.95c0 .64-.52 1.16-1.16 1.16H7.28c-.64 0-1.16-.52-1.16-1.16V6.26c0-.641.52-1.16 1.16-1.161zm0 .19a.971.971 0 00-.97.97v12.688c0 .536.435.97.97.97h9.435c.536 0 .97-.434.97-.97V6.262a.971.971 0 00-.97-.97h-1.315v.295a2.054 2.054 0 01-2.051 2.051h-2.702a2.054 2.054 0 01-2.051-2.051V5.29H7.28z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.133 11.25a.546.546 0 01.761.78l-3.234 3.158a.545.545 0 01-.762 0l-1.802-1.76a.545.545 0 01.77-.773l1.413 1.38 2.854-2.785z"
    />
  </svg>
</template>
