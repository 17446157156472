import Podcast from '@/models/podcasts/podcast'

export default {
  loadingPodcast: false,
  updatingPodcast: false,
  podcasts: [],
  podcast: new Podcast(),
  categories: [],
  subcategories: [],
  audios: [],
  podcastSortTypes: [],
  audiosPagination: {},
  podcastAudios: [],
  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },
  filters: {
    params: {
      insertedDate: null,
      key: null,
      value: null,
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },

  filtersOfAudio: {
    params: {
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
      onlyReady: true,
      onlyPublishedAudios: true,
    },
  },
  // Audio Analytics data
  podcastAnalyticsTotal: 0,
  listenEpisodeTotal: 0,
  liveViewersTotal: 0,
  podcastAnalytics: [],
  topCountries: [],
  topDevices: [],
  topBrowsers: [],
  customDate: [],
  podcastEpisodes: [],
  listenEpisode: [],
  dateStr: '',
  listenEpisodeGroupedData: [],
  topBrowsersGroupedData: {},
  podcastAnalyticsGropuedData: {},
  liveViewersGroupedData: {},
  topCountriesGroupedData: {},
  topDevicesGroupedData: {},

  // Audio Analytics loaders
  loadingTopBrowsers: false,
  loadingAnalytics: false,
  loadingTopCountries: false,
  loadingTopDevices: false,
  loadingLiveViewers: false,
  loadingCustomAnalytics: false,
  loadingEpisodes: false,
  loadingListenEpisode: false,
  loadingAnalyticsReport: false,
  analyticsReport: null,
}
