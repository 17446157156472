<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.417 6.403a.6.6 0 101.2 0V5.281a.6.6 0 00-1.2 0v1.122zM13.525 7.675a.6.6 0 01-.22-.82l.562-.972a.6.6 0 111.04.6l-.562.973a.6.6 0 01-.82.22zM15.58 8.69a.6.6 0 10.6 1.04l.972-.561a.6.6 0 00-.6-1.04l-.973.562z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.05 12.018a3.968 3.968 0 017.772-1.134 3.408 3.408 0 012.971 3.34h.242a2.565 2.565 0 010 5.13h-6.737a2.565 2.565 0 01-2.21-3.868 3.967 3.967 0 01-2.038-3.469zm3.968-2.769a2.77 2.77 0 012.557 1.704 3.409 3.409 0 00-2.595 3.291 2.55 2.55 0 00-.996.342 2.77 2.77 0 011.034-5.337z"
    />
    <path
      d="M4.28 11.418a.6.6 0 100 1.2h1.123a.6.6 0 100-1.2H4.281zM4.663 15.686a.6.6 0 01.22-.82l.972-.561a.6.6 0 01.6 1.04l-.973.56a.6.6 0 01-.82-.22zM8.511 7.675a.6.6 0 01-.82-.22l-.56-.972a.6.6 0 111.038-.6l.562.973a.6.6 0 01-.22.82zM5.856 9.73a.6.6 0 00.6-1.04l-.972-.56a.6.6 0 00-.6 1.039l.972.561z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
