<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.686 7a2.6 2.6 0 012.6-2.6h7.428a2.6 2.6 0 012.6 2.6v.505a3.601 3.601 0 012.286 3.352v5a2.314 2.314 0 01-2.314 2.314h-.127a2.601 2.601 0 01-2.445 1.715H8.286a2.601 2.601 0 01-2.447-1.72A2.6 2.6 0 013.4 15.571v-4.714c0-1.524.948-2.827 2.286-3.352V7zm11.328.257H6.886V7a1.4 1.4 0 011.4-1.4h7.428a1.4 1.4 0 011.4 1.4v.257h-.1zm-10.025 1.2a2.4 2.4 0 00-2.389 2.4v4.714a1.4 1.4 0 001.086 1.365v-1.365a2.6 2.6 0 012.6-2.6h7.428a2.6 2.6 0 012.6 2.6v1.4a1.114 1.114 0 001.086-1.114v-5a2.4 2.4 0 00-2.389-2.4H6.99zm1.297 5.714a1.4 1.4 0 00-1.4 1.4v1.715a1.4 1.4 0 001.4 1.4h7.428a1.4 1.4 0 001.4-1.4V15.57a1.4 1.4 0 00-1.4-1.4H8.286z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.857 10.714a.571.571 0 11-1.143 0 .571.571 0 011.143 0zm1.714 0a.571.571 0 11-1.142 0 .571.571 0 011.142 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
