import CustomParameter from './CustomParameters'
import CustomOverlays from './CustomOverlays'
import Chapters from './Chapters'

class Video {
  constructor() {
    this.id = null
    this.publicId = ''
    this.name = ''
    this.title = ''
    this.description = ''
    this.canEmbed = true
    this.canPreview = true
    this.thumbnailUrl = ''
    this.filmstripUrl = ''
    this.insertDate = null
    this.publishDate = null
    this.publishEndDate = null
    this.publishStatusId = null
    this.publishStatusName = ''
    this.duration = null
    this.videoPagePermalink = ''
    this.userName = null
    this.categoryId = null
    this.publicAdScheduleId = null
    this.flagIds = []
    this.geoBlockingRuleId = null
    this.restriction = null
    this.skipIntroState = false
    this.skipIntroFrom = null
    this.skipIntroTo = null
    this.source = ''
    this.author = ''
    this.geoBlockingRuleId = null
    this.tagNames = []
    this.customParameters = []
    this.sharedHistory = []
    this.visibilityOptionId = 1
    this.customOverlayState = false
    this.customOverlays = []
    this.chapterState = false
    this.chapters = []
    this.lockAfter = 0
    this.videoLockState = false
    this.passwordProtectedTitle = ''
    this.passwordProtectedDescription = ''
    this.password = ''
  }

  fromData(data) {
    this.id = data.id ? data.id : null
    this.publicId = data.publicId ? data.publicId : ''
    this.name = data.name ? data.name : ''
    this.title = data.title ? data.title : ''
    this.description = data.description ? data.description : ''
    this.canEmbed = data.canEmbed || false
    this.canPreview = data.canPreview || false
    this.thumbnailUrl = data.thumbnailUrl ? data.thumbnailUrl : ''
    this.filmstripUrl = data.filmstripUrl ? data.filmstripUrl : ''
    this.insertDate = data.insertDate ? data.insertDate : null
    this.publishDate = data.publishDate ? data.publishDate : null
    this.publishEndDate = data.publishEndDate ? data.publishEndDate : null
    this.publishStatusId = data.publishStatusId ? data.publishStatusId : null
    this.publishStatusName = data.publishStatusName ? data.publishStatusName : ''
    this.duration = data.duration ? data.duration : null
    this.videoPagePermalink = data.videoPagePermalink ? data.videoPagePermalink : ''
    this.userName = data.userName ? data.userName : null
    this.categoryId = data.categoryId ? data.categoryId : null
    this.publicAdScheduleId = data.publicAdScheduleId ? data.publicAdScheduleId : null
    this.flagIds = data.flagIds ? data.flagIds : []
    this.geoBlockingRuleId = data.geoBlockingRuleId
    this.restriction = data.restriction ? data.restriction : null
    this.skipIntroState = data.skipIntroState || false
    this.customOverlayState = data.customOverlayState || false
    this.chapterState = data.chapterState || false
    this.skipIntroFrom = data.skipIntroFrom || null
    this.skipIntroTo = data.skipIntroTo || null
    this.source = data.source ? data.source : ''
    this.author = data.author ? data.author : ''
    this.geoBlockingRuleId = data.geoBlockingRuleId ? data.geoBlockingRuleId : null
    this.tagNames = data.tagNames ? data.tagNames : []
    this.visibilityOptionId = data.visibilityOptionId ? data.visibilityOptionId : 1
    this.passwordProtectedTitle = data.passwordProtectedTitle || ''
    this.passwordProtectedDescription = data.passwordProtectedDescription || ''
    this.password = data.password || ''
    this.customParameters = data.customParameters.length > 0 ? data.customParameters.map(cp => {
      const ncp = new CustomParameter()
      ncp.fromData(cp)
      return ncp
    }) : []
    this.customOverlays = data.customOverlays.length > 0 ? data.customOverlays.map(cp => {
      const ncp = new CustomOverlays()
      ncp.fromData(cp)
      return ncp
    }) : []
    this.chapters = data.chapters.length > 0 ? data.chapters.map(cp => {
      const ncp = new Chapters()
      ncp.fromData(cp)
      return ncp
    }) : []
    this.lockAfter = data.lockAfter || 0
    this.videoLockState = data.videoLockState
  }
}

export default Video
