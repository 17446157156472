<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.21 6.44A2.247 2.247 0 004 4.6V3.4a3.447 3.447 0 013.39 2.822l1.343 7.29a1.4 1.4 0 001.377 1.147h7.297a1.4 1.4 0 001.4-1.4V9.88a.4.4 0 00-.332-.394l-7.762-1.338a.6.6 0 11.204-1.183l7.762 1.339a1.6 1.6 0 011.328 1.576v3.38a2.6 2.6 0 01-2.6 2.6H10.11a2.6 2.6 0 01-2.557-2.129L6.21 6.44z"
    />
    <path
      d="M11.111 18.815a1.185 1.185 0 11-2.37 0 1.185 1.185 0 012.37 0zM18.222 18.815a1.185 1.185 0 11-2.37 0 1.185 1.185 0 012.37 0z"
    />
  </svg>
</template>
