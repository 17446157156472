export default {
  scenes: [
    // {
    //   id: 1,
    //   channelId: 1,
    //   thumbnailUrl: 'https://cdn.vpplayer.tech/dev-agmipnxd/live-videos/default-screens/870020c9-5d81-40c7-a957-6cbb2b9e487c',
    //   title: 'Camera 2',
    //   liveStatus: 'Processing',
    //   type: 5,
    // },
    // {
    //   id: 2,
    //   channelId: 2,
    //   thumbnailUrl: 'https://cdn.vpplayer.tech/dev-agmipnxd/live-videos/default-screens/870020c9-5d81-40c7-a957-6cbb2b9e487c',
    //   title: 'Prelive',
    //   liveStatus: 'Processing',
    //   type: 1,
    // },
    // {
    //   id: 3,
    //   channelId: 3,
    //   thumbnailUrl: 'https://cdn.vpplayer.tech/dev-agmipnxd/live-videos/default-screens/870020c9-5d81-40c7-a957-6cbb2b9e487c',
    //   title: 'End',
    //   liveStatus: '',
    //   type: 2,
    // },
    // {
    //   id: 4,
    //   channelId: 4,
    //   thumbnailUrl: 'https://cdn.vpplayer.tech/dev-agmipnxd/live-videos/default-screens/870020c9-5d81-40c7-a957-6cbb2b9e487c',
    //   title: 'Pause',
    //   liveStatus: 'Processing',
    //   type: 3,
    // },
    // {
    //   id: 0,
    //   channelId: 5,
    //   thumbnailUrl: 'https://cdn.vpplayer.tech/dev-agmipnxd/live-videos/default-screens/870020c9-5d81-40c7-a957-6cbb2b9e487c',
    //   title: 'Prelive',
    //   liveStatus: 'Processing',
    //   type: 5,
    // },
    // {
    //   id: 0,
    //   channelId: 6,
    //   thumbnailUrl: 'https://cdn.vpplayer.tech/dev-agmipnxd/live-videos/default-screens/870020c9-5d81-40c7-a957-6cbb2b9e487c',
    //   title: 'GamingRoom',
    //   liveStatus: 'Processing',
    //   type: 5,
    // },
    // {
    //   id: 0,
    //   channelId: 7,
    //   thumbnailUrl: 'https://cdn.vpplayer.tech/dev-agmipnxd/live-videos/default-screens/870020c9-5d81-40c7-a957-6cbb2b9e487c',
    //   title: 'NewsRoom',
    //   liveStatus: 'Processing',
    //   type: 5,
    // },
  ],
  scene: null,
  fetchingScenes: false,
  gettingScene: false,
  addingScene: false,
  updatingScene: false,
  deletingScene: false,
}
