<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.018 7.05a3.968 3.968 0 00-1.862 7.473 2.565 2.565 0 002.143 3.975h.992l-.408.816a.6.6 0 101.074.537l.676-1.353h2.309l-.409.818a.6.6 0 001.073.537l.678-1.355h.752a2.565 2.565 0 100-5.13h-.242a3.408 3.408 0 00-2.93-3.335 3.97 3.97 0 00-3.846-2.984zm2.608 3.035a2.77 2.77 0 00-5.376.932 2.77 2.77 0 001.89 2.627c.258-.13.541-.219.84-.256a3.408 3.408 0 012.646-3.303zM3.68 11.018a.6.6 0 01.6-.6h1.123a.6.6 0 010 1.2H4.281a.6.6 0 01-.6-.6zM4.662 14.686a.6.6 0 01.22-.82l.972-.561a.6.6 0 01.6 1.04l-.972.56a.6.6 0 01-.82-.219zM15.36 8.51a.6.6 0 01.22-.82l.972-.56a.6.6 0 11.6 1.039l-.973.561a.6.6 0 01-.82-.22zM13.525 6.675a.6.6 0 01-.22-.82l.562-.972a.6.6 0 111.039.6l-.561.973a.6.6 0 01-.82.22zM11.018 6.004a.6.6 0 01-.6-.6V4.28a.6.6 0 111.2 0v1.123a.6.6 0 01-.6.6zM8.51 6.675a.6.6 0 01-.82-.22l-.56-.972a.6.6 0 111.038-.6l.562.973a.6.6 0 01-.22.82zM6.675 8.51a.6.6 0 01-.82.22l-.972-.561a.6.6 0 11.6-1.04l.973.562a.6.6 0 01.22.82z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
