<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.575 3.4H16.5A2.6 2.6 0 0119.1 6v12a2.6 2.6 0 01-2.6 2.6h-9A2.6 2.6 0 014.9 18V8.888L10.575 3.4zm.486 1.2L6.1 9.397V18a1.4 1.4 0 001.4 1.4h9a1.4 1.4 0 001.4-1.4V6a1.4 1.4 0 00-1.4-1.4h-5.44z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.818 3.4a.6.6 0 01.6.6v3.143a2.6 2.6 0 01-2.6 2.6H5.5a.6.6 0 010-1.2h3.318a1.4 1.4 0 001.4-1.4V4a.6.6 0 01.6-.6zM9.037 12.571a.6.6 0 01.6-.6h4.727a.6.6 0 010 1.2H9.637a.6.6 0 01-.6-.6zM9.037 15.286a.6.6 0 01.6-.6h4.727a.6.6 0 010 1.2H9.637a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
