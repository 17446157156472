export default [
  {
    path: '/error-400',
    name: 'error-400',
    component: () => import('@/views/pages/error/Error400.vue'),
    meta: {
      errorPage: true,
      projectRequired: false,
      pageTitle: 'Error',
      titleText: 'Bad Request',
      parentText: 'Dashboard',
      breadcrumb: [
        {
          text: 'Error 400',
          disabled: true,
        },
      ],
    },
  },
  {
    path: '/error-403',
    name: 'error-403',
    component: () => import('@/views/pages/error/Error403.vue'),
    meta: {
      errorPage: true,
      projectRequired: false,
      pageTitle: 'Error',
      titleText: 'Forbidden',
      parentText: 'Dashboard',
      breadcrumb: [
        {
          text: 'Error 403',
          disabled: true,
        },
      ],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/error/Error404.vue'),
    meta: {
      errorPage: true,
      projectRequired: false,
      pageTitle: 'Error',
      titleText: 'Not Found',
      parentText: 'Dashboard',
      breadcrumb: [
        {
          text: 'Error 404',
          disabled: true,
        },
      ],
    },
  },
  {
    path: '/error-409',
    name: 'error-409',
    component: () => import('@/views/pages/error/Error409.vue'),
    meta: {
      errorPage: true,
      projectRequired: false,
      pageTitle: 'Error',
      titleText: 'Conflict',
      parentText: 'Dashboard',
      breadcrumb: [
        {
          text: 'Error 409',
          disabled: true,
        },
      ],
    },
  },
  {
    path: '/error-500',
    name: 'error-500',
    component: () => import('@/views/pages/error/Error500.vue'),
    meta: {
      errorPage: true,
      projectRequired: false,
      pageTitle: 'Error',
      titleText: 'Internal Server Error',
      parentText: 'Dashboard',
      breadcrumb: [
        {
          text: 'Error 500',
          disabled: true,
        },
      ],
    },
  },
  {
    path: '/error-503',
    name: 'error-503',
    component: () => import('@/views/pages/error/Error503.vue'),
    meta: {
      errorPage: true,
      projectRequired: false,
      pageTitle: 'Error',
      titleText: 'Service Unavailable',
      parentText: 'Dashboard',
      breadcrumb: [
        {
          text: 'Error 503',
          disabled: true,
        },
      ],
    },
  },
]
