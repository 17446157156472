class Chapters {
  constructor(title = '', time = 0) {
    this.title = title
    this.time = time
  }

  fromData(data) {
    this.title = data.title || ''
    this.time = data.time || 0
  }
}

export default Chapters
