import Video from '@/models/videos/video'

export default {
  loadingVideos: true,
  intervalType: 2,
  diffInTimeCustomDate: null,
  customDate: [],
  dateStr: '',
  isLoading: true,
  isUploading: false,
  newVideo: false,
  reloadPage: false,
  newReencodeVideo: false,
  videoLoaded: false,
  playBackUrl: '',
  originalFile: '',
  videos: [],
  recentlyDeletedVideos: [],
  video: new Video(),
  thumbnailSecond: 1.0,
  customThumbnail: '',
  streams: [],
  subtitles: [],
  visibilityOptions: [],
  customOverlaysPositions: [],
  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },
  filters: {
    params: {
      folderView: false,
      folderId: null,
      insertedDate: null,
      key: null,
      value: null,
      author: null,
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
      onlyReadyVideos: false,
      isLive: false,
      recentlyDeleted: true,
    },
  },
  playlists: [],
  // Video Analytics data
  videoAnalyticsTotal: 0,
  liveViewersTotal: 0,
  videoAnalytics: [],
  keyMoments: [],
  keyMomentsCards: [],
  topCountries: [],
  topDevices: [],
  topBrowsers: [],
  keyMomentsGroupedData: {},
  videoAnalyticsGropuedData: {},
  liveViewersGroupedData: {},
  topCountriesGroupedData: {},
  topDevicesGroupedData: {},
  topBrowsersGroupedData: {},
  // Video Analytics loaders
  loadingAnalytics: false,
  loadingTopCountries: false,
  loadingTopDevices: false,
  loadingLiveViewers: false,
  loadingKeyMoments: false,
  loadingKeyMomentsCards: false,
  loadingCustomAnalytics: false,
  loadingTopBrowsers: false,
  loadingAnalyticsReport: false,
  analyticsReport: null,
}
