<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6668 6.81118C14.6668 7.91575 13.7714 8.81118 12.6668 8.81118C12.3078 8.81118 11.9709 8.71659 11.6797 8.55097L8.70595 13.9037C8.57106 14.1465 8.34662 14.3416 8.07285 14.421C7.78456 14.5046 7.4566 14.4516 7.19998 14.2165C6.78867 13.8397 6.36916 13.2056 6.17655 12.2426C5.96746 11.1971 6.33319 10.2189 6.58742 9.53892C6.60865 9.48215 6.62909 9.42747 6.64836 9.37499C6.65485 9.35734 6.65348 9.34948 6.65338 9.34892C6.65321 9.34784 6.65274 9.34545 6.64959 9.34136C6.64366 9.33365 6.61985 9.31116 6.56484 9.31116L3.12555 9.31116C1.89747 9.31116 1.01665 8.12731 1.36954 6.95102L2.56954 2.95102C2.80218 2.17555 3.51594 1.64449 4.32555 1.64449L11.6668 1.64449C11.7189 1.64449 11.7691 1.65245 11.8163 1.6672C12.0744 1.54574 12.3627 1.47784 12.6668 1.47784C13.7714 1.47784 14.6668 2.37328 14.6668 3.47784V6.81118ZM11.6668 6.81118L11.6668 3.47784C11.6668 2.92556 12.1146 2.47784 12.6668 2.47784C13.2191 2.47784 13.6668 2.92556 13.6668 3.47784V6.81118C13.6668 7.36346 13.2191 7.81118 12.6668 7.81118C12.1146 7.81118 11.6668 7.36346 11.6668 6.81118ZM10.8482 2.64449C10.7318 2.89818 10.6668 3.18043 10.6668 3.47784L10.6668 6.81118C10.6668 7.18304 10.7683 7.53121 10.9451 7.82948C10.9271 7.85172 10.9108 7.87581 10.8964 7.90167L7.8318 13.418C7.82938 13.4224 7.82686 13.4264 7.82431 13.4301C7.58629 13.1917 7.29988 12.7602 7.15713 12.0464C7.00627 11.2921 7.27252 10.5714 7.53872 9.85084L7.58707 9.71972C7.86509 8.96264 7.26328 8.31116 6.56484 8.31116H3.12555C2.56733 8.31116 2.16696 7.77305 2.32736 7.23837L3.52736 3.23837C3.63311 2.88588 3.95755 2.64449 4.32555 2.64449L10.8482 2.64449Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
