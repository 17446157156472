<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1251 18.6832C11.8501 18.6832 11.5917 18.4998 11.5167 18.2165C11.4251 17.8832 11.6251 17.5415 11.9667 17.4498C15.3501 16.5582 17.7084 13.4915 17.7084 9.9915C17.7084 5.7415 14.2501 2.28317 10.0001 2.28317C6.39175 2.28317 4.02508 4.3915 2.91675 5.6665H5.36675C5.70842 5.6665 5.99175 5.94984 5.99175 6.2915C5.99175 6.63317 5.71675 6.92484 5.36675 6.92484H1.67508C1.63341 6.92484 1.55841 6.9165 1.50008 6.89984C1.42508 6.87484 1.35841 6.8415 1.30008 6.79984C1.22508 6.74984 1.16675 6.68317 1.12508 6.60817C1.08341 6.53317 1.05008 6.4415 1.04175 6.34984C1.04175 6.32484 1.04175 6.30817 1.04175 6.28317V2.49984C1.04175 2.15817 1.32508 1.87484 1.66675 1.87484C2.00841 1.87484 2.29175 2.15817 2.29175 2.49984V4.4915C3.65008 3.03317 6.20842 1.0415 10.0001 1.0415C14.9417 1.0415 18.9584 5.05817 18.9584 9.99984C18.9584 14.0665 16.2167 17.6332 12.2834 18.6665C12.2334 18.6748 12.1751 18.6832 12.1251 18.6832Z"
      fill="currentColor"
    />
    <path
      d="M9.40841 18.9417C9.39175 18.9417 9.37508 18.9333 9.36675 18.9333C8.46675 18.875 7.58341 18.675 6.75008 18.35C6.50841 18.2583 6.34175 18.0167 6.35008 17.7583C6.35008 17.6833 6.36675 17.6083 6.39175 17.5417C6.51675 17.225 6.89175 17.0667 7.20008 17.1833C7.92508 17.4667 8.68341 17.6333 9.45008 17.6917C9.77508 17.7083 10.0334 17.9917 10.0334 18.325L10.0251 18.3583C10.0084 18.6833 9.73341 18.9417 9.40841 18.9417ZM4.81675 17.15C4.67508 17.15 4.54175 17.1 4.42508 17.0167C3.72508 16.45 3.10841 15.7917 2.60841 15.0583C2.53341 14.95 2.49175 14.8333 2.49175 14.7083C2.49175 14.5 2.59175 14.3083 2.76675 14.1917C3.04175 14 3.44175 14.075 3.63341 14.3417C3.63341 14.35 3.63341 14.35 3.63341 14.35C3.64175 14.3583 3.65008 14.375 3.65841 14.3833C4.09175 15.0083 4.61675 15.5667 5.20841 16.0333C5.35008 16.15 5.44175 16.325 5.44175 16.5167C5.44175 16.6583 5.40008 16.8 5.30841 16.9167C5.18341 17.0667 5.00841 17.15 4.81675 17.15ZM2.03341 13.0833C1.75841 13.0833 1.51675 12.9083 1.44175 12.65C1.17508 11.7917 1.04175 10.9 1.04175 10V9.99167C1.05008 9.65 1.32508 9.375 1.66675 9.375C2.00841 9.375 2.29175 9.65833 2.29175 10C2.29175 10.7833 2.40841 11.55 2.63341 12.275C2.65008 12.3417 2.65841 12.4 2.65841 12.4667C2.65841 12.7333 2.48341 12.975 2.21675 13.0583C2.15841 13.075 2.10008 13.0833 2.03341 13.0833Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
