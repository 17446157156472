<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.036 14.182a.6.6 0 01.6-.6h2.909a2.636 2.636 0 110 5.273.6.6 0 110-1.2 1.436 1.436 0 100-2.873h-2.91a.6.6 0 01-.6-.6zM11.563 14.782a1.436 1.436 0 000 2.872h4.074a1.472 1.472 0 11-.002 2.946h-1.454a.6.6 0 010-1.2h1.455a.273.273 0 000-.545h-4.073a2.636 2.636 0 110-5.273h.29a.6.6 0 110 1.2h-.29z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.255 13.6a3.51 3.51 0 017.018 0 .6.6 0 01-1.2 0 2.31 2.31 0 10-4.165 1.375.6.6 0 11-.964.715 3.496 3.496 0 01-.69-2.09z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.18 11.273a4.09 4.09 0 018.093-.856.6.6 0 11-1.174.25 2.892 2.892 0 10-3.64 3.381.6.6 0 01-.336 1.152 4.092 4.092 0 01-2.942-3.927zM3.69 11.273a.6.6 0 01.6-.6h1.164a.6.6 0 010 1.2H4.29a.6.6 0 01-.6-.6zM4.706 15.064a.6.6 0 01.22-.82l1.007-.582a.6.6 0 01.6 1.04l-1.008.581a.6.6 0 01-.82-.22zM15.79 8.664a.6.6 0 01.22-.82l1.008-.582a.6.6 0 01.6 1.04l-1.008.581a.6.6 0 01-.82-.22zM13.881 6.754a.6.6 0 01-.22-.82l.582-1.008a.6.6 0 011.04.6L14.7 6.534a.6.6 0 01-.82.22zM11.273 6.055a.6.6 0 01-.6-.6V4.29a.6.6 0 111.2 0v1.164a.6.6 0 01-.6.6zM8.663 6.754a.6.6 0 01-.82-.22l-.581-1.008a.6.6 0 111.04-.6l.581 1.008a.6.6 0 01-.22.82zM6.754 8.664a.6.6 0 01-.82.22L4.926 8.3a.6.6 0 01.6-1.039l1.008.582a.6.6 0 01.22.82z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
