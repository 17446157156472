<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    fill="none"
    viewBox="0 0 25 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.625"
      y="0.371094"
      width="7.33333"
      height="2"
      rx="1"
      style="fill: currentColor"
    />
    <rect
      x="8.95898"
      y="0.371094"
      width="7.33333"
      height="2"
      rx="1"
      style="fill: currentColor"
    />
    <rect
      x="17.291"
      y="0.371094"
      width="7.33333"
      height="2"
      rx="1"
      style="fill: currentColor"
    />
    <rect
      x="0.625"
      y="4.37109"
      width="24"
      height="15"
      rx="2"
      style="fill: currentColor"
    />
  </svg>
</template>
