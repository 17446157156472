<template>
  <div
    id="app"
    class=" h-100"
    :class="[skinClasses]"
  >
    <!-- <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" /> -->
    <template
      v-if="(authIsLoading && layout !='layout-full') "
    >
      <div class="callback">
        <div class="d-flex flex-column justify-content-center align-items-center vh-100">

          <span class="brand-logo my-2">
            <b-img
              :src="vpLogoDark"
              alt="logo"
              style="height: 18px; width: auto; max-width: 200px;"
              class="mx-auto"
            />
          </span>
          <div class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner
              class="text-main"
            />
            <!-- <div class="waiting mt-1">
              Logging in...
            </div> -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <component :is="layout">
        <router-view
          :key="`router-view-key-${keyToogle}`"
        ></router-view>
      </component>

      <scroll-to-top v-if="enableScrollToTop" />

      <!-- TODO : do not load in app.vue, it becomes part of app.js (and that's bad) -->
      <Upload />
    </template>
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import Upload from '@/components/toasts/Upload.vue'

import {
  setUserLoginHook,
  setUserLogoutHook,
} from '@/auth/config'
import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
    Upload,
  },
  data() {
    return {
      // isAppLoading: true,
      keyToogle: false,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$store.state.app.isIframe) return 'layout-full'
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    // sidebar() {
    //   if (!this.$route.meta.hiddenSidebar) return false
    //   return true
    // },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    authIsLoading: {
      get() {
        return this.$store.getters['authModule/getIsLoading']
      },
      set(value) {
        this.$store.commit('authModule/SET_ISLOADING', value)
      },
    },
    uploads() {
      return this.$store.getters['uploads/getUploads']
    },
    project() {
      return this.$store.getters['projectModule/getActiveProject']
    },
    organization() {
      return this.$store.getters['organizationModule/getActiveOrganization']
    },
    userId() {
      return this.$store.getters['userModule/getUserData'].publicId
    },

  },

  beforeCreate() {
    // store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    if ((process.env.VUE_APP_ENV).toLowerCase() === 'production' || !process.env.VUE_APP_ENV) {
      // LOAD CLARITY SCRIPT
      const clarityScript = document.createElement('script')
      const scriptContent = `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "iko7pai0ko");
      `
      clarityScript.innerHTML = scriptContent
      document.head.appendChild(clarityScript)

      // LOAD PENDO SCRIPT
      const pendoScript = document.createElement('script')
      const pendoScriptContent = `
        (function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    })('11bb140d-4c46-4270-55e4-92c98538e6e4');
      `
      pendoScript.innerHTML = pendoScriptContent
      document.head.appendChild(pendoScript)
    }

    // LOAD BISKO SCRIPT
    const biskoScript = document.createElement('script')
    biskoScript.setAttribute('src', 'https://bisko.gjirafa.net/web/gjirafatech-sdk.js')
    document.head.appendChild(biskoScript)

    /*
      setUserLoginHook

      userDataObject
      --
      {
        id: 1,
        fullName: '',
        username: '',
        avatar: '',
        email: '',
        role: 'admin',
        ability: [{ action: 'manage', subject: 'all' }],
      }
    */
    // eslint-disable-next-line no-unused-vars
    setUserLoginHook(async (userDataObject, user, decodedAccessToken) => {
      if (this.$route.path !== '/callback') {
        sessionStorage.setItem('active_route', this.$route.path)
        // sessionStorage.setItem('active_route', '/set-user')
      }

      return userDataObject
    })

    // setUserLogoutHook
    setUserLogoutHook(() => {
      console.log('userLogoutHook')
      localStorage.removeItem('projectId')
      localStorage.removeItem('organizationId')
    })

    try {
      this.$store.state.app.isIframe = window.self !== window.top || window !== window.parent
    } catch (e) {
      this.$store.state.app.isIframe = false
    }
  },
  mounted() {
    this.authIsLoading = false
    window.onpopstate = () => {
      // eslint-disable-next-line no-restricted-globals
      // location.reload()
      this.keyToogle = !this.keyToogle
    }
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })
    const { vpLogoDark } = $themeConfig.app

    return {
      skinClasses,
      enableScrollToTop,
      vpLogoDark,
    }
  },
}
</script>
