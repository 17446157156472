export default [
  // {
  //   path: '/error-404',
  //   name: 'error-404',
  //   component: () => import('@/views/pages/error/Error404.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     isPublic: true,
  //   },
  // },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      isPublic: true,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/auth/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/silent-callback',
    name: 'callback',
    component: () => import('@/views/pages/auth/SilentCallback.vue'),
    meta: {
      isPublic: true,
      layout: 'full',
      // redirectIfLoggedIn: true,
    },
  },
  {
    path: '/oidc-callback-error',
    name: 'callback-error',
    component: () => import('@/views/pages/error/ErrorCallback.vue'),
    meta: {
      isPublic: true,
      noOrganizationAccess: true,
      layout: 'full',
    },
  },

]
