<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.4a8.6 8.6 0 100 17.2 8.6 8.6 0 000-17.2zM4.6 12a7.4 7.4 0 0112.19-5.64L6.36 16.79A7.37 7.37 0 014.6 12zm2.608 5.64A7.4 7.4 0 0017.64 7.208L7.21 17.638z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
