<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 7.846a2.6 2.6 0 012.6-2.6h12a2.6 2.6 0 012.6 2.6V18a2.6 2.6 0 01-2.6 2.6H6A2.6 2.6 0 013.4 18V7.846zm2.6-1.4a1.4 1.4 0 00-1.4 1.4V18A1.4 1.4 0 006 19.4h12a1.4 1.4 0 001.4-1.4V7.846a1.4 1.4 0 00-1.4-1.4H6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 10.154a.6.6 0 01.6-.6h16a.6.6 0 110 1.2H4a.6.6 0 01-.6-.6zM7.692 3.4a.6.6 0 01.6.6v3.692a.6.6 0 01-1.2 0V4a.6.6 0 01.6-.6zM16.308 3.4a.6.6 0 01.6.6v3.692a.6.6 0 11-1.2 0V4a.6.6 0 01.6-.6zM8.309 13.538a.923.923 0 11-1.846 0 .923.923 0 011.846 0zm2.155 4a.923.923 0 100-1.846.923.923 0 000 1.846zm-3.078 0a.923.923 0 100-1.846.923.923 0 000 1.846zm7.077-.923a.923.923 0 11-1.846 0 .923.923 0 011.846 0zm2.154.924a.923.923 0 100-1.847.923.923 0 000 1.846zm-6.153-3.078a.923.923 0 100-1.846.923.923 0 000 1.846zm4-.923a.923.923 0 11-1.847 0 .923.923 0 011.846 0zm2.152.923a.923.923 0 100-1.846.923.923 0 000 1.846z"
    />
  </svg>
</template>
