<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M19.5 16.75C19.09 16.75 18.75 16.41 18.75 16V6.5C18.75 5.81 18.19 5.25 17.5 5.25H13C12.59 5.25 12.25 4.91 12.25 4.5C12.25 4.09 12.59 3.75 13 3.75H17.5C19.02 3.75 20.25 4.98 20.25 6.5V16C20.25 16.41 19.91 16.75 19.5 16.75Z"
      fill="currentColor"
    />
    <path
      d="M15.5 7.74993C15.33 7.74993 15.16 7.68993 15.02 7.57993L12.02 5.07993C11.85 4.93993 11.75 4.72993 11.75 4.49993C11.75 4.26993 11.85 4.06993 12.02 3.91993L15.02 1.41993C15.34 1.14993 15.81 1.19993 16.08 1.51993C16.35 1.83993 16.3 2.30993 15.98 2.57993L13.67 4.49993L15.98 6.41993C16.3 6.68993 16.34 7.15992 16.08 7.47992C15.93 7.65992 15.71 7.74993 15.5 7.74993Z"
      fill="currentColor"
    />
    <path
      d="M5.5 16.75C5.09 16.75 4.75 16.41 4.75 16V9C4.75 8.59 5.09 8.25 5.5 8.25C5.91 8.25 6.25 8.59 6.25 9V16C6.25 16.41 5.91 16.75 5.5 16.75Z"
      fill="currentColor"
    />
    <path
      d="M5.75 9.25C3.54 9.25 1.75 7.46 1.75 5.25C1.75 3.04 3.54 1.25 5.75 1.25C7.96 1.25 9.75 3.04 9.75 5.25C9.75 7.46 7.96 9.25 5.75 9.25ZM5.75 2.75C4.37 2.75 3.25 3.87 3.25 5.25C3.25 6.63 4.37 7.75 5.75 7.75C7.13 7.75 8.25 6.63 8.25 5.25C8.25 3.87 7.13 2.75 5.75 2.75Z"
      fill="currentColor"
    />
    <path
      d="M5.5 22.75C3.43 22.75 1.75 21.07 1.75 19C1.75 16.93 3.43 15.25 5.5 15.25C7.57 15.25 9.25 16.93 9.25 19C9.25 21.07 7.57 22.75 5.5 22.75ZM5.5 16.75C4.26 16.75 3.25 17.76 3.25 19C3.25 20.24 4.26 21.25 5.5 21.25C6.74 21.25 7.75 20.24 7.75 19C7.75 17.76 6.74 16.75 5.5 16.75Z"
      fill="currentColor"
    />
    <path
      d="M19.5 22.75C17.43 22.75 15.75 21.07 15.75 19C15.75 16.93 17.43 15.25 19.5 15.25C21.57 15.25 23.25 16.93 23.25 19C23.25 21.07 21.57 22.75 19.5 22.75ZM19.5 16.75C18.26 16.75 17.25 17.76 17.25 19C17.25 20.24 18.26 21.25 19.5 21.25C20.74 21.25 21.75 20.24 21.75 19C21.75 17.76 20.74 16.75 19.5 16.75Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
