<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 48 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9998 7.58333C24.9663 7.58333 25.7498 8.36683 25.7498 9.33333V18.6667C25.7498 19.6332 24.9663 20.4167 23.9998 20.4167C23.0333 20.4167 22.2498 19.6332 22.2498 18.6667V9.33333C22.2498 8.36683 23.0333 7.58333 23.9998 7.58333Z"
      fill="currentColor"
    />
    <path
      d="M23.9998 28C25.2885 28 26.3332 26.9553 26.3332 25.6667C26.3332 24.378 25.2885 23.3333 23.9998 23.3333C22.7112 23.3333 21.6665 24.378 21.6665 25.6667C21.6665 26.9553 22.7112 28 23.9998 28Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.13317 36.4L4.39984 39.2C2.86162 40.3537 0.666504 39.2561 0.666504 37.3333V7C0.666504 3.13401 3.80051 0 7.6665 0H40.3332C44.1992 0 47.3332 3.13401 47.3332 7V28C47.3332 31.866 44.1992 35 40.3332 35H12.3332C10.8186 35 9.34484 35.4912 8.13317 36.4ZM4.1665 35L6.03317 33.6C7.85068 32.2369 10.0613 31.5 12.3332 31.5H40.3332C42.2662 31.5 43.8332 29.933 43.8332 28V7C43.8332 5.067 42.2662 3.5 40.3332 3.5H7.6665C5.73351 3.5 4.1665 5.067 4.1665 7V35Z"
      fill="currentColor"
    />
  </svg>
</template>
