<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 17.8101H2C1.59 17.8101 1.25 17.4701 1.25 17.0601C1.25 16.6501 1.59 16.3101 2 16.3101H12C12.41 16.3101 12.75 16.6501 12.75 17.0601C12.75 17.4801 12.41 17.8101 12 17.8101Z"
      fill="currentColor"
    />
    <path
      d="M9.77979 22.75H4.22974C2.03974 22.75 1.25977 21.98 1.25977 19.8V15.7C1.25977 13.52 2.03974 12.75 4.22974 12.75H9.77979C11.9698 12.75 12.7498 13.52 12.7498 15.7V19.81C12.7498 21.98 11.9698 22.75 9.77979 22.75ZM4.21973 14.25C2.85973 14.25 2.74976 14.36 2.74976 15.7V19.81C2.74976 21.15 2.85973 21.26 4.21973 21.26H9.76978C11.1298 21.26 11.2397 21.15 11.2397 19.81V15.7C11.2397 14.36 11.1298 14.25 9.76978 14.25H4.21973Z"
      fill="currentColor"
    />
    <path
      d="M15.0002 22.75C14.7302 22.75 14.4802 22.6 14.3502 22.37C14.2202 22.13 14.2202 21.85 14.3602 21.61L15.4102 19.86C15.6202 19.51 16.0802 19.39 16.4402 19.6C16.8002 19.81 16.9102 20.27 16.7002 20.63L16.4302 21.08C19.1902 20.43 21.2602 17.95 21.2602 14.99C21.2602 14.58 21.6002 14.24 22.0102 14.24C22.4202 14.24 22.7602 14.58 22.7602 14.99C22.7502 19.27 19.2702 22.75 15.0002 22.75Z"
      fill="currentColor"
    />
    <path
      d="M2 9.75C1.59 9.75 1.25 9.41 1.25 9C1.25 4.73 4.73 1.25 9 1.25C9.27 1.25 9.52002 1.4 9.65002 1.63C9.78002 1.87 9.78001 2.15 9.64001 2.39L8.59003 4.14C8.38003 4.49 7.92 4.61 7.56 4.4C7.2 4.19 7.08999 3.73 7.29999 3.37L7.57001 2.92C4.81001 3.57 2.73999 6.05 2.73999 9.01C2.74999 9.41 2.41 9.75 2 9.75Z"
      fill="currentColor"
    />
    <path
      d="M18.5 11.75C15.61 11.75 13.25 9.4 13.25 6.5C13.25 3.6 15.6 1.25 18.5 1.25C21.4 1.25 23.75 3.6 23.75 6.5C23.75 9.4 21.39 11.75 18.5 11.75ZM18.5 2.75C16.43 2.75 14.75 4.43 14.75 6.5C14.75 8.57 16.43 10.25 18.5 10.25C20.57 10.25 22.25 8.57 22.25 6.5C22.25 4.43 20.57 2.75 18.5 2.75Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
