// import FlagModel from '@/models/flags/flag'

export default {
  SET_FLAGS: (state, data) => { state.flags = data || [] },
  SET_FLAG: (state, data) => {
    // const flag = new FlagModel()
    // flag.fromData(data)
    state.flag = data
  },
  SET_FLAG_CUSTOMSETTING: (state, data) => { state.flag.flagCustomSetting = data },
  SET_NAME: (state, data) => { state.flag.name = data },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  CLEAR_FLAGS: state => { state.flags = [] },

  SET_FLAG_TYPES: (state, data) => { data.map(x => { x.value = x.id; return x }); data.unshift({ id: 0, name: 'All', value: null }); state.flagTypes = data },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },

  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'flagTypeId') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        flagTypeId: null,
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
}
