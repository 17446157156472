<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 20.6a2.1 2.1 0 01-2.1-2.1v-13c0-1.16.94-2.1 2.1-2.1h1c1.16 0 2.1.94 2.1 2.1v13a2.1 2.1 0 01-2.1 2.1h-1zm-.9-2.1a.9.9 0 00.9.9h1a.9.9 0 00.9-.9v-13a.9.9 0 00-.9-.9h-1a.9.9 0 00-.9.9v13zM16.6 13.6a.6.6 0 00-1.2 0v2.3h-2.3a.6.6 0 100 1.2h2.3v2.299a.6.6 0 101.2 0v-2.3h2.3a.6.6 0 000-1.2h-2.3V13.6z"
    />
  </svg>
</template>
