<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 12C20.25 8.01619 16.9197 4.75 12.7657 4.75C9.62806 4.75 6.95355 6.6178 5.83848 9.25L8 9.25C8.41421 9.25 8.75 9.58579 8.75 10C8.75 10.4142 8.41421 10.75 8 10.75H4.5C3.80964 10.75 3.25 10.1904 3.25 9.5V6C3.25 5.58579 3.58579 5.25 4 5.25C4.41421 5.25 4.75 5.58579 4.75 6V8.04407C6.23599 5.19282 9.27349 3.25 12.7657 3.25C17.7071 3.25 21.75 7.14725 21.75 12C21.75 16.8527 17.7071 20.75 12.7657 20.75C8.86627 20.75 5.53378 18.3277 4.29519 14.9231C4.15358 14.5338 4.35434 14.1035 4.74359 13.9619C5.13285 13.8202 5.5632 14.021 5.70481 14.4103C6.727 17.22 9.49467 19.25 12.7657 19.25C16.9197 19.25 20.25 15.9838 20.25 12Z"
      fill="currentColor"
    />
  </svg>
</template>
