import Playlist from '@/models/playlists/playlist'
import CustomParameter from '@/models/playlists/CustomParameters'

export default {
  SET_PLAYLIST: (state, data) => { const fd = new Playlist(); fd.fromData(data); state.playlist = fd },
  SET_PLAYLISTS: (state, data) => { state.playlists = data },
  SET_VIDEOS: (state, data) => { state.videos = data },
  SET_VIDEOS_BY_FILTERS: (state, data) => { state.videos = data },
  SET_PLAYLIST_VIDEOS: (state, data) => { state.playlistVideos = data },
  SET_PLAYLIST_DISPLAY_TYPES: (state, data) => { state.playlistDisplayTypes = data },
  SET_SELECTED_TYPE: (state, data) => { state.selectedType = data },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },
  SET_OPTIONS: (state, data) => { state.options = data },
  SET_VIDEOS_PAGINATION: (state, data) => { state.videosPagination = data },
  SET_TYPE_OPTIONS: (state, data) => {
    state.options.playlistTypeOptions = data
    state.options.playlistTypeOptions.push(
      {
        id: 5,
        name: 'Search',
        value: 'SEARCH',
        description: '',
        cs: true,
      },
      {
        id: 6,
        name: 'Article Matching',
        value: 'ARTICLE-MATCHING',
        description: '',
        cs: true,
      },
    )
  },
  SET_STREAMS: (state, data) => { state.streams = data },
  SET_PUBLIC_ID: (state, data) => { state.playlist.publicId = data },
  SET_TITLE: (state, data) => { state.playlist.title = data },
  SET_DESCRIPTION: (state, data) => { state.playlist.description = data },
  SET_TYPE: (state, data) => { state.playlist.type = data },
  SET_MAX_VIDEOS: (state, data) => { state.playlist.maxVideos = data },
  SET_SORT_OPTION_ID: (state, data) => { state.playlist.playlistSortOptionId = data },
  SET_INCLUDE_VIDEO_TAGS: (state, data) => { state.playlist.includeTags = data },
  SET_EXCLUDE_VIDEO_TAGS: (state, data) => { state.playlist.excludeTags = data },
  SET_INCLUDE_VIDEO_TAGS_STATE: (state, data) => { state.playlist.includeTagsState = data },
  SET_EXCLUDE_VIDEO_TAGS_STATE: (state, data) => { state.playlist.excludeTagsState = data },
  SET_INCLUDE_TAGS_MODE_ID: (state, data) => { state.playlist.includeTagModeId = data },
  SET_EXCLUDE_TAGS_MODE_ID: (state, data) => { state.playlist.excludeTagModeId = data },
  SET_INCLUDE_VIDEO_FLAGS: (state, data) => { state.playlist.includeFlags = data },
  SET_EXCLUDE_VIDEO_FLAGS: (state, data) => { state.playlist.excludeFlags = data },
  SET_INCLUDE_FLAGS_MODE_ID: (state, data) => { state.playlist.includeFlagModeId = data },
  SET_EXCLUDE_FLAGS_MODE_ID: (state, data) => { state.playlist.excludeFlagModeId = data },
  SET_INCLUDE_VIDEO_FLAGS_STATE: (state, data) => { state.playlist.includeFlagsState = data },
  SET_EXCLUDE_VIDEO_FLAGS_STATE: (state, data) => { state.playlist.excludeFlagsState = data },
  SET_SELECTED_FOLDERS: (state, data) => { state.playlist.folders = data },
  SET_ASPECT_RATIOS: (state, data) => { state.aspectRatios = data },
  SET_ASPECT_RATIO_ID: (state, data) => { state.playlist.aspectRatioTypeId = data },
  SET_INCLUDE_ALL_PROJECTS: (state, data) => { state.playlist.includeAllProjects = data },
  SET_INCLUDE_PROJECTS: (state, data) => { state.playlist.includeProjects = data },
  UPDATE_CURRENT_ITEM_ID: (state, data) => { state.playlist.currentItemId = data },
  UPDATE_PLAYLIST_USER_ID: (state, data) => { state.playlist.userId = data },
  SET_PLAYLIST_DISPLAY_TYPE_ID: (state, data) => { state.playlist.playlistDisplayTypeId = data },
  SET_PLAYLIST_DATE_FROM: (state, data) => { state.playlist.DateFrom = data },
  SET_PLAYLIST_DATE_TO: (state, data) => { state.playlist.DateTo = data },
  ADD_CUSTOM_PARAMETER: state => { const ncp = new CustomParameter(); state.playlist.customParameters.push(ncp) },

  UPDATE_PROJECT_ID: (state, data) => { state.filters.params.ProjectId = data },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  UPDATE_VIDEOARRAYISLOADING: (state, data) => { state.videoArrayIsLoading = data },
  UPDATE_CUSTOM_PARAMETER_KEY: (state, data) => { state.playlist.customParameters[data.index].key = data.key },
  UPDATE_CUSTOM_PARAMETER_VALUE: (state, data) => { state.playlist.customParameters[data.index].value = data.value },
  UPDATE_ISUPLOADING: (state, data) => { state.isUploading = data },

  CLEAR_PLAYLIST: state => { state.playlist = new Playlist(); state.videos = []; state.playlistVideos = []; state.options = {} },
  CLEAR_PLAYLISTS: state => { state.playlists = [] },
  CLEAR_VIDEOS: state => { state.videos = [] },
  CLEAR_INCLUDE_VIDEO_TAGS: state => { state.playlist.includeTags = [] },
  CLEAR_EXCLUDE_VIDEO_TAGS: state => { state.playlist.excludeTags = [] },
  REMOVE_CUSTOM_PARAMETER: (state, index) => { state.playlist.customParameters.splice(index, 1) },
  // REMOVE_INCLUDE_VIDEO_TAG: (state, index) => { state.playlist.includeTags.splice(index, 1) },
  // REMOVE_EXCLUDE_VIDEO_TAG: (state, index) => { state.playlist.excludeTags.splice(index, 1) },

  UPDATE_PLAYLIST: (state, data) => {
    const playlistIndex = state.playlists.findIndex(p => p.id === data.id)
    Object.assign(state.playlists[playlistIndex], data)
  },

  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },

  UPDATE_ELEMENT_FILTER: (state, data) => {
    state.filtersOfVideo.params[data.propKey] = data.value
  },
  RESET_ELEMENT_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
        onlyReadyVideos: true,
        onlyPublishedVideos: true,
        visibilityOptionIds: 1,
      },
    }
    state.filtersOfVideo = filteri
  },
}
