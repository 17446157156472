<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.693 16.908a1.246 1.246 0 100 2.492 1.246 1.246 0 000-2.492zm-2.446 1.246a2.446 2.446 0 114.892 0 2.446 2.446 0 01-4.892 0zM16.307 16.908a1.246 1.246 0 100 2.492 1.246 1.246 0 000-2.492zm-2.446 1.246a2.446 2.446 0 114.892 0 2.446 2.446 0 01-4.892 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.092 8.923a.6.6 0 01.6-.6h1.723a2.6 2.6 0 012.414 1.634l.585 1.463a2.6 2.6 0 01.186.965v3.307a2.446 2.446 0 01-2.446 2.447v-1.2c.688 0 1.246-.558 1.246-1.247v-3.307a1.4 1.4 0 00-.1-.52l-.585-1.462a1.4 1.4 0 00-1.3-.88h-1.723a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 4.6A1.4 1.4 0 004.6 6v7.246h9.092a1.4 1.4 0 001.4-1.4V6a1.4 1.4 0 00-1.4-1.4H6zm-1.4 9.846h9.092a2.6 2.6 0 002.6-2.6V6a2.6 2.6 0 00-2.6-2.6H6A2.6 2.6 0 003.4 6v9.692a2.446 2.446 0 002.446 2.447v-1.2A1.246 1.246 0 014.6 15.692v-1.246zM8.939 17.538a.6.6 0 01.6-.6h4.923a.6.6 0 110 1.2H9.539a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.325 7.103c.27 0 .501.048.692.143.19.094.333.222.428.381.095.16.143.335.143.524 0 .18-.03.346-.093.5-.061.155-.182.338-.36.549-.18.21-.444.474-.795.79h1.318l-.1.78h-2.34v-.717c.387-.386.673-.683.859-.89.185-.207.315-.378.388-.511a.797.797 0 00.11-.385.398.398 0 00-.095-.281.344.344 0 00-.265-.103c-.11 0-.21.029-.298.087-.089.058-.18.148-.274.271l-.566-.474c.144-.204.32-.365.531-.485.21-.119.45-.179.717-.179zm4.29 2.191v.77h-.35v.705h-.973l-.005-.706H9.858v-.679l.938-2.307.847.316-.767 1.901h.42l.116-.78h.852v.78h.35z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
