<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.79 11.88C11.25 11.88 10.7 11.78 10.27 11.59L4.37002 8.97C2.87002 8.3 2.65002 7.4 2.65002 6.91C2.65002 6.42 2.87002 5.52 4.37002 4.85L10.27 2.23C11.14 1.84 12.45 1.84 13.32 2.23L19.23 4.85C20.72 5.51 20.95 6.42 20.95 6.91C20.95 7.4 20.73 8.3 19.23 8.97L13.32 11.59C12.88 11.79 12.34 11.88 11.79 11.88ZM11.79 3.44C11.45 3.44 11.12 3.49 10.88 3.6L4.98002 6.22C4.37002 6.5 4.15002 6.78 4.15002 6.91C4.15002 7.04 4.37002 7.33 4.97002 7.6L10.87 10.22C11.35 10.43 12.22 10.43 12.7 10.22L18.61 7.6C19.22 7.33 19.44 7.04 19.44 6.91C19.44 6.78 19.22 6.49 18.61 6.22L12.71 3.6C12.47 3.5 12.13 3.44 11.79 3.44Z"
      fill="currentColor"
    />
    <path
      d="M12 17.09C11.62 17.09 11.24 17.01 10.88 16.85L4.09 13.83C3.06 13.38 2.25 12.13 2.25 11C2.25 10.59 2.59 10.25 3 10.25C3.41 10.25 3.75 10.59 3.75 11C3.75 11.55 4.2 12.24 4.7 12.47L11.49 15.49C11.81 15.63 12.18 15.63 12.51 15.49L19.3 12.47C19.8 12.25 20.25 11.55 20.25 11C20.25 10.59 20.59 10.25 21 10.25C21.41 10.25 21.75 10.59 21.75 11C21.75 12.13 20.94 13.38 19.91 13.84L13.12 16.86C12.76 17.01 12.38 17.09 12 17.09Z"
      fill="currentColor"
    />
    <path
      d="M12 22.0902C11.62 22.0902 11.24 22.0102 10.88 21.8502L4.09 18.8302C2.97 18.3302 2.25 17.2202 2.25 15.9902C2.25 15.5802 2.59 15.2402 3 15.2402C3.41 15.2402 3.75 15.5902 3.75 16.0002C3.75 16.6302 4.12 17.2102 4.7 17.4702L11.49 20.4902C11.81 20.6302 12.18 20.6302 12.51 20.4902L19.3 17.4702C19.88 17.2102 20.25 16.6402 20.25 16.0002C20.25 15.5902 20.59 15.2502 21 15.2502C21.41 15.2502 21.75 15.5902 21.75 16.0002C21.75 17.2302 21.03 18.3402 19.91 18.8402L13.12 21.8602C12.76 22.0102 12.38 22.0902 12 22.0902Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
