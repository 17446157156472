export default {
  getIsLoading: state => state.isLoading,
  getChannel: state => state.channel,
  getChannelTitle: state => state.channel.title,
  getChannels: state => state.channels,
  getDefaultChannels: state => state.defaultChannels,
  getOptions: state => state.options,
  getChannelLogo: state => state.channel.logo,
  getChannelTypeId: state => state.channel.typeId,
  getChannelType: state => state.channel.type,
  getChannelLatencyId: state => state.channel.latencyTypeId,
  getChannelLatency: state => state.channel.latencyType,
  getLiveChannels: state => state.liveChannels,
  getPagination: state => state.pagination,
  getPageLength: state => state.filters.params.take,
  getCurrentPage: state => state.filters.params.page,
  getChannelSelectList: state => state.channelSelectList,
}
