<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.731 18.325l-.023.106.064-.088-.04-.018zm3.007-8.269v.002l-.036-.002h.036zm-.902-1.513c.3.446.462.97.466 1.509-.002.046 0 .093.004.14.051.932-.06 3.233-1.866 6.326.072-.995.033-2.142-.275-3.263a.618.618 0 00-.596-.454 2.732 2.732 0 112.267-4.258zm.538-1.28a3.968 3.968 0 10-3.299 6.742c.24 1.172.169 2.383.024 3.34a12.7 12.7 0 01-.255 1.256 7.867 7.867 0 01-.138.483.618.618 0 001.07.581c3.604-4.564 3.842-8.16 3.764-9.548l-.001-.02-.001-.016v-.013a3.972 3.972 0 00-1.164-2.804zm5.462 11.062l-.024.106.064-.088-.04-.018zm3.006-8.269v.002l-.036-.002h.036zm-.901-1.513c.3.446.462.97.465 1.508-.001.047 0 .094.004.14.052.933-.059 3.233-1.866 6.327.072-.995.034-2.142-.274-3.263a.618.618 0 00-.596-.454 2.732 2.732 0 112.267-4.258zm.538-1.28a3.968 3.968 0 10-3.299 6.742c.24 1.172.168 2.383.023 3.34a12.68 12.68 0 01-.255 1.256c-.078.302-.137.479-.138.483a.618.618 0 001.07.581c3.604-4.564 3.843-8.16 3.764-9.548l-.001-.02v-.013l-.001-.003v-.013a3.972 3.972 0 00-1.163-2.804z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
