<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.307 7a.6.6 0 01-.077.846L4.744 9.084a.4.4 0 00-.144.307V18A1.4 1.4 0 006 19.4h12a1.4 1.4 0 001.4-1.4V9.391a.4.4 0 00-.144-.307L17.77 7.846a.6.6 0 11.768-.922l1.486 1.238a1.6 1.6 0 01.576 1.23V18a2.6 2.6 0 01-2.6 2.6H6A2.6 2.6 0 013.4 18V9.391c0-.474.21-.925.576-1.229l1.486-1.238A.6.6 0 016.307 7z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.48 9.546a.6.6 0 01.82-.22l7.5 4.328a.4.4 0 00.4 0l7.5-4.328a.6.6 0 11.6 1.04l-7.5 4.327a1.6 1.6 0 01-1.6 0l-7.5-4.327a.6.6 0 01-.22-.82z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.846 4.6a1.4 1.4 0 00-1.4 1.4v4.77a.6.6 0 01-1.2 0V6a2.6 2.6 0 012.6-2.6h8.308a2.6 2.6 0 012.6 2.6v4.77a.6.6 0 01-1.2 0V6a1.4 1.4 0 00-1.4-1.4H7.846z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.938 7.077a.6.6 0 01.6-.6h4.924a.6.6 0 010 1.2H9.538a.6.6 0 01-.6-.6zM8.938 9.538a.6.6 0 01.6-.6h4.924a.6.6 0 010 1.2H9.538a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
