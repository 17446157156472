<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.576 5.576a.6.6 0 000 .848L14.15 12l-5.575 5.576a.6.6 0 10.848.848L15.85 12 9.424 5.576a.6.6 0 00-.848 0z"
    />
  </svg>
</template>
