<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.1668 11.1253V6.33886C14.1668 5.77528 13.9429 5.23478 13.5444 4.83626L11.2476 2.53939C10.849 2.14088 10.3085 1.91699 9.74496 1.91699H7.0835C5.90989 1.91699 4.9585 2.86839 4.9585 4.04199V11.1253C4.9585 12.2989 5.90989 13.2503 7.0835 13.2503H12.0418C13.2154 13.2503 14.1668 12.2989 14.1668 11.1253ZM6.021 11.1253C6.021 11.7121 6.49669 12.1878 7.0835 12.1878H12.0418C12.6286 12.1878 13.1043 11.7121 13.1043 11.1253V7.58366C13.1043 6.99686 12.6286 6.52116 12.0418 6.52116H11.3335C10.3555 6.52116 9.56266 5.72833 9.56266 4.75033V4.04199C9.56266 3.45519 9.08697 2.97949 8.50016 2.97949H7.0835C6.49669 2.97949 6.021 3.45519 6.021 4.04199V11.1253ZM10.4963 3.29069L10.4839 3.27852C10.5751 3.51547 10.6252 3.77289 10.6252 4.04199V4.75033C10.6252 5.14153 10.9423 5.45866 11.3335 5.45866H12.0418C12.3109 5.45866 12.5684 5.50868 12.8053 5.59993L12.7931 5.58756L10.4963 3.29069Z"
      fill="currentColor"
    />
    <path
      d="M3.36475 5.45866C3.36475 5.16526 3.1269 4.92741 2.8335 4.92741C2.54009 4.92741 2.30225 5.16526 2.30225 5.45866V13.2503C2.30225 14.7173 3.49149 15.9066 4.9585 15.9066H10.6252C10.9186 15.9066 11.1564 15.6687 11.1564 15.3753C11.1564 15.0819 10.9186 14.8441 10.6252 14.8441H4.9585C4.07829 14.8441 3.36475 14.1305 3.36475 13.2503V5.45866Z"
      fill="currentColor"
    />
  </svg>
</template>
