<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 3.4A2.6 2.6 0 003.4 6v12A2.6 2.6 0 006 20.6h12a2.6 2.6 0 002.6-2.6V6A2.6 2.6 0 0018 3.4H6zm13.4 5.364V6A1.4 1.4 0 0018 4.6H6A1.4 1.4 0 004.6 6v2.764h14.8zm-14.8 1.2v3.8h6.8V10l.001-.036H4.6zm7.999 0L12.6 10v3.764h6.8v-3.8h-6.801zm6.801 5h-6.8V19.4H18a1.4 1.4 0 001.4-1.4v-3.036zm-14.8 0h6.8V19.4H6A1.4 1.4 0 014.6 18v-3.036z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
