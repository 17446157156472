<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.432 3.408a.6.6 0 01.493.69l-2.667 16a.6.6 0 01-1.183-.197l2.667-16a.6.6 0 01.69-.493zM8.424 7.576a.6.6 0 010 .848L4.85 12l3.575 3.576a.6.6 0 01-.848.848L3.15 12l4.425-4.424a.6.6 0 01.848 0zm7.152 0a.6.6 0 01.848 0L20.85 12l-4.425 4.424a.6.6 0 11-.848-.848L19.15 12l-3.575-3.576a.6.6 0 010-.848z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
