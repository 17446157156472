import AdSchedule from '@/models/ad-schedules/adSchedules'
import AdFunction from '@/models/ad-schedules/AdFunction'

export default {
  async SET_AD_SCHEDULE(state, data) {
    const adSchedule = new AdSchedule()
    await adSchedule.fromData(data)
    state.adSchedule = adSchedule
  },
  SET_OPTIONS: (state, data) => { state.options = data },
  SET_AD_MANAGER_OPTIONS: (state, data) => { state.options.adManagerOptions = data },
  SET_AD_SCHEDULE_TYPES_OPTIONS: (state, data) => { state.options.adScheduleTypeOptions = data },
  SET_AD_SCHEDULES: (state, data) => { state.adSchedules = data },
  SET_DEFAULT_AD_SCHEDULES: (state, data) => { state.defaultAdSchedules = data || [] },
  SET_PAGINATION: (state, data) => {
    const { items, ...p } = data
    state.pagination = p
  },
  CLEAR_AD_SCHEDULE: state => {
    state.adSchedule = new AdSchedule()
  },
  CLEAR_AD_SCHEDULES: state => {
    state.adSchedules = []
    state.pagination = []
  },
  async CLEAR_EMPTY_VAST(state) {
    const s = JSON.parse(JSON.stringify(state.adSchedule))
    const filteredData = state.adSchedule.adBreaks.filter(adb => adb.waterfallTags.filter(wt => wt.waterfallTagUrl !== '' && !wt.isDeleted).length > 0)
    s.adBreaks = filteredData
    const adSchedule = new AdSchedule()
    await adSchedule.fromData(s)
    state.adSchedule = adSchedule
  },
  UPDATE_AD_MANAGER: (state, data) => {
    state.adSchedule.adManager = data
    state.adSchedule.adManagerId = data.id
  },
  UPDATE_AD_MANAGER_ID: (state, data) => { state.adSchedule.adManagerId = data },
  UPDATE_VMAP_AD_TAG: (state, data) => { state.adSchedule.vmapAdTag = data },
  UPDATE_START_ON_PLAYLIST_ITEM: (state, data) => { state.adSchedule.startOnPlaylistItem = data },
  UPDATE_REPEAT_ON_INTERVAL: (state, data) => { state.adSchedule.repeatOnInterval = data },
  UPDATE_TIME_BETWEEN_ADS: (state, data) => { state.adSchedule.timeBetweenAds = data },
  UPDATE_AD_SEEN_ON_SUBSEQUENT_VISIT_ID: (state, data) => { state.adSchedule.adSeenOnSubsequentVisitOptionId = data },
  UPDATE_NAME: (state, data) => { state.adSchedule.name = data },
  UPDATE_ENABLE_BIDDING: (state, data) => { state.adSchedule.enableBidding = data },
  UPDATE_BIDDING_TEMPLATE_PUBLIC_ID: (state, data) => { state.adSchedule.biddingTemplatePublicId = data },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  UPDATE_TARGET_ALL_PREMIUM: (state, data) => { state.adSchedule.targetAllPremium = data },
  // ADD_AD_BREAK: (state, data) => {
  //   const { adBreaks } = state.adSchedule
  //   const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakType.name === data.type)
  //   const rollsLength = adBreaks.filter(x => x.adBreakType.name === data.type).length
  //   if (adBreaks.length) {
  //     state.adSchedule.adBreaks.splice(rollsStartingIndex + rollsLength, 0, new AdBreaks(data.type, data.id))
  //   } else {
  //     state.adSchedule.adBreaks.push(new AdBreaks(data.type, data.id))
  //   }
  // },
  UPDATE_AD_BREAK: (state, data) => {
    const { adBreaks } = state.adSchedule
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakType.name === data.type)
    if (data.deleted) {
      state.adSchedule.adBreaks.splice(rollsStartingIndex + data.index, 1)
    } else {
      data.adBreak.isDeleted = false
      data.adBreak.isVerified = true
      state.adSchedule.adBreaks.splice(rollsStartingIndex + data.index, 1, data.adBreak)
    }
  },
  // # WATERFALL TAG URLS

  UPDATE_SKIPPABLE_AFTER_SECONDS(state, data) {
    const { adBreaks } = state.adSchedule
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakType.name === data.type)
    state.adSchedule.adBreaks[rollsStartingIndex + data.adBreakIndex][data.property] = data.value
  },
  UPDATE_SKIPPABLE_AFTER_SECONDS_NR: (state, data) => { state.adSchedule.biddingTemplatePublicId = data },

  REMOVE_SEGMENT: (state, index) => {
    if (state.adSchedule.adScheduleSegments[index].id === 0) {
      state.adSchedule.adScheduleSegments.splice(index, 1)
    } else {
      state.adSchedule.adScheduleSegments[index].isDeleted = true
    }
  },

  /*      AD FUNCTIONS MUTATIONS     */
  SET_AD_FUNCTION: (state, data) => {
    const adFunction = new AdFunction()
    adFunction.fromData(data)
    state.adFunction = adFunction
  },
  SET_AD_FUNCTIONS: (state, data) => { state.adFunctions = data },
  ADD_AD_FUNCTION_TO_WORKFLOW: (state, data) => {
    state.adSchedule.adWorkflows.push({
      id: null,
      adScheduleId: data.adScheduleId,
      adFunctionId: data.id,
      adFunctionName: data.name,
    })
  },
  CLEAR_AD_FUNCTION: state => { state.adFunction = new AdFunction() },
  CLEAR_AD_FUNCTIONS: state => { state.adFunctions = [] },
  UPDATE_WORKFLOW_FUNCTION: (state, data) => {
    state.adSchedule.adWorkflows = state.adSchedule.adWorkflows.map(x => {
      if (x.adFunctionId === data.adFunctionId) return { ...data }
      return x
    })
  },
  REMOVE_AD_FUNCTION_FROM_WORKFLOW: (state, i) => {
    state.adSchedule.adWorkflows.splice(i, 1)
  },
  // UPDATE_AD_FUNCTION_ISLOADING: (state, data) => { state.isLoading = data },

  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },

}
