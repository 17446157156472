<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.154 3.4a.6.6 0 01.6.6v4.154a2.6 2.6 0 01-2.6 2.6H4a.6.6 0 010-1.2h4.154a1.4 1.4 0 001.4-1.4V4a.6.6 0 01.6-.6zM13.846 3.4a.6.6 0 01.6.6v4.154a1.4 1.4 0 001.4 1.4H20a.6.6 0 110 1.2h-4.154a2.6 2.6 0 01-2.6-2.6V4a.6.6 0 01.6-.6zM15.846 14.446a1.4 1.4 0 00-1.4 1.4V20a.6.6 0 11-1.2 0v-4.154a2.6 2.6 0 012.6-2.6H20a.6.6 0 010 1.2h-4.154zM3.4 13.846a.6.6 0 01.6-.6h4.154a2.6 2.6 0 012.6 2.6V20a.6.6 0 01-1.2 0v-4.154a1.4 1.4 0 00-1.4-1.4H4a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
