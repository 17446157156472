<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.25 8C3.25 7.58579 3.58579 7.25 4 7.25H20C20.4142 7.25 20.75 7.58579 20.75 8C20.75 8.41421 20.4142 8.75 20 8.75L4 8.75C3.58579 8.75 3.25 8.41421 3.25 8Z"
      fill="currentColor"
    />
    <path
      d="M3.25 12C3.25 11.58579 3.58579 11.25 4 11.25H20C20.4142 11.25 20.75 11.58579 20.75 12C20.75 12.41421 20.4142 12.75 20 12.75L4 12.75C3.58579 12.75 3.25 12.41421 3.25 12Z"
      fill="currentColor"
    />
    <path
      d="M3.25 16C3.25 15.58579 3.58579 15.25 4 15.25H20C20.4142 15.25 20.75 15.58579 20.75 16C20.75 16.41421 20.4142 16.75 20 16.75L4 16.75C3.58579 16.75 3.25 16.41421 3.25 16Z"
      fill="currentColor"
    />
  </svg>
</template>
