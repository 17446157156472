import LiveVideo from '@/models/live-videos/LiveVideo'

export default {
  SET_ISLOADING: (state, data) => { state.isLoading = data },
  SET_LIVEVIDEO: (state, data) => {
    const liveVideo = new LiveVideo()
    liveVideo.fromData(data)
    state.liveVideo = liveVideo
  },
  SET_LIVEVIDEOS: (state, data) => { state.liveVideos = data || [] },
  SET_LIVEVIDEO_TITLE: (state, data) => { state.liveVideo.title = data },
  SET_LIVEVIDEO_THUMBNAIL: (state, data) => { state.liveVideo.thumbnail = data },
  SET_LIVEVIDEO_STARTDATE: (state, data) => { state.liveVideo.startDate = data },
  SET_LIVEVIDEO_ENDDATE: (state, data) => { state.liveVideo.endDate = data },

  CLEAR_LIVEVIDEO: state => { state.liveVideo = new LiveVideo() },
  CLEAR_LIVEVIDEOS: state => { state.liveVideos = [] },

  SET_LIVEVIDEO_CUSTOMSCREENS: (state, data) => { state.liveVideoCustomScreens = data },
  SET_ACTIVE_SCREEN_HISTORY: (state, data) => { state.activeScreenHistory = data },
  SET_CUSTOMSCREENS_SELECTLIST: (state, data) => { state.customScreensSelectList = data },
  SET_PUBLISH_DATE: (state, data) => {
    state.liveVideo.publishDate = data
  },

  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },
  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'search') {
      state.filters.params.search = data.value
      state.filters.params.page = 1
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
  UPDATE_LIVE_STATUS_BY_ID: (state, data) => {
    const videoIndex = state.liveVideos.findIndex(v => v.publicId === data.id)
    state.liveVideos[videoIndex].liveStatus = data.liveStatus
    state.liveVideos[videoIndex].isLiveStreaming = data.isLiveStreaming
    state.liveVideos[videoIndex].canStopAndCut = data.canStopAndCut
  },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },

  // Anaylytics
  SET_ANALYTICS(state, payload) {
    const { result, intervalType, differenceInDays } = payload
    state.intervalType = intervalType
    state.diffInTimeCustomDate = differenceInDays
    state.videoAnalytics = result
    state.videoAnalyticsGropuedData = {
      result: result?.graphResult?.map(entry => entry?.result),
      date: result?.graphResult?.map(entry => entry?.date),
    }
    state.videoAnalyticsTotal = result?.total
  },
  SET_LIVE_VIEWERS(state, data) {
    state.liveViewers = data?.liveViewers
    state.liveViewersGroupedData = {
      uniqueViewers: data?.liveViewers?.map(entry => entry?.uniqueViewers),
      date: data?.liveViewers?.map(entry => entry?.date),
    }
    state.liveViewersTotal = data?.total
  },
  SET_CUSTOM_DATE(state, { e, dateStr }) {
    state.customDate = e
    state.dateStr = dateStr
  },
  LOADING_ANALYTICS(state, value) {
    state.loadingAnalytics = value
  },
  LOADING_ANALYTICS_TOP_COUNTRIES(state, value) {
    state.loadingTopCountries = value
  },
  LOADING_ANALYTICS_TOP_BROWSERS(state, value) {
    state.loadingTopBrowsers = value
  },
  LOADING_ANALYTICS_TOP_DEVICES(state, value) {
    state.loadingTopDevices = value
  },
  LOADING_ANALYTICS_LIVE_VIEWERS(state, value) {
    state.loadingLiveViewers = value
  },
  SET_TOP_COUNTRIES(state, data) {
    state.topCountries = data
    state.topCountriesGroupedData = data.map(entry => ({
      names: entry?.countryName,
      values: entry?.countryNr,
    }))
  },
  SET_TOP_DEVICES(state, data) {
    state.topDevices = data
    state.topDevicesGroupedData = {
      deviceType: data.map(entry => entry?.deviceType),
      deviceTypeNr: data.map(entry => entry?.deviceTypeNr),
    }
  },
  SET_TOTAL_CLIPS(state, data) {
    state.totalClipsPagination = data
    const newClips = data.items
    const existingClips = state.totalClips || []
    state.totalClips = [...existingClips, ...newClips].filter((clip, index, self) => index === self.findIndex(c => c.mediaId === clip.mediaId))
  },
  DESTROY_TOTAL_CLIPS(state) {
    state.totalClips = []
  },

  LOADING_TOTAL_CLIPS(state, value) {
    state.loadingTotalClips = value
  },
  SET_TOP_BROWSERS(state, data) {
    state.topBrowsers = data
    state.topBrowsersGroupedData = data.map(entry => ({
      names: entry?.browser,
      values: entry?.browserTypeNr,
    }))
  },

  LOADING_CUSTOM_ANALYTICS(state, value) {
    state.loadingCustomAnalytics = value
  },
  DESTROY_CUSTOM_DATE(state) {
    state.customDate = []
    state.dateStr = ''
  },
  DESTROY_LIVE_VIDEO_ANALYTICS(state) {
    state.videoAnalytics = []
    state.videoAnalyticsGropuedData = {}
    state.videoAnalyticsTotal = 0
    state.liveViewersTotal = 0
    state.topDevices = []
    state.topBrowsers = []
    state.topCountries = []
    state.totalClips = []
    state.totalClipsPagination = {}
    state.topBrowsersGroupedData = {}
    state.topCountriesGroupedData = {}
    state.topDevicesGroupedData = {}
    state.liveViewersGroupedData = {}
  },
  LOADING_ANALYTICS_REPORT(state, value) {
    state.loadingAnalyticsReport = value
  },
  SET_ANALYTICS_REPORT(state, data) {
    state.analyticsReport = data
  },
  SET_LOADING_PLAYER: (state, data) => { state.loadingPlayer = data },
  ADD_CUSTOM_SCREEN: (state, data) => {
    const index = state.liveVideoCustomScreens.findIndex(e => e.id === data.id)
    if (index > -1) state.liveVideoCustomScreens[index] = data
  },
  SET_SECURE_LINK: (state, data) => { state.secureLink = data },
}
