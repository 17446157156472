<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.857 3.4a2.6 2.6 0 00-2.6 2.6v12a2.6 2.6 0 002.6 2.6h6.286a2.6 2.6 0 002.6-2.6V6a2.6 2.6 0 00-2.6-2.6H8.857zM10.8 17.114a.6.6 0 100 1.2h2.428a.6.6 0 100-1.2H10.8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
