import state from './moduleWebhooksState'
import mutations from './moduleWebhooksMutations'
import actions from './moduleWebhooksActions'
import getters from './moduleWebhooksGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
