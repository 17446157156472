/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {
  getWrappers({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/wrappers`, state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_WRAPPERS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getWrapper({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/wrappers/${id}`, state)
        .then(response => {
          commit('SET_WRAPPER', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeWrapperById({ state }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${project.state.activeProjectId}/wrappers/${itemId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  postWrapper({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/projects/${project.state.activeProjectId}/wrappers`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putWrapper({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/projects/${project.state.activeProjectId}/wrappers`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getCurrencies({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/currencies/select-list')
        .then(response => {
          commit('SET_CURRENCIES', response.data.result)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  getBidOptions({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/bid-options/select-list')
        .then(response => {
          commit('SET_BID_OPTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  getWrapperOptions({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/wrappers/select-list`)
        .then(response => {
          commit('SET_WRAPPER_OPTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },

}
