<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.441 6.387v6.185H18.4V6.387H16.44zm-1.2-.073c0-.621.503-1.127 1.126-1.127h2.106c.623 0 1.127.506 1.127 1.127v6.33c0 .622-.504 1.128-1.127 1.128h-2.106a1.127 1.127 0 01-1.126-1.128v-6.33z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.974 5.6a.784.784 0 00-.763.596.34.34 0 00.294.42l1.552.156a.6.6 0 11-.12 1.194l-1.552-.155a1.54 1.54 0 01-1.339-1.905A1.984 1.984 0 017.99 4.399l.601.006v.002c1.914.03 3.319.113 4.191.26 1.184.197 2.333.785 3.445 1.713a.6.6 0 01-.77.921c-.993-.83-1.95-1.297-2.872-1.451-.86-.144-2.384-.23-4.606-.25L7.974 5.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.337 7.808a.626.626 0 00-.671.486.355.355 0 00.309.432l1.557.156a.6.6 0 11-.12 1.194L6.856 9.92a1.555 1.555 0 01-1.36-1.893 1.826 1.826 0 011.96-1.413l1.603.158a.6.6 0 01-.118 1.194l-1.603-.158z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.811 9.918a.626.626 0 00-.67.486.355.355 0 00.308.432l1.558.156a.6.6 0 01-.12 1.194L6.33 12.03a1.555 1.555 0 01-1.36-1.893A1.826 1.826 0 016.93 8.724l1.603.158a.6.6 0 11-.117 1.194L6.81 9.918z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.283 12.028a.626.626 0 00-.671.486c-.057.25.1.498.347.554l.008.002 3.432.72a1.127 1.127 0 01.86 1.384l-.61 2.383a.675.675 0 00.52.83.704.704 0 00.828-.556c.377-1.896.94-3.39 1.73-4.423.807-1.057 1.855-1.627 3.115-1.627a.6.6 0 110 1.2c-.84 0-1.552.357-2.162 1.156-.63.823-1.145 2.113-1.506 3.928v.002a1.904 1.904 0 01-2.339 1.473 1.875 1.875 0 01-1.348-2.28l.59-2.311-3.357-.704-.029-.007a1.663 1.663 0 01-1.25-1.99 1.826 1.826 0 011.96-1.414l1.603.158a.6.6 0 11-.118 1.194l-1.603-.158z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
