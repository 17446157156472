<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.333 3.675H17.6c.157 0 .307.062.418.173m0 0l2.134 2.134c.11.11.173.261.173.418v12.267a1.66 1.66 0 01-1.658 1.658H5.333a1.66 1.66 0 01-1.658-1.658V5.333a1.66 1.66 0 011.658-1.658m0 1.183a.475.475 0 00-.475.475v13.334a.475.475 0 00.417.471V12.8a1.66 1.66 0 011.658-1.658h10.134a1.66 1.66 0 011.658 1.658v6.338a.475.475 0 00.417-.471V6.645l-1.787-1.787h-.23V8.8A1.125 1.125 0 0116 9.925H8A1.125 1.125 0 016.875 8.8V4.858H5.333zm2.725 0v3.884h7.884V4.858H8.058zm-1.125 7.467a.475.475 0 00-.475.475v6.342h11.084V12.8a.475.475 0 00-.475-.475H6.933z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
