import Player from '@/models/players/videoPlayer'

export default {
  isLoading: true,
  players: [],
  defaultPlayers: [],
  playersSelectList: [],
  player: new Player(),
  filters: {
    params: {
      // page: 1,
      // take: 10,
      search: '',
      order: 'desc',
    },
  },

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

  playerLoaded: false,

  options: {
    aspectRatios: null,
    autoStartOptions: [],
    castingOptions: [],
    defaultBandwidthEstimateOptions: [],
    floatPositionOptions: [],
    logoPositionOptions: [],
    playerSizeTypes: [],
    preloadOptions: [],
    releaseChannels: [],
    responsiveSizeOptions: [],
    sharingTypes: [],
    socialNetworkOptions: [],
    adSchedules: [],
    controlBarSpreadOptions: [],
  },
}
