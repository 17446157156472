<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.714 18.821a.6.6 0 01-.6-.6V5.65a.6.6 0 011.2 0v12.571a.6.6 0 01-.6.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 16.746a.6.6 0 01.833-.167l1.381.921 1.382-.92a.6.6 0 01.665.998l-2.047 1.364-2.047-1.364a.6.6 0 01-.166-.832zM10.257 15.364a.6.6 0 01.6-.6H20a.6.6 0 110 1.2h-9.143a.6.6 0 01-.6-.6zM10.257 11.936a.6.6 0 01.6-.6h6.857a.6.6 0 110 1.2h-6.857a.6.6 0 01-.6-.6zM10.257 8.507a.6.6 0 01.6-.6h4.572a.6.6 0 110 1.2h-4.572a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
