<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
    xmlns="http://www.w3.org/2000/svg"
    style="fill: transparent;"
  >
    <path
      fill-rule="evenodd"
      d="M18.5 18.5a2 2 0 002-2c0-.736-.667-1.736-2-3-1.333 1.264-2 2.264-2 3a2 2 0 002 2z"
      clip-rule="evenodd"
      stroke="none"
    />
    <path d="M10.427 2.752l1.768 1.768" />
    <path d="M11.84 4.166l-7.777 7.778 5.657 5.657 7.778-7.778-5.657-5.657z" />
    <path d="M6 10.036l8.48 2.788" />
    <path d="M2 21.5h20" />
  </svg>
</template>
