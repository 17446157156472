<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 7A2.6 2.6 0 016 4.4h11.373a2.6 2.6 0 012.6 2.6v6.98a2.6 2.6 0 01-2.6 2.6H14.9a1.4 1.4 0 00-1.132.577l-1.436 1.974a.8.8 0 01-1.294 0l-1.435-1.974a1.4 1.4 0 00-1.132-.577H6a2.6 2.6 0 01-2.6-2.6V7zM6 5.6A1.4 1.4 0 004.6 7v6.98a1.4 1.4 0 001.4 1.4h2.472a2.6 2.6 0 012.103 1.071l1.111 1.529 1.112-1.529a2.6 2.6 0 012.103-1.07h2.472a1.4 1.4 0 001.4-1.4V7a1.4 1.4 0 00-1.4-1.4H6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.341 9.392a.6.6 0 01.6-.6h5.49a.6.6 0 110 1.2h-5.49a.6.6 0 01-.6-.6zM9.341 11.588a.6.6 0 01.6-.6h5.49a.6.6 0 110 1.2h-5.49a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
