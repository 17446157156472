<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      d="M14.9697 22.75H8.96973C3.53973 22.75 1.21973 20.43 1.21973 15V9C1.21973 3.57 3.53973 1.25 8.96973 1.25H14.9697C20.3997 1.25 22.7197 3.57 22.7197 9V15C22.7197 20.43 20.4097 22.75 14.9697 22.75ZM8.96973 2.75C4.35973 2.75 2.71973 4.39 2.71973 9V15C2.71973 19.61 4.35973 21.25 8.96973 21.25H14.9697C19.5797 21.25 21.2197 19.61 21.2197 15V9C21.2197 4.39 19.5797 2.75 14.9697 2.75H8.96973Z"
      fill="currentColor"
    />
    <path
      d="M14.9697 22.75C14.5597 22.75 14.2197 22.41 14.2197 22V2C14.2197 1.59 14.5597 1.25 14.9697 1.25C15.3797 1.25 15.7197 1.59 15.7197 2V22C15.7197 22.41 15.3897 22.75 14.9697 22.75Z"
      fill="currentColor"
    />
    <path
      d="M7.97016 15.31C7.78016 15.31 7.59016 15.24 7.44016 15.09C7.15016 14.8 7.15016 14.32 7.44016 14.03L9.47016 12L7.44016 9.97001C7.15016 9.68001 7.15016 9.2 7.44016 8.91C7.73016 8.62 8.21016 8.62 8.50016 8.91L11.0602 11.47C11.3502 11.76 11.3502 12.24 11.0602 12.53L8.50016 15.09C8.36016 15.24 8.17016 15.31 7.97016 15.31Z"
      fill="currentColor"
    />
  </svg>
</template>
