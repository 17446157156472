import state from './moduleDashboardState'
import mutations from './moduleDashboardMutations'
import actions from './moduleDashboardActions'
import getters from './moduleDashboardGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
