<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.69 10.968c.614-.125 1.153-.48 1.63-1.105.63-.823 1.145-2.113 1.506-3.928v-.002a1.904 1.904 0 012.339-1.473 1.875 1.875 0 011.348 2.28l-.591 2.311 3.357.705.03.006a1.663 1.663 0 011.249 1.99c-.098.429-.34.79-.665 1.042.167.317.225.693.14 1.069-.098.428-.34.79-.666 1.042.166.317.225.692.14 1.068a1.825 1.825 0 01-.676 1.05c.163.32.215.697.122 1.072A1.984 1.984 0 0116.01 19.6l-.622-.006v-.002c-1.903-.03-3.3-.114-4.17-.259-.89-.149-1.76-.517-2.609-1.086a1.125 1.125 0 01-.977.567H5.526A1.127 1.127 0 014.4 17.686v-6.33c0-.622.503-1.128 1.126-1.128h2.106c.487 0 .9.309 1.058.74z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
