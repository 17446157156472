<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.7624 3.17687C16.4458 2.49345 17.5539 2.49345 18.2373 3.17687L20.8231 5.76266C21.5065 6.44608 21.5065 7.55411 20.8231 8.23753L8.23729 20.8233C7.55387 21.5067 6.44583 21.5067 5.76241 20.8233L3.17663 18.2375C2.49321 17.5541 2.49321 16.4461 3.17663 15.7627L15.7624 3.17687ZM16.8231 4.23753C16.9207 4.1399 17.079 4.1399 17.1766 4.23753L19.7624 6.82332C19.86 6.92095 19.86 7.07924 19.7624 7.17687L7.17662 19.7627C7.07899 19.8603 6.9207 19.8603 6.82307 19.7627L4.23729 17.1769C4.13966 17.0792 4.13965 16.9209 4.23729 16.8233L5.99985 15.0608L6.46952 15.5304C6.76241 15.8233 7.23729 15.8233 7.53018 15.5304C7.82307 15.2375 7.82307 14.7627 7.53018 14.4698L7.06051 14.0001L7.99985 13.0608L9.46952 14.5304C9.76241 14.8233 10.2373 14.8233 10.5302 14.5304C10.8231 14.2375 10.8231 13.7627 10.5302 13.4698L9.06051 12.0001L9.99985 11.0608L10.4695 11.5304C10.7624 11.8233 11.2373 11.8233 11.5302 11.5304C11.8231 11.2375 11.8231 10.7627 11.5302 10.4698L11.0605 10.0001L11.9998 9.06075L13.4695 10.5304C13.7624 10.8233 14.2373 10.8233 14.5302 10.5304C14.8231 10.2375 14.8231 9.76266 14.5302 9.46976L13.0605 8.00009L13.9998 7.06075L14.4695 7.53042C14.7624 7.82332 15.2373 7.82332 15.5302 7.53042C15.8231 7.23753 15.8231 6.76266 15.5302 6.46976L15.0605 6.00009L16.8231 4.23753Z"
      fill="currentColor"
    />
    <path
      d="M17.4695 13.4698C17.1766 13.7627 17.1766 14.2375 17.4695 14.5304L19.8837 16.9446C20.1182 17.1791 20.2498 17.497 20.2498 17.8285V20.2501H17.8283C17.4968 20.2501 17.1788 20.1184 16.9444 19.884L14.5302 17.4698C14.2373 17.1769 13.7624 17.1769 13.4695 17.4698C13.1766 17.7627 13.1766 18.2375 13.4695 18.5304L15.8837 20.9446C16.3995 21.4604 17.0989 21.7501 17.8283 21.7501H20.4998C21.1902 21.7501 21.7498 21.1904 21.7498 20.5001V17.8285C21.7498 17.0992 21.4601 16.3997 20.9444 15.884L18.5302 13.4698C18.2373 13.1769 17.7624 13.1769 17.4695 13.4698Z"
      fill="currentColor"
    />
    <path
      d="M8.23729 3.17687C7.55387 2.49345 6.44583 2.49345 5.76241 3.17687L3.17663 5.76266C2.49321 6.44607 2.49321 7.55411 3.17663 8.23753L5.46952 10.5304C5.76241 10.8233 6.23729 10.8233 6.53018 10.5304C6.82307 10.2375 6.82307 9.76266 6.53018 9.46976L4.23729 7.17687C4.13965 7.07924 4.13966 6.92095 4.23729 6.82332L6.82307 4.23753C6.9207 4.1399 7.079 4.1399 7.17663 4.23753L9.46952 6.53042C9.76241 6.82332 10.2373 6.82332 10.5302 6.53042C10.8231 6.23753 10.8231 5.76266 10.5302 5.46976L8.23729 3.17687Z"
      fill="currentColor"
    />
  </svg>
</template>
