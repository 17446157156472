<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >

    <path
      d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
      fill="#1C1C1C"
    />
    <path
      d="M8.99999 21.75H7.99999C7.58999 21.75 7.24999 21.41 7.24999 21C7.24999 20.59 7.56999 20.26 7.97999 20.25C6.40999 14.89 6.40999 9.11 7.97999 3.75C7.56999 3.74 7.24999 3.41 7.24999 3C7.24999 2.59 7.58999 2.25 7.99999 2.25H8.99999C9.23999 2.25 9.46999 2.37 9.60999 2.56C9.74999 2.76 9.78999 3.01 9.70999 3.24C7.82999 8.89 7.82999 15.11 9.70999 20.77C9.78999 21 9.74999 21.25 9.60999 21.45C9.46999 21.63 9.23999 21.75 8.99999 21.75Z"
      fill="#1C1C1C"
    />
    <path
      d="M15 21.7502C14.92 21.7502 14.84 21.7402 14.76 21.7102C14.37 21.5802 14.15 21.1502 14.29 20.7602C16.17 15.1102 16.17 8.89018 14.29 3.23018C14.16 2.84018 14.37 2.41018 14.76 2.28018C15.16 2.15018 15.58 2.36018 15.71 2.75018C17.7 8.71018 17.7 15.2702 15.71 21.2202C15.61 21.5502 15.31 21.7502 15 21.7502Z"
      fill="#1C1C1C"
    />
    <path
      d="M12 17.1998C9.21 17.1998 6.43 16.8098 3.75 16.0198C3.74 16.4198 3.41 16.7498 3 16.7498C2.59 16.7498 2.25 16.4098 2.25 15.9998V14.9998C2.25 14.7598 2.37 14.5298 2.56 14.3898C2.76 14.2498 3.01 14.2098 3.24 14.2898C8.89 16.1698 15.12 16.1698 20.77 14.2898C21 14.2098 21.25 14.2498 21.45 14.3898C21.65 14.5298 21.76 14.7598 21.76 14.9998V15.9998C21.76 16.4098 21.42 16.7498 21.01 16.7498C20.6 16.7498 20.27 16.4298 20.26 16.0198C17.57 16.8098 14.79 17.1998 12 17.1998Z"
      fill="#1C1C1C"
    />
    <path
      d="M21 9.74986C20.92 9.74986 20.84 9.73986 20.76 9.70986C15.11 7.82986 8.88003 7.82986 3.23003 9.70986C2.83003 9.83986 2.41003 9.62986 2.28003 9.23986C2.16003 8.83986 2.37003 8.41986 2.76003 8.28986C8.72003 6.29986 15.28 6.29986 21.23 8.28986C21.62 8.41986 21.84 8.84986 21.7 9.23986C21.61 9.54986 21.31 9.74986 21 9.74986Z"
      fill="#1C1C1C"
    />

  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
