<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.387 10.834a1.607 1.607 0 113.214 0 1.607 1.607 0 01-3.214 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.684 4.348a1.6 1.6 0 00-2.35.973l-.031.117a2.4 2.4 0 01-1.705 1.705l-.117.03a1.6 1.6 0 00-.973 2.351l.06.105a2.4 2.4 0 010 2.411l-.06.104a1.6 1.6 0 00.973 2.35l.117.032c.39.103.739.3 1.023.566l-1.099 1.726a1.6 1.6 0 00.527 2.231l.985.591a1.6 1.6 0 002.227-.605l1.099-2.014a2.403 2.403 0 011.269 0l1.098 2.014a1.6 1.6 0 002.228.605l.985-.59a1.6 1.6 0 00.526-2.232l-1.099-1.727a2.399 2.399 0 011.024-.565l.116-.031a1.6 1.6 0 00.974-2.35l-.06-.105a2.4 2.4 0 010-2.411l.06-.105a1.6 1.6 0 00-.974-2.35l-.116-.031a2.4 2.4 0 01-1.705-1.705l-.031-.117a1.6 1.6 0 00-2.35-.973l-.105.06a2.4 2.4 0 01-2.411 0l-.105-.06zm1.31 3.68a2.807 2.807 0 100 5.613 2.807 2.807 0 000-5.614z"
    />
  </svg>
</template>
