<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
    xmlns="http://www.w3.org/2000/svg"
    style="fill: transparent;"
  >
    <path d="M18.5 3.5h-13v17h13v-17z" />
    <path d="M5.5 5.5H2v13h3.5v-13z" />
    <path d="M22 5.5h-3.5v13H22v-13z" />
    <path d="M11 10l3 2-3 2v-4z" />
  </svg>
</template>
