// import Video from '@/models/videos/video'
// import CustomParameter from '@/models/videos/CustomParameters'

export default {
  ADD_FOLDER: (state, data) => { state.folders.push(data) },
  SET_LANGUAGES: (state, data) => { state.languages = data },

  CLEAR_LANGUAGES: state => {
    state.languages = []
  },

  UPDATE_ISLOADING: (state, data) => {
    state.isLoading = data
  },
}
