<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.4 7.333a1.933 1.933 0 013.867 0v9.334a1.933 1.933 0 01-3.867 0V7.333zM8.333 6.6a.733.733 0 00-.733.733v9.334a.733.733 0 001.467 0V7.333a.733.733 0 00-.734-.733zM13.067 7.333a1.933 1.933 0 113.866 0v9.334a1.933 1.933 0 01-3.866 0V7.333zM15 6.6a.733.733 0 00-.733.733v9.334a.733.733 0 101.466 0V7.333A.733.733 0 0015 6.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
