// import Video from '@/models/videos/video'
// import CustomParameter from '@/models/videos/CustomParameters'

export default {
  ADD_FOLDER: (state, data) => { state.folders.push(data) },
  SET_FOLDERS: (state, { children, folder }) => { state.folders = children; state.folder = folder },
  SET_FOLDER_SELECTLIST: (state, data) => { state.folders = data },

  MOVING_FOLDER: (state, id) => {
    state.moving = {
      state: true,
      folder: true,
      elements: id,
    }
  },
  MOVING_VIDEOS: (state, ids) => {
    state.moving = {
      state: true,
      folder: false,
      elements: ids,
    }
  },

  RESET_MOVING: state => {
    state.moving = {
      state: false,
      folder: false,
      elements: [],
    }
  },

  REMOVE_FOLDER_FROM_FOLDERS: (state, id) => {
    const { folders } = state
    state.folders.splice(folders.findIndex(i => i.id === parseInt(id, 10)), 1)
  },
  CLEAR_FOLDERS: state => {
    state.folders = []
  },

  LOADING_FOLDERS_CHILDREN: (state, value) => {
    state.loadingFoldersChildren = value
  },

  UPDATE_ISLOADING: (state, data) => {
    state.isLoading = data
  },
  UPDATE_FOLDER_BY_ID: (state, { id, value }) => {
    state.folders[state.folders.findIndex(x => x.id === id)] = { ...value }
  },
  // UPDATE_FOLDER_PROP: (state, {key, value}) => {
  //   state.folder[key] = value
  // },
  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params.take = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },

  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
        onlyReadyVideos: false,
        isLive: false,
      },
    }
    state.filters = filteri
  },
}
