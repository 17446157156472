<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 7.5A2.5 2.5 0 0110 5h4a2.5 2.5 0 012.5 2.5v9A2.5 2.5 0 0114 19h-4a2.5 2.5 0 01-2.5-2.5v-9zM10 6a1.5 1.5 0 00-1.5 1.5v9A1.5 1.5 0 0010 18h4a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0014 6h-4zM20 6a1.5 1.5 0 00-1.5 1.5v9A1.5 1.5 0 0020 18h1.5a.5.5 0 010 1H20a2.5 2.5 0 01-2.5-2.5v-9A2.5 2.5 0 0120 5h1.5a.5.5 0 010 1H20zM4 6a1.5 1.5 0 011.5 1.5v9A1.5 1.5 0 014 18H2.5a.5.5 0 000 1H4a2.5 2.5 0 002.5-2.5v-9A2.5 2.5 0 004 5H2.5a.5.5 0 000 1H4z"
    />
  </svg>
</template>
