<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.56 4.408h-.287a.504.504 0 010-1.008h.79c.279 0 .504.226.504.504v3.695a.504.504 0 01-1.008 0V4.408zM20.096 6.423H8.607a.504.504 0 010-1.007h11.49a.504.504 0 010 1.007zM8.607 11.496h11.49a.504.504 0 110 1.008H8.606a.504.504 0 010-1.008zM20.096 17.577H8.607a.504.504 0 000 1.007h11.49a.504.504 0 100-1.007zM5.889 13.335c-.297.004-.61.007-.89.008.19-.257.437-.598.752-1.047.239-.341.394-.665.463-.962a.504.504 0 00.01-.05l.017-.14a.5.5 0 00.004-.064c0-.79-.642-1.432-1.43-1.432-.683 0-1.274.486-1.405 1.155a.504.504 0 10.988.194.424.424 0 01.839.057l-.01.074c-.031.12-.11.315-.302.59-.572.817-.913 1.265-1.097 1.505l-.001.002c-.232.303-.36.47-.276.744a.52.52 0 00.338.343c.068.022.123.04.826.04.28 0 .661-.002 1.186-.009a.504.504 0 00-.012-1.008zM3.5 17.049a1.43 1.43 0 011.4-1.152 1.43 1.43 0 011.088 2.351A1.43 1.43 0 014.9 20.6a1.433 1.433 0 01-1.428-1.428.504.504 0 011.008 0 .421.421 0 00.42.42.42.42 0 000-.84.504.504 0 010-1.007.42.42 0 10-.412-.502.504.504 0 01-.99-.194z"
    />
    <path
      d="M4.56 4.408h-.287a.504.504 0 010-1.008h.79c.279 0 .504.226.504.504v3.695a.504.504 0 01-1.008 0V4.408zM20.096 6.423H8.607a.504.504 0 010-1.007h11.49a.504.504 0 010 1.007zM8.607 11.496h11.49a.504.504 0 110 1.008H8.606a.504.504 0 010-1.008zM20.096 17.577H8.607a.504.504 0 000 1.007h11.49a.504.504 0 100-1.007zM5.889 13.335c-.297.004-.61.007-.89.008.19-.257.437-.598.752-1.047.239-.341.394-.665.463-.962a.504.504 0 00.01-.05l.017-.14a.5.5 0 00.004-.064c0-.79-.642-1.432-1.43-1.432-.683 0-1.274.486-1.405 1.155a.504.504 0 10.988.194.424.424 0 01.839.057l-.01.074c-.031.12-.11.315-.302.59-.572.817-.913 1.265-1.097 1.505l-.001.002c-.232.303-.36.47-.276.744a.52.52 0 00.338.343c.068.022.123.04.826.04.28 0 .661-.002 1.186-.009a.504.504 0 00-.012-1.008zM3.5 17.049a1.43 1.43 0 011.4-1.152 1.43 1.43 0 011.088 2.351A1.43 1.43 0 014.9 20.6a1.433 1.433 0 01-1.428-1.428.504.504 0 011.008 0 .421.421 0 00.42.42.42.42 0 000-.84.504.504 0 010-1.007.42.42 0 10-.412-.502.504.504 0 01-.99-.194z"
      stroke="#2C2C2C"
      stroke-:width="size"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
