import state from './moduleNotificationsState'
import mutations from './moduleNotificationsMutations'
import actions from './moduleNotificationsActions'
import getters from './moduleNotificationsGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
