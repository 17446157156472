/* eslint-disable no-unused-vars */
import Player from '@/models/players/verticalPlayer'

export default {
  ADD_PLAYER: (state, data) => state.players.unshift(data),
  // SET_PLAYER: (state, data) => { state.player = data },
  SET_PLAYERS: (state, data) => { state.players = data },
  SET_DEFAULT_PLAYERS: (state, data) => { state.defaultPlayers = data || [] },
  SET_PLAYERS_SELECTLIST: (state, data) => { state.playersSelectList = data.map(p => { p.id = p.value; return p }) },
  SET_PLAYER: (state, data) => {
    const player = new Player()
    player.fromData(data)
    state.player = player
  },
  SET_PLAYER_PLAYERSIZETYPE: (state, data) => { state.player.playerSizeTypeId = data.id; state.player.playerSizeType = data },
  SET_PLAYER_RELEASECHANNELS: (state, data) => { state.player.releaseChannels = data },
  SET_PLAYER_RELEASECHANNEL: (state, data) => { state.player.releaseChannel = data },
  SET_PLAYER_ELEMENTS_DEFAULT_COLORS: (state, data) => {
    state.player.elementsIconButtonColor = `#${data['verticalplayer.color.elementsiconbuttoncolor']}`
    state.player.elementsBackgroundColor = `#${data['verticalplayer.color.elementsbackgroundcolor']}`
    state.player.elementsSeekBarRailColor = `#${data['verticalplayer.color.elementsseekbarrailcolor']}`
    state.player.elementsSeekBarBufferColor = `#${data['verticalplayer.color.elementsseekbarbuffercolor']}`
    state.player.elementsSeekBarProgressColor = `#${data['verticalplayer.color.elementsseekbarprogresscolor']}`
    state.player.elementsSeekBarDraggerColor = `#${data['verticalplayer.color.elementsseekbardraggercolor']}`
  },
  SET_PLAYER_DEFAULT_COLORS: (state, data) => {
    // Object.keys(data).forEach(x => {
    //   state.player[x] = `#${data[x]}`
    // })
    state.player.skinTextColor = `#${data['verticalplayer.color.skintextcolor']}`
    state.player.elementsIconButtonColor = `#${data['verticalplayer.color.elementsiconbuttoncolor']}`
    state.player.elementsBackgroundColor = `#${data['verticalplayer.color.elementsbackgroundcolor']}`
    state.player.elementsSeekBarRailColor = `#${data['verticalplayer.color.elementsseekbarrailcolor']}`
    state.player.elementsSeekBarBufferColor = `#${data['verticalplayer.color.elementsseekbarbuffercolor']}`
    state.player.elementsSeekBarProgressColor = `#${data['verticalplayer.color.elementsseekbarprogresscolor']}`
    state.player.elementsSeekBarDraggerColor = `#${data['verticalplayer.color.elementsseekbardraggercolor']}`
  },
  SET_PAGINATION: (state, data) => {
    const { items, ...p } = data
    state.pagination = p
  },
  SET_PLAYER_LOCALIZATION_DEFAULT: (state, data) => {
    Object.keys(data).forEach(x => {
      const y = x.split('.')[1]
      state.player[y] = data[x]
    })
  },
  SET_OPTIONS: (state, data) => { state.options = data.data.result },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  SCRIPT_IS_LOADED: (state, data) => { state.playerLoaded = data },
  CLEAR_PLAYERS: state => { state.players = [] },
  CLEAR_PLAYERS_SELECTLIST: state => { state.playersSelectList = [] },
  CLEAR_PLAYER: state => { state.player = new Player() },
  UPDATE_PLAYER: (state, data) => {
    const playerIndex = state.players.findIndex(p => p.id === data.id)
    Object.assign(state.players[playerIndex], data)
  },
  UPDATE_PLAYER_PROP: (state, { key, value }) => {
    state.player[key] = value
  },
  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
}
