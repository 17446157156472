<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.85 7.8a2.4 2.4 0 012.4-2.4h11.5a2.4 2.4 0 012.4 2.4v7.75a2.4 2.4 0 01-2.4 2.4h-4.813a.6.6 0 000 1.2h4.813a3.6 3.6 0 003.6-3.6V7.8a3.6 3.6 0 00-3.6-3.6H6.25a3.6 3.6 0 00-3.6 3.6v7.75a3.6 3.6 0 003.6 3.6h1.528a1.25 1.25 0 100-1.2H6.25a2.4 2.4 0 01-2.4-2.4V7.8z"
    />
    <path
      d="M10.125 9.557a.528.528 0 01.816-.443L14.15 11.2a.528.528 0 010 .886L10.94 14.17a.528.528 0 01-.816-.443V9.557z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
