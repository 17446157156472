/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

const baseURL = process.env.VUE_APP_API_AUDIO_PLAYER

export default {

  getPlayerById({ commit, state }, playerId) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}api/v1/${project.state.activeProjectId}/audio-players/${playerId}`)
        .then(response => {
          resolve(response)
          commit('SET_PLAYER', response.data.result)
        })
        .catch(error => { reject(error) })
    })
  },

  getPlayers({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}api/v2/${project.state.activeProjectId}/audio-players`, state.filters)
        .then(response => {
          // commit('SET_PAGINATION', response.data.result)
          commit('SET_PLAYERS', response.data.result.items)
          commit('SET_DEFAULT_PLAYERS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getPlayersSelectList({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}api/v1/${project.state.activeProjectId}/audio-players/select-list`)
        .then(response => {
          commit('SET_PLAYERS_SELECTLIST', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  addPlayer({ commit }, player) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}api/v1/${project.state.activeProjectId}/audio-players`, player)
        .then(response => {
          commit('ADD_PLAYER', Object.assign(player, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updatePlayer({ commit }, player) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseURL}api/v1/${project.state.activeProjectId}/audio-players`, player)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removePlayer({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${baseURL}api/v1/${project.state.activeProjectId}/audio-players/${itemId}`,
        {
          config: {
            showToast: true,
            requestToast: {
              title: 'Deleting player',
            },
            responseToast: {
              title: 'Players Deleted successfully',
              variant: 'success',
              text: `Player with id ${itemId} has been deleted`,
            },
          },
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getOptions({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}api/v1/audio-players/options`)
        .then(response => {
          commit('SET_OPTIONS', response)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getLocalizationDefaults({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/language/languageResources/1/AudioPlayer.')
        .then(response => {
          commit('SET_PLAYER_LOCALIZATION_DEFAULT', response.data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  uploadImage({ commit }, file) {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/projects/${project.state.activeProjectId}/uploads/image`, file,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  colorDefaults({ commit }, variable) {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/projects/${project.state.activeProjectId}/app-settings/audioplayer.color.${variable}`)
        .then(response => {
          commit(`SET_PLAYER_${variable ? `${variable.toUpperCase()}_` : ''}DEFAULT_COLORS`, response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updatePlayersOrder({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseURL}api/v1/${project.state.activeProjectId}/audio-players/order`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
