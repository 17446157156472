import state from './moduleAudioPlayersState'
import mutations from './moduleAudioPlayersMutations'
import actions from './moduleAudioPlayersActions'
import getters from './moduleAudioPlayersGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
