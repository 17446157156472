<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.84742 9.15302C5.84742 8.87809 6.0703 8.65521 6.34523 8.65521H11.6551C11.9301 8.65521 12.1529 8.87809 12.1529 9.15302C12.1529 9.42795 11.9301 9.65082 11.6551 9.65082H6.34523C6.0703 9.65082 5.84742 9.42795 5.84742 9.15302Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.00019 14.7948C12.1161 14.7948 14.642 12.2689 14.642 9.15302C14.642 6.03715 12.1161 3.51123 9.00019 3.51123C5.88431 3.51123 3.3584 6.03715 3.3584 9.15302C3.3584 12.2689 5.88431 14.7948 9.00019 14.7948ZM9.00019 15.7904C12.6659 15.7904 15.6376 12.8187 15.6376 9.15302C15.6376 5.48729 12.6659 2.51562 9.00019 2.51562C5.33445 2.51562 2.36279 5.48729 2.36279 9.15302C2.36279 12.8187 5.33445 15.7904 9.00019 15.7904Z"
      fill="currentColor"
    />
    <line
      x1="3.13121"
      y1="3.48574"
      x2="14.7723"
      y2="15.1269"
      stroke="white"
      stroke-width="1.4"
    />
    <line
      x1="3.79695"
      y1="3.2417"
      x2="14.9088"
      y2="14.3536"
      stroke="currentColor"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
