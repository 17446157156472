<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 7.825c0-1.5 1.215-2.715 2.715-2.715h11.77c1.5 0 2.715 1.215 2.715 2.715v8.353c0 1.5-1.215 2.715-2.715 2.715H6.115A2.715 2.715 0 013.4 16.178V7.825zm2.715-1.462c-.808 0-1.462.654-1.462 1.462v8.353c0 .807.654 1.462 1.462 1.462h11.77c.808 0 1.462-.655 1.462-1.462V7.825c0-.808-.654-1.462-1.462-1.462H6.115z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.97 10.293c0-.346.28-.627.626-.627h14.808a.626.626 0 110 1.253H4.596a.626.626 0 01-.627-.626zM6.305 8.584a.57.57 0 100-1.139.57.57 0 000 1.14zm1.708 0a.57.57 0 100-1.139.57.57 0 000 1.14zm2.278-.57a.57.57 0 11-1.139 0 .57.57 0 011.14 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
