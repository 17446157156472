export default {
  SET_RESTREAMS(state, restreams) {
    state.restreams = restreams
  },
  ADD_RESTREAM(state, restream) {
    state.restreams.push(restream)
  },
  DELETE_RESTREAM(state, id) {
    state.restreams = state.restreams.filter(r => r.id !== id)
  },
  UPDATE_RESTREAM(state, updatedRestream) {
    const index = state.restreams.findIndex(r => r.id === updatedRestream.id)
    if (index !== -1) {
      state.restreams[index].status = updatedRestream.status
    }
  },
  SET_CURRENT_RESTREAM(state, restream) {
    state.currentRestream = restream
  },
  RESET_CURRENT_RESTREAM(state) {
    state.currentRestream = {
      videoId: '',
      title: '',
      streamUrl: '',
      streamKey: '',
      status: true,
    }
  },
}
