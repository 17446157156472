export default {
  ADD_ROLE: (state, data) => state.roles.unshift(data),
  SET_ROLES: (state, data) => { state.roles = data },
  SET_ROLE: (state, data) => { state.role = data },
  SET_ACTIVE_ROLE_ID: (state, data) => { state.activeRoleId = data },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },

  SET_PERMISSIONS(state, data) {
    state.permissions[data.property] = data.data.map(x => ({
      id: x.id,
      subject: x.key.split('.')[0],
      action: x.key.split('.')[1],
      key: x.key,
    }))
  },

  CLEAR_ROLES: state => { state.roles = [] },

  UPDATE_NAME: (state, data) => { state.role.name = data },
  UPDATE_DESCRIPTION: (state, data) => { state.role.description = data },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  // UPDATE_PERMISSIONS_OF_ROLE: (state, data) => { state.permissions.ofRole = data },
  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
}
