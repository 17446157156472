<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6 8.829h.256a2.6 2.6 0 012.5 1.882 3.459 3.459 0 012.389 3.26h.254a2.6 2.6 0 110 5.201h-6.857a2.6 2.6 0 01-.545-5.143H6a2.6 2.6 0 01-.315-5.181 3.457 3.457 0 016.914-.02zm-6.71 1.204A1.4 1.4 0 006 12.829h5.034a3.46 3.46 0 012.92-2.27 1.398 1.398 0 00-1.098-.53H10a.6.6 0 010-1.2h1.4A2.257 2.257 0 107.33 10.2a.6.6 0 01-.965.715 3.452 3.452 0 01-.474-.883z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
