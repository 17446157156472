<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 4.6c-1.848 0-3.3 1.427-3.3 3.133s1.452 3.133 3.3 3.133c1.848 0 3.3-1.427 3.3-3.133S10.348 4.6 8.5 4.6zM4 7.733C4 5.315 6.04 3.4 8.5 3.4S13 5.315 13 7.733s-2.04 4.333-4.5 4.333S4 10.15 4 7.733zm1.9 6.999c-1.847 0-3.3 1.427-3.3 3.135V18A1.4 1.4 0 004 19.4h9a1.4 1.4 0 001.4-1.4v-.133c0-1.708-1.453-3.135-3.3-3.135H5.9zm-4.5 3.135c0-2.419 2.04-4.335 4.5-4.335h5.2c2.46 0 4.5 1.916 4.5 4.335V18a2.6 2.6 0 01-2.6 2.6H4A2.6 2.6 0 011.4 18v-.133z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.462 8.034c-1.19 0-2.1.908-2.1 1.966 0 1.058.91 1.966 2.1 1.966 1.189 0 2.1-.908 2.1-1.966 0-1.058-.911-1.966-2.1-1.966zM13.162 10c0-1.777 1.506-3.166 3.3-3.166 1.794 0 3.3 1.389 3.3 3.166s-1.506 3.166-3.3 3.166c-1.794 0-3.3-1.389-3.3-3.166zm.039 4.966a.6.6 0 01.6-.6h4.5c1.794 0 3.3 1.39 3.3 3.167a2.067 2.067 0 01-2.067 2.067H15a.6.6 0 010-1.2h4.534a.867.867 0 00.867-.867c0-1.058-.912-1.967-2.1-1.967h-4.5a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>
