<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.02 10.93a.6.6 0 100 1.2h5.02a.6.6 0 100-1.2H9.02z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 11.53a8.13 8.13 0 1114.287 5.308l2.737 2.738a.6.6 0 11-.848.848l-2.738-2.738A8.13 8.13 0 013.4 11.53zm8.13-6.93a6.93 6.93 0 100 13.859 6.93 6.93 0 000-13.859z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
