<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.39583 12.8346C9.39583 12.4549 9.70364 12.1471 10.0833 12.1471H12.1458V10.0846C12.1458 9.70494 12.4536 9.39714 12.8333 9.39714C13.213 9.39714 13.5208 9.70494 13.5208 10.0846V12.1471H15.5833C15.963 12.1471 16.2708 12.4549 16.2708 12.8346C16.2708 13.2143 15.963 13.5221 15.5833 13.5221H13.5208V15.5846C13.5208 15.9643 13.213 16.2721 12.8333 16.2721C12.4536 16.2721 12.1458 15.9643 12.1458 15.5846V13.5221H10.0833C9.70364 13.5221 9.39583 13.2143 9.39583 12.8346Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7708 5.73047V5.5013C10.7708 4.10908 9.64222 2.98047 8.25 2.98047H4.58333C3.19112 2.98047 2.0625 4.10908 2.0625 5.5013V15.5846C2.0625 17.4831 3.60152 19.0221 5.5 19.0221H16.5C18.3985 19.0221 19.9375 17.4831 19.9375 15.5846V9.16797C19.9375 7.26949 18.3985 5.73047 16.5 5.73047H10.7708ZM4.58333 4.35547C3.95051 4.35547 3.4375 4.86848 3.4375 5.5013V15.5846C3.4375 16.7237 4.36091 17.6471 5.5 17.6471H16.5C17.6391 17.6471 18.5625 16.7237 18.5625 15.5846V9.16797C18.5625 8.02888 17.6391 7.10547 16.5 7.10547H5.5C5.1203 7.10547 4.8125 6.79766 4.8125 6.41797C4.8125 6.03827 5.1203 5.73047 5.5 5.73047H9.39583V5.5013C9.39583 4.86848 8.88283 4.35547 8.25 4.35547H4.58333Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
