import project from '@/store/account/project/moduleProject'

export default {
  isLoading: true,
  loadingFoldersChildren: false,
  folder: null,
  folders: [],
  moving: {
    state: false,
    folder: false,
    elements: [],
  },

  filters: {
    params: {
      ProjectId: project.state.activeProjectId,
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
      onlyReadyVideos: false,
      isLive: false,
    },
  },
}
