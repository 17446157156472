<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.129 6.883A3.6 3.6 0 017.04 5.4h6.372A3.6 3.6 0 0116.2 6.72l1.468 1.795a1.4 1.4 0 00.944.506l.589.059a2.6 2.6 0 012.341 2.587v1.655a2.6 2.6 0 01-2.6 2.6h-.787a2.53 2.53 0 01-4.711 0h-3.43a.599.599 0 01-.203-.036 2.53 2.53 0 01-4.724.036H4.94a2.6 2.6 0 01-2.6-2.6v-3.136a2.6 2.6 0 01.497-1.53l1.29-1.773zm5.826 7.841a.611.611 0 01.058-.003h3.272a2.529 2.529 0 015.027 0h.63a1.4 1.4 0 001.4-1.4v-1.654a1.4 1.4 0 00-1.261-1.393l-.59-.059a2.6 2.6 0 01-1.753-.94L15.27 7.48a2.4 2.4 0 00-1.858-.88H7.04a2.4 2.4 0 00-1.94.988L3.808 9.363a1.4 1.4 0 00-.268.823v3.136a1.4 1.4 0 001.387 1.4 2.529 2.529 0 015.027.002zM6.113 15a1.329 1.329 0 112.657 0 1.329 1.329 0 01-2.657 0zm8.357 0a1.329 1.329 0 112.657 0 1.329 1.329 0 01-2.657 0z"
    />
  </svg>
</template>
