class AdBreakSettings {
  constructor(toSecond) {
    this.adBreakId = 0
    this.fromSecond = 0
    this.toSecond = toSecond || null
    this.adsNumber = 1
    this.percentages = [
      50,
    ]
    this.offset = 0
    this.offsetState = false
  }

  fromData(data) {
    this.adBreakId = data.adBreakId || 0
    this.fromSecond = data.fromSecond || 0
    this.toSecond = data.toSecond || null
    this.adsNumber = !Number.isNaN(data.adsNumber) ? data.adsNumber : 1
    this.percentages = data.percentages || [
      50,
    ]
    this.offset = data.offset || 0
    this.offsetState = !!data.offset
  }
}

export default AdBreakSettings
