<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5833 5C14.5833 4.58579 14.9191 4.25 15.3333 4.25H20C20.4142 4.25 20.75 4.58579 20.75 5V9.66667C20.75 10.0809 20.4142 10.4167 20 10.4167C19.5858 10.4167 19.25 10.0809 19.25 9.66667V6.81066L11.9748 14.0859C11.6819 14.3788 11.207 14.3788 10.9141 14.0859C10.6212 13.793 10.6212 13.3181 10.9141 13.0252L18.1893 5.75H15.3333C14.9191 5.75 14.5833 5.41421 14.5833 5ZM7.55556 8.08333C7.34191 8.08333 7.13701 8.1682 6.98594 8.31927C6.83487 8.47035 6.75 8.67524 6.75 8.88889V17.4444C6.75 17.6581 6.83487 17.863 6.98594 18.0141C7.13701 18.1651 7.34191 18.25 7.55556 18.25H16.1111C16.3248 18.25 16.5297 18.1651 16.6807 18.0141C16.8318 17.863 16.9167 17.6581 16.9167 17.4444V12.7778C16.9167 12.3636 17.2525 12.0278 17.6667 12.0278C18.0809 12.0278 18.4167 12.3636 18.4167 12.7778V17.4444C18.4167 18.0559 18.1738 18.6423 17.7414 19.0747C17.309 19.5071 16.7226 19.75 16.1111 19.75H7.55556C6.94408 19.75 6.35766 19.5071 5.92528 19.0747C5.49291 18.6423 5.25 18.0559 5.25 17.4444V8.88889C5.25 8.27742 5.49291 7.69099 5.92528 7.25861C6.35766 6.82624 6.94408 6.58333 7.55556 6.58333H12.2222C12.6364 6.58333 12.9722 6.91912 12.9722 7.33333C12.9722 7.74755 12.6364 8.08333 12.2222 8.08333H7.55556Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
