import state from './moduleWrapperState'
import mutations from './moduleWrapperMutations'
import actions from './moduleWrapperActions'
import getters from './moduleWrapperGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
