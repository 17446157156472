/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'
import video from '@/store/videos/video/moduleVideos'

const analyticsUrl = process.env.VUE_APP_API_ANALYTICS_URL

export default {
  getVideo({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/videos/${id}`)
        .then(async response => {
          commit('SET_VIDEO', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getVideos({ commit, state }) {
    commit('LOADING_VIDEOS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/projects/${project.state.activeProjectId}/videos`,
          state.filters,
        )
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_VIDEOS', response.data.result.items)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('LOADING_VIDEOS', false)
        })
    })
  },
  getRecentlyDeletedVideos({ commit, state }) {
    commit('LOADING_VIDEOS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/projects/${project.state.activeProjectId}/videos/recently-deleted`,
          state.filters,
        )
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_RECENTLY_DELETED_VIDEOS', response.data.result.items)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('LOADING_VIDEOS', false)
        })
    })
  },
  getVideosV2({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/v2/projects/${project.state.activeProjectId}/videos`,
          state.filters,
        )
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_VIDEOS', response.data.result.items)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getFolderVideos({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/projects/${project.state.activeProjectId}/videos`,
          state.filters,
        )
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('ADD_VIDEOS', response.data.result.items)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getFolderVideosV2({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/v2/projects/${project.state.activeProjectId}/videos`,
          state.filters,
        )
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('ADD_VIDEOS', response.data.result.items)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getEmbed({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/video/embed/${data.player}/${data.id}/${data.seo}`)
        .then(async response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getVisibilityOptions({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/visibility-options/select-list')
        .then(async response => {
          resolve(response)
          commit('SET_VISIBILITY_OPTIONS', response.data.result)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getCustomOverlaysPositions({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/custom-overlays/select-list')
        .then(async response => {
          resolve(response)
          commit('SET_CUSTOM_OVERLAYS_POSITIONS', response.data.result)
        })
        .catch(error => {
          commit('SET_CUSTOM_OVERLAYS_POSITIONS', [])
          reject(error)
        })
    })
  },

  putVideo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/videos`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  removeVideo({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `/api/projects/${project.state.activeProjectId}/videos?id=${itemId}`,
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  permanentlyDeleteVideo({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `/api/projects/${project.state.activeProjectId}/videos/permanently-delete?id=${id}`,
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  recoverVideo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/api/projects/${project.state.activeProjectId}/videos/recover`,
          null,
          { params: data },
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  encodesVideo({ commit }, data) {
    data.folderId = video.state.filters.params.folderId || 0
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/encodes`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  reencodesVideo({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/api/projects/${project.state.activeProjectId}/encodes/${id}`,
          data,
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getVideoStreams({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/projects/${project.state.activeProjectId}/videos/${id}/stream`,
        )
        .then(async response => {
          commit('SET_VIDEO_STREAMS', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getVideoAssets({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/projects/${project.state.activeProjectId}/videos/${id}/assets`,
          {
            params: {
              anyStatus: true,
            },
          },
        )
        .then(async response => {
          commit('SET_VIDEO_ASSETS', response.data.result)
          commit('SET_THUMBNAIL_SECOND', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  uploadThumbnail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `api/projects/${project.state.activeProjectId}/videos/thumbnail/${data.id}`,
          data.fd,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  postVideoAdSchedule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/projects/${project.state.activeProjectId}/videos/${data.id}/ad-schedule`,
          data.data,
        )
        .then(response => {
          commit('SET_AD_SCHEDULE', data.data.publicAdScheduleId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getAdscheduleObject({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/adSchedule/${id}.json`)
        .then(response => {
          // commit('SET_AD_SCHEDULE', data.data.publicAdScheduleId)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getGeoBlockingSecureLink({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/projects/${project.state.activeProjectId}/videos/${id}/secure-link`,
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // Subtitles
  getVideoSubtitles({ commit }, videoId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/projects/${project.state.activeProjectId}/videos/${videoId}/subtitles`,
        )
        .then(response => {
          commit('SET_SUBTITLES', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  putSubtitle({ commit }, { data, index }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/subtitles`, data)
        .then(response => {
          commit('UPDATE_SUBTITLES_ROW', {
            data: response.data.result,
            index,
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  postSubtitle({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/subtitles`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteSubtitle({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `/api/projects/${project.state.activeProjectId}/subtitles/${id}`,
        )
        .then(response => {
          commit('REMOVE_SUBTITLE_ROW', id)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getVideoManualPlaylists({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/projects/${project.state.activeProjectId}/videos/${id}/manual-playlists`,
        )
        .then(async response => {
          commit('SET_VIDEO_PLAYLISTS', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getShareHistory({ commit, state }, videoId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/projects/${project.state.activeProjectId}/videos/${videoId}/share-history`,
        )
        .then(async response => {
          commit('SET_SHARED_HISTORY', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // Analytics
  getVideoAnalytics({ commit, state }, query) {
    const { videoId, data } = query

    let endpoint = ''

    if (videoId === undefined) {
      endpoint = `${analyticsUrl}api/v2/projects/${
        project.state.activeProjectId
      }/video/analytics/graph?metric=${data.metric || 0}&intervalType=${
        data.intervalType || 0
      }&isLive=${false}`
    } else {
      endpoint = `${analyticsUrl}api/v2/projects/${
        project.state.activeProjectId
      }/video/analytics/graph?videoId=${videoId}&metric=${
        data.metric
      }&intervalType=${data.intervalType}`
    }

    let differenceInDays = null
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      commit('LOADING_CUSTOM_ANALYTICS', true)
      const [from, to] = state.dateStr.split(' to ')
      const utcDates = data.customDate.map(dateString => new Date(dateString))
      const startDate = utcDates[0]
      const endDate = utcDates[1]
      const differenceinTime = endDate.getTime() - startDate.getTime()
      differenceInDays = differenceinTime / (1000 * 3600 * 24)
      differenceInDays = 5
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS', true)

    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(response => {
          const result = response?.data?.result
          commit('SET_ANALYTICS', {
            result,
            intervalType: query.data.intervalType,
            differenceInDays,
          })
          resolve(response)
        })
        .catch(error => {
          commit('SET_ANALYTICS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS', false)
          commit('LOADING_CUSTOM_ANALYTICS', false)
        })
    })
  },

  getVideoAnalyticsTopCountries({ commit, state }, query) {
    const { videoId, data } = query
    let endpoint = ''

    // Usage in Advanced analytics
    if (videoId === undefined) {
      endpoint = `${analyticsUrl}api/v2/projects/${
        project.state.activeProjectId
      }/analytics/topCountries?intervalType=${
        data.intervalType
      }&isLive=${false}`
    } else {
      endpoint = `${analyticsUrl}api/v2/projects/${
        project.state.activeProjectId
      }/analytics/topCountries?videoId=${videoId}&intervalType=${
        data.intervalType
      }`
    }

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_COUNTRIES', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_COUNTRIES', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_COUNTRIES', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_COUNTRIES', false)
        })
    })
  },

  getVideoAnalyticsTopBrowsers({ commit, state }, query) {
    const { data } = query
    let endpoint = ''

    // Usage in Advanced analytics

    endpoint = `${analyticsUrl}api/v2/projects/${
      project.state.activeProjectId
    }/analytics/topBrowsers?intervalType=${data.intervalType}`

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_BROWSERS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_BROWSERS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_BROWSERS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_BROWSERS', false)
        })
    })
  },

  getVideoAnalyticsTopDevices({ commit, state }, query) {
    const { videoId, data } = query

    let endpoint = ''

    if (videoId === undefined) {
      endpoint = `${analyticsUrl}api/v2/projects/${
        project.state.activeProjectId
      }/analytics/topDevices?intervalType=${data.intervalType}&isLive=${false}`
    } else {
      endpoint = `${analyticsUrl}api/v2/projects/${
        project.state.activeProjectId
      }/analytics/topDevices?videoId=${videoId}&intervalType=${
        data.intervalType
      }`
    }

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_DEVICES', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_DEVICES', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_DEVICES', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_DEVICES', false)
        })
    })
  },

  getVideoAnalyticsLiveViewrs({ commit, state }, query) {
    const { videoId, projectId, data } = query

    let endpoint = ''
    // Usage in Advanced analytics
    if (videoId === undefined) {
      endpoint = `${analyticsUrl}api/v2/projects/${
        project.state.activeProjectId
      }/analytics/liveViewers?&isLive=${false}`
    } else {
      endpoint = `${analyticsUrl}api/v2/projects/${
        project.state.activeProjectId
      }/analytics/liveViewers?videoId=${videoId}`
    }

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_LIVE_VIEWERS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_LIVE_VIEWERS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_LIVE_VIEWERS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_LIVE_VIEWERS', false)
        })
    })
  },

  getVideoAnalyticsKeyMoments({ commit, state }, query) {
    const { videoId, data } = query

    let endpoint = `${analyticsUrl}api/v2/projects/${
      project.state.activeProjectId
    }/video/analytics/keyMoments/graph/${videoId}?metric=${
      data.metric
    }&intervalType=${data.intervalType}`

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_KEY_MOMENTS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_KEY_MOMENTS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_KEY_MOMENTS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_KEY_MOMENTS', false)
        })
    })
  },
  updateSubtitleStatus({ commit, state }, { id, status }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/subtitles/${id}`, {
          active: status,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  generateSubtitle({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/projects/${project.state.activeProjectId}/videos/subtitles/${id}`,
          data,
        )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getVideoAnalyticsKeyMomentsCards({ commit, state }, query) {
    const { videoId, data } = query

    let endpoint = `${analyticsUrl}api/v2/projects/${
      project.state.activeProjectId
    }/video/analytics/keyMoments/cards/${videoId}?metric=${
      data.metric
    }&intervalType=${data.intervalType}`

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_KEY_MOMENTS_CARDS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_KEY_MOMENTS_CARDS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_KEY_MOMENTS_CARDS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_KEY_MOMENTS_CARDS', false)
        })
    })
  },

  getVideoAnalyticsReport({ commit, state }, query) {
    const { videoId, data } = query
    let endpoint = `${analyticsUrl}api/v2/projects/${project.state.activeProjectId}/video/analytics/download/xls?videoId=${videoId}&intervalType=${data.intervalType}`

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_REPORT', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_ANALYTICS_REPORT', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_ANALYTICS_REPORT', null)
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_REPORT', false)
        })
    })
  },
}
