import Vue from 'vue'
import { VueGoodTable } from 'vue-good-table'
import Icon from '@/components/icons/Icon.vue'
import TitleCard from '@/components/cards/TitleCard.vue'

import FullHeightLayout from '@/components/gjirafafront/full-height-layout.vue'
import InPageHeader from '@/components/in-page-header/InPageHeader.vue'
import Pagination from '@/components/tables/Pagination.vue'
import Error400 from '@/views/pages/error/Error400.vue'
import Error404 from '@/views/pages/error/Error404.vue'
import Error500 from '@/views/pages/error/Error500.vue'

Vue.use(VueGoodTable)
Vue.component('TitleCard', TitleCard)
Vue.component('GjIcon', Icon)
Vue.component('vue-good-table', VueGoodTable)
Vue.component('FullHeightLayout', FullHeightLayout)
Vue.component('in-page-header', InPageHeader)
Vue.component('PaginationC', Pagination)
Vue.component('error-400', Error400)
Vue.component('error-404', Error404)
Vue.component('error-500', Error500)
