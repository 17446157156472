<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.771 4.454A3.6 3.6 0 0115.317 3.4H18A2.6 2.6 0 0120.6 6v2.683a3.6 3.6 0 01-.772 2.228 6.935 6.935 0 01.546 1.982c.164 1.399-.089 3.226-1.728 4.865a.6.6 0 01-.848-.849c1.324-1.324 1.515-2.756 1.384-3.876a5.685 5.685 0 00-.27-1.171l-7.147 7.148a2.6 2.6 0 01-3.677 0L4.99 15.913a2.6 2.6 0 010-3.677l7.781-7.782zm1.888 3.102a1.785 1.785 0 113.57 0 1.785 1.785 0 01-3.57 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
