<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 11.5385C9.65833 11.5385 9.375 11.2552 9.375 10.9135V6.74683C9.375 6.40516 9.65833 6.12183 10 6.12183C10.3417 6.12183 10.625 6.40516 10.625 6.74683V10.9135C10.625 11.2552 10.3417 11.5385 10 11.5385Z"
      fill="currentColor"
    />
    <path
      d="M9.99998 19.0384C5.63331 19.0384 2.08331 15.4884 2.08331 11.1217C2.08331 6.75508 5.63331 3.20508 9.99998 3.20508C14.3666 3.20508 17.9166 6.75508 17.9166 11.1217C17.9166 11.4634 17.6333 11.7467 17.2916 11.7467C16.95 11.7467 16.6666 11.4634 16.6666 11.1217C16.6666 7.44674 13.675 4.45508 9.99998 4.45508C6.32498 4.45508 3.33331 7.44674 3.33331 11.1217C3.33331 14.7967 6.32498 17.7884 9.99998 17.7884C10.3416 17.7884 10.625 18.0717 10.625 18.4134C10.625 18.7551 10.3416 19.0384 9.99998 19.0384Z"
      fill="currentColor"
    />
    <path
      d="M12.5 2.37183H7.5C7.15833 2.37183 6.875 2.08849 6.875 1.74683C6.875 1.40516 7.15833 1.12183 7.5 1.12183H12.5C12.8417 1.12183 13.125 1.40516 13.125 1.74683C13.125 2.08849 12.8417 2.37183 12.5 2.37183Z"
      fill="currentColor"
    />
    <path
      d="M13.4584 18.4302C13.1667 18.4302 12.9 18.3635 12.6584 18.2218C12.1084 17.9052 11.7917 17.2635 11.7917 16.4635V14.5385C11.7917 13.7385 12.1084 13.0968 12.6584 12.7802C13.2084 12.4635 13.9167 12.5135 14.6084 12.9052L16.275 13.8718C16.9667 14.2718 17.3667 14.8635 17.3667 15.4968C17.3667 16.1302 16.9667 16.7218 16.275 17.1218L14.6084 18.0885C14.225 18.3135 13.8334 18.4302 13.4584 18.4302ZM13.4667 13.8135C13.4 13.8135 13.3334 13.8302 13.2834 13.8552C13.1334 13.9385 13.0417 14.1968 13.0417 14.5302V16.4552C13.0417 16.7885 13.1334 17.0468 13.2834 17.1302C13.4334 17.2135 13.7 17.1718 13.9834 17.0052L15.65 16.0385C15.9417 15.8718 16.1167 15.6635 16.1167 15.4968C16.1167 15.3302 15.9417 15.1218 15.65 14.9552L13.9834 13.9885C13.7917 13.8718 13.6084 13.8135 13.4667 13.8135Z"
      fill="currentColor"
    />
  </svg>

</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
