<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.6 11.428v6.186h1.959v-6.186H5.6zm-1.2-.072c0-.622.503-1.128 1.126-1.128h2.106c.623 0 1.127.506 1.127 1.128v6.33c0 .622-.504 1.128-1.127 1.128H5.526A1.127 1.127 0 014.4 17.686v-6.33z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.405 16.571a.6.6 0 01.657-.537l1.552.156a1.54 1.54 0 011.339 1.905A1.984 1.984 0 0116.01 19.6l-.622-.006v-.002c-1.902-.03-3.3-.114-4.17-.259-1.184-.198-2.332-.785-3.444-1.714a.6.6 0 11.769-.92c.994.829 1.951 1.296 2.873 1.45.859.144 2.383.23 4.605.251h.004a.784.784 0 00.764-.595.34.34 0 00-.295-.421l-1.552-.156a.6.6 0 01-.537-.657z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.932 14.461a.6.6 0 01.657-.537l1.558.156a1.555 1.555 0 011.36 1.893 1.826 1.826 0 01-1.96 1.413l-1.603-.158a.6.6 0 01.118-1.194l1.603.158c.313.03.6-.176.671-.485a.355.355 0 00-.309-.433l-1.558-.156a.6.6 0 01-.537-.657z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.459 12.351a.6.6 0 01.657-.537l1.557.156a1.555 1.555 0 011.36 1.893 1.826 1.826 0 01-1.96 1.413l-1.603-.158a.6.6 0 01.118-1.194l1.604.158c.312.03.6-.176.67-.485a.355.355 0 00-.308-.433l-1.558-.156a.6.6 0 01-.537-.657z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.831 5.614a.704.704 0 00-.828.554l-.588-.116.588.117c-.377 1.896-.94 3.39-1.73 4.423-.807 1.057-1.855 1.628-3.115 1.628a.6.6 0 010-1.2c.84 0 1.552-.358 2.162-1.157.63-.823 1.145-2.113 1.506-3.928v-.002a1.904 1.904 0 012.339-1.473 1.875 1.875 0 011.348 2.28l-.591 2.311 3.357.705.03.006a1.663 1.663 0 011.249 1.99 1.826 1.826 0 01-1.959 1.414l-1.603-.158a.6.6 0 01.117-1.194l1.604.158c.312.03.6-.176.671-.486a.463.463 0 00-.347-.554l-.008-.002-3.432-.72a1.127 1.127 0 01-.86-1.383l.61-2.384a.675.675 0 00-.52-.83z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
