<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.021 7.862a.6.6 0 01.6-.6h2.483a3.634 3.634 0 110 7.27H9.035A3.635 3.635 0 017.197 7.76a.6.6 0 11.608 1.035 2.435 2.435 0 001.23 4.536h6.07a2.435 2.435 0 000-4.869H12.62a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.056 7.862a4.462 4.462 0 018.924 0 .6.6 0 11-1.2 0 3.262 3.262 0 10-5.928 1.88.6.6 0 11-.98.693 4.444 4.444 0 01-.816-2.573z"
    />
    <path
      d="M12.07 19.448a.552.552 0 11-1.103 0 .552.552 0 011.103 0zM12.622 17.241a.552.552 0 11-1.103 0 .552.552 0 011.103 0zM8.759 19.448a.552.552 0 11-1.104 0 .552.552 0 011.104 0zM9.311 17.241a.552.552 0 11-1.103 0 .552.552 0 011.103 0zM15.38 19.448a.552.552 0 11-1.103 0 .552.552 0 011.103 0zM15.932 17.241a.552.552 0 11-1.103 0 .552.552 0 011.103 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
