import { Ability } from '@casl/ability'
// import store from '@/store'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// TODO : change this localStorage solution, not safe!!!
const storedAbilities = localStorage.getItem('userData')
const abilities = storedAbilities ? JSON.parse(storedAbilities).ability : initialAbility
export default new Ability(abilities)
