<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.516 4.6a5.958 5.958 0 00-3.421 1.073.6.6 0 01-.687-.984A7.158 7.158 0 0112.516 3.4c1.955 0 3.654.635 4.92 1.878 1.262 1.238 2.043 3.028 2.266 5.25.217 2.174.098 3.889-.266 5.433-.364 1.54-.965 2.884-1.677 4.307a.6.6 0 01-1.073-.536c.7-1.4 1.251-2.645 1.582-4.046.33-1.397.446-2.976.24-5.038-.202-2.013-.895-3.516-1.912-4.514-1.012-.993-2.395-1.534-4.08-1.534zm-1.964 3.709c-.458.554-.73 1.351-.73 2.28v.024c0 1.444 0 4.544-1.444 7.264-.324.609-.88 1.127-1.506 1.375-.644.256-1.436.24-2.041-.37-.624-.63-.83-1.585-.413-2.836l.013-.04.02-.039c.868-1.737.877-3.635.877-5.379v-.01c0-.374 0-1.007.128-1.688.129-.684.396-1.465.968-2.1a.6.6 0 01.89.805c-.368.409-.572.951-.678 1.517-.107.564-.108 1.102-.108 1.476v.046c0 1.69 0 3.831-.984 5.83-.297.919-.08 1.35.139 1.573.18.182.423.228.747.1.34-.135.687-.446.888-.823 1.296-2.44 1.304-5.274 1.304-6.726 0-1.151.337-2.236 1.005-3.044.679-.82 1.672-1.32 2.89-1.32a4.365 4.365 0 014.364 4.364.6.6 0 01-1.2 0 3.165 3.165 0 00-3.165-3.164c-.861 0-1.515.342-1.964.885z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.281 12.812a.6.6 0 01.6.6c0 .307-.072.745-.184 1.23-.116.5-.283 1.091-.494 1.724-.42 1.262-1.025 2.717-1.76 3.943a.6.6 0 11-1.03-.618c.677-1.127 1.249-2.495 1.652-3.704.2-.603.357-1.158.462-1.614.109-.47.154-.798.154-.961a.6.6 0 01.6-.6zM12.477 9.99a.6.6 0 01.638.558c.162 2.433-.002 5.865-2.443 9.77a.6.6 0 11-1.017-.636c2.265-3.624 2.414-6.78 2.263-9.054a.6.6 0 01.559-.638z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
