<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    style="fill: currentColor"
    viewBox="0 0 18 18"
  >
    <rect
      width="18"
      height="18"
      rx="3"
      fill="#25D366"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.52244 2.47227C11.1157 2.55058 12.6003 3.2076 13.7343 4.34229C14.9438 5.55258 15.6096 7.16139 15.6089 8.87227C15.6075 12.4028 12.7333 15.2755 9.2025 15.2755H9.19985C8.12762 15.2751 7.07409 15.0062 6.13835 14.4961L2.74219 15.3865L3.65108 12.0684C3.09045 11.0973 2.79544 9.99579 2.79591 8.86719C2.79732 5.33671 5.67126 2.46436 9.20241 2.46436L9.52244 2.47227ZM6.29569 13.3369L6.49016 13.4522C7.3077 13.9371 8.24482 14.1937 9.20032 14.1941H9.20247C12.1374 14.1941 14.526 11.8065 14.5272 8.8719C14.5278 7.44984 13.9744 6.11266 12.9691 5.1067C11.9638 4.10067 10.6268 3.54632 9.2046 3.54585C6.26746 3.54585 3.87874 5.93317 3.87757 8.86756C3.87717 9.87322 4.15867 10.8526 4.69169 11.6999L4.8183 11.9013L4.28038 13.8653L6.29569 13.3369ZM12.43 10.3949C12.39 10.3282 12.2833 10.2881 12.1232 10.2081C11.9631 10.128 11.1761 9.74091 11.0294 9.68747C10.8827 9.63415 10.776 9.60747 10.6693 9.76759C10.5626 9.92774 10.2558 10.2881 10.1624 10.3949C10.069 10.5017 9.97564 10.5151 9.81559 10.435C9.65552 10.3549 9.13973 10.1859 8.5283 9.64086C8.05242 9.2166 7.73116 8.69269 7.63778 8.53251C7.54442 8.37235 7.62782 8.28573 7.70795 8.20598C7.77998 8.13427 7.86806 8.01909 7.94809 7.92566C8.02813 7.83227 8.05479 7.76548 8.10817 7.65875C8.16152 7.55195 8.13483 7.45855 8.09483 7.37846C8.05479 7.29834 7.73467 6.5108 7.60128 6.19044C7.47132 5.87849 7.33935 5.92072 7.2411 5.91579C7.14784 5.91117 7.04102 5.91016 6.93432 5.91016C6.8276 5.91016 6.65418 5.95017 6.50745 6.11038C6.36071 6.27053 5.94718 6.65765 5.94718 7.44512C5.94718 8.23269 6.52079 8.99343 6.60083 9.10022C6.68087 9.20705 7.72959 10.823 9.33536 11.5161C9.71728 11.681 10.0155 11.7794 10.2479 11.8531C10.6314 11.9749 10.9803 11.9578 11.2562 11.9165C11.5637 11.8706 12.2033 11.5295 12.3366 11.1558C12.47 10.782 12.47 10.4617 12.43 10.3949Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
