/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {
  getBiddings({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/bidding-templates/select-list`)
        .then(response => {
          commit('SET_BIDDINGS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getBiddingById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/bidding-templates/${id}`)
        .then(response => {
          commit('SET_BIDDING', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  postBidding({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/projects/${project.state.activeProjectId}/bidding-templates`, data)
        .then(response => {
          if (response.data.success) {
            commit('SET_BIDDING_TEMPLATE_ON_LIST', {
              name: response.data.result.name,
              id: response.data.result.publicId,
            })
          }
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putBidding({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/projects/${project.state.activeProjectId}/bidding-templates`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getBiddingTemplateById({ commit }, bidderTemplateId) {
    return new Promise(resolve => {
      axios.get(`/api/projects/${project.state.activeProjectId}/bidding-templates/${bidderTemplateId}`)
        .then(response => {
          commit('SET_BIDDING', response.data.result)
          resolve(response)
        })
    })
  },
  getBiddingTemplateBiddersById({ commit }, data) {
    return new Promise(resolve => {
      axios.get(`/api/projects/${project.state.activeProjectId}/bidders/${data.value}`)
        .then(response => {
          response.data.result.bidderId = data.value
          response.data.result.id = 0
          response.data.result.biddingTemplatePublicId = data.biddingTemplatePublicId
          resolve(response)
        })
    })
  },

  getOptions({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/bidding-templates/select-options`)
        .then(response => {
          commit('SET_OPTIONS', response)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

}
