<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.65 9.858a2.6 2.6 0 000 4.284l4.65 3.198c1.726 1.186 4.074-.049 4.074-2.142V8.802c0-2.093-2.348-3.328-4.073-2.142L9.649 9.858zM6.318 5.673a.6.6 0 00-.6.6v11.454a.6.6 0 001.2 0V6.273a.6.6 0 00-.6-.6z"
    />
  </svg>
</template>
