<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M12.5 8C16.9183 8 20.5 6.65685 20.5 5C20.5 3.34315 16.9183 2 12.5 2C8.08172 2 4.5 3.34315 4.5 5C4.5 6.65685 8.08172 8 12.5 8Z"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M20.5 12C20.5 13.6569 16.9183 15 12.5 15C8.08172 15 4.5 13.6569 4.5 12"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M20.5 5V19C20.5 20.6569 16.9183 22 12.5 22C8.08172 22 4.5 20.6569 4.5 19V5"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M8.5 8V10"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M8.5 15V17"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
