import Wrapper from '@/models/ad-schedules/wrapper'

export default {
  async SET_WRAPPER(state, data) {
    const wrapper = new Wrapper()
    await wrapper.fromData(data)
    state.wrapper = wrapper
  },
  SET_WRAPPERS: (state, data) => { state.wrappers = data },
  SET_CURRENCIES: (state, data) => { state.currencies = data },
  SET_BID_OPTIONS: (state, data) => { state.bidOptions = data },
  SET_WRAPPER_OPTIONS: (state, data) => { state.wrapperOptions = data },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },

  SET_PAGINATION: (state, data) => {
    const { items, ...p } = data
    state.pagination = p
  },
  CLEAR_WRAPPER: state => {
    state.wrapper = new Wrapper()
  },
  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },

}
