<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.998 8.6a3.4 3.4 0 100 6.8 3.4 3.4 0 000-6.8zm-4.6 3.4a4.6 4.6 0 119.2 0 4.6 4.6 0 01-9.2 0zM11.998 18.257a.6.6 0 01.6.6V20a.6.6 0 11-1.2 0v-1.143a.6.6 0 01.6-.6zM11.998 3.4a.6.6 0 01.6.6v1.143a.6.6 0 11-1.2 0V4a.6.6 0 01.6-.6zM8.869 17.419a.6.6 0 01.22.82l-.572.99a.6.6 0 01-1.04-.6l.572-.99a.6.6 0 01.82-.22zM16.3 4.552a.6.6 0 01.22.82l-.572.99a.6.6 0 01-1.04-.6l.572-.99a.6.6 0 01.82-.22zM6.58 15.129a.6.6 0 01-.22.82l-.99.57a.6.6 0 01-.6-1.039l.99-.571a.6.6 0 01.82.22zM19.45 7.7a.6.6 0 01-.22.82l-.989.571a.6.6 0 01-.6-1.04l.99-.57a.6.6 0 01.82.219zM5.743 12a.6.6 0 01-.6.6H4a.6.6 0 110-1.2h1.143a.6.6 0 01.6.6zM20.6 12a.6.6 0 01-.6.6h-1.143a.6.6 0 010-1.2H20a.6.6 0 01.6.6zM6.58 8.871a.6.6 0 01-.82.22l-.99-.571a.6.6 0 01.6-1.04l.99.572a.6.6 0 01.22.82zM19.446 16.3a.6.6 0 01-.82.22l-.99-.572a.6.6 0 11.6-1.039l.99.571a.6.6 0 01.22.82zM8.874 6.581a.6.6 0 01-.82-.22l-.571-.99a.6.6 0 111.04-.6l.57.99a.6.6 0 01-.219.82zM16.3 19.448a.6.6 0 01-.82-.22l-.57-.99a.6.6 0 011.039-.6l.571.99a.6.6 0 01-.22.82z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
