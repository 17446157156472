import state from './moduleGeoBlockingRulesState'
import mutations from './moduleGeoBlockingRulesMutations'
import actions from './moduleGeoBlockingRulesActions'
import getters from './moduleGeoBlockingRulesGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
