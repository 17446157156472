<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.44 6.12a.6.6 0 01.84.12L18.35 9l-2.07 2.76a.6.6 0 01-.96-.72L16.4 9.6h-3.3a2.4 2.4 0 00-2.4 2.391v.018a2.4 2.4 0 002.4 2.391h5.7l-1.08-1.44a.6.6 0 01.96-.72L20.75 15l-2.07 2.76a.6.6 0 01-.96-.72l1.08-1.44h-5.7a3.597 3.597 0 01-3-1.61 3.597 3.597 0 01-3 1.61H3.5a.6.6 0 110-1.2h3.6a2.4 2.4 0 002.4-2.391v-.018A2.4 2.4 0 007.1 9.6H3.5a.6.6 0 010-1.2h3.6c1.252 0 2.355.64 3 1.61a3.597 3.597 0 013-1.61h3.3l-1.08-1.44a.6.6 0 01.12-.84z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
