<template>
  <svg
    style="fill: currentColor;"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  ><path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.303 3.5a.6.6 0 100 1.2h3.368a.6.6 0 100-1.2h-3.368zm1.684 2.246a7.337 7.337 0 105.595 2.59l1.005-1.005a.6.6 0 00-.849-.848l-1.005 1.005a7.308 7.308 0 00-4.746-1.742zM5.85 13.082a6.137 6.137 0 1112.274 0 6.137 6.137 0 01-12.274 0zm6.137-3.407a3.407 3.407 0 100 6.814 3.407 3.407 0 000-6.814zM9.78 13.082a2.207 2.207 0 114.414 0 2.207 2.207 0 01-4.414 0z"
  /></svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '24',
    },
  },
}
</script>
