<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.913 16.825a1.087 1.087 0 110 2.175 1.087 1.087 0 010-2.175zM10.087 16.825a1.087 1.087 0 110 2.175 1.087 1.087 0 010-2.175zM17.913 9a1.087 1.087 0 110 2.175 1.087 1.087 0 010-2.175zM14 12.913a1.087 1.087 0 110 2.174 1.087 1.087 0 010-2.174zM17.913 12.913a1.087 1.087 0 110 2.174 1.087 1.087 0 010-2.174zM14 16.825A1.087 1.087 0 1114 19a1.087 1.087 0 010-2.175z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
