<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.399 10.799a.6.6 0 11-.849-.849l5-5 .424-.424.425.424 5 5a.6.6 0 11-.849.849l-3.976-3.976v12.051a.6.6 0 01-1.2 0V6.823L7.4 10.799z"
    />
  </svg>
</template>
