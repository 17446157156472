import Vue from 'vue'
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: h => h('GjIcon', { props: { size: '16', name: 'Close' } }),
  },
  OpenIndicator: {
    render: h => h('GjIcon', { props: { size: '16', name: 'ArrowDown' }, class: 'open-indicator' }),
  },
})

Vue.component('v-select', vSelect)
