<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5754 7.58282L12.9177 7.58282C12.5725 7.58283 12.2927 7.303 12.2927 6.95782C12.2927 6.61265 12.5725 6.33282 12.9177 6.33282L16.6677 6.33282C17.243 6.33282 17.7094 6.79919 17.7094 7.37449V11.1245C17.7094 11.4697 17.4295 11.7495 17.0844 11.7495C16.7392 11.7495 16.4594 11.4697 16.4594 11.1245V8.46661L11.6921 13.2339C11.4594 13.4666 11.0862 13.479 10.8385 13.2623L7.94556 10.7307L3.77489 14.9014C3.53081 15.1455 3.13508 15.1455 2.891 14.9014C2.64693 14.6573 2.64693 14.2616 2.89101 14.0175L7.47515 9.43336C7.70783 9.20069 8.08105 9.18827 8.32868 9.40496L11.2217 11.9366L15.5754 7.58282Z"
      fill="currentColor"
    />
  </svg>
</template>
