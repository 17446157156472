export default {
  isLoading: true,
  isUploading: false,
  webhooks: [],
  webhook: null,
  webhookEvents: [],
  webhookFiltersSelected: [],

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

  filters: {
    params: {
      webhookTypeId: null,
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },
}
