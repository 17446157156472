<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.4 8.286a2.6 2.6 0 012.6-2.6h7.429a2.6 2.6 0 012.6 2.6V18a2.6 2.6 0 01-2.6 2.6H7A2.6 2.6 0 014.4 18V8.286zm2.6-1.4a1.4 1.4 0 00-1.4 1.4V18A1.4 1.4 0 007 19.4h7.429a1.4 1.4 0 001.4-1.4V8.286a1.4 1.4 0 00-1.4-1.4H7z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.429 4.6a1.4 1.4 0 00-1.4 1.4v.286a.6.6 0 01-1.2 0V6a2.6 2.6 0 012.6-2.6h7.428a2.6 2.6 0 012.6 2.6v9.714a2.6 2.6 0 01-2.6 2.6H16.43a.6.6 0 110-1.2h1.428a1.4 1.4 0 001.4-1.4V6a1.4 1.4 0 00-1.4-1.4H10.43z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.257 9.714a.6.6 0 01.6-.6h5.714a.6.6 0 110 1.2H7.857a.6.6 0 01-.6-.6zM7.257 12a.6.6 0 01.6-.6h5.714a.6.6 0 010 1.2H7.857a.6.6 0 01-.6-.6zM7.257 14.286a.6.6 0 01.6-.6h5.714a.6.6 0 010 1.2H7.857a.6.6 0 01-.6-.6zM7.257 16.571a.6.6 0 01.6-.6h5.714a.6.6 0 010 1.2H7.857a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
