<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.23 6.462A1.23 1.23 0 105.23 4a1.23 1.23 0 000 2.462zM18.77 6.462a1.23 1.23 0 100-2.462 1.23 1.23 0 000 2.462zM12 6.462A1.23 1.23 0 1012 4a1.23 1.23 0 000 2.462zM5.23 20a1.23 1.23 0 100-2.462 1.23 1.23 0 000 2.462zM18.77 20a1.23 1.23 0 100-2.461 1.23 1.23 0 000 2.461zM12 20a1.23 1.23 0 100-2.461A1.23 1.23 0 0012 20zM5.23 13.23a1.23 1.23 0 100-2.46 1.23 1.23 0 000 2.46zM18.77 13.23a1.23 1.23 0 100-2.46 1.23 1.23 0 000 2.46zM12 13.23a1.23 1.23 0 100-2.46 1.23 1.23 0 000 2.46z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
