<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 17 17"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 6.94381C14 10.7788 10.8912 13.8876 7.05619 13.8876C5.90175 13.8876 4.8131 13.6059 3.85526 13.1074C3.63906 12.9949 3.38432 12.9744 3.15935 13.0681L1.07048 13.9385C0.434552 14.2035 -0.20342 13.5655 0.0615451 12.9296L0.931901 10.8407C1.02564 10.6157 1.00507 10.361 0.89257 10.1448C0.394114 9.18691 0.112385 8.09826 0.112385 6.94381C0.112385 3.10885 3.22123 0 7.05619 0C10.8912 0 14 3.10885 14 6.94381ZM7.82779 3.85767C7.82779 3.43156 7.48237 3.08614 7.05626 3.08614C6.63015 3.08614 6.28473 3.43156 6.28473 3.85767V6.94381C6.28473 7.36991 6.63015 7.71534 7.05626 7.71534C7.48237 7.71534 7.82779 7.36991 7.82779 6.94381V3.85767ZM7.05626 10.8015C7.48237 10.8015 7.8278 10.4561 7.8278 10.0299C7.8278 9.60384 7.48237 9.25841 7.05626 9.25841C6.63015 9.25841 6.28473 9.60384 6.28473 10.0299C6.28473 10.4561 6.63015 10.8015 7.05626 10.8015Z"
      fill="currentColor"
    />
  </svg>
</template>
