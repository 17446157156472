<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6 7.8V5.3a3.1 3.1 0 00-3.1-3.1h-1a3.1 3.1 0 00-3.1 3.1v2.5H7a3 3 0 00-3 3v8a3 3 0 003 3h10a3 3 0 003-3v-8a3 3 0 00-3-3h-1.4zm-7.2 4a.6.6 0 101.2 0V9h4.8v2.8a.6.6 0 101.2 0V9H17a1.8 1.8 0 011.8 1.8v8a1.8 1.8 0 01-1.8 1.8H7a1.8 1.8 0 01-1.8-1.8v-8A1.8 1.8 0 017 9h1.4v2.8zm1.2-4h4.8V5.3a1.9 1.9 0 00-1.9-1.9h-1a1.9 1.9 0 00-1.9 1.9v2.5z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
