<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.138 12a1.861 1.861 0 113.723 0 1.861 1.861 0 01-3.723 0z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.384 3.4c-.947 0-1.727.72-1.82 1.643-.26.091-.514.197-.76.315a1.831 1.831 0 00-2.45.126l-.87.87a1.831 1.831 0 00-.126 2.45c-.118.246-.224.5-.315.76a1.831 1.831 0 00-1.643 1.82v1.231c0 .948.72 1.728 1.643 1.822.091.26.197.513.315.759-.587.719-.545 1.78.126 2.45l.87.87a1.83 1.83 0 002.45.126c.246.118.5.224.76.315a1.83 1.83 0 001.82 1.643h1.231c.948 0 1.728-.72 1.822-1.643.26-.091.513-.197.759-.315a1.83 1.83 0 002.45-.126l.87-.87c.67-.67.712-1.731.126-2.45.118-.246.224-.5.315-.76a1.831 1.831 0 001.643-1.82v-1.231c0-.948-.72-1.728-1.643-1.822a7.32 7.32 0 00-.315-.759c.586-.719.544-1.78-.126-2.45l-.87-.87a1.831 1.831 0 00-2.45-.126 7.335 7.335 0 00-.76-.315 1.831 1.831 0 00-1.82-1.643h-1.232zM12 8.938a3.062 3.062 0 100 6.124 3.062 3.062 0 000-6.124z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
