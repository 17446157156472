<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 12a8.6 8.6 0 1117.2 0 8.6 8.6 0 01-17.2 0zM12 7.4a.6.6 0 01.6.6v4.572a.6.6 0 01-1.2 0V8a.6.6 0 01.6-.6zm0 7.429a.6.6 0 01.6.6v.011a.6.6 0 11-1.2 0v-.011a.6.6 0 01.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
