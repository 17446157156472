<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.55 6.55a2.1 2.1 0 00-2.1-2.1h-13a2.1 2.1 0 00-2.1 2.1v1c0 1.16.94 2.1 2.1 2.1h13a2.1 2.1 0 002.1-2.1v-1zm-2.1-.9a.9.9 0 01.9.9v1a.9.9 0 01-.9.9h-13a.9.9 0 01-.9-.9v-1a.9.9 0 01.9-.9h13zM13.55 16.65a.6.6 0 010-1.2h2.3v-2.299a.6.6 0 111.2 0v2.3h2.299a.6.6 0 010 1.2h-2.3v2.299a.6.6 0 11-1.2 0v-2.3h-2.3z"
    />
  </svg>
</template>
