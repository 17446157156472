class WaterfallTagUrl {
  constructor() {
    this.id = 0
    this.waterfallTagUrl = ''
    this.adBreakId = 0
    this.isDeleted = false
  }

  fromData(data) {
    this.id = data.id
    this.waterfallTagUrl = data.waterfallTagUrl ? data.waterfallTagUrl : ''
    this.isDeleted = data.isDeleted ? data.isDeleted : false
    this.adBreakId = data.adBreakId ? data.adBreakId : 0
  }
}

export default WaterfallTagUrl
