export default {
  getIsLoading: state => state.isLoading,
  getUserData: state => state.user,
  getUserDefaultOrganization: state => state.userDefaultOrganization,
  getUserDefaultProject: state => state.userDefaultProject,
  getUsers: state => state.users,
  getUsersForProject: state => state.usersForProject,
  getUsersOfProject: state => state.usersOfProject,
  getName: state => state.users.name,
  getDescription: state => state.users.description,
  getRole: state => state.userEdited.role,
  getEmail: state => state.userEdited.email,
  getOptions: state => state.users.options,
  getUsersOnAdd: state => state.usersOnAdd,
  getUsersOnAddOnIndex: (state, index) => state.usersOnAdd[index],
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,
}
