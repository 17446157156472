<script>
export default {
  name: 'Doublearrow',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 17 18"
    style="fill: currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.62468 7.6493C5.44456 7.4897 5.44456 7.22104 5.62468 7.06145L8.50001 4.51367L11.3753 7.06145C11.5555 7.22104 11.5555 7.4897 11.3753 7.64929C11.2077 7.79782 10.9438 7.79782 10.7762 7.6493L8.50001 5.63241L6.22383 7.6493C6.05621 7.79782 5.7923 7.79782 5.62468 7.6493Z"
      fill="#E0E5EB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.62468 10.3507C5.44456 10.5102 5.44456 10.7789 5.62468 10.9385L8.50001 13.4863L11.3753 10.9385C11.5555 10.7789 11.5555 10.5102 11.3753 10.3507C11.2077 10.2021 10.9438 10.2021 10.7762 10.3507L8.50001 12.3675L6.22383 10.3507C6.05621 10.2021 5.7923 10.2021 5.62468 10.3507Z"
      fill="#E0E5EB"
    />
  </svg>
</template>
