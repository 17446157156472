class AdFunction {
  constructor() {
    this.id = 0
    this.name = ''
    this.key = ''
    this.dynamicKey = false
    this.dynamicValue = false
    this.value = ''
  }

  fromData(data) {
    this.id = data.id || 0
    this.name = data.name || ''
    this.key = data.key || ''
    this.dynamicKey = data.dynamicKey || false
    this.dynamicValue = data.dynamicValue || false
    this.value = data.value || ''
  }
}

export default AdFunction
