<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.212 9.627a2.6 2.6 0 000 4.285l4.316 2.967c1.725 1.186 4.073-.05 4.073-2.143V8.802c0-2.093-2.348-3.328-4.073-2.142l-4.316 2.967zm.68 3.296a1.4 1.4 0 010-2.307l4.316-2.968c.928-.638 2.193.027 2.193 1.154v5.934c0 1.128-1.265 1.793-2.194 1.154l-4.315-2.967z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.373 6.66c1.725-1.186 4.073.049 4.073 2.142V9a.6.6 0 01-1.2 0v-.198c0-1.127-1.264-1.792-2.193-1.154l-4.316 2.968a1.4 1.4 0 000 2.307l4.316 2.967c.929.639 2.193-.026 2.193-1.154v-.198a.6.6 0 011.2 0v.198c0 2.094-2.348 3.329-4.073 2.143l-4.316-2.967a2.6 2.6 0 010-4.285L9.373 6.66z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
