<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4925 10.8879L16.8047 13.1998L14.4925 15.512L13.7461 14.7653L14.8507 13.6607C13.4817 13.5014 12.2843 13.0598 11.2106 12.323C10.0037 11.4936 9.09703 10.3971 8.21988 9.3368C6.51551 7.27523 4.90535 5.32898 1.19602 5.32898V4.27113C3.0818 4.27113 4.6677 4.73098 6.04371 5.67738C7.24992 6.50602 8.15625 7.60254 9.03516 8.66285C10.5117 10.4498 11.9201 12.1507 14.6844 12.5719L13.7461 11.6364L14.4925 10.8879ZM12.3427 6.21492C13.0493 5.81871 13.8227 5.55855 14.6823 5.42777L13.7461 6.36293L14.4932 7.11L16.8047 4.79953L14.4925 2.4873L13.7461 3.23402L14.85 4.33793C13.7341 4.46695 12.7368 4.78266 11.8266 5.29313C11.1987 5.64469 10.3187 6.23145 9.07629 7.64859L9.87117 8.34469C10.5043 7.62223 11.2939 6.80309 12.3427 6.21492ZM4.91027 11.7844C3.84574 12.382 2.63039 12.6717 1.19531 12.6717V13.7282C2.81637 13.7282 4.20047 13.3938 5.42707 12.7058C6.61535 12.0379 7.48406 11.1407 8.17734 10.3504L7.38281 9.6532C6.75 10.3771 5.95898 11.1962 4.91027 11.7844Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
