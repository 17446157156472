<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 9.5c0-1.16.94-2.1 2.1-2.1h13c1.16 0 2.1.94 2.1 2.1v1a2.1 2.1 0 01-2.1 2.1H13a.6.6 0 010-1.2h5.5a.9.9 0 00.9-.9v-1a.9.9 0 00-.9-.9h-13a.9.9 0 00-.9.9v.5a.6.6 0 01-1.2 0v-.5z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.975 12.323a.6.6 0 10-.849-.848L7.5 13.101l-1.626-1.626a.6.6 0 00-.848.848l1.626 1.626-1.626 1.626a.6.6 0 00.848.849L7.5 14.798l1.626 1.626a.6.6 0 00.849-.849L8.349 13.95l1.626-1.626z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
