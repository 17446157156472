/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import organization from '@/store/account/organization/moduleOrganization'
import project from '@/store/account/project/moduleProject'

export default {

  // ////////// //
  /* Project Notifications CRUD */
  postProjectNotification({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/user-notifications`, data)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getProjectNotifications({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/user-notifications`)
        .then(response => {
          commit('SET_NOTIFICATIONS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getProjectNotification({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/user-notifications/${id}`)
        .then(response => {
          commit('SET_NOTIFICATION', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateProjectNotification({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/user-notifications`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateProjectNotificationsStatus({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/user-notifications/${id}/status`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteProjectNotification({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/projects/${project.state.activeProjectId}/user-notifications/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // ////////// //
  /* Organization Notifications CRUD */
  postOrganizationNotification({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/organizations/${organization.state.activeOrganizationId}/user-notifications`, data)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getOrganizationNotifications({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/user-notifications`)
        .then(response => {
          commit('SET_NOTIFICATIONS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getOrganizationNotification({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/user-notifications/${id}`)
        .then(response => {
          commit('SET_NOTIFICATION', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateOrganizationNotification({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/organizations/${organization.state.activeOrganizationId}/user-notifications`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateOrganizationNotificationsStatus({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/organizations/${organization.state.activeOrganizationId}/user-notifications/${id}/status`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteOrganizationNotification({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/organizations/${organization.state.activeOrganizationId}/user-notifications/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // ////////// //
  /* Select Lists */
  notificationTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/notification-types/select-list')
        .then(async response => {
          commit('SET_TYPES_LIST', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  usageVolumesSelectList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/usage-volumes/select-list')
        .then(async response => {
          commit('SET_USAGEVOLUMES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  notificationsDateSelectList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/user-notification-dates/select-list')
        .then(async response => {
          commit('SET_DATES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
