<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3 5.15a.6.6 0 101.2 0v-.8a.6.6 0 10-1.2 0v.8zM6.924 6.891a.6.6 0 01-.848.849l-.566-.566a.6.6 0 01.849-.848l.565.565zM5.9 12.75a6.1 6.1 0 118.7 5.52v.98a1.6 1.6 0 01-1.6 1.6h-2a1.6 1.6 0 01-1.6-1.6v-.98a6.1 6.1 0 01-3.5-5.52zm6.1 6.1c.482 0 .95-.056 1.4-.161v.561a.4.4 0 01-.4.4h-2a.4.4 0 01-.4-.4v-.561c.45.105.918.161 1.4.161zm0-11a4.9 4.9 0 100 9.8 4.9 4.9 0 000-9.8zm-7 3.8a.6.6 0 00-.6-.6h-.8a.6.6 0 000 1.2h.8a.6.6 0 00.6-.6zm14 0a.6.6 0 01.6-.6h.8a.6.6 0 010 1.2h-.8a.6.6 0 01-.6-.6zm-1.924-4.759a.6.6 0 10.848.849l.566-.566a.6.6 0 00-.849-.848l-.565.565z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
