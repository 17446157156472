<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99977 14C9.41644 14 8.83311 13.775 8.39144 13.3333L2.95811 7.89997C2.71644 7.6583 2.71644 7.2583 2.95811 7.01664C3.19977 6.77497 3.59977 6.77497 3.84144 7.01664L9.27477 12.45C9.67477 12.85 10.3248 12.85 10.7248 12.45L16.1581 7.01664C16.3998 6.77497 16.7998 6.77497 17.0414 7.01664C17.2831 7.2583 17.2831 7.6583 17.0414 7.89997L11.6081 13.3333C11.1664 13.775 10.5831 14 9.99977 14Z"
      fill="currentColor"
    />
  </svg>

</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
