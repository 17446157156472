<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.22541 0.257446H1.95268V3.16654H1.16291V0.984719H1.14587L0.509503 1.3654V0.694946L1.22541 0.257446Z"
      fill="currentColor"
    />
    <path
      d="M3.83333 1.83329C3.83333 1.55715 4.05719 1.33329 4.33333 1.33329H12.3333C12.6095 1.33329 12.8333 1.55715 12.8333 1.83329C12.8333 2.10944 12.6095 2.33329 12.3333 2.33329H4.33333C4.05719 2.33329 3.83333 2.10944 3.83333 1.83329Z"
      fill="currentColor"
    />
    <path
      d="M4.33333 5.99996C4.05719 5.99996 3.83333 6.22382 3.83333 6.49996C3.83333 6.7761 4.05719 6.99996 4.33333 6.99996H12.3333C12.6095 6.99996 12.8333 6.7761 12.8333 6.49996C12.8333 6.22382 12.6095 5.99996 12.3333 5.99996H4.33333Z"
      fill="currentColor"
    />
    <path
      d="M4.33333 10.6666C4.05719 10.6666 3.83333 10.8905 3.83333 11.1666C3.83333 11.4428 4.05719 11.6666 4.33333 11.6666H12.3333C12.6095 11.6666 12.8333 11.4428 12.8333 11.1666C12.8333 10.8905 12.6095 10.6666 12.3333 10.6666H4.33333Z"
      fill="currentColor"
    />
    <path
      d="M0.526548 7.8332H2.7595V7.20252H1.62314V7.18548L1.89587 6.9582C2.55922 6.40565 2.72825 6.11871 2.72825 5.78207C2.72825 5.23945 2.28365 4.88434 1.58621 4.88434C0.90865 4.88434 0.46831 5.26076 0.46973 5.87298H1.21973C1.21831 5.62866 1.36746 5.49229 1.58621 5.49229C1.80354 5.49229 1.95837 5.62724 1.95837 5.85025C1.95837 6.05621 1.82626 6.18974 1.61178 6.36729L0.526548 7.26502V7.8332Z"
      fill="currentColor"
    />
    <path
      d="M2.87314 11.6646C2.87598 12.1774 2.37456 12.5396 1.66291 12.5396C0.973991 12.5396 0.483935 12.1561 0.481094 11.6135H1.27655C1.27939 11.7825 1.44132 11.9033 1.66859 11.9033C1.88734 11.9033 2.04075 11.7769 2.03791 11.5965C2.04075 11.4203 1.86178 11.2953 1.60609 11.2953H1.31064V10.7499H1.60609C1.84189 10.7499 2.00666 10.6249 2.00382 10.4487C2.00666 10.2797 1.86746 10.159 1.66859 10.159C1.45126 10.159 1.29643 10.284 1.29359 10.4601H0.537912C0.540753 9.92743 1.0095 9.55101 1.66859 9.55101C2.31348 9.55101 2.76803 9.90754 2.76518 10.3976C2.76803 10.7229 2.51518 10.9459 2.16859 10.9828V11.0056C2.63734 11.0567 2.87598 11.3081 2.87314 11.6646Z"
      fill="currentColor"
    />
  </svg>

</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
