/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'
import organization from '@/store/account/organization/moduleOrganization'

export default {

  getApiKeys({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/api-keys`)
        .then(response => {
          commit('SET_API_KEYS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getProjectApiKeys({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/api-keys`)
        .then(response => {
          commit('SET_API_KEYS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getApiKey({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/api-keys/${id}`)
        .then(response => {
          commit('SET_API_KEY', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  addUserApiKey({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/organizations/${organization.state.activeOrganizationId}/api-keys`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  addProjectUserApiKey({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/api-keys`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateApiKey({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/organizations/${organization.state.activeOrganizationId}/api-keys`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateProjectApiKey({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/api-keys`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removeApiKeyById({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/organizations/${organization.state.activeOrganizationId}/api-keys/${itemId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeProjectApiKeyById({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${project.state.activeProjectId}/api-keys/${itemId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeProjectFromApiKey({ commit }, {
    apiKeyId, projectId, apiKeyIndex, projectIndex,
  }) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/organizations/${organization.state.activeOrganizationId}/api-keys/${apiKeyId}/projects?projectId=${projectId}`)
        .then(response => {
          commit('REMOVE_PROJECT_FROM_APIKEY', { apiKeyIndex, projectIndex })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getApiKeyRoleOptions({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/roles`, state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_ROLES', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  addApiKeyToProjects({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/organizations/${organization.state.activeOrganizationId}/api-keys/projects`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getProjectsOfUserNotAlreadySelected({ commit, state }, apiKey) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/api-keys/${apiKey}/projects/select-list`)
        .then(response => {
          commit('SET_PROJECTS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

}
