<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1667 18.9584H5.83334C2.15834 18.9584 1.04167 17.8417 1.04167 14.1667V5.83335C1.04167 2.15835 2.15834 1.04169 5.83334 1.04169H7.08334C8.54167 1.04169 9.00001 1.51669 9.58334 2.29169L10.8333 3.95835C11.1083 4.32502 11.15 4.37502 11.6667 4.37502H14.1667C17.8417 4.37502 18.9583 5.49169 18.9583 9.16669V14.1667C18.9583 17.8417 17.8417 18.9584 14.1667 18.9584ZM5.83334 2.29169C2.85001 2.29169 2.29167 2.85835 2.29167 5.83335V14.1667C2.29167 17.1417 2.85001 17.7084 5.83334 17.7084H14.1667C17.15 17.7084 17.7083 17.1417 17.7083 14.1667V9.16669C17.7083 6.19169 17.15 5.62502 14.1667 5.62502H11.6667C10.6 5.62502 10.25 5.25835 9.83334 4.70835L8.58334 3.04169C8.15001 2.46669 8.01667 2.29169 7.08334 2.29169H5.83334Z"
      fill="currentColor"
    />
    <path
      d="M16.6667 5.94169C16.325 5.94169 16.0417 5.65835 16.0417 5.31669V4.16669C16.0417 2.85002 15.4833 2.29169 14.1667 2.29169H6.66667C6.32501 2.29169 6.04167 2.00835 6.04167 1.66669C6.04167 1.32502 6.32501 1.04169 6.66667 1.04169H14.1667C16.1833 1.04169 17.2917 2.15002 17.2917 4.16669V5.31669C17.2917 5.65835 17.0083 5.94169 16.6667 5.94169Z"
      fill="currentColor"
    />
  </svg>

</template>
