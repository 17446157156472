/* eslint-disable no-unused-vars */
import axios from '@axios'
import organization from '@/store/account/organization/moduleOrganization'
import project from '@/store/account/project/moduleProject'

export default {
  getVideoTitles({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/projects/${project.state.activeProjectId}/searches/video-titles`, state.filters.titles)
        .then(response => {
          commit('SET_VIDEOTITLES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getVideoTags({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/projects/${project.state.activeProjectId}/searches/video-tags`, state.filters.tags)
        .then(response => {
          commit('SET_VIDEOTAGS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getVideoAuthors({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/projects/${project.state.activeProjectId}/searches/video-authors`, state.filters.authors)
        .then(response => {
          commit('SET_VIDEOAUTHORS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getVideoSource({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/projects/${project.state.activeProjectId}/searches/video-source`, state.filters.source)
        .then(response => {
          commit('SET_VIDEOSOURCE', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getProjectDomains({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/domains`, state.filters.domains)
        .then(response => {
          commit('SET_PROJECTDOMAINS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getOrganizationUsers({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/users`, state.filters.organizationUsers)
        .then(response => {
          commit('SET_ORGANIZATIONUSERS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  // getOrganizationUsers({ state, commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios.get(`/api/organizations/${organization.state.activeOrganizationId}/users`, state.filters.organizationUsers)
  //       .then(response => {
  //         commit('SET_ORGANIZATIONUSERS', response.data.result.items)
  //         resolve(response)
  //       })
  //       .catch(error => { reject(error) })
  //   })
  // },
}
