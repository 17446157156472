<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.302 7.726l-.297-.98A2.6 2.6 0 0010.516 4.9H6a2.6 2.6 0 00-2.6 2.6v9A2.6 2.6 0 006 19.1h12a2.6 2.6 0 002.6-2.6v-6.174a2.6 2.6 0 00-2.6-2.6h-4.698z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
