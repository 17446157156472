<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 3.4A2.6 2.6 0 005.4 6v12A2.6 2.6 0 008 20.6h8.571a2.6 2.6 0 002.6-2.6V6a2.6 2.6 0 00-2.6-2.6H8zm2 5.714a.6.6 0 000 1.2h2.857a.6.6 0 100-1.2H10zm-.6 5.172a.6.6 0 01.6-.6h4.571a.6.6 0 010 1.2H10a.6.6 0 01-.6-.6zM10 11.4a.6.6 0 000 1.2h4.571a.6.6 0 100-1.2H10z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
