<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.77 10.216L4.92 12l1.85 1.784a.587.587 0 01.183.422v3.724c0 .817.686 1.467 1.513 1.467h1.573c.357 0 .632.277.632.602a.617.617 0 01-.632.601H8.466c-1.54 0-2.778-1.206-2.778-2.67v-3.477l-2.106-2.03a.58.58 0 010-.845l2.106-2.031V6.07c0-1.464 1.239-2.67 2.778-2.67h1.573c.357 0 .632.277.632.602a.617.617 0 01-.632.601H8.466c-.827 0-1.513.65-1.513 1.467v3.724a.587.587 0 01-.183.422zM17.23 13.784L19.08 12l-1.85-1.784a.587.587 0 01-.183-.422V6.07c0-.817-.686-1.467-1.513-1.467h-1.573a.617.617 0 01-.632-.601c0-.325.275-.602.632-.602h1.573c1.54 0 2.778 1.206 2.778 2.67v3.477l2.106 2.03a.58.58 0 010 .845l-2.106 2.031v3.477c0 1.464-1.239 2.67-2.778 2.67h-1.573a.617.617 0 01-.632-.602c0-.324.275-.601.632-.601h1.573c.827 0 1.513-.65 1.513-1.467v-3.723c0-.157.065-.31.183-.423z"
    />
  </svg>
</template>
