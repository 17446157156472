import { newAddHash } from '@/services/mixins/playerMixins'
import Player from './player'

class AudioPlayer extends Player {
  constructor() {
    super()
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // SETUP
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    this.isDefault = false
    this.playerTypeId = 1
    this.responsiveSize = 100
    this.podcastId = 0
    this.audioSocialNetworkIds = []
    this.pauseOtherAudios = true
    this.highlightCurrentAudio = false
    this.publicId = ''
    this.playerSocialNetworks = []

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // COSTUMIZE
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.backgroundUseThumbnail = false
    this.thumbnailOn = true
    this.backgroundBlur = false
    this.backgroundUrl = ''
    this.sliderOn = true
    this.showPlaylistItemProgress = false
    this.backgroundGradient = false
    this.gradientDirection = {}
    this.gradientDirectionOptionId = 1
    this.backgroundBorderWidth = 0
    this.elementsSkipButtonOn = true
    this.elementsNextPrevButtonOn = true
    this.elementsSettingsButtonOn = true
    this.elementsVolumeButtonOn = true
    this.elementsListButtonOn = true
    this.logoOn = true
    this.elementsLogoColor = ''
    this.backgroundPrimaryColor = '#011030'
    this.backgroundSecondaryColor = '#EA622B'
    this.backgroundBorderColor = '#FFFFFF'
    this.elementsPrimaryColor = '#FFFFFF'
    this.elementsSecondaryColor = '#FFFFFF'
    this.elementsPlaylistColor = '#011030'
    this.elementsPlaybackButtonColor = '#FFFFFF'
    this.elementsVolumeButtonColor = '#FFFFFF'
    this.elementsSkipButtonColor = '#FFFFFF'
    this.elementsSettingsButtonColor = '#FFFFFF'
    this.elementsNextPrevButtonColor = '#FFFFFF'
    this.elementsListButtonColor = '#FFFFFF'
    this.elementsTimeInfoColor = '#FFFFFF'
    this.elementsTitleColor = '#FFFFFF'
    this.elementsAuthorColor = '#FFFFFF'
    this.elementsDescriptionColor = '#FFFFFF'
    this.elementsDefaultColor = '#FFFFFF'
    this.elementsDividersColor = '#FFFFFF'
    this.elementsSliderRailColor = '#FFFFFF'
    this.elementsSliderProgressBarColor = '#EA622B'
    this.elementsDraggerColor = '#FFFFFF'
    this.elementsItemColor = '#FFFFFF'
    this.menuBackgroundDefaultColor = '#011030'
    this.menuTextDefaultColor = '#FFFFFF'
    this.menuToggleDefaultColor = '#FFFFFF'
    this.menuToggleOnColor = '#206ED5'
    this.menuToggleOffColor = '#CACACA'
    this.PlaylistText = 'Continue Listening'

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // LOCALIZATION
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.Autoplay = 'Autoplay'
    this.AutoplayOff = 'Off'
    this.AutoplayOn = 'On'
  }

  fromData(data) {
    super.fromData(data)

    // If data property is undefined or null, keep the default value
    this.isDefault = data.isDefault ?? this.isDefault
    this.playerTypeId = +data.playerTypeId ?? this.playerTypeId
    this.responsiveSize = +data.responsiveSize ?? this.responsiveSize
    this.podcastId = data.podcastId ?? this.podcastId
    this.audioSocialNetworkIds = data.audioSocialNetworkIds ?? this.audioSocialNetworkIds
    this.showPlaylistItemProgress = data.showPlaylistItemProgress ?? this.showPlaylistItemProgress
    this.pauseOtherAudios = data.pauseOtherAudios ?? this.pauseOtherAudios
    this.highlightCurrentAudio = data.highligshtCurrentAudio ?? this.highlightCurrentAudio
    this.thumbnailOn = data.thumbnailOn ?? this.thumbnailOn
    this.publicId = data.publicId ?? this.publicId
    this.playerSocialNetworks = data.playerSocialNetworks ?? this.playerSocialNetworks

    this.backgroundUrl = data.backgroundUrl ?? this.backgroundUrl
    this.PlaylistText = data.PlaylistText || 'Continue Listening'

    this.backgroundUseThumbnail = data.backgroundUseThumbnail ?? this.backgroundUseThumbnail
    this.backgroundGradient = data.backgroundGradient ?? this.backgroundGradient
    this.backgroundBlur = data.backgroundBlur ?? this.backgroundBlur
    this.gradientDirection = data.gradientDirection ?? this.gradientDirection
    this.gradientDirectionOptionId = data.gradientDirectionOptionId ?? this.gradientDirectionOptionId
    this.backgroundBorderWidth = data.backgroundBorderWidth ?? this.backgroundBorderWidth
    this.elementsSkipButtonOn = data.elementsSkipButtonOn ?? this.elementsSkipButtonOn
    this.elementsNextPrevButtonOn = data.elementsNextPrevButtonOn ?? this.elementsNextPrevButtonOn
    this.elementsSettingsButtonOn = data.elementsSettingsButtonOn ?? this.elementsSettingsButtonOn
    this.elementsVolumeButtonOn = data.elementsVolumeButtonOn ?? this.elementsVolumeButtonOn
    this.elementsListButtonOn = data.elementsListButtonOn ?? this.elementsListButtonOn
    this.elementsLogoColor = data.elementsLogoColor ?? this.elementsLogoColor
    this.sliderOn = data.sliderOn ?? this.sliderOn
    this.sliderOn = data.logoOn ?? this.logoOn
    this.backgroundPrimaryColor = newAddHash(data.backgroundPrimaryColor) ?? this.backgroundPrimaryColor
    this.backgroundSecondaryColor = newAddHash(data.backgroundSecondaryColor) ?? this.backgroundSecondaryColor
    this.backgroundBorderColor = newAddHash(data.backgroundBorderColor) ?? this.backgroundBorderColor
    this.elementsPrimaryColor = newAddHash(data.elementsPrimaryColor) ?? this.elementsPrimaryColor
    this.elementsSecondaryColor = newAddHash(data.elementsSecondaryColor) ?? this.elementsSecondaryColor
    this.elementsPlaylistColor = newAddHash(data.elementsPlaylistColor) ?? this.elementsPlaylistColor
    this.elementsPlaybackButtonColor = newAddHash(data.elementsPlaybackButtonColor) ?? this.elementsPlaybackButtonColor
    this.elementsVolumeButtonColor = newAddHash(data.elementsVolumeButtonColor) ?? this.elementsVolumeButtonColor
    this.elementsSkipButtonColor = newAddHash(data.elementsSkipButtonColor) ?? this.elementsSkipButtonColor
    this.elementsSettingsButtonColor = newAddHash(data.elementsSettingsButtonColor) ?? this.elementsSettingsButtonColor
    this.elementsNextPrevButtonColor = newAddHash(data.elementsNextPrevButtonColor) ?? this.elementsNextPrevButtonColor
    this.elementsListButtonColor = newAddHash(data.elementsListButtonColor) ?? this.elementsListButtonColor
    this.elementsTimeInfoColor = newAddHash(data.elementsTimeInfoColor) ?? this.elementsTimeInfoColor
    this.elementsTitleColor = newAddHash(data.elementsTitleColor) ?? this.elementsTitleColor
    this.elementsAuthorColor = newAddHash(data.elementsAuthorColor) ?? this.elementsAuthorColor
    this.elementsDescriptionColor = newAddHash(data.elementsDescriptionColor) ?? this.elementsDescriptionColor
    this.elementsDefaultColor = newAddHash(data.elementsDefaultColor) ?? this.elementsDefaultColor
    this.elementsDividersColor = newAddHash(data.elementsDividersColor) ?? this.elementsDividersColor
    this.elementsSliderRailColor = newAddHash(data.elementsSliderRailColor) ?? this.elementsSliderRailColor
    this.elementsSliderProgressBarColor = newAddHash(data.elementsSliderProgressBarColor) ?? this.elementsSliderProgressBarColor
    this.elementsDraggerColor = newAddHash(data.elementsDraggerColor) ?? this.elementsDraggerColor
    this.elementsItemColor = newAddHash(data.elementsItemColor) ?? this.elementsItemColor
    this.menuBackgroundDefaultColor = newAddHash(data.menuBackgroundDefaultColor) ?? this.menuBackgroundDefaultColor
    this.menuTextDefaultColor = newAddHash(data.menuTextDefaultColor) ?? this.menuTextDefaultColor
    this.menuToggleDefaultColor = newAddHash(data.menuToggleDefaultColor) ?? this.menuToggleDefaultColor
    this.menuToggleOnColor = newAddHash(data.menuToggleOnColor) ?? this.menuToggleOnColor
    this.menuToggleOffColor = newAddHash(data.menuToggleOffColor) ?? this.menuToggleOffColor

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // LOCALIZATION
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.Autoplay = data.Autoplay || 'Autoplay'
    this.AutoplayOff = data.AutoplayOff || 'Off'
    this.AutoplayOn = data.AutoplayOn || 'On'
  }
}

export default AudioPlayer
