<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.816 3.4a.6.6 0 00-.6.6v16a.6.6 0 101.2 0V4a.6.6 0 00-.6-.6zM17.144 7.182a1.6 1.6 0 00-1.6-1.6h-4.546a1.6 1.6 0 00-1.6 1.6v2.363a1.6 1.6 0 001.6 1.6h4.546a1.6 1.6 0 001.6-1.6V7.182zm-1.6-.4a.4.4 0 01.4.4v2.363a.4.4 0 01-.4.4h-4.546a.4.4 0 01-.4-.4V7.182c0-.221.18-.4.4-.4h4.546zM17.144 14.455a1.6 1.6 0 00-1.6-1.6H5.18a1.6 1.6 0 00-1.6 1.6v2.363a1.6 1.6 0 001.6 1.6h10.364a1.6 1.6 0 001.6-1.6v-2.363zm-1.6-.4a.4.4 0 01.4.4v2.363a.4.4 0 01-.4.4H5.18a.4.4 0 01-.4-.4v-2.363c0-.221.18-.4.4-.4h10.364z"
    />
  </svg>
</template>
