<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.39999 7.00002C4.39999 5.56408 5.56405 4.40002 6.99999 4.40002H17C18.4359 4.40002 19.6 5.56408 19.6 7.00002V17C19.6 18.436 18.4359 19.6 17 19.6H6.99999C5.56405 19.6 4.39999 18.436 4.39999 17V7.00002ZM6.99999 5.60002C6.2268 5.60002 5.59999 6.22683 5.59999 7.00002V17C5.59999 17.7732 6.2268 18.4 6.99999 18.4H17C17.7732 18.4 18.4 17.7732 18.4 17V7.00002C18.4 6.22683 17.7732 5.60002 17 5.60002H6.99999Z"
      fill="#2C2C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.692 10.1154C14.692 10.5614 14.3304 10.9231 13.8844 10.9231C13.4383 10.9231 13.0767 10.5614 13.0767 10.1154C13.0767 9.66929 13.4383 9.30768 13.8844 9.30768C14.3304 9.30768 14.692 9.66929 14.692 10.1154ZM12.2348 11.6698C12.0264 11.4134 11.631 11.426 11.4394 11.6953L9.86875 13.9024C9.63319 14.2334 9.86985 14.6923 10.2761 14.6923H13.6406C14.0616 14.6923 14.2942 14.2037 14.0286 13.877L12.2348 11.6698Z"
      fill="#2C2C2C"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
