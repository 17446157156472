<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.429 6.308a1.148 1.148 0 01-1.143-1.154c0-.637.511-1.154 1.143-1.154.63 0 1.142.517 1.142 1.154 0 .637-.511 1.154-1.142 1.154zm6.857 7.5a1.723 1.723 0 01-1.715-1.731c0-.956.768-1.73 1.715-1.73.947 0 1.714.774 1.714 1.73s-.767 1.73-1.714 1.73zm-2 5.192c-1.105 0-2-.904-2-2.02a2.01 2.01 0 012-2.019c1.104 0 2 .905 2 2.02a2.01 2.01 0 01-2 2.019zm-10-2.02c0 .16.128.29.285.29.158 0 .286-.13.286-.29a.287.287 0 00-.286-.288.287.287 0 00-.285.289zm-.572-9.807c0 .478.384.865.857.865a.861.861 0 00.858-.865.861.861 0 00-.858-.865.861.861 0 00-.857.865zm-1.143 5.48A.574.574 0 014 12.078c0-.319.256-.577.571-.577.316 0 .572.258.572.577a.574.574 0 01-.572.577zm10.286-5.48c0 .797.64 1.442 1.429 1.442s1.428-.645 1.428-1.442c0-.796-.64-1.442-1.428-1.442-.79 0-1.429.646-1.429 1.442z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
