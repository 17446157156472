<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.355 5.085a1 1 0 00-1.683-.047L4.94 6.114l-.496-.337.496.337a1.967 1.967 0 00-.223 1.773c.263.73.617 1.462 1.064 2.198l1.257-1.257a2.902 2.902 0 00.436-3.544l-.12-.199zm-.895 6.019l1.426-1.427a4.102 4.102 0 00.617-5.01l-.12-.199a2.2 2.2 0 00-3.704-.105L3.948 5.44c-.57.839-.704 1.9-.36 2.854.823 2.28 2.416 4.509 4.733 6.69 2.261 2.128 4.454 3.663 6.58 4.576a3.167 3.167 0 003.03-.29l.985-.67a2.2 2.2 0 00-.105-3.705l-.199-.12a4.102 4.102 0 00-5.01.618l-1.31 1.31a28.559 28.559 0 01-3.149-2.593c-1.07-1.007-1.962-2.01-2.683-3.006zm6.854 6.273c.7.432 1.387.791 2.06 1.08a1.966 1.966 0 001.882-.18l.985-.67a1 1 0 00-.047-1.683l-.199-.12a2.902 2.902 0 00-3.545.437l-1.136 1.136zM14.208 4.412a.6.6 0 01.774-.348 9.611 9.611 0 015.58 5.607.6.6 0 11-1.124.42 8.411 8.411 0 00-4.882-4.905.6.6 0 01-.348-.774zM12.947 7.041a.6.6 0 01.773-.35 7.036 7.036 0 014.11 4.122.6.6 0 11-1.125.418 5.836 5.836 0 00-3.407-3.417.6.6 0 01-.351-.773z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
