<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 6A2.6 2.6 0 016 3.4h12A2.6 2.6 0 0120.6 6v12a2.6 2.6 0 01-2.6 2.6H6A2.6 2.6 0 013.4 18V6zM6 4.6A1.4 1.4 0 004.6 6v12A1.4 1.4 0 006 19.4h12a1.4 1.4 0 001.4-1.4V6A1.4 1.4 0 0018 4.6H6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.477 8.077a1.6 1.6 0 011.6-1.6h1.077a1.6 1.6 0 011.6 1.6v1.077a1.6 1.6 0 01-1.6 1.6H8.077a1.6 1.6 0 01-1.6-1.6V8.077zm1.6-.4a.4.4 0 00-.4.4v1.077c0 .22.179.4.4.4h1.077a.4.4 0 00.4-.4V8.077a.4.4 0 00-.4-.4H8.077zM12.015 7.077a.6.6 0 01.6-.6h4.308a.6.6 0 010 1.2h-4.308a.6.6 0 01-.6-.6zM12.015 10.154a.6.6 0 01.6-.6h4.308a.6.6 0 010 1.2h-4.308a.6.6 0 01-.6-.6zM6.477 14.23a1.6 1.6 0 011.6-1.6h1.077a1.6 1.6 0 011.6 1.6v1.078a1.6 1.6 0 01-1.6 1.6H8.077a1.6 1.6 0 01-1.6-1.6V14.23zm1.6-.4a.4.4 0 00-.4.4v1.078c0 .22.179.4.4.4h1.077a.4.4 0 00.4-.4V14.23a.4.4 0 00-.4-.4H8.077zM12.015 13.23a.6.6 0 01.6-.6h4.308a.6.6 0 010 1.2h-4.308a.6.6 0 01-.6-.6zM12.015 16.308a.6.6 0 01.6-.6h4.308a.6.6 0 010 1.2h-4.308a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>
