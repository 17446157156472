<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.595 17.669a.6.6 0 00.84-.12l2.091-2.789-2.091-2.788a.6.6 0 10-.96.72l1.101 1.468H4.68V6.8a.6.6 0 00-1.2 0v7.86c0 .017 0 .034.002.05a.6.6 0 00.598.65h14.496l-1.101 1.469a.6.6 0 00.12.84z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
