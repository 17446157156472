<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.81405 4.31132C9.88991 4.0458 9.73617 3.76906 9.47065 3.69319C9.20513 3.61733 8.92839 3.77108 8.85253 4.0366L6.18586 13.3699C6.11 13.6354 6.26375 13.9122 6.52926 13.9881C6.79478 14.0639 7.07152 13.9102 7.14739 13.6447L9.81405 4.31132Z"
      fill="currentColor"
    />
    <path
      d="M5.02021 5.82027C5.21547 6.01554 5.21547 6.33212 5.02021 6.52738L2.7071 8.8405L5.02021 11.1536C5.21547 11.3489 5.21547 11.6655 5.02021 11.8607C4.82495 12.056 4.50837 12.056 4.3131 11.8607L1.88214 9.42975C1.5567 9.10431 1.5567 8.57668 1.88214 8.25124L4.3131 5.82027C4.50837 5.62501 4.82495 5.62501 5.02021 5.82027Z"
      fill="currentColor"
    />
    <path
      d="M10.9798 5.82027C10.7845 6.01554 10.7845 6.33212 10.9798 6.52738L13.2929 8.8405L10.9798 11.1536C10.7845 11.3489 10.7845 11.6655 10.9798 11.8607C11.175 12.056 11.4916 12.056 11.6869 11.8607L14.1178 9.42975C14.4433 9.10431 14.4433 8.57668 14.1178 8.25124L11.6869 5.82027C11.4916 5.62501 11.175 5.62501 10.9798 5.82027Z"
      fill="currentColor"
    />
  </svg>
</template>
