<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 20.6a8.586 8.586 0 007.485-4.363A8.561 8.561 0 0020.6 12a8.56 8.56 0 00-1.797-5.261A8.586 8.586 0 0012 3.4a8.586 8.586 0 00-7.485 4.362A8.56 8.56 0 003.4 12a8.56 8.56 0 001.797 5.261A8.586 8.586 0 0012 20.6zm0-16c-.755 0-1.6.628-2.292 2.012C9.034 7.96 8.6 9.864 8.6 12s.434 4.04 1.108 5.389C10.4 18.773 11.245 19.4 12 19.4c.755 0 1.6-.627 2.292-2.011C14.966 16.04 15.4 14.136 15.4 12s-.434-4.04-1.108-5.388C13.6 5.228 12.755 4.6 12 4.6zm2.825.558a7.402 7.402 0 010 13.684c.197-.284.378-.592.54-.917.774-1.547 1.235-3.643 1.235-5.925s-.461-4.378-1.235-5.925a7.38 7.38 0 00-.54-.917zm-5.65 13.684a7.376 7.376 0 01-.54-.917C7.86 16.378 7.4 14.282 7.4 12s.461-4.378 1.235-5.925c.162-.324.342-.633.54-.917a7.402 7.402 0 000 13.684z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.803 6.739a19.36 19.36 0 01-13.606 0c-.251.323-.48.665-.682 1.023a20.56 20.56 0 0014.97 0 8.607 8.607 0 00-.682-1.023zM18.803 17.261c.251-.323.479-.665.682-1.023a20.56 20.56 0 00-14.97 0c.203.358.43.7.682 1.023a19.36 19.36 0 0113.606 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
