<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.845 6.86a.6.6 0 10-.958-.72L4.52 9.281l-.272.36.272.362 2.366 3.143a.6.6 0 00.958-.722l-1.642-2.182h8.868a3.329 3.329 0 013.329 3.329 3.34 3.34 0 01-2.82 3.287c-.287.045-.524.276-.524.566 0 .373.304.68.673.625 2.182-.324 3.87-2.203 3.87-4.478a4.529 4.529 0 00-4.528-4.529H6.203l1.642-2.182z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
