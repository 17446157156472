<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.05 3.84a.6.6 0 000 1.2 7.168 7.168 0 017.163 6.896l-.78-.52a.6.6 0 00-.665 1l1.935 1.29 1.934-1.29a.6.6 0 10-.665-1l-.565.377A8.368 8.368 0 0011.05 3.84z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.964 20.148a.815.815 0 110-1.63.815.815 0 010 1.63zM5.374 18.03a.815.815 0 101.152-1.152.815.815 0 00-1.152 1.152zM3.11 12.294a.815.815 0 101.63 0 .815.815 0 00-1.63 0zM5.375 6.533a.813.813 0 101.15 1.15.813.813 0 00-1.15-1.15z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
