<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.467 3.921c.39-.564 1.274-.288 1.276.397l.01 5.92h3.25a.7.7 0 01.576 1.098l-6.048 8.743c-.39.564-1.274.288-1.275-.397l-.01-5.92h-3.25a.7.7 0 01-.577-1.099l6.048-8.742zm.078 1.996L7.95 12.561h2.397a1.1 1.1 0 011.1 1.098l.007 4.424 4.596-6.645h-2.396a1.1 1.1 0 01-1.1-1.098l-.008-4.423z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
