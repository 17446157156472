import state from './modulePodcastsState'
import mutations from './modulePodcastsMutations'
import actions from './modulePodcastsActions'
import getters from './modulePodcastsGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
