import Player from '@/models/players/videoPlayer'

export default {
  ADD_PLAYER: (state, data) => state.players.unshift(data),
  // SET_PLAYER: (state, data) => { state.player = data },
  SET_PLAYERS: (state, data) => { state.players = data },
  SET_DEFAULT_PLAYERS: (state, data) => { state.defaultPlayers = data || [] },
  SET_PLAYERS_SELECTLIST: (state, data) => { state.playersSelectList = data.map(p => { p.id = p.value; return p }) },
  SET_PLAYER: (state, data) => {
    const player = new Player()
    player.fromData(data)
    state.player = player
  },
  SET_PLAYER_RELEASECHANNELS: (state, data) => { state.player.releaseChannels = data },
  SET_PLAYER_RELEASECHANNEL: (state, data) => { state.player.releaseChannel = data },
  SET_PLAYER_PLAYERSIZETYPE: (state, data) => { state.player.playerSizeTypeId = data.id; state.player.playerSizeType = data },
  SET_PLAYER_PRELOADOPTION: (state, data) => { state.player.preloadOptionId = data.id; state.player.preloadOption = data },
  SET_PLAYER_DEFAULTBANDWIDTHESTIMATEOPTION: (state, data) => { state.player.defaultBandwidthEstimateOptionId = data.id; state.player.defaultBandwidthEstimateOption = data },
  SET_PLAYER_RESPONSIVESIZEOPTION: (state, data) => { state.player.responsiveSizeOptionId = data.id; state.player.responsiveSizeOption = data },
  SET_PLAYER_LOGOPOSITIONOPTION: (state, data) => { state.player.logoPositionOptionId = data.id; state.player.logoPositionOption = data },
  SET_PLAYER_FLOATPOSITIONOPTION: (state, data) => { state.player.floatPositionOptionId = data.id; state.player.floatPositionOption = data },
  // SET_PLAYER_: (state, data) => { state.player = data },
  SET_PLAYER_CONTROLBAR_DEFAULT_COLORS: (state, data) => {
    state.player.controlBarBackgroundColor = `#${data.controlbarbackgroundcolor}`
    state.player.controlBarTextColor = `#${data.controlbartextcolor}`
    state.player.controlBarIconsDefaultColor = `#${data.controlbariconsdefaultcolor}`
    state.player.controlBarIconsHoverColor = `#${data.controlbariconshovercolor}`
    state.player.controlBarTimesliderProgressColor = `#${data.controlbartimesliderprogresscolor}`
    state.player.controlBarTimesliderRailColor = `#${data.controlbartimesliderrailcolor}`
    state.player.controlBarTimesliderBufferColor = `#${data.controlbartimesliderbuffercolor}`
    state.player.controlBarTimesliderDraggerColor = `#${data.controlbartimesliderdraggercolor}`
    state.player.controlBarTimeSliderOnAdProgressColor = `#${data.controlbartimeslideronadprogresscolor}`
    state.player.controlBarTimeSliderOnAdRailColor = `#${data.controlbartimeslideronadrailcolor}`
    state.player.controlBarTimeSliderOnAdDraggerColor = `#${data.controlbartimeslideronaddraggercolor}`
    state.player.controlBarVolumeDraggerColor = `#${data.controlbarvolumedraggercolor}`
    state.player.controlBarVolumeProgressColor = `#${data.controlbarvolumeprogresscolor}`
    state.player.controlBarVolumeRailColor = `#${data.controlbarvolumerailcolor}`
    state.player.controlBarVolumeNotifierColor = `#${data.controlbarvolumenotifiercolor}`
    state.player.controlBarLoaderColor = `#${data.controlbarloadercolor}`
  },
  SET_PLAYER_MENU_DEFAULT_COLORS: (state, data) => {
    state.player.menuBackroundDefaultColor = `#${data.menubackrounddefaultcolor}`
    state.player.menuBackroundHoverColor = `#${data.menubackroundhovercolor}`
    state.player.menuLinksDefaultColor = `#${data.menulinksdefaultcolor}`
    state.player.menuLinksHoverColor = `#${data.menulinkshovercolor}`
    state.player.menuAutoplayOnColor = `#${data.menuautoplayoncolor}`
    state.player.menuAutoplayOffColor = `#${data.menuautoplayoffcolor}`
  },
  SET_PLAYER_PLAYLIST_DEFAULT_COLORS: (state, data) => {
    state.player.playlistBackgroundColor = `#${data.playlistbackgroundcolor}`
    state.player.playlistTextColor = `#${data.playlisttextcolor}`
    state.player.playlistCardTitleColor = `#${data.playlistcardtitlecolor}`
    state.player.playlistCardBackgroundColor = `#${data.playlistcardbackgroundcolor}`
    state.player.playlistCardDurationTextColor = `#${data.playlistcarddurationtextcolor}`
    state.player.playlistCardDurationBackgroundColor = `#${data.playlistcarddurationbackgroundcolor}`
    state.player.playlistCardIconPlayDefaulColor = `#${data.playlistcardiconplaydefaulcolor}`
    state.player.playlistCardIconPlayHoverColor = `#${data.playlistcardiconplayhovercolor}`
    state.player.playlistIconsArrActiveColor = `#${data.playlisticonsarractivecolor}`
    state.player.playlistIconsArrInactiveColor = `#${data.playlisticonsarrinactivecolor}`
    state.player.playlistIconsCloseColor = `#${data.playlisticonsclosecolor}`
  },
  SET_PLAYER_NEXTVIDEO_DEFAULT_COLORS: (state, data) => {
    state.player.nextVideoBackgroundColor = `#${data.nextvideobackgroundcolor}`
    state.player.nextVideoTextColor = `#${data.nextvideotextcolor}`
    state.player.nextVideoTsRailColor = `#${data.nextvideotsrailcolor}`
    state.player.nextVideoTsProgressColor = `#${data.nextvideotsprogresscolor}`
    state.player.nextVideoTsIconsCloseColor = `#${data.nextvideotsiconsclosecolor}`
    state.player.nextVideoIconsPlayDefaultColor = `#${data.nextvideoiconsplaydefaultcolor}`
    state.player.nextVideoIconsPlayHoverColor = `#${data.nextvideoiconsplayhovercolor}`
  },
  SET_PLAYER_TOOLTIPS_DEFAULT_COLORS: (state, data) => {
    state.player.tooltipsTextColor = `#${data.tooltipstextcolor}`
    state.player.tooltipsBackroundColor = `#${data.tooltipsbackroundcolor}`
  },
  SET_PLAYER_DEFAULT_COLORS: (state, data) => {
    // Object.keys(data).forEach(x => {
    //   state.player[x] = `#${data[x]}`
    // })
    state.player.controlBarBackgroundColor = `#${data.controlbarbackgroundcolor}`
    state.player.controlBarTextColor = `#${data.controlbartextcolor}`
    state.player.controlBarIconsDefaultColor = `#${data.controlbariconsdefaultcolor}`
    state.player.controlBarIconsHoverColor = `#${data.controlbariconshovercolor}`
    state.player.controlBarTimesliderProgressColor = `#${data.controlbartimesliderprogresscolor}`
    state.player.controlBarTimesliderRailColor = `#${data.controlbartimesliderrailcolor}`
    state.player.controlBarTimesliderBufferColor = `#${data.controlbartimesliderbuffercolor}`
    state.player.controlBarTimesliderDraggerColor = `#${data.controlbartimesliderdraggercolor}`
    state.player.controlBarTimeSliderOnAdProgressColor = `#${data.controlbartimeslideronadprogresscolor}`
    state.player.controlBarTimeSliderOnAdRailColor = `#${data.controlbartimeslideronadrailcolor}`
    state.player.controlBarTimeSliderOnAdDraggerColor = `#${data.controlbartimeslideronaddraggercolor}`
    state.player.controlBarVolumeDraggerColor = `#${data.controlbarvolumedraggercolor}`
    state.player.controlBarVolumeProgressColor = `#${data.controlbarvolumeprogresscolor}`
    state.player.controlBarVolumeRailColor = `#${data.controlbarvolumerailcolor}`
    state.player.controlBarVolumeNotifierColor = `#${data.controlbarvolumenotifiercolor}`
    state.player.controlBarLoaderColor = `#${data.controlbarloadercolor}`
    state.player.menuBackroundDefaultColor = `#${data.menubackrounddefaultcolor}`
    state.player.menuBackroundHoverColor = `#${data.menubackroundhovercolor}`
    state.player.menuLinksDefaultColor = `#${data.menulinksdefaultcolor}`
    state.player.menuLinksHoverColor = `#${data.menulinkshovercolor}`
    state.player.menuAutoplayOnColor = `#${data.menuautoplayoncolor}`
    state.player.menuAutoplayOffColor = `#${data.menuautoplayoffcolor}`
    state.player.playlistBackgroundColor = `#${data.playlistbackgroundcolor}`
    state.player.playlistTextColor = `#${data.playlisttextcolor}`
    state.player.playlistCardTitleColor = `#${data.playlistcardtitlecolor}`
    state.player.playlistCardBackgroundColor = `#${data.playlistcardbackgroundcolor}`
    state.player.playlistCardDurationTextColor = `#${data.playlistcarddurationtextcolor}`
    state.player.playlistCardDurationBackgroundColor = `#${data.playlistcarddurationbackgroundcolor}`
    state.player.playlistCardIconPlayDefaulColor = `#${data.playlistcardiconplaydefaulcolor}`
    state.player.playlistCardIconPlayHoverColor = `#${data.playlistcardiconplayhovercolor}`
    state.player.playlistIconsArrActiveColor = `#${data.playlisticonsarractivecolor}`
    state.player.playlistIconsArrInactiveColor = `#${data.playlisticonsarrinactivecolor}`
    state.player.playlistIconsCloseColor = `#${data.playlisticonsclosecolor}`
    state.player.nextVideoBackgroundColor = `#${data.nextvideobackgroundcolor}`
    state.player.nextVideoTextColor = `#${data.nextvideotextcolor}`
    state.player.nextVideoTsRailColor = `#${data.nextvideotsrailcolor}`
    state.player.nextVideoTsProgressColor = `#${data.nextvideotsprogresscolor}`
    state.player.nextVideoTsIconsCloseColor = `#${data.nextvideotsiconsclosecolor}`
    state.player.nextVideoIconsPlayDefaultColor = `#${data.nextvideoiconsplaydefaultcolor}`
    state.player.nextVideoIconsPlayHoverColor = `#${data.nextvideoiconsplayhovercolor}`
    state.player.tooltipsTextColor = `#${data.tooltipstextcolor}`
    state.player.tooltipsBackroundColor = `#${data.tooltipsbackroundcolor}`
  },
  SET_PLAYER_LOCALIZATION_DEFAULT: (state, data) => {
    Object.keys(data).forEach(x => {
      const y = x.split('.')[1]
      state.player[y] = data[x]
    })
  },
  SET_PAGINATION: (state, data) => {
    const { items, ...p } = data
    state.pagination = p
  },
  SET_PLAYER_LOGOINACTIVEOPACITY: (state, value) => {
    state.player.logoInactiveOpacity = value
  },
  SET_PLAYER_LOGODEFAULTOPACITY: (state, value) => {
    state.player.logoDefaultOpacity = value
  },
  SET_OPTIONS: (state, data) => { state.options = data.data.result },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  SCRIPT_IS_LOADED: (state, data) => { state.playerLoaded = data },
  CLEAR_PLAYERS: state => { state.players = [] },
  CLEAR_PLAYERS_SELECTLIST: state => { state.playersSelectList = [] },
  CLEAR_PLAYER: state => { state.player = new Player() },
  UPDATE_PLAYER: (state, data) => {
    const playerIndex = state.players.findIndex(p => p.id === data.id)
    Object.assign(state.players[playerIndex], data)
  },
  UPDATE_PLAYER_PROP: (state, { key, value }) => {
    state.player[key] = value
  },
  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
}
