<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.25 1C0.25 0.585788 0.585786 0.25 1 0.25H17C17.4142 0.25 17.75 0.585786 17.75 1C17.75 1.41421 17.4142 1.75 17 1.75L1 1.75C0.585787 1.75 0.25 1.41421 0.25 1Z"
      fill="currentColor"
    />
    <path
      d="M0.25 5C0.25 4.58579 0.585786 4.25 1 4.25L9 4.25C9.41421 4.25 9.75 4.58579 9.75 5C9.75 5.41422 9.41421 5.75 9 5.75L1 5.75C0.585786 5.75 0.25 5.41422 0.25 5Z"
      fill="currentColor"
    />
    <path
      d="M1 8.25C0.585786 8.25 0.25 8.58579 0.25 9C0.25 9.41422 0.585787 9.75 1 9.75L13 9.75C13.4142 9.75 13.75 9.41421 13.75 9C13.75 8.58579 13.4142 8.25 13 8.25L1 8.25Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
