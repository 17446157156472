<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.764 4.004a.7.7 0 011.297 0l2.066 5.104 5.493.388a.7.7 0 01.401 1.234l-4.216 3.542 1.329 5.344a.7.7 0 01-1.05.762l-4.671-2.914-4.672 2.914a.7.7 0 01-1.05-.762l1.329-5.344-4.216-3.542a.7.7 0 01.4-1.234l5.493-.388 2.067-5.104z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
