<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.398 13.201a.6.6 0 00-.848.849l5 5 .424.424.425-.424 5-5a.6.6 0 10-.85-.849l-3.975 3.976V5.126a.6.6 0 10-1.2 0v12.051l-3.976-3.976z"
    />
  </svg>
</template>
