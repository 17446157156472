class Subtitle {
  constructor() {
    this.id = 0
    this.languageId = 0
    this.videoId = ''
    this.filePath = ''
  }

  fromData(data) {
    this.id = data.id || 0
    this.languageId = data.languageId || 0
    this.videoId = data.videoId || ''
    this.filePath = data.filePath || ''
  }
}

export default Subtitle
