<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 4.4A2.6 2.6 0 003.4 7v8.572a2.6 2.6 0 002.6 2.6h8.864a2.1 2.1 0 002.065 1.714H18.5a2.1 2.1 0 002.1-2.1v-5a2.1 2.1 0 00-2.1-2.1h-.186V7a2.6 2.6 0 00-2.6-2.6H6zm11.711 7.486h.789a.9.9 0 01.9.9v5a.9.9 0 01-.9.9h-1.571a.9.9 0 01-.9-.9v-.21-.005V12.787a.9.9 0 01.9-.9h.782zm-.597-1.2V7a1.4 1.4 0 00-1.4-1.4H6A1.4 1.4 0 004.6 7v8.572a1.4 1.4 0 001.4 1.4h8.829v-4.186c0-1.16.94-2.1 2.1-2.1h.185zm-9.143 8.6a.6.6 0 01.6-.6h4.572a.6.6 0 010 1.2H8.57a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
