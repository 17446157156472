import state from './moduleUserRolesState'
import mutations from './moduleUserRolesMutations'
import actions from './moduleUserRolesActions'
import getters from './moduleUserRolesGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
