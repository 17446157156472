<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.268 3.904a2.6 2.6 0 012.465 0l5.5 2.962c.363.195.668.47.897.797l-7.45 3.638a.4.4 0 01-.359 0l-7.46-3.625c.23-.332.538-.612.906-.81l5.5-2.962zM3.425 8.798a2.601 2.601 0 00-.025.357v5.688a2.6 2.6 0 001.367 2.289l5.5 2.961c.203.11.416.19.634.241v-7.907a1.585 1.585 0 01-.116-.052l-7.36-3.577zM12.1 20.334c.219-.052.431-.132.633-.24l5.5-2.962a2.6 2.6 0 001.367-2.29V9.155c0-.126-.009-.25-.027-.373l-7.357 3.593a1.593 1.593 0 01-.115.052v7.907z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.319 7.678a.6.6 0 01-.247.812l-4.01 2.138v3.967a.6.6 0 11-1.2 0V9.908l4.645-2.477a.6.6 0 01.812.247zM17.225 14.607a.6.6 0 01-.255.809l-2.885 1.5a.6.6 0 11-.554-1.065l2.885-1.5a.6.6 0 01.81.255z"
    />
  </svg>
</template>
