<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 6.5a3.1 3.1 0 013.1-3.1h2.53a3.1 3.1 0 013.1 3.1v2.53a3.1 3.1 0 01-3.1 3.1H6.5a3.1 3.1 0 01-3.1-3.1V6.5zM13.282 6.941a2.6 2.6 0 012.6-2.6h2.165a2.6 2.6 0 012.6 2.6v2.165a2.6 2.6 0 01-2.6 2.6h-2.165a2.6 2.6 0 01-2.6-2.6V6.94zM13.282 15.853c0-1.16.94-2.1 2.1-2.1h1.706c1.16 0 2.1.94 2.1 2.1v1.706a2.1 2.1 0 01-2.1 2.1h-1.706a2.1 2.1 0 01-2.1-2.1v-1.706zM4.812 15.84a2.6 2.6 0 012.6-2.6h2.16a2.6 2.6 0 012.6 2.6V18a2.6 2.6 0 01-2.6 2.6h-2.16a2.6 2.6 0 01-2.6-2.6v-2.16z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
