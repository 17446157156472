<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 3.4C6.04 3.4 4 5.315 4 7.733s2.04 4.333 4.5 4.333S13 10.15 13 7.733 10.96 3.4 8.5 3.4z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.9 13.532c-2.46 0-4.5 1.916-4.5 4.335V18A2.6 2.6 0 004 20.6h9c.832 0 1.573-.391 2.05-1h4.484a2.067 2.067 0 002.067-2.067c0-1.777-1.507-3.167-3.3-3.167h-4.5l-.045.001a4.604 4.604 0 00-2.656-.835H5.9z"
    />
    <path
      d="M16.462 6.834c-1.794 0-3.3 1.389-3.3 3.166s1.506 3.166 3.3 3.166c1.794 0 3.3-1.389 3.3-3.166s-1.506-3.166-3.3-3.166z"
    />
  </svg>
</template>
