<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.237 4.616c0-.34.28-.616.626-.616h6.01c.346 0 .627.276.627.616 0 .34-.28.616-.627.616H15.3l-5.258 13.536h2.095c.346 0 .626.276.626.616 0 .34-.28.616-.626.616h-6.01a.622.622 0 01-.627-.616c0-.34.28-.616.627-.616H8.7l5.258-13.536h-2.095a.622.622 0 01-.626-.616z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
