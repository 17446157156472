<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    style="fill: currentColor;"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0001 3.54167C10.3453 3.54167 10.6251 3.82149 10.6251 4.16667V11.2183C10.6268 11.2163 10.6284 11.2143 10.6301 11.2123C10.8057 11.0051 10.9777 10.7861 11.1384 10.5813C11.1509 10.5654 11.1633 10.5496 11.1756 10.5339C11.3358 10.33 11.5044 10.1157 11.6352 9.98107C11.8758 9.73354 12.2715 9.7279 12.519 9.96848C12.7665 10.2091 12.7722 10.6047 12.5316 10.8523C12.4577 10.9283 12.3363 11.0799 12.1587 11.306C12.1458 11.3224 12.1328 11.339 12.1195 11.3559C11.9611 11.5577 11.7753 11.7945 11.5838 12.0204C11.3786 12.2626 11.149 12.514 10.9194 12.7096C10.8044 12.8075 10.6747 12.9042 10.5352 12.9788C10.4007 13.0508 10.2153 13.125 10.0001 13.125C9.78484 13.125 9.59947 13.0508 9.46494 12.9788C9.32545 12.9042 9.19578 12.8075 9.08075 12.7096C8.85119 12.514 8.62154 12.2625 8.41637 12.0204C8.22493 11.7945 8.03911 11.5578 7.88072 11.356C7.86745 11.3391 7.85438 11.3224 7.84151 11.306C7.66385 11.0799 7.54249 10.9283 7.46857 10.8523C7.22799 10.6048 7.23361 10.2091 7.48113 9.9685C7.72865 9.72791 8.12434 9.73353 8.36492 9.98105C8.49581 10.1157 8.66433 10.33 8.82453 10.5339C8.83688 10.5496 8.8493 10.5655 8.86179 10.5814C9.02255 10.7861 9.19447 11.0051 9.37004 11.2123C9.37172 11.2143 9.3734 11.2163 9.37508 11.2183V4.16667C9.37508 3.82149 9.6549 3.54167 10.0001 3.54167Z"
      fill="#1C1C1C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.54175 15.8333C3.54175 15.4882 3.82157 15.2083 4.16675 15.2083H15.8335C16.1787 15.2083 16.4585 15.4882 16.4585 15.8333C16.4585 16.1785 16.1787 16.4583 15.8335 16.4583H4.16675C3.82157 16.4583 3.54175 16.1785 3.54175 15.8333Z"
      fill="#1C1C1C"
    />
  </svg>

</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
