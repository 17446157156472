<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 7.825c0-1.5 1.215-2.715 2.715-2.715h11.77c1.5 0 2.715 1.215 2.715 2.715v1.841H3.4V7.825zm3.474.19a.57.57 0 11-1.139 0 .57.57 0 011.14 0zm1.709 0a.57.57 0 11-1.14 0 .57.57 0 011.14 0zm1.139.57a.57.57 0 100-1.14.57.57 0 000 1.14z"
    />
    <path
      d="M3.4 10.92h17.2v5.258c0 1.5-1.216 2.715-2.715 2.715H6.115A2.715 2.715 0 013.4 16.178v-5.259z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
