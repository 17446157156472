import state from './moduleCategoriesState'
import mutations from './moduleCategoriesMutations'
import actions from './moduleCategoriesActions'
import getters from './moduleCategoriesGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
