<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9592 3.25111C16.9728 3.25037 16.9864 3.25 17 3.25C17.9946 3.25 18.9484 3.64509 19.6516 4.34835C20.3549 5.05161 20.75 6.00544 20.75 7C20.75 7.01362 20.7496 7.02723 20.7489 7.04083C20.6439 8.96628 19.9703 10.8174 18.8133 12.36C17.756 13.7697 16.3385 14.864 14.7138 15.5305C14.6087 16.4992 14.2947 17.4358 13.7913 18.2748C13.2008 19.2591 12.369 20.0765 11.3746 20.6498C11.1562 20.7757 10.8892 20.7834 10.6638 20.6704C10.4385 20.5575 10.285 20.3388 10.2552 20.0885C10.0632 18.4726 9.33309 16.9682 8.18242 15.8176C7.03175 14.6669 5.52741 13.9368 3.91148 13.7448C3.66117 13.715 3.44254 13.5615 3.32955 13.3362C3.21656 13.1108 3.22434 12.8438 3.35024 12.6254C3.92353 11.631 4.74093 10.7992 5.72519 10.2087C6.56418 9.70526 7.50078 9.39133 8.46951 9.28616C9.13596 7.66154 10.2303 6.24394 11.64 5.18665C13.1826 4.02967 15.0337 3.3561 16.9592 3.25111ZM17.0194 4.75008C15.3979 4.84272 13.8396 5.41196 12.54 6.38665C11.2347 7.36564 10.249 8.7097 9.70749 10.2489C9.60488 10.5406 9.33378 10.7394 9.02475 10.7496C8.13248 10.7791 7.26246 11.0356 6.49693 11.4949C6.04752 11.7645 5.64284 12.0988 5.29504 12.4854C6.7815 12.8789 8.14558 13.6594 9.24308 14.7569C10.3406 15.8544 11.1211 17.2185 11.5146 18.7049C11.9012 18.3571 12.2355 17.9525 12.5051 17.5031C12.9644 16.7375 13.2209 15.8675 13.2504 14.9752C13.2606 14.6662 13.4594 14.3951 13.7511 14.2925C15.2903 13.751 16.6344 12.7653 17.6133 11.46C18.588 10.1604 19.1573 8.60212 19.2499 6.98058C19.2448 6.39086 19.0084 5.82639 18.591 5.40901C18.1736 4.99163 17.6091 4.75516 17.0194 4.75008Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.6531 13.6313C7.85674 13.992 7.72941 14.4495 7.36871 14.6531C6.45803 15.1672 5.72166 15.9416 5.25397 16.877C4.87685 17.6312 4.6883 18.4624 4.70024 19.2998C5.53757 19.3117 6.36877 19.1231 7.12301 18.746C8.05838 18.2783 8.83276 17.542 9.34689 16.6313C9.55052 16.2706 10.008 16.1433 10.3687 16.3469C10.7294 16.5505 10.8567 17.008 10.6531 17.3687C9.99208 18.5396 8.99645 19.4864 7.79383 20.0877C6.59121 20.689 5.2364 20.9174 3.9031 20.7437C3.56575 20.6998 3.30023 20.4342 3.25628 20.0969C3.08257 18.7636 3.31102 17.4088 3.91233 16.2062C4.51364 15.0035 5.46041 14.0079 6.63128 13.3469C6.99198 13.1433 7.44946 13.2706 7.6531 13.6313Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7626 7.76256C14.0907 7.43437 14.5359 7.25 15 7.25C15.4641 7.25 15.9092 7.43437 16.2374 7.76256C16.5656 8.09075 16.75 8.53587 16.75 9C16.75 9.46413 16.5656 9.90925 16.2374 10.2374C15.9092 10.5656 15.4641 10.75 15 10.75C14.5359 10.75 14.0907 10.5656 13.7626 10.2374C13.4344 9.90925 13.25 9.46413 13.25 9C13.25 8.53587 13.4344 8.09075 13.7626 7.76256ZM15 8.75C14.9337 8.75 14.8701 8.77634 14.8232 8.82322C14.7763 8.87011 14.75 8.9337 14.75 9C14.75 9.0663 14.7763 9.12989 14.8232 9.17678C14.8701 9.22366 14.9337 9.25 15 9.25C15.0663 9.25 15.1299 9.22366 15.1768 9.17678C15.2237 9.12989 15.25 9.0663 15.25 9C15.25 8.9337 15.2237 8.87011 15.1768 8.82322C15.1299 8.77634 15.0663 8.75 15 8.75Z"
      fill="currentColor"
    />
  </svg>

</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
