<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.71 6.768l7.548 5.06a1.4 1.4 0 001.484 0l7.604-4.95A2.6 2.6 0 0018 5.4H6a2.6 2.6 0 00-2.29 1.368z"
    />
    <path
      d="M20.6 8.144l-7.222 4.702a2.6 2.6 0 01-2.756 0L3.4 8.005V16A2.6 2.6 0 006 18.6h12a2.6 2.6 0 002.6-2.6V8.144z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
