<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.278 3.4a2.6 2.6 0 00-2.579 2.936l1.076 8.244a5.6 5.6 0 003.04 4.28l2.442 1.226a2.6 2.6 0 002.333 0l2.441-1.226a5.6 5.6 0 003.04-4.28l1.076-8.244A2.6 2.6 0 0017.569 3.4H7.278zm8.195 5.97a.6.6 0 00-.946-.74l-2.694 3.448-1.395-1.488a.6.6 0 10-.876.82l1.795 1.915a.7.7 0 001.063-.048l3.053-3.908z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
