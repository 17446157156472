import Webhook from '@/models/webhooks/Webhook'

export default {
  SET_WEBHOOKS: (state, data) => { state.webhooks = data || [] },
  SET_WEBHOOK: (state, data) => {
    // const webhook = new WebhookModel()
    // webhook.fromData(data)
    state.webhook = data
  },
  SET_WEBHOOK_FILTERS_SELECTED: (state, data) => { state.webhookFiltersSelected = data },
  SET_WEBHOOK_EVENTS: (state, data) => { state.webhookEvents = data },
  SET_NAME: (state, data) => { state.webhook.name = data },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  CLEAR_WEBHOOKS: state => { state.webhooks = [] },
  CLEAR_WEBHOOK: state => { state.webhook = new Webhook() },
  CLEAR_EVENTS: state => { state.webhookEvents = [] },

  UPDATE_WEBHOOK_LIST_STATUS: (state, { index, active }) => { state.webhooks[index].active = active },
  SET_WEBHOOK_TYPES: (state, data) => { data.map(x => { x.value = x.id; return x }); data.unshift({ id: 0, name: 'All', value: null }); state.webhookTypes = data },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },

  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'webhookTypeId') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        webhookTypeId: null,
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
}
