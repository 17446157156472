<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.70281 6.02406L9.83429 7.44505C10.2301 7.70893 10.2301 8.29057 9.83429 8.55445L7.70281 9.97544C7.25977 10.2708 6.66634 9.9532 6.66634 9.42073V6.57876C6.66634 6.04629 7.25977 5.7287 7.70281 6.02406Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967ZM13.6663 7.99967C13.6663 11.1293 11.1293 13.6663 7.99967 13.6663C4.87006 13.6663 2.33301 11.1293 2.33301 7.99967C2.33301 4.87006 4.87006 2.33301 7.99967 2.33301C11.1293 2.33301 13.6663 4.87006 13.6663 7.99967Z"
      fill="currentColor"
    />
  </svg>
</template>
