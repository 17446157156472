<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.248 18.065A2.447 2.447 0 013.4 15.692V6A2.6 2.6 0 016 3.4h7.693a2.6 2.6 0 012.6 2.6v2.323h1.122a2.6 2.6 0 012.414 1.634l.585 1.463c.123.307.186.634.186.965v3.307a2.447 2.447 0 01-1.848 2.373l.001.089a2.446 2.446 0 11-4.892 0v-.015h-3.722v.015a2.446 2.446 0 11-4.89-.09zM4.6 14.446h9.093a2.6 2.6 0 002.6-2.6V9.523h1.122a1.4 1.4 0 011.3.88l.585 1.462c.066.166.1.342.1.52v3.307c0 .6-.422 1.1-.985 1.22a2.445 2.445 0 00-4.231.027H9.816a2.445 2.445 0 00-4.23-.028 1.247 1.247 0 01-.986-1.219v-1.246zm3.725-7.343c.27 0 .501.048.692.142.19.095.333.223.428.382.096.16.143.335.143.524 0 .18-.03.346-.092.5-.062.155-.183.338-.361.549-.18.21-.444.474-.795.79h1.318l-.1.78h-2.34v-.717c.387-.386.674-.683.86-.89.185-.207.314-.378.388-.511a.797.797 0 00.11-.385.398.398 0 00-.095-.282.344.344 0 00-.266-.102c-.11 0-.21.029-.298.087-.089.058-.18.148-.273.27l-.567-.473c.144-.204.32-.365.531-.485.211-.12.45-.179.717-.179zm4.29 2.191v.77h-.35v.705h-.973l-.005-.706H9.858v-.68l.938-2.306.847.316-.767 1.901h.42l.116-.78h.852v.78h.351zm-4.922 7.614a1.246 1.246 0 100 2.492 1.246 1.246 0 000-2.492zm8.614 0a1.246 1.246 0 100 2.492 1.246 1.246 0 000-2.492z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
