<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.773 7.424a.6.6 0 00-.849-.848l-5 5L4.5 12l.424.424 5 5a.6.6 0 00.849-.848L6.797 12.6H18.85a.6.6 0 100-1.2H6.797l3.976-3.976z"
    />
  </svg>
</template>
