import ApiKey from '@/models/api-keys/ApiKey'

export default {
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },

  SET_API_KEYS(state, data) { state.apiKeys = data },
  SET_API_KEY(state, data) { const apiKey = new ApiKey(); apiKey.fromData({ roleId: data?.roleId, userApiKey: { ...data } }, true); state.apiKey = apiKey },
  SET_API_KEY_PROJECT_IDS_SELECTED(state, data) { state.apiKey.listProjectsId = data },
  SET_API_KEY_PROJECT_ID(state, data) { state.apiKey.projectId = data },
  SET_API_KEY_ROLE_SELECTED(state, data) { state.apiKey.roleId = data },
  SET_API_KEY_NAME(state, data) { state.apiKey.userApiKey.apiName = data },
  SET_API_KEY_HAS_EXPIRE_TIME(state, data) { state.apiKey.userApiKey.hasExpireTime = data },
  SET_API_KEY_START_DATE_TIME(state, data) { state.apiKey.userApiKey.startDateTime = data },
  SET_API_KEY_EXPIRE_DATE_TIME(state, data) { state.apiKey.userApiKey.expireDateTime = data },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },
  SET_PROJECTS: (state, data) => { state.apiKeyProjectsOptions = data },

  REMOVE_PROJECT_FROM_APIKEY: (state, { apiKeyIndex, projectIndex }) => { state.apiKeys[apiKeyIndex].projectsNameList.splice(projectIndex, 1) },
  UPDATE_API_KEY_ON_ROW: (state, id, name, startDate, expireDate, hasExpireTime) => {
    // const index = state.apiKeys.findIndex(x => x.apiKeyId === data.id)
    state.apiKeys.map(x => {
      if (x.apiKeyId === id) {
        return {
          apiKeyId: id,
          apiKeyName: name,
          apiKey: x.apiKey,
          startDate,
          expireDate,
          hasExpireTime,
          projectsNameList: x.projectsNameList,
        }
      }
      return x
    })
  },
  CLEAR_API_KEY(state) { state.apiKey = new ApiKey() },
  CLEAR_API_KEYS(state) { state.apiKeys = [] },

  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'take') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
}
