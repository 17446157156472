<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 15A1.6 1.6 0 015 13.4h4a1.6 1.6 0 011.6 1.6v4A1.6 1.6 0 019 20.6H5A1.6 1.6 0 013.4 19v-4zm1.6-.4a.4.4 0 00-.4.4v4c0 .22.18.4.4.4h4a.4.4 0 00.4-.4v-4a.4.4 0 00-.4-.4H5zM3.4 5A1.6 1.6 0 015 3.4h4A1.6 1.6 0 0110.6 5v4A1.6 1.6 0 019 10.6H5A1.6 1.6 0 013.4 9V5zM5 4.6a.4.4 0 00-.4.4v4c0 .22.18.4.4.4h4a.4.4 0 00.4-.4V5a.4.4 0 00-.4-.4H5zM13.4 15a1.6 1.6 0 011.6-1.6h4a1.6 1.6 0 011.6 1.6v4a1.6 1.6 0 01-1.6 1.6h-4a1.6 1.6 0 01-1.6-1.6v-4zm1.6-.4a.4.4 0 00-.4.4v4c0 .22.18.4.4.4h4a.4.4 0 00.4-.4v-4a.4.4 0 00-.4-.4h-4zM13.4 5A1.6 1.6 0 0115 3.4h4A1.6 1.6 0 0120.6 5v4a1.6 1.6 0 01-1.6 1.6h-4A1.6 1.6 0 0113.4 9V5zm1.6-.4a.4.4 0 00-.4.4v4c0 .22.18.4.4.4h4a.4.4 0 00.4-.4V5a.4.4 0 00-.4-.4h-4z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
