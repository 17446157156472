<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.25 8C3.25 7.58579 3.58579 7.25 4 7.25H20C20.4142 7.25 20.75 7.58579 20.75 8C20.75 8.41421 20.4142 8.75 20 8.75L4 8.75C3.58579 8.75 3.25 8.41421 3.25 8Z"
      fill="currentColor"
    />
    <path
      d="M7.25 12C7.25 11.5858 7.58579 11.25 8 11.25L16 11.25C16.4142 11.25 16.75 11.5858 16.75 12C16.75 12.4142 16.4142 12.75 16 12.75L8 12.75C7.58579 12.75 7.25 12.4142 7.25 12Z"
      fill="currentColor"
    />
    <path
      d="M6 15.25C5.58579 15.25 5.25 15.5858 5.25 16C5.25 16.4142 5.58579 16.75 6 16.75L18 16.75C18.4142 16.75 18.75 16.4142 18.75 16C18.75 15.5858 18.4142 15.25 18 15.25L6 15.25Z"
      fill="currentColor"
    />
  </svg>
</template>
