export default {
  billings: [],
  invoiceData: null,
  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },
  filters: {
    params: {
      insertedDate: null,
      key: null,
      value: null,
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },
}
