<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 16.25C9.38 16.25 7.25 14.12 7.25 11.5V6C7.25 3.38 9.38 1.25 12 1.25C14.62 1.25 16.75 3.38 16.75 6V11.5C16.75 14.12 14.62 16.25 12 16.25ZM12 2.75C10.21 2.75 8.75 4.21 8.75 6V11.5C8.75 13.29 10.21 14.75 12 14.75C13.79 14.75 15.25 13.29 15.25 11.5V6C15.25 4.21 13.79 2.75 12 2.75Z"
      fill="currentColor"
    />
    <path
      d="M12 19.75C7.36998 19.75 3.59998 15.98 3.59998 11.35V9.65002C3.59998 9.24002 3.93998 8.90002 4.34998 8.90002C4.75998 8.90002 5.09998 9.24002 5.09998 9.65002V11.35C5.09998 15.15 8.19998 18.25 12 18.25C15.8 18.25 18.9 15.15 18.9 11.35V9.65002C18.9 9.24002 19.24 8.90002 19.65 8.90002C20.06 8.90002 20.4 9.24002 20.4 9.65002V11.35C20.4 15.98 16.63 19.75 12 19.75Z"
      fill="currentColor"
    />
    <path
      d="M13.39 7.17995C13.31 7.17995 13.22 7.16995 13.13 7.13995C12.4 6.86995 11.6 6.86995 10.87 7.13995C10.48 7.27995 10.05 7.07995 9.91 6.68995C9.77 6.29995 9.97 5.86995 10.36 5.72995C11.42 5.34995 12.59 5.34995 13.65 5.72995C14.04 5.86995 14.24 6.29995 14.1 6.68995C13.98 6.98995 13.69 7.17995 13.39 7.17995Z"
      fill="currentColor"
    />
    <path
      d="M12.8 9.29995C12.73 9.29995 12.67 9.28995 12.6 9.26995C12.2 9.15995 11.79 9.15995 11.39 9.26995C10.99 9.37995 10.58 9.13995 10.47 8.73995C10.36 8.34995 10.6 7.93995 11 7.82995C11.65 7.64995 12.35 7.64995 13 7.82995C13.4 7.93995 13.64 8.34995 13.53 8.74995C13.44 9.07995 13.13 9.29995 12.8 9.29995Z"
      fill="currentColor"
    />
    <path
      d="M12 22.75C11.59 22.75 11.25 22.41 11.25 22V19C11.25 18.59 11.59 18.25 12 18.25C12.41 18.25 12.75 18.59 12.75 19V22C12.75 22.41 12.41 22.75 12 22.75Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
