<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 6A2.6 2.6 0 016 3.4h7A2.6 2.6 0 0115.6 6v12a2.6 2.6 0 01-2.6 2.6H6A2.6 2.6 0 013.4 18V6zM6 4.6A1.4 1.4 0 004.6 6v12A1.4 1.4 0 006 19.4h7a1.4 1.4 0 001.4-1.4V6A1.4 1.4 0 0013 4.6H6zM16.4 8.5A2.6 2.6 0 0119 5.9h1a.6.6 0 110 1.2h-1a1.4 1.4 0 00-1.4 1.4v7a1.4 1.4 0 001.4 1.4h1a.6.6 0 010 1.2h-1a2.6 2.6 0 01-2.6-2.6v-7z"
    />
    <path
      d="M12.685 9.846a.923.923 0 11-1.846 0 .923.923 0 011.846 0zM9.819 11.55a.5.5 0 00-.796.025l-1.93 2.712a.5.5 0 00.408.79h4.134a.5.5 0 00.388-.815L9.819 11.55z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
