<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 5.35A1.6 1.6 0 015 3.75h3.874a1.6 1.6 0 011.6 1.6v3.874a1.6 1.6 0 01-1.6 1.6H5a1.6 1.6 0 01-1.6-1.6V5.35zm1.6-.4a.4.4 0 00-.4.4v3.874c0 .221.18.4.4.4h3.874a.4.4 0 00.4-.4V5.35a.4.4 0 00-.4-.4H5zM12.543 5.82a.6.6 0 01.6-.6H20a.6.6 0 110 1.2h-6.857a.6.6 0 01-.6-.6zM12.543 8.432a.6.6 0 01.6-.6H20a.6.6 0 110 1.2h-6.857a.6.6 0 01-.6-.6zM3.4 14.779a1.6 1.6 0 011.6-1.6h3.874a1.6 1.6 0 011.6 1.6v3.874a1.6 1.6 0 01-1.6 1.6H5a1.6 1.6 0 01-1.6-1.6v-3.874zm1.6-.4a.4.4 0 00-.4.4v3.874c0 .22.18.4.4.4h3.874a.4.4 0 00.4-.4v-3.874a.4.4 0 00-.4-.4H5zM12.543 15.534a.6.6 0 01.6-.6H20a.6.6 0 110 1.2h-6.857a.6.6 0 01-.6-.6zM12.543 18.146a.6.6 0 01.6-.6H20a.6.6 0 110 1.2h-6.857a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
