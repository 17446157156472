<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.118 4.483A2.953 2.953 0 109.01 8.036h7.684v2.1a.7.7 0 001.195.495l2.7-2.7a.7.7 0 000-.99l-2.7-2.7a.7.7 0 00-1.195.496v2.1H9.01a2.954 2.954 0 00-2.892-2.354zM4.365 7.436a1.753 1.753 0 113.506 0 1.753 1.753 0 01-3.506 0zm13.53-.006V8.93l1.492-1.493-1.493-1.492V7.43zM7.306 13.854v2.1h7.625V13.6h5.904v5.904h-5.904v-2.35H7.306v2.1a.7.7 0 01-1.195.495l-2.7-2.7a.7.7 0 010-.99l2.7-2.7a.7.7 0 011.195.495zm-1.2 4.193V15.062l-1.493 1.492 1.493 1.493zm13.53-3.247H16.13v3.504h3.504V14.8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
