<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.375"
      y="0.371094"
      width="16"
      height="15"
      rx="2"
      style="fill: currentColor"
    />
    <rect
      x="18.375"
      y="2.87109"
      width="6"
      height="2"
      rx="1"
      style="fill: currentColor"
    />
    <rect
      x="18.375"
      y="6.87109"
      width="6"
      height="2"
      rx="1"
      style="fill: currentColor"
    />
    <rect
      x="18.375"
      y="10.8711"
      width="6"
      height="2"
      rx="1"
      style="fill: currentColor"
    />
  </svg>
</template>
