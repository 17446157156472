<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.419 6.686H6a2.6 2.6 0 00-2.6 2.6v7.428a2.6 2.6 0 002.6 2.6h12a2.6 2.6 0 002.6-2.6V9.286a2.6 2.6 0 00-2.6-2.6h-1.419A2.6 2.6 0 0014 4.4h-4a2.6 2.6 0 00-2.581 2.286zm1.216 0h6.73A1.4 1.4 0 0014 5.6h-4a1.4 1.4 0 00-1.365 1.086zM6 7.886a1.4 1.4 0 00-1.4 1.4v7.428a1.4 1.4 0 001.4 1.4h12a1.4 1.4 0 001.4-1.4V9.286a1.4 1.4 0 00-1.4-1.4H6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 10.171a2.829 2.829 0 100 5.658 2.829 2.829 0 000-5.658zM7.973 13a4.029 4.029 0 118.057 0 4.029 4.029 0 01-8.057 0z"
    />
  </svg>
</template>
