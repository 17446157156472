<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.18 17.218a.6.6 0 01.268.805l-.842 1.684a.6.6 0 11-1.073-.537l.842-1.684a.6.6 0 01.805-.268zM13.53 17.218a.6.6 0 01.269.805l-.842 1.684a.6.6 0 11-1.074-.537l.843-1.684a.6.6 0 01.805-.268z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.299 14.425a1.365 1.365 0 000 2.73h6.737a1.365 1.365 0 000-2.73h-2.807a.6.6 0 110-1.2h2.807a2.565 2.565 0 010 5.13h-6.737a2.565 2.565 0 010-5.13h.28a.6.6 0 010 1.2h-.28z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.98 13.263a3.407 3.407 0 116.814 0 .6.6 0 01-1.2 0 2.207 2.207 0 10-3.98 1.314.6.6 0 11-.964.715 3.395 3.395 0 01-.67-2.029z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.05 11.018a3.968 3.968 0 017.85-.83.6.6 0 11-1.174.25 2.77 2.77 0 10-3.486 3.237.6.6 0 01-.336 1.152 3.97 3.97 0 01-2.854-3.81zM3.68 11.018a.6.6 0 01.6-.6h1.124a.6.6 0 110 1.2H4.28a.6.6 0 01-.6-.6zM4.662 14.686a.6.6 0 01.22-.82l.972-.561a.6.6 0 01.6 1.04l-.972.56a.6.6 0 01-.82-.219zM15.36 8.51a.6.6 0 01.22-.82l.972-.56a.6.6 0 01.6 1.039l-.973.561a.6.6 0 01-.82-.22zM13.525 6.675a.6.6 0 01-.22-.82l.562-.972a.6.6 0 111.04.6l-.562.973a.6.6 0 01-.82.22zM11.018 6.004a.6.6 0 01-.6-.6V4.28a.6.6 0 011.2 0v1.123a.6.6 0 01-.6.6zM8.51 6.675a.6.6 0 01-.82-.22l-.56-.972a.6.6 0 011.038-.6l.562.973a.6.6 0 01-.22.82zM6.675 8.51a.6.6 0 01-.82.22l-.972-.561a.6.6 0 11.6-1.04l.973.562a.6.6 0 01.22.82z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
