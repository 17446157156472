<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.4a8.6 8.6 0 100 17.2 8.6 8.6 0 000-17.2zm0 4.571a2.314 2.314 0 00-2.314 2.315.6.6 0 101.2 0c0-.616.499-1.115 1.114-1.115h.686a1 1 0 011 1v.115c0 .615-.499 1.114-1.114 1.114H11.4v2.314a.6.6 0 001.2 0V12.6a2.314 2.314 0 002.286-2.314v-.114a2.2 2.2 0 00-2.2-2.2H12zm.6 7.446a.6.6 0 00-1.2 0v.012a.6.6 0 001.2 0v-.012z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
