import state from './moduleFoldersState'
import mutations from './moduleFoldersMutations'
import actions from './moduleFoldersActions'
import getters from './moduleFoldersGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
