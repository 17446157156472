import state from './moduleAnalyticsState'
import mutations from './moduleAnalyticsMutations'
import actions from './moduleAnalyticsActions'
import getters from './moduleAnalyticsGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
