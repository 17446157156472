import state from './moduleLiveVideosState'
import mutations from './moduleLiveVideosMutations'
import actions from './moduleLiveVideosActions'
import getters from './moduleLiveVideosGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
