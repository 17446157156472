import state from './moduleBillingState'
import mutations from './moduleBillingMutations'
import actions from './moduleBillingActions'
import getters from './moduleBillingGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
