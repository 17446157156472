<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.714 3.4a2.6 2.6 0 00-2.6 2.6v12a2.6 2.6 0 002.6 2.6h8.572a2.6 2.6 0 002.6-2.6V6a2.6 2.6 0 00-2.6-2.6H7.714zm5.143 14.029a.857.857 0 11-1.714 0 .857.857 0 011.714 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
