import axios from '@axios'
import project from '@/store/account/project/moduleProject'

const baseURL = process.env.VUE_APP_API_LIVESTREAM
const version = 1
export default {
  fetchRestreams({ commit }, liveVideoId) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}api/v${version}/${project.state.activeProjectId}/live-video/${liveVideoId}/restreams`)
        .then(response => {
          commit('SET_RESTREAMS', response.data.result)
          resolve(response)
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    })
  },
  createRestream({ commit }, restream) {
    return new Promise((resolve, reject) => {
      axios.post(`${baseURL}api/v${version}/${project.state.activeProjectId}/restreams`, {
        ...restream,
      })
        .then(response => {
          commit('ADD_RESTREAM', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateRestream({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.put(`${baseURL}api/v${version}/${project.state.activeProjectId}/restreams/${id}/toggle`)
        .then(response => {
          commit('UPDATE_RESTREAM', response.data.result)
          resolve(response)
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    })
  },
  deleteRestream({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${baseURL}api/v${version}/${project.state.activeProjectId}/restreams/${id}`)
        .then(() => {
          commit('DELETE_RESTREAM', id)
          resolve()
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    })
  },
}
