<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5833 18.9583H3.41666C1.81666 18.9583 1.04166 18.1417 1.04166 16.475V13.1083C1.04166 11.4333 1.81666 10.625 3.41666 10.625H16.5833C18.1833 10.625 18.9583 11.4417 18.9583 13.1083V16.475C18.9583 18.1417 18.1833 18.9583 16.5833 18.9583ZM3.41666 11.875C2.57499 11.875 2.29166 12.05 2.29166 13.1083V16.475C2.29166 17.5333 2.57499 17.7083 3.41666 17.7083H16.5833C17.425 17.7083 17.7083 17.5333 17.7083 16.475V13.1083C17.7083 12.05 17.425 11.875 16.5833 11.875H3.41666Z"
      fill="currentColor"
    />
    <path
      d="M16.5833 9.37502H3.41666C1.81666 9.37502 1.04166 8.55835 1.04166 6.89169V3.52502C1.04166 1.85002 1.81666 1.04169 3.41666 1.04169H16.5833C18.1833 1.04169 18.9583 1.85835 18.9583 3.52502V6.89169C18.9583 8.55835 18.1833 9.37502 16.5833 9.37502ZM3.41666 2.29169C2.57499 2.29169 2.29166 2.46669 2.29166 3.52502V6.89169C2.29166 7.95002 2.57499 8.12502 3.41666 8.12502H16.5833C17.425 8.12502 17.7083 7.95002 17.7083 6.89169V3.52502C17.7083 2.46669 17.425 2.29169 16.5833 2.29169H3.41666Z"
      fill="currentColor"
    />
  </svg>

</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
