import CustomReport from '@/models/custom-reports/CustomReport'
// import CustomReportViewTable from '@/models/custom-reports/CustomReportViewTable'
// import CustomReportViewChart from '@/models/custom-reports/CustomReportViewChart'

export default {
  isLoading: true,

  // # LIVE VIEWERS
  liveViewers: [],
  liveViewersIsLoading: true,
  liveViewersFilters: {
    params: {
      videoId: '',
      endDate: '',
      seconds: null,
      intervalLength: null,
    },
  },

  // # VIDEO ANALYTICS
  videoAnalyticsIsLoading: false,
  videoAnalytics: {
    playsNumber: 0,
    embedNumber: 0,
    completesNumber: 0,
    uniqueViewersNumber: 0,
    timeWatchedPerViewer: 0,
  },

  // # CONTENT
  topContent: [],
  topContentIsLoading: true,
  topContentFilters: {
    params: {
      seconds: 120,
      items: 8,
    },
  },

  // # DEVICES
  topDevicesIsLoading: true,
  topDevices: [],

  // # BROWSERS
  topBrowsersIsLoading: true,
  topBrowsers: [],

  // # DOMAINS
  topDomainsIsLoading: true,
  topDomains: [],

  // # OPERATING SYSTEMS
  topOperatingSystemsIsLoading: true,
  topOperatingSystems: [],

  // # COUNTRIES
  topCountriesIsLoading: true,
  topCountries: [],

  // # ANALYTICS USAGE DATA
  usageDatas: {},
  loadingUsageData: false,

  // # ANALYTICS CONTENT STATE
  contentIsLoading: false,
  content: [],
  contentOptions: null,

  // # ANALYTICS CUSTOM REPORTS STATE
  customReportsIsLoading: false,
  customReportIsLoading: false,
  customReports: [],
  customReport: new CustomReport(),
  customReportsSelectedMetrics: [],
  customReportsSelectedDimensions: [],
  customReportsSelectedFilters: [],
  customReportViewChart: {},
  customReportViewChartQuery: {},
  isLoadingChart: true,
  customReportViewTable: {},
  customReportViewTableQuery: {},
  customReportViewTablePagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },
  customReportViewTableFilters: {
    params: {
      organizationId: 0,
      projectId: '',
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },
  isLoadingTable: true,
  customReportsOptions: null,

  // # ANALYTICS USAGE HISTORY STATE
  usageHistoryIsLoading: false,
  usageHistory: [],
  usageHistoryTotal: null,
  usageHistoryOptions: null,

  // # ANALYTICS USAGE HISTORY STATE
  contractTotal: {},
  contractUsage: {},
  contractIsLoading: false,
  // usageHistoryTotal: null,
  // usageHistoryOptions: null,

  // # ANALYTICS FILTERS STATE
  filters: {
    params: {
      organizationId: 0,
      projectId: '',
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
      takeAll: false,
    },
  },

  // # ANALYTICS PAGINATION STATE
  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

}
