<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    style="fill: currentColor"
    viewBox="0 0 16 16"
  >
    <rect
      width="16"
      height="16"
      rx="3"
      fill="#1877F2"
    />
    <path
      d="M11.1114 10.2222L11.467 7.99997H9.33366V6.44442C9.33366 5.8222 9.55588 5.33331 10.5337 5.33331H11.5559V3.28886C10.9781 3.19997 10.3559 3.11108 9.7781 3.11108C7.95588 3.11108 6.66699 4.2222 6.66699 6.2222V7.99997H4.66699V10.2222H6.66699V16C7.55588 16 8.44477 16 9.33366 16V10.2222H11.1114Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
