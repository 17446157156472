<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.692 16.908a1.246 1.246 0 100 2.492 1.246 1.246 0 000-2.492zm-2.446 1.246a2.446 2.446 0 114.893 0 2.446 2.446 0 01-4.893 0zM16.308 16.908a1.246 1.246 0 100 2.492 1.246 1.246 0 000-2.492zm-2.446 1.246a2.446 2.446 0 114.892 0 2.446 2.446 0 01-4.892 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.092 8.923a.6.6 0 01.6-.6h1.723a2.6 2.6 0 012.414 1.634l.585 1.463a2.6 2.6 0 01.186.965v3.307a2.446 2.446 0 01-2.446 2.447v-1.2c.688 0 1.246-.558 1.246-1.247v-3.307c0-.178-.034-.354-.1-.52l-.585-1.462a1.4 1.4 0 00-1.3-.88h-1.723a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 4.6A1.4 1.4 0 004.6 6v7.246h9.092a1.4 1.4 0 001.4-1.4V6a1.4 1.4 0 00-1.4-1.4H6zm-1.4 9.846h9.092a2.6 2.6 0 002.6-2.6V6a2.6 2.6 0 00-2.6-2.6H6A2.6 2.6 0 003.4 6v9.692a2.446 2.446 0 002.446 2.447v-1.2A1.246 1.246 0 014.6 15.692v-1.246zM8.938 17.538a.6.6 0 01.6-.6h4.924a.6.6 0 010 1.2H9.538a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.401 6.631a.6.6 0 01.045.847l-2.699 2.999a.7.7 0 01-1.015.027l-.849-.849a.6.6 0 11.849-.848l.476.475 2.346-2.606a.6.6 0 01.847-.045z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
