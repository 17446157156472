<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.764 7.449a.6.6 0 01.787.315L4 8l.551-.236.004.007a2.683 2.683 0 00.09.188c.067.133.171.328.314.563.287.471.725 1.1 1.33 1.728C7.497 11.503 9.35 12.733 12 12.733c2.65 0 4.503-1.23 5.71-2.483a9.97 9.97 0 001.33-1.728 8.4 8.4 0 00.405-.751l.003-.007a.6.6 0 011.104.472L20 8l.552.236-.002.003-.002.005-.007.016-.024.053a9.54 9.54 0 01-.45.832 11.16 11.16 0 01-1.492 1.938c-.19.198-.396.396-.618.59l1.881 1.755a.6.6 0 01-.819.877l-2.023-1.888a8.723 8.723 0 01-4.396 1.498V16a.6.6 0 11-1.2 0v-2.085a8.722 8.722 0 01-4.396-1.498l-2.023 1.888a.6.6 0 01-.819-.877l1.88-1.755a10.275 10.275 0 01-.617-.59c-.68-.706-1.17-1.41-1.491-1.938a9.552 9.552 0 01-.475-.885l-.007-.016-.002-.005-.001-.001s0-.002.551-.238l-.551.236a.6.6 0 01.315-.787z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
