class LiveVideo {
  constructor() {
    this.activeChannelId = ''
    this.publicId = ''
    this.name = ''
    this.title = ''
    this.description = ''
    this.playbackUrl = ''
    this.thumbnail = ''
    this.channelTitle = ''
    this.channelName = ''
    this.channelPublicId = ''
    this.channelId = ''
    this.channelLogo = ''
    this.type = ''
    this.rewindTypeId = null
    this.dvrEnabled = true
    this.rewindDuration = null
    this.canStopWithoutSaving = true
    this.latencyType = ''
    this.liveStatus = ''
    this.canGoLive = false
    this.isLiveStreaming = false
    this.author = ''
    this.livestreamProviderId = 0
    this.livestreamProvider = ''
    this.publishDate = null
    this.hasCustomDate = false
    this.startDateUTC = null
    this.endDateUTC = null
    this.countdownEnabled = false
    this.countdownSeconds = 0
    this.canCutAndPublish = true
    this.canStopAndCut = true
    this.tags = []
    this.customScreenModels = []
    this.geoBlockingRuleId = null
  }

  fromData(data) {
    this.activeChannelId = data.activeChannelId || ''
    this.publicId = data.publicId
    this.name = data.name || ''
    this.title = data.title || ''
    this.description = data.description || ''
    this.playbackUrl = data.playbackUrl || this.playbackUrl || ''
    this.thumbnail = data.thumbnail || ''
    this.channelTitle = data.channelTitle || ''
    this.channelName = data.channelName || ''
    this.channelPublicId = data.channelPublicId || ''
    this.channelId = data.channelId || ''
    this.channelLogo = data.channelLogo || ''
    this.type = data.type || ''
    this.rewindTypeId = data.rewindTypeId || null
    this.dvrEnabled = data.dvrEnabled
    this.rewindDuration = data.rewindDuration || null
    this.canStopWithoutSaving = data.canStopWithoutSaving
    this.latencyType = data.latencyType || ''
    this.liveStatus = data.liveStatus || ''
    this.canGoLive = data.canGoLive || false
    this.isLiveStreaming = data.isLiveStreaming
    this.livestreamProviderId = data.livestreamProviderId || 0
    this.livestreamProvider = data.livestreamProvider || ''
    this.author = data.author || ''
    this.publishDate = data.publishDate || null
    this.hasCustomDate = data.hasCustomDate
    this.startDateUTC = data.startDateUTC || null
    this.endDateUTC = data.endDateUTC || null
    this.countdownEnabled = data.countdownEnabled || false
    this.countdownSeconds = data.countdownSeconds || 0
    this.canCutAndPublish = data.canCutAndPublish || false
    this.canStopAndCut = data.canStopAndCut || false
    this.tags = data.tags || []
    this.customScreenModels = data.customScreenModels || []
    this.geoBlockingRuleId = data.geoBlockingRuleId || null
  }
}
export default LiveVideo
