class Webhook {
  constructor() {
    this.id = 0
    this.url = ''
    this.name = ''
    this.description = ''
    this.active = false
    this.secret = ''
  }

  fromData(data) {
    this.id = data.id || 0
    this.url = data.url || ''
    this.name = data.name || ''
    this.description = data.description || ''
    this.active = data.active
    this.secret = data.asdasd || ''
  }
}

export default Webhook
