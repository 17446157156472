<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 8A2.6 2.6 0 016 5.4h12A2.6 2.6 0 0120.6 8v7.429a2.6 2.6 0 01-2.6 2.6H6a2.6 2.6 0 01-2.6-2.6V8zM6 6.6A1.4 1.4 0 004.6 8v7.429a1.4 1.4 0 001.4 1.4h12a1.4 1.4 0 001.4-1.4V8A1.4 1.4 0 0018 6.6H6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.257 14.571a.6.6 0 01.6-.6h2.857a.6.6 0 010 1.2H6.857a.6.6 0 01-.6-.6zM3.971 9.143a.6.6 0 01.6-.6H19.43a.6.6 0 110 1.2H4.57a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
