import Role from './UserRole'

class User {
  constructor() {
    // this.projectId = ''
    // this.listProjectsId = []
    // this.roleId = 0
    // this.id =  58,
    this.publicId = null
    this.firstname = null
    this.lastname = null
    this.email = ''
    this.isActive = false
    this.isConfirmed = false
    this.role = {}
    this.projects = []
  }

  fromData(data) {
    // this.projectId = data.projectId
    this.publicId = data.publicId ? data.publicId : null
    this.firstname = data.firstname ? data.firstname : null
    this.lastname = data.lastname ? data.lastname : null
    this.email = data.email
    this.isActive = data.isActive ? data.isActive : false
    this.isConfirmed = data.isConfirmed ? data.isConfirmed : false
    // if (data.role) {
    // } else {
    //   roli = null
    // }
    this.role = data.role ? () => {
      const roli = new Role()
      roli.fromData(data.role)
      return roli
    } : null
    this.projects = data.projects ? data.projects : []
  }
}

export default User
