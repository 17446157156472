import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import adsProgrammabilityModule from './content/ad-schedules/ads-programmability/moduleAdsProgrammability'
import adBreaksModule from './content/ad-schedules/ad-breaks/moduleAdBreaks'
import adSchedulesModule from './content/ad-schedules/moduleAdSchedules'
import analyticsModule from './analytics/moduleAnalytics'
import apiKeyModule from './account/api-keys/moduleApiKey'
import audiosModule from './audios/audio/moduleAudios'
import audioPlayersModule from './players/audio/moduleAudioPlayers'
import authModule from './auth/authModule'
import biddingModule from './content/ad-schedules/bidding/moduleBidding'
import categoriesModule from './content/categories/moduleCategories'
import channelsModule from './live/channels/moduleChannels'
import flagsModule from './properties/flags/moduleFlags'
import foldersModule from './videos/video/folders/moduleFolders'
import geoBlockingModule from './properties/geo-blocking/moduleGeoBlockingRules'
import languagesModule from './content/language/moduleLanguage'
import liveVideosModule from './live/live-videos/moduleLiveVideos'
import notificationsModule from './account/notifications/moduleNotifications'
import organizationModule from './account/organization/moduleOrganization'
import playlistsModule from './videos/playlists/modulePlaylists'
import podcastsModule from './audios/podcasts/modulePodcasts'
import projectModule from './account/project/moduleProject'
import searchModule from './search/moduleSearch'
import uploads from './upload/moduleUpload'
import userModule from './account/user/moduleUser'
import userRolesModule from './account/user-roles/moduleUserRoles'
import videosModule from './videos/video/moduleVideos'
import videoPlayersModule from './players/video/moduleVideoPlayers'
import verticalPlayersModule from './players/vertical/moduleVerticalPlayers'
import webhooksModule from './account/webhooks/moduleWebhooks'
import dashboardModule from './dashboard/moduleDashboard'
import socialDistributionModule from './social-distribution/moduleSocialDistribution'
import billingModule from './billing/moduleBilling'
import restreamModule from './live/restream/ moduleRestreamLive'
import wrapperModule from './content/ad-schedules/wrappers/moduleWrapper'

import language from './language/index'
import sceneModule from './live/scene/moduleScene'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    adsProgrammabilityModule,
    adBreaksModule,
    adSchedulesModule,
    analyticsModule,
    app,
    appConfig,
    apiKeyModule,
    dashboardModule,
    audiosModule,
    audioPlayersModule,
    authModule,
    biddingModule,
    categoriesModule,
    channelsModule,
    flagsModule,
    foldersModule,
    geoBlockingModule,
    languagesModule,
    liveVideosModule,
    notificationsModule,
    organizationModule,
    playlistsModule,
    podcastsModule,
    projectModule,
    searchModule,
    uploads,
    userModule,
    userRolesModule,
    verticalMenu,
    videosModule,
    videoPlayersModule,
    verticalPlayersModule,
    webhooksModule,
    language,
    socialDistributionModule,
    billingModule,
    restreamModule,
    sceneModule,
    wrapperModule,
  },
  strict: process.env.DEV,
})
