<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.424 6.176a.6.6 0 010 .848L4.85 10.6l3.575 3.576a.6.6 0 01-.848.848L3.15 10.6l4.425-4.424a.6.6 0 01.848 0zm6.152 3a.6.6 0 01.848 0L19.85 13.6l-4.425 4.424a.6.6 0 11-.848-.848L18.15 13.6l-3.575-3.576a.6.6 0 010-.848z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
