<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.847 5.686A2.6 2.6 0 0110.428 3.4h7.43a2.6 2.6 0 012.6 2.6v9.714a2.6 2.6 0 01-2.6 2.6h-.848a2.6 2.6 0 01-2.582 2.286H7A2.6 2.6 0 014.4 18V8.286a2.6 2.6 0 012.6-2.6h.847zm1.217 0A1.4 1.4 0 0110.428 4.6h7.43a1.4 1.4 0 011.4 1.4v9.714a1.4 1.4 0 01-1.4 1.4h-.83V8.286a2.6 2.6 0 00-2.6-2.6H9.064zM7.857 9.114a.6.6 0 000 1.2h5.714a.6.6 0 000-1.2H7.857zM7.257 12a.6.6 0 01.6-.6h5.714a.6.6 0 010 1.2H7.857a.6.6 0 01-.6-.6zm.6 1.686a.6.6 0 000 1.2h5.714a.6.6 0 000-1.2H7.857zm-.6 2.885a.6.6 0 01.6-.6h5.714a.6.6 0 010 1.2H7.857a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
