// axios
import axios from 'axios'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

let controller = new AbortController()
const { signal } = controller

const jwtConfig = { ...jwtDefaultConfig }

const axiosUpload = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_BASE_URL,
  signal,
})

function getToken() {
  return localStorage.getItem(jwtConfig.storageTokenKeyName)
}

axiosUpload.interceptors.request.use(
  config => {
    // Get token from localStorage
    const accessToken = getToken()

    // If token is present add it to request's Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${jwtConfig.tokenType} ${accessToken}`
      // config.headers['Access-Control-Allow-Origin'] = '*'
    }

    return config
  },
)

const reInitAbortController = () => {
  const newController = new AbortController()
  const newSignal = newController.signal
  axiosUpload.defaults.signal = newSignal

  return newController
}

export const handleCancelRequest = () => {
  controller.abort('Request was canceled')
  controller = reInitAbortController()
}

export default axiosUpload
