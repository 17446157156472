import BiddingTemplate from '@/models/ad-schedules/bidding/bidding'

export default {
  biddings: [],
  bidding: new BiddingTemplate(),
  isLoading: true,
  isAdd: true,
  options: {
    mediationOptions: undefined,
    bidderOptions: undefined,
  },
}
