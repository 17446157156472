export default {
  // # COUNTRIES
  getTopCountries: state => state.topCountriesGroupedData,

  // # DEVICES
  getTopDevices: state => state.topDevices,
  getTopDevicesGroupedData: state => state.topDevicesGroupedData,

  // # TOP CONTENT
  getTopVideos: state => state.topVideos,
  getTopPodcasts: state => state.topPodcasts,
  getTopAudios: state => state.topAudios,
  getTopLiveVideos: state => state.topLiveVideos,
  getVideos: state => state.videos,
  // # VIDEO LIVE CONSUMPTION
  getVideoLiveConsumption: state => state.videoLiveConsumptionGroupedData,
  getVideoLiveConsumptionTotal: state => state.videoLiveConsumptionTotal,

  // # AUDIO LIVE CONSUMPTION
  getAudioLiveConsumption: state => state.audioLiveConsumptionGroupedData,
  getAudioLiveConsumptionTotal: state => state.audioLiveConsumptionTotal,

}
