<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.473 4.5c-.45 0-.89.14-1.218.464l-.003.003c-.309.311-.449.719-.464 1.133-.08.078-.194.18-.335.304-.316.276-.746.63-1.188.978-.443.35-.89.688-1.244.936a7.306 7.306 0 01-.42.277c-.871-.216-1.736-.145-2.327.449-.712.712-.655 1.802-.31 2.775.176.5.444 1.022.79 1.544a.823.823 0 00-.104.131l-3.1 4.66-.002.005a.847.847 0 00.11 1.062l.003.003a.844.844 0 001.066.098l4.66-3.116c.031-.02.06-.043.088-.067.267.187.536.354.803.499.74.402 1.506.657 2.206.657.556 0 1.09-.17 1.478-.56.51-.51.636-1.228.52-1.97v-.031l.01-.016c.063-.11.16-.263.283-.45.245-.372.58-.852.924-1.328.345-.476.695-.94.97-1.284.123-.152.226-.274.304-.36.428-.016.829-.159 1.14-.469.603-.602.553-1.505.243-2.313-.293-.769-.845-1.562-1.536-2.254a7.333 7.333 0 00-1.657-1.262c-.555-.3-1.142-.498-1.69-.498z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
