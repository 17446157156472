<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.91 7.09a1.09 1.09 0 102.18 0 1.09 1.09 0 00-2.18 0zM10.91 16.909a1.09 1.09 0 102.18 0 1.09 1.09 0 00-2.18 0zM10.91 12a1.09 1.09 0 102.18 0 1.09 1.09 0 00-2.18 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
