import SocialDistributionCreate from '@/models/social-distribution/social-distribution-create'

export default {
  socialDistributions: [],
  socialDistribution: {},
  socialDistributionCreate: new SocialDistributionCreate(),
  authorizeUrl: '',
  categories: [],
  privacyStatuses: [],
  facebookTags: [],
  socialMedias: [],
  selectList: [],
  mediaHubs: [],
  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },
  filters: {
    params: {
      insertedDate: null,
      key: null,
      value: null,
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },
}
