export default {
  getNotifications: state => state.notifications,
  getNotification: state => state.notification,
  getTitle: state => state.notification.title,
  getUsageVolumes: state => state.usageVolumes,
  getDateOptions: state => state.dateOptions,
  getNotificationType: state => state.notificationTypes,
  getNotificationThreshold: state => state.notification?.usageVolumeTypes[0]?.threshold,
  getSelectedNotificationType: state => state.notification.userNotificationTypeId,
  getPagination: state => state.pagination,
  getPageLength: state => state.filters.params.take,
  getCurrentPage: state => state.filters.params.page,
}
