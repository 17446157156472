/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {
  getAdBreaks({ commit, state }, adScheduleId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/ad-schedules/${adScheduleId}/ad-breaks`)
        .then(response => {
          commit('SET_AD_BREAKS', { adScheduleId, data: response.data.result })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAdBreak({ commit }, {
    adBreakId, adScheduleId, adBreakTypeId, index,
  }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/ad-schedules/${adScheduleId}/ad-breaks/${adBreakId}`)
        .then(response => {
          commit('SET_EXISTING_AD_BREAK', {
            data: response.data.result,
            adBreakType: adBreakTypeId,
            adBreakIndex: index,
          })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  addAdBreak({ commit }, {
    data, adScheduleId, adBreakType, adBreakIndex,
  }) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/projects/${project.state.activeProjectId}/ad-schedules/${adScheduleId}/ad-breaks`, data)
        .then(response => {
          commit('SET_CREATED_AD_BREAK', { data: response.data.result, adBreakType, adBreakIndex })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateAdBreak({ commit }, { data, adScheduleId }) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/projects/${project.state.activeProjectId}/ad-schedules/${adScheduleId}/ad-breaks`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  deleteAdBreak({ commit }, {
    adBreakId, adBreakTypeId, adBreakTypeName, adScheduleId, adScheduleTypeId, index,
  }) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${project.state.activeProjectId}/ad-schedules/${adScheduleId}/ad-breaks/${adBreakId}`)
        .then(response => {
          if (adScheduleTypeId === 1) {
            commit('REMOVE_AD_BREAK', {
              adBreakTypeId, adBreakIndex: index, adBreakTypeName, adScheduleId,
            })
          } else {
            commit('CLEAR_AD_BREAK_ON_INDEX', {
              adBreakType: adBreakTypeId, adBreakIndex: index, adScheduleId, adBreakTypeName,
            })
          }
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

}
