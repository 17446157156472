<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 6.802A2.802 2.802 0 016.802 4h10.777a2.802 2.802 0 012.802 2.802v6.466a2.802 2.802 0 01-2.802 2.802h-4.496c-.4 0-.784.159-1.067.442l-2.233 2.234c-.544.543-1.472.158-1.472-.61v-1.635a.431.431 0 00-.431-.431H6.802A2.802 2.802 0 014 13.268V6.802zm2.802-1.509c-.833 0-1.509.676-1.509 1.509v6.466c0 .833.676 1.509 1.509 1.509H7.88c.952 0 1.724.772 1.724 1.724v.594l1.498-1.497a2.802 2.802 0 011.981-.821h4.496c.833 0 1.509-.676 1.509-1.509V6.802c0-.833-.676-1.509-1.51-1.509H6.803z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.87 11a1 1 0 100-2 1 1 0 000 2zm3.333 0a1 1 0 100-2 1 1 0 000 2zm4.334-1a1 1 0 11-2 0 1 1 0 012 0z"
    />
  </svg>
</template>
