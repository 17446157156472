<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.218 3.746l-4.96 4.797h3.56a1.4 1.4 0 001.4-1.4V3.746z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.9 9.743V18a2.6 2.6 0 002.6 2.6h9a2.6 2.6 0 002.6-2.6V6a2.6 2.6 0 00-2.6-2.6h-5.082v3.743a2.6 2.6 0 01-2.6 2.6H4.9zm4.737 2.228a.6.6 0 000 1.2h4.727a.6.6 0 100-1.2H9.637zm-.6 3.315a.6.6 0 01.6-.6h4.727a.6.6 0 010 1.2H9.637a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
