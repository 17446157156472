<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.304 3.4a.6.6 0 01.6.6v16a.6.6 0 11-1.2 0V4a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.957 4.266a.6.6 0 01.832-.166l1.514 1.009L13.816 4.1a.6.6 0 11.665.998l-2.178 1.453-2.18-1.453a.6.6 0 01-.166-.832zM12.303 17.417l2.178 1.452a.6.6 0 01-.665.999l-1.514-1.01-1.513 1.01a.6.6 0 11-.665-.999l2.178-1.452zM19.752 7.7a.6.6 0 01-.22.82l-13.856 8a.6.6 0 01-.6-1.04l13.856-8a.6.6 0 01.82.22z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.83 6.102a.6.6 0 01.56.637l-.117 1.815 1.63.807a.6.6 0 11-.53 1.075l-2.349-1.16.17-2.614a.6.6 0 01.637-.56zM7.613 14.708l-.169 2.614a.6.6 0 11-1.197-.078l.117-1.815-1.63-.806a.6.6 0 01.531-1.075l2.348 1.16zM19.75 16.3a.6.6 0 01-.819.22l-13.856-8a.6.6 0 01.6-1.04l13.856 8a.6.6 0 01.22.82z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.175 13.836a.6.6 0 01-.272.803l-1.63.807.116 1.815a.6.6 0 11-1.197.077l-.169-2.614 2.348-1.16a.6.6 0 01.804.272zM7.614 9.292l-2.348 1.16a.6.6 0 01-.532-1.075l1.63-.806-.117-1.815a.6.6 0 011.198-.078l.169 2.614z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
