<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.29 6.89a3.6 3.6 0 013.6-3.6h10a3.6 3.6 0 013.6 3.6v1a.6.6 0 11-1.2 0v-1a2.4 2.4 0 00-2.4-2.4h-10a2.4 2.4 0 00-2.4 2.4v10a2.4 2.4 0 002.4 2.4h1a.6.6 0 110 1.2h-1a3.6 3.6 0 01-3.6-3.6v-10z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.367 7.967a1.6 1.6 0 011.6-1.6h1.077a1.6 1.6 0 011.6 1.6v1.077a1.6 1.6 0 01-1.6 1.6H7.967a1.6 1.6 0 01-1.6-1.6V7.967zm1.6-.4a.4.4 0 00-.4.4v1.077c0 .22.179.4.4.4h1.077a.4.4 0 00.4-.4V7.967a.4.4 0 00-.4-.4H7.967z"
    />
    <path
      d="M12.505 6.367a.6.6 0 100 1.2h4.308a.6.6 0 100-1.2h-4.308zM11.905 10.044a.6.6 0 01.6-.6h2.31a.6.6 0 110 1.2h-2.31a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.967 12.52a1.6 1.6 0 00-1.6 1.6v1.078a1.6 1.6 0 001.6 1.6h1.077a1.6 1.6 0 001.6-1.6V14.12a1.6 1.6 0 00-1.6-1.6H7.967zm-.4 1.6a.4.4 0 01.4-.4h1.077a.4.4 0 01.4.4v1.078a.4.4 0 01-.4.4H7.967a.4.4 0 01-.4-.4V14.12z"
    />
    <path
      d="M19.132 12.648a1.286 1.286 0 00-1.818 0l-1.666 1.666a.6.6 0 01-.849-.848l1.667-1.667a2.486 2.486 0 113.515 3.515l-1.667 1.667a.6.6 0 01-.848-.849l1.666-1.666a1.286 1.286 0 000-1.818z"
    />
    <path
      d="M17.648 14.132a.6.6 0 010 .849l-2.667 2.667a.6.6 0 01-.849-.849l2.667-2.667a.6.6 0 01.849 0z"
    />
    <path
      d="M14.466 19.132a1.286 1.286 0 11-1.818-1.818l1.666-1.666a.6.6 0 10-.848-.849l-1.667 1.667a2.486 2.486 0 103.515 3.515l1.667-1.667a.6.6 0 00-.849-.848l-1.666 1.666z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
