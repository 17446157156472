import state from './moduleAudiosState'
import mutations from './moduleAudiosMutations'
import actions from './moduleAudiosActions'
import getters from './moduleAudiosGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
