export default {
  SET_SCENES: (state, data) => { state.scenes = data || [] },
  SET_SCENE: (state, data) => { state.scene = data },
  SET_FETCHING_SCENES: (state, data) => { state.fetchingScenes = data },
  SET_GETTING_SCENE: (state, data) => { state.gettingScene = data },
  SET_ADDING_SCENE: (state, data) => { state.addingScene = data },
  ADD_SCENE: (state, data) => { state.scenes.unshift(data) },
  SET_UPDATING_SCENE: (state, data) => { state.updatingScene = data },
  SET_REPLACING_SCENE: (state, data) => { state.replacingScene = data },
  DELETE_SCENE: (state, id) => { state.scenes = state.scenes.filter(e => e.channelId !== id) },
  SET_DELETING_SCENE: (state, data) => { state.deletingScene = data },
  UPDATE_SCREEN: (state, data) => {
    const index = state.scenes.findIndex(e => e.type === data.type)
    if (index > -1) state.scenes[index].thumbnailUrl = data.sourcePath
  },
}
