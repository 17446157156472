<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 11.704a8.304 8.304 0 0116.607 0v3.259a2.081 2.081 0 11-4.163 0v-.415a3.445 3.445 0 012.941-3.408 7.104 7.104 0 00-14.163 0 3.445 3.445 0 012.941 3.408v.415a2.081 2.081 0 11-4.163 0v-3.26zm1.2.651v2.608a.882.882 0 001.763 0v-.415A2.245 2.245 0 004.6 12.355zm14.207 0a2.245 2.245 0 00-1.762 2.193v.415a.881.881 0 101.762 0v-2.607z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.407 14.363a.6.6 0 01.6.6v.852a3.6 3.6 0 01-3.6 3.6H12.89a.6.6 0 010-1.2h3.518a2.4 2.4 0 002.4-2.4v-.852a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.704 18.23a.585.585 0 100 1.17.585.585 0 000-1.17zm-1.785.585a1.785 1.785 0 113.57 0 1.785 1.785 0 01-3.57 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
