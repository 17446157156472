<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.714 3.4a2.6 2.6 0 00-2.6 2.6v12a2.6 2.6 0 002.6 2.6h8.572a2.6 2.6 0 002.6-2.6V6a2.6 2.6 0 00-2.6-2.6H7.714zm9.972 10.886V6a1.4 1.4 0 00-1.4-1.4H7.714a1.4 1.4 0 00-1.4 1.4v8.572a1.4 1.4 0 001.4 1.4h8.572a1.4 1.4 0 001.4-1.4v-.286zm-.11 2.543c-.38.218-.82.343-1.29.343H7.714c-.47 0-.91-.125-1.29-.343a1.4 1.4 0 001.29.857h8.572a1.4 1.4 0 001.29-.857zM7.714 19.4a1.4 1.4 0 01-1.29-.857c.38.218.82.343 1.29.343h8.572c.47 0 .91-.125 1.29-.343a1.4 1.4 0 01-1.29.857H7.714z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.892 8.286a.857.857 0 11-1.714 0 .857.857 0 011.714 0zM12.26 9.904a.5.5 0 00-.796.026L9.74 12.353a.5.5 0 00.407.79h3.694a.5.5 0 00.388-.816l-1.97-2.423z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
