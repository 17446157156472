<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.74995 13.5L6.74995 3.5C6.74995 3.08579 6.41416 2.75 5.99995 2.75C5.58574 2.75 5.24995 3.08579 5.24995 3.5V13.5H3.20706C2.7616 13.5 2.53852 14.0386 2.8535 14.3536L5.6464 17.1464C5.84166 17.3417 6.15824 17.3417 6.3535 17.1464L9.1464 14.3536C9.46138 14.0386 9.2383 13.5 8.79284 13.5H6.74995Z"
      fill="currentColor"
    />
    <path
      d="M9.99995 6.25C9.58574 6.25 9.24995 5.91421 9.24995 5.5C9.24995 5.08579 9.58574 4.75 9.99995 4.75L17 4.75C17.4142 4.75 17.75 5.08579 17.75 5.5C17.75 5.91421 17.4142 6.25 17 6.25L9.99995 6.25Z"
      fill="currentColor"
    />
    <path
      d="M9.24995 9.5C9.24995 9.91421 9.58574 10.25 9.99995 10.25L17 10.25C17.4142 10.25 17.75 9.91421 17.75 9.5C17.75 9.08579 17.4142 8.75 17 8.75L9.99995 8.75C9.58574 8.75 9.24995 9.08579 9.24995 9.5Z"
      fill="currentColor"
    />
    <path
      d="M12.25 13.5C12.25 13.9142 12.5857 14.25 13 14.25H17C17.4142 14.25 17.75 13.9142 17.75 13.5C17.75 13.0858 17.4142 12.75 17 12.75H13C12.5857 12.75 12.25 13.0858 12.25 13.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
