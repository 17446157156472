<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.077 7.677a.4.4 0 00-.4.4v1.077c0 .22.179.4.4.4h1.077a.4.4 0 00.4-.4V8.077a.4.4 0 00-.4-.4H8.077zM7.677 14.23c0-.22.179-.4.4-.4h1.077c.22 0 .4.18.4.4v1.078a.4.4 0 01-.4.4H8.077a.4.4 0 01-.4-.4V14.23z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 6A2.6 2.6 0 016 3.4h12A2.6 2.6 0 0120.6 6v12a2.6 2.6 0 01-2.6 2.6H6A2.6 2.6 0 013.4 18V6zm3.077 2.077a1.6 1.6 0 011.6-1.6h1.077a1.6 1.6 0 011.6 1.6v1.077a1.6 1.6 0 01-1.6 1.6H8.077a1.6 1.6 0 01-1.6-1.6V8.077zm6.138-1.6a.6.6 0 100 1.2h4.308a.6.6 0 000-1.2h-4.308zm-.6 3.677a.6.6 0 01.6-.6h4.308a.6.6 0 010 1.2h-4.308a.6.6 0 01-.6-.6zM8.077 12.63a1.6 1.6 0 00-1.6 1.6v1.077a1.6 1.6 0 001.6 1.6h1.077a1.6 1.6 0 001.6-1.6V14.23a1.6 1.6 0 00-1.6-1.6H8.077zm3.938.6a.6.6 0 01.6-.6h4.308a.6.6 0 010 1.2h-4.308a.6.6 0 01-.6-.6zm.6 2.477a.6.6 0 100 1.2h4.308a.6.6 0 000-1.2h-4.308z"
    />
  </svg>
</template>
