<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.4 6A2.6 2.6 0 019 3.4h6A2.6 2.6 0 0117.6 6v11.71c0 1.396-1.664 2.122-2.688 1.173l-2.64-2.448a.4.4 0 00-.544 0l-2.64 2.448c-1.024.95-2.688.223-2.688-1.174V6z"
    />
  </svg>
</template>
