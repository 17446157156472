<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 5a4 4 0 000 8h2.985v5.479a.522.522 0 001.044 0V6.045h2.434v12.434a.522.522 0 001.043 0V6.045h1.19a.522.522 0 100-1.044H13V5H9zm.01 1.043H9a2.957 2.957 0 000 5.913h2.957V6.046h-2.91a.53.53 0 01-.037-.002z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
