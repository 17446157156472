<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.636 4.106c1.219-.444 2.863-.706 4.65-.706 1.787 0 3.43.262 4.65.706.606.22 1.14.497 1.533.835.39.336.703.788.703 1.345 0 .556-.312 1.008-.703 1.344-.393.338-.927.615-1.534.836-1.218.443-2.862.705-4.65.705-1.786 0-3.43-.262-4.649-.705-.607-.22-1.14-.498-1.533-.836-.391-.336-.703-.788-.703-1.344 0-.557.312-1.009.703-1.345.393-.338.926-.615 1.533-.835zm-.75 1.745c-.246.21-.286.36-.286.435 0 .075.04.224.285.434.243.21.63.425 1.161.618 1.056.384 2.555.633 4.24.633 1.684 0 3.183-.249 4.24-.633.53-.193.917-.408 1.16-.618.246-.21.285-.36.285-.434 0-.075-.04-.224-.285-.435-.243-.21-.63-.425-1.16-.618-1.057-.384-2.556-.633-4.24-.633-1.685 0-3.184.25-4.24.633-.53.193-.918.409-1.16.618zM6 11.686a.6.6 0 01.6.6v5.428c0 .075.04.224.285.435.243.21.63.425 1.161.618 1.056.384 2.555.633 4.24.633 1.684 0 3.183-.25 4.24-.633.53-.193.917-.409 1.16-.618.246-.21.285-.36.285-.435v-5.428a.6.6 0 011.2 0v5.428c0 .557-.311 1.009-.702 1.345-.393.338-.927.615-1.534.835-1.218.444-2.862.706-4.65.706-1.786 0-3.43-.262-4.649-.706-.607-.22-1.14-.497-1.533-.835-.391-.336-.703-.788-.703-1.345v-5.428a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 5.686a.6.6 0 01.6.6V12c0 .075.04.224.285.435.243.209.63.425 1.161.617 1.056.384 2.555.634 4.24.634 1.684 0 3.183-.25 4.24-.634.53-.192.917-.408 1.16-.617.246-.211.285-.36.285-.435V6.286a.6.6 0 111.2 0V12c0 .556-.311 1.009-.702 1.345-.393.337-.927.614-1.534.835-1.218.443-2.862.706-4.65.706-1.786 0-3.43-.263-4.649-.706-.607-.22-1.14-.498-1.533-.835-.391-.336-.703-.789-.703-1.345V6.286a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.857 10.857a.571.571 0 11-1.143 0 .571.571 0 011.143 0zm0 5.715a.571.571 0 11-1.143 0 .571.571 0 011.143 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
