<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.303 3.5a.6.6 0 000 1.2h3.368a.6.6 0 000-1.2h-3.368zM9.78 13.082a2.207 2.207 0 114.414 0 2.207 2.207 0 01-4.414 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.987 5.746a7.337 7.337 0 105.595 2.59l1.005-1.005a.6.6 0 00-.849-.848l-1.005 1.005a7.308 7.308 0 00-4.746-1.742zm0 3.93a3.407 3.407 0 100 6.813 3.407 3.407 0 000-6.814z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
