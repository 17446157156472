<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 4.52A2.51 2.51 0 015.5 2h13A2.51 2.51 0 0121 4.52v8.079a7.571 7.571 0 01-3.715 6.526l-4.528 2.668a1.49 1.49 0 01-1.514 0l-4.528-2.668A7.572 7.572 0 013 12.598V4.52zm2.5-1.512c-.828 0-1.5.677-1.5 1.512v8.079a6.562 6.562 0 003.22 5.656l4.528 2.668a.496.496 0 00.504 0l4.528-2.668A6.562 6.562 0 0020 12.599v-8.08c0-.834-.672-1.511-1.5-1.511h-13z"
    />
    <path d="M12.5 11.071a.5.5 0 00-1 0v1.715a.5.5 0 001 0V11.07z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.59 8.5v.414a1.5 1.5 0 00-1.09 1.443V13.5A1.5 1.5 0 0010 15h4a1.5 1.5 0 001.5-1.5v-3.143a1.5 1.5 0 00-1.091-1.443V8.5c0-1.359-1.057-2.5-2.41-2.5-1.351 0-2.408 1.141-2.408 2.5zM12 7c-.757 0-1.41.65-1.41 1.5v.357h2.819V8.5c0-.85-.652-1.5-1.41-1.5zm-2 2.857a.5.5 0 00-.5.5V13.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-3.143a.5.5 0 00-.5-.5h-4z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
