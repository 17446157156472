<template>
  <div class="bg-white d-flex flex-wrap table-footer p-25 justify-content-between gap-12 mb-1 rounded">
    <div class="d-flex align-items-center">
      <span class="flex-wrap text-nowrap px-25 d-flex align-items-center text-xsm text-regular">
        {{ $t('message.pagination.viewingPage') }}
        <b-input
          v-model="currentPageInput"
          class="col-1 mx-50 px-50 text-sm text-center"
          type="number"
          number
          style="min-width: 30px; height: 30px;"
          :disabled="pagination.totalPages <= 1"
        />
        {{ $t('message.pagination.with') }}
        {{ pagination.totalPages > pagination.currentPage ? pagination.pageSize : pagination.totalCount - ((pagination.currentPage - 1) * pagination.pageSize) }}
        {{ $t('message.pagination.itemsOfTotal') }}
        {{ numberWithCommas(pagination.totalCount) }}
      </span>
    </div>
    <div class="d-flex align-items-center">
      <b-pagination
        v-if="pagination.totalPages > 1"
        v-model="currentPage"
        :total-rows="pagination.totalCount"
        :per-page="pageLength"
        first-number
        last-number
        class="mb-0 py-0 text-xsm"
        number
      >
        <template #prev-text>
          <GjIcon
            name="ArrowLeft"
            size="18"
          />
        </template>
        <template #next-text>
          <GjIcon
            name="ArrowRight"
            size="18"
          />
        </template>
        <template #page="{page}">
          <span class="text-xsm">{{ numberWithCommas(page) }}</span>
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import converters from '@/services/mixins/converters'

export default {
  mixins: [converters],
  props: {
    tableLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    paginationGetter: {
      type: String,
      required: true,
    },
    currentPageGetter: {
      type: String,
      required: true,
    },
    pageLengthGetter: {
      type: String,
      required: true,
    },
    filterMuttation: {
      type: String,
      required: true,
    },
    dispatchEvent: {
      type: String,
      required: true,
    },
    entityType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentPageInput: this.$store.getters[this.currentPageGetter],
      fn: null,
    }
  },
  computed: {
    tl: {
      get() { return this.tableLoading },
      set(v) { this.$emit('updateTableLoading', v) },
    },
    pagination() {
      return this.$store.getters[this.paginationGetter]
    },
    currentPage: {
      get() {
        return this.$store.getters[this.currentPageGetter]
      },
      async set(value) {
        if (value !== this.currentPageInput) {
          this.setCurrentPage(value)
          this.$emit('pageChanged')
        }
      },
    },
    pageLength: {
      get() {
        return this.$store.getters[this.pageLengthGetter]
      },
      set(value) {
        this.$store.commit(this.filterMuttation, { propKey: 'take', value })
        this.$store.dispatch(this.dispatchEvent)
      },
    },
  },
  watch: {
    currentPageInput: {
      handler(value) {
        if (value !== this.currentPage) this.setCurrentPage(value)
      },
    },
  },
  mounted() {
    this.fn = async () => {
      if (this.currentPage > 1) this.currentPage -= 1
      else await this.$store.dispatch(this.dispatchEvent)
    }
    this.$root.$on('elementDeleted', this.fn)
  },
  beforeDestroy() {
    this.$root.$off('elementDeleted', this.fn)
  },
  methods: {
    async setCurrentPage(value) {
      value = parseInt(value, 10)
      if (Number.isNaN(value)) {
        this.currentPageInput = 1
        this.currentPage = 1
      } else {
        if (value > this.pagination.totalPages) value = this.pagination.totalPages
        if (value < 1) value = 1
        this.$store.commit(this.filterMuttation, { propKey: 'page', value })
        this.tl = true
        this.currentPageInput = value
        this.currentPage = value
        const a = this.dispatchEvent.split(',')
        if (a.length > 1) await this.$store.dispatch(a[0], this.entityType ? { data: a[1], type: this.entityType } : a[1])
        else await this.$store.dispatch(this.dispatchEvent)
        this.tl = false
      }
    },
  },
}
</script>
