export default {
  restreams: [],
  currentRestream: {
    liveVideoId: '',
    title: '',
    streamUrl: '',
    streamKey: '',
    status: true,
  },
  fetchingRestreams: false,
  fetchingRestream: false,
  creatingRestream: false,
  updatingRestream: false,
  deletingRestream: false,
}
