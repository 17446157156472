import helpers from '@/services/mixins/dataAggregation'

export default {
  getIsLoading: state => state.isLoading,
  getIsUploading: state => state.isUploading,
  getVideo: state => state.video,
  getVideos: state => state.videos,
  getPublicId: state => state.publicId,
  getName: state => state.video.name,
  getTitle: state => state.video.title,
  getDescription: state => state.video.description,
  getThumbnailUrl: state => state.video.thumbnailUrl,
  getPublishDate: state => state.video.publishDate,
  getPublishEndDate: state => state.video.publishEndDate,
  getDuration: state => state.video.duration,
  getEntityId: state => state.video.entityId,
  getVideoPagePermalink: state => state.video.videoPagePermalink,
  getInsertDate: state => state.video.insertDate,
  getUserName: state => state.video.userName,
  getTags: state => state.video.tagNames,
  getCustomParameters: state => state.video.customParameters,
  getCustomOverlays: state => state.video.customOverlays,
  getChapters: state => state.video.chapters,
  getCategory: state => state.video.categoryId,
  getFlags: state => state.video.flagIds,
  getGeoblocking: state => state.video.geoBlockingRuleId,
  getRestriction: state => state.video.restriction,
  getSkipIntroState: state => state.video.skipIntroState,
  getCustomOverlayState: state => state.video.customOverlayState,
  getChapterState: state => state.video.chapterState,
  getSkipIntroFrom: state => state.video.skipIntroFrom,
  getSkipIntroTo: state => state.video.skipIntroTo,
  getAdSchedule: state => state.video.publicAdScheduleId,
  getAuthor: state => state.video.author,
  getSource: state => state.video.source,
  getPasswordProtectedTitle: state => state.video.passwordProtectedTitle,
  getPasswordProtectedDescription: state => state.video.passwordProtectedDescription,
  getPassword: state => state.video.password,

  getThumbnailSecond: state => state.thumbnailSecond,
  getCustomThumbnail: state => state.customThumbnail,
  getStreams: state => state.streams,
  getStreamTypes: state => state.streams.map(s => s.streamType),
  getStreamsUrl: state => (state.streams.length > 0 ? state.streams[0].root : ''),
  getSubtitles: state => state.subtitles,

  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,
  getPlaylists: state => state.playlists,

  // Video Analytics
  getTopCountries: state => state.topCountries,
  getTopDevices: state => state.topDevices,
  getTopDevicesGroupedData: state => state.topDevicesGroupedData,
  getTopBrowsers: state => state.topBrowsers,
  getTopBrowsersGroupedData: state => state.topBrowsersGroupedData,
  getTopCountriesGroupedData: state => state.topCountriesGroupedData,
  getVideoAnalytics: state => state.videoAnalytics,
  getCustomDate: state => state.customDate,
  videoAnalyticsGropuedData: state => {
    const currentYear = new Date().getFullYear()
    let intervalDays = null
    if (state.intervalType === 5) {
      intervalDays = Math.floor(state.diffInTimeCustomDate)
    } else {
      intervalDays = helpers.methods.getIntervalDays(state.intervalType, currentYear)
    }
    return helpers.methods.aggregatedData(state.videoAnalyticsGropuedData, intervalDays, state.videoAnalytics, state.intervalType)
  },
  getVideoAnalyticsTotal: state => state.videoAnalyticsTotal,

  // Live Viewrs
  getVideoAnalyticsLiveViewrs: state => state.liveViewersGroupedData,
  getLiveViewrsTotal: state => state.liveViewersTotal,
  getVideoAnalyticsKeyMoments: state => state.keyMomentsGroupedData,
  getVideoAnalyticsKeyMomentsCards: state => state.keyMomentsCards,

}
