<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.25 7.348a2.6 2.6 0 012.6-2.6h2.034a2.6 2.6 0 012.6 2.6v2.034a2.6 2.6 0 01-2.6 2.6H5.85a2.6 2.6 0 01-2.6-2.6V7.348zM13.745 5.088a2.6 2.6 0 013.677 0l1.438 1.439a2.6 2.6 0 010 3.677l-1.438 1.438a2.6 2.6 0 01-3.677 0l-1.439-1.438a2.6 2.6 0 010-3.677l1.439-1.439zM11.966 16.064a2.6 2.6 0 012.6-2.6H16.6a2.6 2.6 0 012.6 2.6V18.1a2.6 2.6 0 01-2.6 2.6h-2.034a2.6 2.6 0 01-2.6-2.6v-2.035zM3.25 16.064a2.6 2.6 0 012.6-2.6h2.034a2.6 2.6 0 012.6 2.6V18.1a2.6 2.6 0 01-2.6 2.6H5.85a2.6 2.6 0 01-2.6-2.6v-2.035z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
