import CustomReportFilter from './CustomReportFilter'

class CustomReport {
  constructor() {
    this.id = null
    this.name = ''
    this.reportDimensionKeys = []
    this.reportMetricKeys = []
    this.reportFilters = []
    this.customReportDateRangeId = 1
    this.dateFrom = null
    this.dateTo = null
    this.reportEntityTypeId = 1
  }

  fromData(data) {
    this.id = data.id || null
    this.name = data.name || ''
    const rdk = data.reportDimensions.length > 0 ? data.reportDimensions.map(x => x.key) : []
    this.reportDimensionKeys = rdk
    const rmk = data.reportMetrics.length > 0 ? data.reportMetrics.map(x => x.key) : []
    this.reportMetricKeys = rmk
    const rf = data.reportFilters.length > 0 ? data.reportFilters.map(x => {
      const crf = new CustomReportFilter()
      crf.fromData(x)
      return crf
    }) : []
    this.reportFilters = rf
    this.customReportDateRangeId = data.customReportDateRangeId || 1
    this.dateFrom = data.dateFrom || null
    this.dateTo = data.dateTo || null
    this.reportEntityTypeId = data.reportEntityTypeId || 1
  }
}

export default CustomReport
