<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 19.0384H7.50002C2.97502 19.0384 1.04169 17.105 1.04169 12.58V7.58004C1.04169 3.05504 2.97502 1.1217 7.50002 1.1217H12.5C17.025 1.1217 18.9584 3.05504 18.9584 7.58004V12.58C18.9584 17.105 17.025 19.0384 12.5 19.0384ZM7.50002 2.3717C3.65835 2.3717 2.29169 3.73837 2.29169 7.58004V12.58C2.29169 16.4217 3.65835 17.7884 7.50002 17.7884H12.5C16.3417 17.7884 17.7084 16.4217 17.7084 12.58V7.58004C17.7084 3.73837 16.3417 2.3717 12.5 2.3717H7.50002Z"
      fill="currentColor"
    />
    <path
      d="M12.9167 16.1217C11.65 16.1217 10.625 15.0967 10.625 13.83V6.33C10.625 5.06333 11.65 4.03833 12.9167 4.03833C14.1833 4.03833 15.2083 5.06333 15.2083 6.33V13.83C15.2083 15.0967 14.1833 16.1217 12.9167 16.1217ZM12.9167 5.28833C12.3417 5.28833 11.875 5.755 11.875 6.33V13.83C11.875 14.405 12.3417 14.8717 12.9167 14.8717C13.4917 14.8717 13.9583 14.405 13.9583 13.83V6.33C13.9583 5.755 13.4917 5.28833 12.9167 5.28833Z"
      fill="currentColor"
    />
    <path
      d="M7.08335 16.1217C5.81669 16.1217 4.79169 15.0967 4.79169 13.83V10.9134C4.79169 9.6467 5.81669 8.6217 7.08335 8.6217C8.35002 8.6217 9.37502 9.6467 9.37502 10.9134V13.83C9.37502 15.0967 8.35002 16.1217 7.08335 16.1217ZM7.08335 9.8717C6.50835 9.8717 6.04169 10.3384 6.04169 10.9134V13.83C6.04169 14.405 6.50835 14.8717 7.08335 14.8717C7.65835 14.8717 8.12502 14.405 8.12502 13.83V10.9134C8.12502 10.3384 7.65835 9.8717 7.08335 9.8717Z"
      fill="currentColor"
    />
  </svg>

</template>
