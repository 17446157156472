import Channel from '@/models/channels/channel'

export default {
  isLoading: true,
  channel: new Channel(),
  channels: [],
  defaultChannels: [],
  liveChannels: [],
  channelSelectList: [],
  livestreamProviders: [],
  inputTypes: [],

  options: {
    types: [],
    latencyTypes: [],
  },

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },
  filters: {
    params: {
      // page: 1,
      // take: 50,
      search: '',
      order: 'desc',
    },
  },
  liveFilters: {
    params: {
      page: 1,
      take: 99,
      search: '',
      order: 'desc',
      liveStatusId: 1,
    },
  },
}
