import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    loadingLanguages: false,
    languages: [],
  },
  mutations: {
    LOADING_LANGUAGES(state, val) {
      state.loadingLanguages = val
    },
    SET_LANGUAGES(state, data) {
      state.languages = data
    },
  },
  actions: {
    getLanguages({ commit }) {
      commit('LOADING_LANGUAGES', true)
      return new Promise((resolve, reject) => {
        axios.get('/api/languages/select-list')
          .then(async response => {
            commit('SET_LANGUAGES', response?.data?.result)
            resolve(response)
          })
          .catch(error => {
            commit('SET_LANGUAGES', [])
            reject(error)
          })
          .finally(() => {
            commit('LOADING_LANGUAGES', false)
          })
      })
    },
  },
}
