<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.4c-4.185 0-7.6 3.328-7.6 7.457C4.4 13.93 5.968 16.36 7.643 18c.839.822 1.718 1.46 2.47 1.895a7.6 7.6 0 001.035.506c.288.112.592.199.852.199s.563-.087.852-.199a7.6 7.6 0 001.034-.506A12.675 12.675 0 0016.357 18c1.674-1.64 3.243-4.07 3.243-7.143 0-4.13-3.415-7.457-7.6-7.457zm0 4.571c-1.608 0-2.933 1.281-2.933 2.886 0 1.605 1.325 2.886 2.933 2.886 1.608 0 2.933-1.281 2.933-2.886 0-1.605-1.325-2.886-2.933-2.886z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
