import store from '@/store'

export default function fetchUserData(organizationId, projectId) {
  return new Promise((resolve, reject) => {
    store.commit('userModule/UPDATE_ISLOADING', true)
    store.dispatch('userModule/getUserData', { projectId, organizationId })
      .then(response => {
        store.commit('userModule/UPDATE_ISLOADING', false)
        const responseUserRole = response.data.result.user.role !== null ? response.data.result.user.role : null
        const responseUserRoleId = response.data.result.user.role !== null ? response.data.result.user.role.id : null
        store.commit('userRolesModule/SET_ACTIVE_ROLE_ID', responseUserRoleId)
        // Get the user from localStorage and parse into object
        const userData = JSON.parse(localStorage.getItem('userData'))
        userData.role = responseUserRole
        localStorage.setItem('userData', JSON.stringify(userData))
        resolve(response)
      }).catch(err => { store.commit('userModule/UPDATE_ISLOADING', false); reject(err) })
  })
}
