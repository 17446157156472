/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {

  // ////////// //
  /* WebhookEvent R */
  getWebhookEvents({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/webhook-events')
        .then(response => {
          commit('SET_WEBHOOK_EVENTS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getWebhookEventsSelectList({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/webhook-events/select-list')
        .then(response => {
          commit('SET_WEBHOOK_EVENTS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // ////////// //
  /* WebhookSubscriber CRUD */
  postWebhookSubscriber({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/projects/${project.state.activeProjectId}/webhook-subscribers`, data)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getWebhookSubscribers({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/projects/${project.state.activeProjectId}/webhook-subscribers`, state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_WEBHOOKS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putWebhookSubscriber({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/projects/${project.state.activeProjectId}/webhook-subscribers`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteWebhookSubscriber({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/v1/projects/${project.state.activeProjectId}/webhook-subscribers/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateWebhookSubscribersStatus({ commit, state }, { id, status }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/projects/${project.state.activeProjectId}/webhook-subscribers/${id}?active=${status}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // ////////// //
  /* WebhookFilter CRD */
  postWebhookFilter({ commit }, { subscriberId, data }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/projects/${project.state.activeProjectId}/webhook-subscribers/${subscriberId}/events`, data)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getWebhookFilters({ commit, state }, subscriberId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/projects/${project.state.activeProjectId}/webhook-subscribers/${subscriberId}/events`)
        .then(response => {
          commit('SET_WEBHOOK_FILTERS_SELECTED', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteWebhookFilter({ commit, state }, { subscriberId, id }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/v1/projects/${project.state.activeProjectId}/webhook-subscribers/${subscriberId}/events/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteWebhookFilters({ commit, state }, { subscriberId, ids }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/v1/projects/${project.state.activeProjectId}/webhook-subscribers/${subscriberId}/events`, ids)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
