<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    style="fill: currentColor"
    viewBox="0,0,256,256"
  >
    <g transform="translate(38.4,38.4) scale(0.7,0.7)"><g
      fill="#ff5500"
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    ><path
      id="bgRectangle"
      d="M-54.85714,310.85714v-365.71429h365.71429v365.71429z"
    /></g><g
      fill="#ffffff"
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    ><g transform="scale(5.12,5.12)"><path d="M29,3c-2.10547,0 -4.56641,1.65234 -4.9375,9.03125c0.3125,-0.00781 0.62109,-0.03125 0.9375,-0.03125c0.35156,0 0.71484,0.01953 1.0625,0.03125c0.23828,-4.43359 1.29297,-7.03125 2.9375,-7.03125c0.70313,0 1.10156,0.38281 1.84375,1.1875c0.86719,0.94141 2,2.16406 4.21875,2.625c-0.03516,-0.26172 -0.0625,-0.54297 -0.0625,-0.8125c0,-0.42187 0.04297,-0.82031 0.125,-1.21875c-1.375,-0.375 -2.10156,-1.16797 -2.8125,-1.9375c-0.79297,-0.85937 -1.70312,-1.84375 -3.3125,-1.84375zM41,4c-2.20703,0 -4,1.79688 -4,4c0,2.20313 1.79297,4 4,4c2.20703,0 4,-1.79687 4,-4c0,-2.20312 -1.79297,-4 -4,-4zM25,14c-12.13281,0 -22,6.17969 -22,15c0,8.82031 9.86719,16 22,16c12.13281,0 22,-7.17969 22,-16c0,-8.82031 -9.86719,-15 -22,-15zM7.5,14.9375c-1.46094,0 -2.84766,0.59766 -3.90625,1.65625c-1.72266,1.72266 -2.07812,4.19922 -1.09375,6.25c1.51172,-2.92578 4.11328,-5.42187 7.46875,-7.28125c-0.76172,-0.38672 -1.60547,-0.625 -2.46875,-0.625zM42.5,14.9375c-0.86328,0 -1.70703,0.23828 -2.46875,0.625c3.35547,1.85938 5.95703,4.35547 7.46875,7.28125c0.98438,-2.05078 0.62891,-4.52734 -1.09375,-6.25c-1.05859,-1.05859 -2.44531,-1.65625 -3.90625,-1.65625zM17,23c1.65625,0 3,1.34375 3,3c0,1.65625 -1.34375,3 -3,3c-1.65625,0 -3,-1.34375 -3,-3c0,-1.65625 1.34375,-3 3,-3zM33,23c1.65625,0 3,1.34375 3,3c0,1.65625 -1.34375,3 -3,3c-1.65625,0 -3,-1.34375 -3,-3c0,-1.65625 1.34375,-3 3,-3zM16.0625,34c0.25,0.04297 0.49609,0.18359 0.65625,0.40625c0.10547,0.14844 2.44922,3.28125 8.28125,3.28125c5.91016,0 8.25781,-3.21875 8.28125,-3.25c0.31641,-0.44922 0.95313,-0.57031 1.40625,-0.25c0.44922,0.31641 0.53516,0.92188 0.21875,1.375c-0.11719,0.16797 -2.96875,4.125 -9.90625,4.125c-6.94141,0 -9.78906,-3.95703 -9.90625,-4.125c-0.31641,-0.45312 -0.23437,-1.05859 0.21875,-1.375c0.22656,-0.16016 0.5,-0.23047 0.75,-0.1875z" /></g></g></g>
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
