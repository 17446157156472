// import TagURL from './adSchedulesTagURL'

class AdUnitBidderValues {
  constructor() {
    this.value = ''
    this.bidParamId = null
    this.adUnitBidderId = null // differennt

    this.placeholder = ''
  }

  fromData(data) {
    this.bidParamId = data.id || null
    this.adUnitBidderId = data.bidderId || null
    this.value = data.value ? data.value : ''
    this.placeholder = data.placeholder ? data.placeholder : ''
  }
}

export default AdUnitBidderValues
