<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.95 5.6A2.4 2.4 0 004.55 8v8a2.4 2.4 0 002.4 2.4h8a2.4 2.4 0 002.4-2.4v-3a.6.6 0 011.2 0v3a3.6 3.6 0 01-3.6 3.6h-8a3.6 3.6 0 01-3.6-3.6V8a3.6 3.6 0 013.6-3.6h7.5a.6.6 0 010 1.2h-7.5z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.461 5.522a.7.7 0 01-.033.99l-8.487 7.936a.8.8 0 01-1.093 0l-2.376-2.222a.7.7 0 01.956-1.023l1.966 1.839 8.078-7.553a.7.7 0 01.99.033z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
