/* eslint-disable no-unused-vars */
export default [

  // Profile
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/account/profile/View.vue'),
    meta: {
      profile: true,
      hiddenSidebar: true,
      noOrganizationAccess: true,
      contentClass: 'profile',
      navActiveLink: 'profile',
    },
  },
  {
    path: '/profile/update',
    name: 'profile-update',
    component: () => import('@/views/account/profile/Edit.vue'),
    meta: {
      profile: true,
      hiddenSidebar: true,
      noOrganizationAccess: true,
      contentClass: 'profile',
      navActiveLink: 'profile',
    },
  },
  // Organization
  {
    path: '/select-organization',
    name: 'organization-select',
    component: () => import('@/views/account/organizations/Select.vue'),
    meta: {
      hiddenSidebar: true,
      noOrganizationAccess: true,
      organizationAccess: true,
      resource: 'Auth',
    },
  },
  {
    path: '',
    component: () => import('@/views/account/organizations/View.vue'),
    children: [
      {
        path: '/:organizationId/organization/roles',
        name: 'organization-roles',
        component: () => import('@/views/account/user-roles/UserRoles.vue'),
        meta: {
          hiddenSidebar: true,
          organizationAccess: true,
          contentClass: 'organization',
          navActiveLink: 'organization',
        },
      },
      {
        path: '/:organizationId/organization/user',
        name: 'organization-user',
        component: () => import('@/views/account/user/User.vue'),
        props: {
          allowCreate: false,
          displayRole: false,
          displayProjects: true,
          getDatas: 'userModule/getUsers',
          getRows: 'userModule/getUsers',
          clearUsers: 'userModule/CLEAR_USERS',
          updateFilter: 'userModule/UPDATE_FILTER',
          resetFilter: 'userModule/RESET_FILTER',
          deleteAction: 'userModule/removeUserFromOrganization',
          roleUpdateAction: 'userModule/updateUserOrganizationRole',
          columns: [
            {
              label: 'Full Name',
              field: 'full-name',
            },
            {
              label: 'E-mail',
              field: 'email',
            },
            {
              label: 'Projects',
              field: 'projects',
              sortable: false,
              // width: '250px',
            },
            {
              label: 'Status',
              field: 'status',
              // width: '100px',
            },
            {
              label: '',
              field: 'actions',
              width: '50px',
            },
          ],
        },
        meta: {
          hiddenSidebar: true,
          organizationAccess: true,
          contentClass: 'organization',
          navActiveLink: 'organization',
        },
      },
      {
        path: '/:organizationId/organization/notifications',
        name: 'organization-notifications',
        component: () => import('@/views/account/notifications/OrganizationNotifications.vue'),
        meta: {
          hiddenSidebar: true,
          organizationAccess: true,
          contentClass: 'organization',
          navActiveLink: 'organization',
        },
      },
      {
        path: '/:organizationId/organization/social-platforms',
        name: 'organization-social-platforms',
        component: () => import('@/views/account/organizations/components/SocialPlatforms.vue'),
        meta: {
          hiddenSidebar: true,
          organizationAccess: true,
          contentClass: 'organization',
          navActiveLink: 'organization',
        },
      },
      {
        path: '/:organizationId/organization/social-platforms/:id',
        name: 'organization-social-platform',
        component: () => import('@/views/account/organizations/components/SocialPlatformView.vue'),
        meta: {
          hiddenSidebar: true,
          organizationAccess: true,
          contentClass: 'organization',
          navActiveLink: 'organization',
        },
      },
      {
        path: '/:organizationId/organization/billing',
        name: 'organization-billing',
        component: () => import('@/views/account/organizations/components/Billing.vue'),
        meta: {
          hiddenSidebar: true,
          organizationAccess: true,
          contentClass: 'organization',
          navActiveLink: 'organization',
        },
      },
      {
        path: '/:organizationId/organization/:general?',
        name: 'organization',
        component: () => import('@/views/account/organizations/components/GeneralTab.vue'),
        beforeEnter: (to, from, next) => {
          const { general } = to.params
          const validGeneralValues = ['general', undefined]

          if (!validGeneralValues.includes(general)) {
            const errorPath = '/error-404'
            next(errorPath)
          } else {
            next()
          }
        },
        meta: {
          hiddenSidebar: true,
          organizationAccess: true,
          contentClass: 'organization',
          navActiveLink: 'organization',
        },
      },
    ],
  },
  {
    path: '/:organizationId/organization/edit/',
    name: 'organization-edit',
    component: () => import('@/views/account/organizations/Edit.vue'),
    meta: {
      hiddenSidebar: true,
      organizationAccess: true,
      contentClass: 'organization',
      navActiveLink: 'organization',
    },
  },
  // Project
  {
    path: '/select-project',
    name: 'projects-select',
    component: () => import('@/views/account/project/Select.vue'),
    meta: {
      hiddenSidebar: true,
      organizationAccess: true,
      resource: 'Auth',
    },
  },
  {
    path: '/:organizationId/projects/add',
    name: 'project-add',
    component: () => import('@/views/account/project/Add.vue'),
    meta: {
      hiddenSidebar: true,
      organizationAccess: true,
      contentClass: 'project',
      navActiveLink: 'project',
    },
  },
  {
    path: '/project-connections',
    name: 'project-connections',
    component: () => import('@/views/account/project/components/ProjectCallback.vue'),
    meta: {
      hiddenSidebar: true,
      isPublic: true,
      globalAccess: true,
      noOrganizationAccess: true,
    },
  },
  {
    path: '/:projectId/project/edit/',
    name: 'project-edit',
    component: () => import('@/views/account/project/Edit.vue'),
    meta: {
      title: '',
      titleText: 'Projects',
      parentText: 'Dashboard',
      contentClass: 'project',
      navActiveLink: 'project',
      gjIcon: 'Widget',
      action: 'execute',
      resource: 'project',
      projectAccess: true,
      breadcrumb: [
        {
          text: 'Project Settings',
          to: 'project',
        },
        {
          dynamic: true,
          text: 'Project Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '',
    component: () => import('@/views/account/project/View.vue'),
    children: [
      {
        path: '/:projectId/project/case-covers',
        name: 'project-case-covers',
        component: () => import('@/views/account/project/components/CaseCovers.vue'),
        meta: {
          titleText: 'Projects',
          parentText: 'Dashboard',
          contentClass: 'project',
          navActiveLink: 'project',
          gjIcon: 'Widget',
          action: 'read',
          resource: 'project',
          projectAccess: true,
          breadcrumb: [
            {
              text: 'Project settings',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/project/users',
        name: 'project-users',
        component: () => import('@/views/account/user/User.vue'),
        props: {
          getDatas: 'userModule/getUsersOfProject',
          getRows: 'userModule/getUsers',
          clearUsers: 'userModule/CLEAR_USERS',
          updateFilter: 'userModule/UPDATE_FILTER',
          resetFilter: 'userModule/RESET_FILTER',
          deleteAction: 'projectModule/removeProjectUser',
          roleUpdateAction: 'userModule/updateUserProjectRole',
          columns: [
            {
              label: 'Full Name',
              field: 'full-name',
            },
            {
              label: 'E-mail',
              field: 'email',
            },
            {
              label: 'Role',
              field: 'role',
              sortable: false,
              width: '250px',
            },
            {
              label: 'Status',
              field: 'status',
              // width: '100px',
            },
            {
              label: '',
              field: 'actions',
              width: '50px',
            },
          ],
        },
        meta: {
          titleText: 'Projects',
          parentText: 'Dashboard',
          contentClass: 'project',
          navActiveLink: 'project',
          gjIcon: 'Widget',
          action: 'read',
          resource: 'project',
          projectAccess: true,
          breadcrumb: [
            {
              text: 'Project settings',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/project/:general?',
        name: 'project',
        component: () => import('@/views/account/project/components/GeneralTab.vue'),
        beforeEnter: (to, from, next) => {
          const { general } = to.params
          const validGeneralValues = ['general', undefined]

          if (!validGeneralValues.includes(general)) {
            const errorPath = '/error-404'
            next(errorPath)
          } else {
            next()
          }
        },
        meta: {
          titleText: 'Projects',
          parentText: 'Dashboard',
          contentClass: 'project',
          navActiveLink: 'project',
          gjIcon: 'Widget',
          action: 'read',
          resource: 'project',
          projectAccess: true,
          breadcrumb: [
            {
              text: 'Project settings',
              active: true,
            },
          ],
        },
      },

    ],
  },

  // User
  {
    path: '/:projectId/user/',
    name: 'user',
    component: () => import('@/views/account/user/User.vue'),
    meta: {
      titleText: 'Users',
      parentText: 'Dashboard',
      contentClass: 'user',
      navActiveLink: 'user',
      gjIcon: 'AvatarGroup',
      action: 'read',
      resource: 'user',
      projectAccess: true,
      breadcrumb: [
        {
          text: 'Users',
          to: 'user',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:projectId/user/add',
    name: 'user-add',
    component: () => import('@/views/account/user/Add.vue'),
    meta: {
      titleText: 'Users',
      parentText: 'Dashboard',
      contentClass: 'user',
      navActiveLink: 'user',
      gjIcon: 'AvatarGroup',
      action: 'write',
      resource: 'user',
      projectAccess: true,
      breadcrumb: [
        {
          text: 'Users',
          to: 'project',
          activeTab: 'users',
        },
        {
          text: 'Add users',
          active: true,
        },
      ],
    },
  },

  // API kyes
  {
    path: '/:projectId/api-keys',
    name: 'api-keys',
    component: () => import('@/views/account/api-keys/ApiKeys.vue'),
    meta: {
      titleText: 'API Keys',
      parentText: 'Dashboard',
      contentClass: 'api-keys',
      navActiveLink: 'api-keys',
      gjIcon: 'Api',
      action: 'write',
      resource: 'user',
      projectAccess: true,
      breadcrumb: [
        {
          text: 'API Keys',
          to: 'api-keys',
          active: true,
        },
      ],
    },
  },
  {
    path: '/social-distribution-connections',
    name: 'social-distribution-connections',
    component: () => import('@/views/account/organizations/components/SocialMediaCallback.vue'),
    meta: {
      hiddenSidebar: true,
      isPublic: true,
      globalAccess: true,
      noOrganizationAccess: true,
    },
  },
  // {
  //   path: '/:projectId/api-keys/add',
  //   name: 'api-keys-add',
  //   component: () => import('@/views/account/api-keys/Add.vue'),
  //   meta: {
  //     titleText: 'API Keys',
  //     parentText: 'Dashboard',
  //     contentClass: 'api-key',
  //     navActiveLink: 'api-keys',
  //     gjIcon: 'Api',
  //     action: 'write',
  //     resource: 'user',
  //     projectAccess: true,
  //     breadcrumb: [
  //       {
  //         text: route => 'API Keys',
  //         to: 'api-keys',
  //       },
  //       {
  //         text: route => 'Add api key',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // User Roles
  {
    path: '/:organizationId/user-roles',
    name: 'user-roles',
    component: () => import('@/views/account/user-roles/UserRoles.vue'),
    action: 'read',
    resource: 'role',
    meta: {
      titleText: 'User Roles',
      parentText: 'Dashboard',
      contentClass: 'user-roles',
      navActiveLink: 'user-roles',
      gjIcon: 'Privacy',
      action: 'read',
      resource: 'user',
      projectAccess: true,
      breadcrumb: [
        {
          text: 'User roles',
          to: 'user-roles',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/:organizationId/user-roles/add',
  //   name: 'user-roles-add',
  //   component: () => import('@/views/account/user-roles/Add.vue'),
  //   meta: {
  //     titleText: 'User Roles',
  //     parentText: 'Dashboard',
  //     contentClass: 'user-role',
  //     navActiveLink: 'user-roles',
  //     gjIcon: 'Privacy',
  //     action: 'write',
  //     resource: 'user',
  //     breadcrumb: [
  //       {
  //         text: 'User Roles',
  //         to: 'user-roles',
  //       },
  //       {
  //         text: 'Add User Role',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/:organizationId/user-roles/:id',
  //   name: 'user-roles-edit',
  //   component: () => import('@/views/account/user-roles/Edit.vue'),
  //   meta: {
  //     titleText: 'User Roles',
  //     parentText: 'Dashboard',
  //     contentClass: 'user-role',
  //     navActiveLink: 'user-roles',
  //     gjIcon: 'Privacy',
  //     action: 'execute',
  //     resource: 'user',
  //     breadcrumb: [
  //       {
  //         text: 'User Roles',
  //         to: 'user-roles',
  //       },
  //       {
  //         text: 'Edit User Role Permissions',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

]
