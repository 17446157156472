export default {
  getIsLoading: state => state.isLoading,

  // # LIVE VIEWERS GETTERS
  getLiveViewers: state => state.liveViewers,
  getLiveViewersIsLoading: state => state.liveViewersIsLoading,

  // # CONTENT GETTERS
  getTopContent: state => state.topContent,
  getTopContentIsLoading: state => state.topContentIsLoading,

  // # VIDEO ANALYTICS GETTERS
  getVideoAnalytics: state => state.videoAnalytics,
  getVideoAnalyticsIsLoading: state => state.videoAnalyticsIsLoading,

  // # DEVICES GETTERS
  getTopDevices: state => state.topDevices,
  getTopDevicesIsLoading: state => state.topDevicesIsLoading,

  // # BROWSERS GETTERS
  getTopBrowsers: state => state.topBrowsers,
  getTopBrowsersIsLoading: state => state.topBrowsersIsLoading,

  // # DOMAINS GETTERS
  getTopDomains: state => state.topDomains,
  getTopDomainsIsLoading: state => state.topDomainsIsLoading,

  // # OPERATING SYSTEMS GETTERS
  getTopOperatingSystems: state => state.topOperatingSystems,
  getTopOperatingSystemsIsLoading: state => state.topOperatingSystemsIsLoading,

  // # COUNTRIES GETTERS
  getTopCountries: state => state.topCountries,
  getTopCountriesIsLoading: state => state.topCountriesIsLoading,

  // # STREAM DATA GETTERS
  getStreamDataFileSize: state => state.usageDatas?.stream,
  getStreamDataDuration: state => state.usageDatas?.encodeVod,

  // # STORAGE GETTERS
  getStorageTotalBytes: state => state.usageDatas?.storage,

  // # PLAY NUMBER GETTERS
  getPlaysNumber: state => state.usageDatas?.plays,

  // # AD PLAY NUMBER GETTERS
  getAdPlaysNumber: state => state.usageDatas?.ads,

  // # CUSTOM REPORT VIEW TABLE GETTERS
  getCustomReportViewTitle: state => state.customReport?.name || 'Example custom report',
  getCustomReportViewTableColumns: state => {
    const dimensions = state.customReportViewTable.rowsMetadata?.dimensions || []
    const metrics = state.customReportViewTable.rowsMetadata?.metrics || []
    const filteredDimensions = dimensions.map(d => ({
      label: d.display,
      field: d.key,
    }))
    const filteredMetrics = metrics.map(d => ({
      label: d.display,
      field: d.key,
    }))
    const columns = filteredDimensions.concat(filteredMetrics)
    return columns
  },
  getCustomReportViewTableRows: (state, getters) => {
    const rowsArray = []
    if (state.customReportViewTable?.items?.length > 0) {
      state.customReportViewTable.items.forEach(r => {
        const objFromArr = {}
        getters.getCustomReportViewTableColumns.forEach((e, i) => {
          objFromArr[e.field] ??= r[i]
        })
        rowsArray.push(objFromArr)
      })
    }
    return rowsArray
  },
  getCustomReportViewTableMetrics: state => {
    const metrics = state.customReportViewTable.rowsMetadata?.metrics || []
    return metrics
  },
  getCustomReportViewTableDimensions: state => {
    const dimensions = state.customReportViewTable.rowsMetadata?.dimensions || []
    return dimensions
  },
  getTableIsLoading: state => state.isLoadingTable,

  // # CUSTOM REPORT VIEW CHART GETTERS
  getCustomReportViewChartRows: state => state.customReportViewChart.items,
  getCustomReportViewChartColumns: state => {
    const metrics = state.customReportViewTable.rowsMetadata?.metrics || []
    return metrics
  },
  getChartIsLoading: state => state.isLoadingChart,

  // # ANALYTICS CONTENT GETTERS
  // #
  getContent: state => state.content,
  getContentIsLoading: state => state.contentIsLoading,
  getContentOptions: state => state.contentOptions,

  // # CUSTOM REPORTS GETTERS
  // #
  getCustomReport: state => state.customReport,
  getCustomReports: state => state.customReports,
  getCustomReportsIsLoading: state => state.customReportsIsLoading,
  getCustomReportIsLoading: state => state.customReportIsLoading,
  getCustomReportsName: state => state.customReport.name,
  getCustomReportsSelectedMetrics: state => state.customReport.reportMetricKeys,
  getCustomReportsSelectedDimensions: state => state.customReport.reportDimensionKeys,
  getCustomReportsSelectedFilters: state => state.customReport.reportFilters,
  getCustomReportsPagination: state => state.customReportViewTablePagination,
  getCustomReportsCurrentPage: state => state.customReportViewTableFilters.params.page,
  getCustomReportsPageLength: state => state.customReportViewTableFilters.params.take,
  getCustomParametersFilters: state => index => {
    const usedFilterParameters = state.customReport.reportFilters
      .filter(x => x.filterId !== state.customReport.reportFilters[index].filterId)
      .map(x => x.filterId)

    return state.customReportsOptions.filters.filter(x => !usedFilterParameters.includes(x.id))
  },
  getCustomReportsDateRangeId: state => state.customReport.customReportDateRangeId,
  getCustomReportsDateFrom: state => state.customReport.dateFrom,
  getCustomReportsDateTo: state => state.customReport.dateTo,
  getCustomReportsDateFull(state) {
    const from = state.customReport.dateFrom
    const to = state.customReport.dateTo

    return `${from} to ${to}`
  },
  getCustomReportsOptions: state => state.customReportsOptions,
  getCustomReportsDateRangeOptions: state => state.customReportsOptions.dateRanges,

  // # USAGE HISTORY GETTERS
  // #
  getUsageHistory: state => state.usageHistory,
  getUsageHistoryTotal: state => state.usageHistoryTotal,
  getUsageHistoryIsLoading: state => state.usageHistoryIsLoading,
  getUsageHistoryOptions: state => state.usageHistoryOptions,

  // # USAGE HISTORY GETTERS
  // #
  getContractTotal: state => state.contractTotal,
  getContractUsage: state => state.contractUsage,
  getContractIsLoading: state => state.contractIsLoading,

  // # ANALYTICS PAGINATION
  // #
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,

}
