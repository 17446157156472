import AdBreakSettings from './AdBreakSettings'
import WaterfallTagUrl from './AdBreakTagURL'

class AdBreak {
  constructor(adScheduleId, type, typeId) {
    this.id = 0
    this.adScheduleId = adScheduleId
    this.liveVideoPrerollsNr = 0
    this.secondsToRepeat = 0
    this.protectFirstSeconds = 0
    this.protectLastSeconds = 0
    this.nonLinearOverlayAd = true
    this.skipableAfterSeconds = true
    this.skipableAfterSecondsNr = 0
    this.adBreakTypeId = typeId
    this.adBreakType = {
      id: typeId,
      name: type,
    }
    this.midrollTypeId = 1
    this.midrollType = null
    this.distributionMethodTypeId = null
    this.distributionMethodType = null
    this.breakTimingTypeId = 1
    this.breakTiming = 0
    this.breakTimingType = null
    this.adBreakSettings = [new AdBreakSettings()]
    this.waterfallTags = [new WaterfallTagUrl()]
    this.isDeleted = false
    this.isVerified = false
  }

  async fromData(data) {
    this.id = data.id || 0
    this.adScheduleId = data.adScheduleId || ''
    this.liveVideoPrerollsNr = data.liveVideoPrerollsNr
    this.secondsToRepeat = data.secondsToRepeat
    this.protectFirstSeconds = data.protectFirstSeconds
    this.protectLastSeconds = data.protectLastSeconds
    this.nonLinearOverlayAd = data.nonLinearOverlayAd
    this.skipableAfterSeconds = data.skipableAfterSeconds
    this.skipableAfterSecondsNr = data.skipableAfterSecondsNr
    this.adBreakTypeId = data.adBreakTypeId
    this.adBreakType = data.adBreakType || null
    this.midrollTypeId = data.midrollTypeId || 1
    this.midrollType = data.midrollType || null
    this.distributionMethodTypeId = data.distributionMethodTypeId || null
    this.distributionMethodType = data.distributionMethodType || null
    this.breakTiming = data.breakTiming || 0
    this.breakTimingTypeId = data.breakTimingTypeId || 1
    this.breakTimingType = data.breakTimingType || null
    this.adBreakSettings = data.adBreakSettings ? data.adBreakSettings.map(x => {
      const ads = new AdBreakSettings(1232)
      ads.fromData(x)
      return ads
    }) : [new AdBreakSettings()]
    this.waterfallTags = data.waterfallTags.length ? data.waterfallTags.map(x => {
      const waterfallTagUrl = new WaterfallTagUrl()
      x.adBreakId = x.adBreakId ? x.adBreakId : data.id
      waterfallTagUrl.fromData(x)
      return waterfallTagUrl
    }) : [new WaterfallTagUrl()]
    this.isDeleted = false
    this.isVerified = true
  }
}

export default AdBreak
