<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.993 5.044a1.6 1.6 0 012.014 0l6.651 5.388c.513.415.22 1.243-.44 1.243h-1.26V17a2.6 2.6 0 01-2.6 2.6H8.642a2.6 2.6 0 01-2.6-2.6v-5.325h-1.26c-.66 0-.954-.828-.441-1.243l6.65-5.388zm1.259.932a.4.4 0 00-.504 0l-5.554 4.5h.349a.7.7 0 01.7.7V17a1.4 1.4 0 001.4 1.4h6.714a1.4 1.4 0 001.4-1.4v-5.825a.7.7 0 01.7-.7h.35l-5.555-4.499z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
