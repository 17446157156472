<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.625 5C10.625 4.65482 10.3452 4.375 10 4.375C9.65482 4.375 9.375 4.65482 9.375 5V9.375H5C4.65482 9.375 4.375 9.65482 4.375 10C4.375 10.3452 4.65482 10.625 5 10.625H9.375V15C9.375 15.3452 9.65482 15.625 10 15.625C10.3452 15.625 10.625 15.3452 10.625 15V10.625H15C15.3452 10.625 15.625 10.3452 15.625 10C15.625 9.65482 15.3452 9.375 15 9.375H10.625V5Z"
      fill="currentColor"
    />
  </svg>

</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
