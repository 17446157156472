<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.885 4.1a.63.63 0 00-.631.63v.71l-.437.123c-.466.132-.91.317-1.325.55l-.396.22-.501-.5a.63.63 0 00-.892 0l-.87.87a.63.63 0 000 .892l.5.501-.22.396a6.128 6.128 0 00-.55 1.325l-.124.437h-.708a.63.63 0 00-.631.63v1.231c0 .349.282.631.63.631h.71l.123.437c.132.466.317.91.55 1.325l.22.396-.5.501a.63.63 0 000 .893l.87.87a.63.63 0 00.892 0l.501-.502.396.222c.415.232.859.417 1.325.55l.437.123v.708c0 .349.282.631.63.631h1.231a.63.63 0 00.631-.63v-.256a.6.6 0 011.2 0v.255a1.83 1.83 0 01-1.83 1.831h-1.231a1.83 1.83 0 01-1.822-1.643 7.32 7.32 0 01-.759-.315 1.83 1.83 0 01-2.45-.126l-.87-.87a1.831 1.831 0 01-.126-2.45 7.325 7.325 0 01-.315-.76 1.831 1.831 0 01-1.643-1.82v-1.231c0-.948.72-1.728 1.643-1.822.091-.26.197-.513.315-.759a1.831 1.831 0 01.126-2.45l.87-.87a1.831 1.831 0 012.45-.126c.246-.118.5-.224.76-.315a1.831 1.831 0 011.82-1.643h1.231c.948 0 1.728.72 1.822 1.643.26.091.513.197.759.315a1.831 1.831 0 012.45.126l.87.87c.67.67.713 1.731.126 2.45.118.246.224.5.315.76a1.83 1.83 0 011.643 1.82v1.231a1.83 1.83 0 01-1.83 1.831h-.256a.6.6 0 010-1.2h.255a.63.63 0 00.631-.63v-1.231a.63.63 0 00-.63-.631h-.71l-.123-.437a6.132 6.132 0 00-.55-1.325l-.22-.396.5-.501a.63.63 0 000-.892l-.87-.87a.63.63 0 00-.892 0l-.501.5-.396-.22a6.128 6.128 0 00-1.325-.55l-.437-.124v-.708a.63.63 0 00-.63-.631h-1.231z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.02 9.455c-.877-1.408-2.692-1.785-4.226-1.426l-.173.04-.122.127-.07.071-.053.053-.416.423 2.32 2.329-.13.132-.475.483-.483.479-.129.127L8.737 9.96l-.425.432-.054.053a6.199 6.199 0 00-.073.073l-.119.12-.038.166c-.357 1.536.017 3.357 1.421 4.237l.008.005c1.417.86 2.716.722 3.634.382l2.648 3.09 1.488 1.895.025.025c.88.884 2.308.884 3.189 0 .88-.882.878-2.31 0-3.193l-.015-.015-1.813-1.58-3.185-2.608c.322-.916.435-2.19-.403-3.579l-.005-.007zm-1.653 2.223l-.36.367-.482.49-.002.001-.487.483-.365.361-.002.002a.862.862 0 01-1.214 0l-.003-.003L9.1 12.024c.028.868.382 1.619.983 1.997 1.2.728 2.24.474 2.912.143l.418-.206 3.254 3.798 1.458 1.857c.412.39 1.061.383 1.465-.023.408-.408.413-1.07.014-1.486l-1.766-1.538-3.877-3.174.2-.424c.321-.676.55-1.7-.161-2.882-.378-.603-1.126-.959-1.99-.986l1.356 1.361.001.001a.862.862 0 010 1.216z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
