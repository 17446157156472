import state from './moduleChannelsState'
import mutations from './moduleChannelsMutations'
import actions from './moduleChannelsActions'
import getters from './moduleChannelsGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
