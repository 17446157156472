<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 10.3916C13.4083 10.3916 13.125 10.1083 13.125 9.76664V8.29168C13.125 7.90001 12.8083 7.58331 12.4167 7.58331H6.25C5.90833 7.58331 5.625 7.29998 5.625 6.95831C5.625 6.61665 5.90833 6.33331 6.25 6.33331H12.4167C13.5 6.33331 14.375 7.21668 14.375 8.29168V9.76664C14.375 10.1083 14.0917 10.3916 13.75 10.3916Z"
      fill="currentColor"
    />
    <path
      d="M7.65833 8.98334C7.49999 8.98334 7.34166 8.92498 7.21666 8.79998L5.80833 7.39165C5.56666 7.14998 5.56666 6.74998 5.80833 6.50832L7.21666 5.09998C7.45833 4.85832 7.85833 4.85832 8.09999 5.09998C8.34166 5.34165 8.34166 5.74165 8.09999 5.98331L7.13332 6.95001L8.09999 7.91665C8.34166 8.15832 8.34166 8.55832 8.09999 8.79998C7.97499 8.92498 7.81666 8.98334 7.65833 8.98334Z"
      fill="currentColor"
    />
    <path
      d="M13.75 13.675H7.58334C6.50001 13.675 5.625 12.7917 5.625 11.7167V10.2417C5.625 9.90003 5.90833 9.6167 6.25 9.6167C6.59167 9.6167 6.875 9.90003 6.875 10.2417V11.7167C6.875 12.1084 7.19167 12.425 7.58334 12.425H13.75C14.0917 12.425 14.375 12.7084 14.375 13.05C14.375 13.3917 14.0917 13.675 13.75 13.675Z"
      fill="currentColor"
    />
    <path
      d="M12.3417 15.075C12.1833 15.075 12.025 15.0166 11.9 14.8916C11.6583 14.6499 11.6583 14.2499 11.9 14.0083L12.8667 13.0416L11.9 12.0749C11.6583 11.8333 11.6583 11.4333 11.9 11.1916C12.1417 10.9499 12.5417 10.9499 12.7833 11.1916L14.1917 12.5999C14.4333 12.8416 14.4333 13.2416 14.1917 13.4833L12.7833 14.8916C12.6667 15.0166 12.5 15.075 12.3417 15.075Z"
      fill="currentColor"
    />
    <path
      d="M9.99996 18.9583C5.05829 18.9583 1.04163 14.9417 1.04163 9.99999C1.04163 5.05832 5.05829 1.04166 9.99996 1.04166C14.9416 1.04166 18.9583 5.05832 18.9583 9.99999C18.9583 14.9417 14.9416 18.9583 9.99996 18.9583ZM9.99996 2.29166C5.74996 2.29166 2.29163 5.74999 2.29163 9.99999C2.29163 14.25 5.74996 17.7083 9.99996 17.7083C14.25 17.7083 17.7083 14.25 17.7083 9.99999C17.7083 5.74999 14.25 2.29166 9.99996 2.29166Z"
      fill="currentColor"
    />
  </svg>
</template>
