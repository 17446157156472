<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.46967 8.53033C6.76256 8.82322 7.23744 8.82322 7.53033 8.53033L9.25 6.81066V13C9.25 13.4142 9.58579 13.75 10 13.75C10.4142 13.75 10.75 13.4142 10.75 13V6.81066L12.4697 8.53033C12.7626 8.82322 13.2374 8.82322 13.5303 8.53033C13.8232 8.23744 13.8232 7.76256 13.5303 7.46967L10 3.93934L6.46967 7.46967C6.17678 7.76256 6.17678 8.23744 6.46967 8.53033Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 11.5C0 9.35802 1.22445 7.50204 3.01159 6.5938C3.22197 2.91685 6.27035 0 10 0C13.7296 0 16.778 2.91685 16.9884 6.5938C18.7755 7.50204 20 9.35802 20 11.5C20 14.369 17.8032 16.725 15 16.9776V17H5V16.9776C2.19675 16.725 0 14.369 0 11.5ZM14.8654 15.4836L14.6837 15.5H5.31625L5.13463 15.4836C3.09749 15.3001 1.5 13.5857 1.5 11.5C1.5 9.94436 2.38774 8.59344 3.69117 7.93103L4.45988 7.54036L4.50914 6.67949C4.67436 3.79179 7.07008 1.5 10 1.5C12.9299 1.5 15.3256 3.79179 15.4909 6.67949L15.5401 7.54036L16.3088 7.93103C17.6123 8.59344 18.5 9.94436 18.5 11.5C18.5 13.5857 16.9025 15.3001 14.8654 15.4836Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
