import { addHash } from '@/services/mixins/playerMixins'
import Player from './player'

class VideoPlayer extends Player {
  constructor() {
    super()
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // SETUP
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    this.width = 640
    this.height = 360
    this.playerSizeTypeId = 1
    this.responsiveSizeOptionId = 1
    this.pauseOtherVideos = true
    this.highlightCurrentVideo = false
    this.delayLiveVideosTime = 0
    this.delayLiveVideos = false
    this.showPlayerControlsDuringVideoAdvertisement = false

    this.autoStartOnLoad = true
    this.autoStartOnLoadMobile = false
    this.autoStartOnLoadOnData = false

    this.socialNetworkIds = []

    this.responsiveSizeOption = {
      id: 1,
      name: '16:9',
      width: 16,
      height: 9,
    }
    this.playerSizeType = {
      id: 1,
      name: 'Responsive',
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // COSTUMIZE
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.controlBarBackgroundColor = ''
    this.controlBarGradientMidpoint = 50
    this.controlBarSpreadOptionId = 1
    this.controlBarTextColor = '#ffffff'

    this.controlBarIconsDefaultColor = '#ffffff'
    this.controlBarIconsHoverColor = '#ffffff'

    this.controlBarTimesliderProgressColor = '#F26822'
    this.controlBarTimesliderRailColor = '#FFFFFF80'
    this.controlBarTimesliderBufferColor = '#FFFFFFB2'
    this.controlBarTimesliderDraggerColor = '#F26822'

    this.controlBarTimeSliderOnAdProgressColor = '#ffffff'
    this.controlBarTimeSliderOnAdRailColor = '#9f9f9f'
    this.controlBarTimeSliderOnAdDraggerColor = '#FF0000'

    this.controlBarVolumeDraggerColor = '#ffffff'
    this.controlBarVolumeProgressColor = '#ffffff'
    this.controlBarVolumeRailColor = '#9f9f9f'
    this.controlBarVolumeNotifierColor = 'rgba(0, 0, 0, 0.3)'
    this.controlBarLoaderColor = '#808080'

    this.menuBackroundDefaultColor = 'rgba(28, 28, 28, 0.7)'
    this.menuBackroundHoverColor = 'rgba(0, 0, 0, 0.5)'

    this.menuLinksDefaultColor = '#ffffff'
    this.menuLinksHoverColor = '#F26822'

    this.menuAutoplayOnColor = '#F26822'
    this.menuAutoplayOffColor = '#A7A7A7'

    this.tooltipsTextColor = '#ffffff00'
    this.tooltipsBackroundColor = '#ffffff00'

    this.nextVideoBackgroundColor = '#2e2e2e'
    this.nextVideoTextColor = '#ffffff'
    this.nextVideoTsRailColor = '#000000'
    this.nextVideoTsProgressColor = '#ffffff'
    this.nextVideoTsIconsCloseColor = '#ffffff'

    this.nextVideoIconsPlayDefaultColor = '#ffffff'
    this.nextVideoIconsPlayHoverColor = '#ffffff'

    this.playlistBackgroundColor = '#2e2e2e'
    this.playlistTextColor = '#ffffff'

    this.playlistCardTitleColor = '#ffffff'
    this.playlistCardBackgroundColor = '#000000'
    this.playlistCardDurationTextColor = '#ffffff'
    this.playlistCardDurationBackgroundColor = '#1a1a1a'

    this.playlistCardIconPlayDefaulColor = '#ffffff'
    this.playlistCardIconPlayHoverColor = '#ffffff'

    this.playlistIconsArrActiveColor = '#ffffff'
    this.playlistIconsArrInactiveColor = '#666666'
    this.playlistIconsCloseColor = '#ffffff'

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // LOCALIZATION
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.Autoplay = 'Autoplay'
    this.AutoplayOff = 'Off'
    this.AutoplayOn = 'On'
  }

  fromData(data) {
    super.fromData(data)
    this.width = data.width || 640
    this.height = data.height || 360
    this.playerSizeTypeId = data.playerSizeTypeId || 1
    this.responsiveSizeOptionId = data.responsiveSizeOptionId || 1
    this.pauseOtherVideos = data.pauseOtherVideos
    this.highlightCurrentVideo = data.highlightCurrentVideo
    this.delayLiveVideos = data.delayLiveVideos
    this.delayLiveVideosTime = data.delayLiveVideosTime || 0
    this.showPlayerControlsDuringVideoAdvertisement = data.showPlayerControlsDuringVideoAdvertisement

    this.autoStartOnLoad = data.autoStartOnLoad
    this.autoStartOnLoadMobile = data.autoStartOnLoadMobile
    this.autoStartOnLoadOnData = data.autoStartOnLoadOnData

    this.socialNetworkIds = data.socialNetworkIds

    this.responsiveSizeOption = data.responsiveSizeOption || {
      id: 1,
      name: '16:9',
    }
    this.playerSizeType = data.playerSizeType || {
      id: 1,
      name: 'Responsive',
    }

    this.controlBarBackgroundColor = addHash(data.controlBarBackgroundColor) || ''
    this.controlBarGradientMidpoint = data.controlBarGradientMidpoint || 50
    this.controlBarSpreadOptionId = data.controlBarSpreadOptionId || 1
    this.controlBarTextColor = addHash(data.controlBarTextColor) || '#ffffff'

    this.controlBarIconsDefaultColor = addHash(data.controlBarIconsDefaultColor) || '#ffffff'
    this.controlBarIconsHoverColor = addHash(data.controlBarIconsHoverColor) || '#ffffff'

    this.controlBarTimesliderProgressColor = addHash(data.controlBarTimesliderProgressColor) || '#F26822'
    this.controlBarTimesliderRailColor = addHash(data.controlBarTimesliderRailColor) || '#FFFFFF80'
    this.controlBarTimesliderBufferColor = addHash(data.controlBarTimesliderBufferColor) || '#FFFFFFB2'
    this.controlBarTimesliderDraggerColor = addHash(data.controlBarTimesliderDraggerColor) || '#F26822'

    this.controlBarTimeSliderOnAdProgressColor = addHash(data.controlBarTimeSliderOnAdProgressColor) || '#f0000'
    this.controlBarTimeSliderOnAdRailColor = addHash(data.controlBarTimeSliderOnAdRailColor) || '#9f9f9f'
    this.controlBarTimeSliderOnAdDraggerColor = addHash(data.controlBarTimeSliderOnAdDraggerColor) || '#ffffff'

    this.controlBarVolumeProgressColor = addHash(data.controlBarVolumeProgressColor) || '#ffffff'
    this.controlBarVolumeRailColor = addHash(data.controlBarVolumeRailColor) || '#9f9f9f'
    this.controlBarVolumeDraggerColor = addHash(data.controlBarVolumeDraggerColor) || '#ffffff'
    this.controlBarVolumeNotifierColor = addHash(data.controlBarVolumeNotifierColor) || 'rgba(0, 0, 0, 0.3)'

    this.controlBarLoaderColor = addHash(data.controlBarLoaderColor) || '#808080'

    this.menuBackroundDefaultColor = addHash(data.menuBackroundDefaultColor) || 'rgba(28, 28, 28, 0.7)'
    this.menuBackroundHoverColor = addHash(data.menuBackroundHoverColor) || 'rgba(0, 0, 0, 0.5)'

    this.menuLinksDefaultColor = addHash(data.menuLinksDefaultColor) || '#ffffff'
    this.menuLinksHoverColor = addHash(data.menuLinksHoverColor) || '#F26822'

    this.menuAutoplayOnColor = addHash(data.menuAutoplayOnColor) || '#F26822'
    this.menuAutoplayOffColor = addHash(data.menuAutoplayOffColor) || '#A7A7A7'

    this.playlistBackgroundColor = addHash(data.playlistBackgroundColor) || 'rgba(28, 28, 28, 0.7)'
    this.playlistTextColor = addHash(data.playlistTextColor) || '#ffffff'
    this.playlistIconsArrActiveColor = addHash(data.playlistIconsArrActiveColor) || '#ffffff'
    this.playlistIconsArrInactiveColor = addHash(data.playlistIconsArrInactiveColor) || '#666666'
    this.playlistIconsCloseColor = addHash(data.playlistIconsCloseColor) || '#ffffff'
    this.playlistCardBackgroundColor = addHash(data.playlistCardBackgroundColor) || 'rgba(0, 0, 0, 0.35)'
    this.playlistCardTitleColor = addHash(data.playlistCardTitleColor) || '#ffffff'
    this.playlistCardDurationTextColor = addHash(data.playlistCardDurationTextColor) || '#ffffff'
    this.playlistCardDurationBackgroundColor = addHash(data.playlistCardDurationBackgroundColor) || 'rgba(26, 26, 26, 0.5)'
    this.playlistCardIconPlayDefaulColor = addHash(data.playlistCardIconPlayDefaulColor) || '#ffffff'
    this.playlistCardIconPlayHoverColor = addHash(data.playlistCardIconPlayHoverColor) || '#ffffff'

    this.nextVideoBackgroundColor = addHash(data.nextVideoBackgroundColor) || '#2e2e2e'
    this.nextVideoTextColor = addHash(data.nextVideoTextColor) || '#ffffff'
    this.nextVideoTsRailColor = addHash(data.nextVideoTsRailColor) || '#000000'
    this.nextVideoTsProgressColor = addHash(data.nextVideoTsProgressColor) || '#ffffff'
    this.nextVideoTsIconsCloseColor = addHash(data.nextVideoTsIconsCloseColor) || '#ffffff'
    this.nextVideoIconsPlayDefaultColor = addHash(data.nextVideoIconsPlayDefaultColor) || '#ffffff'
    this.nextVideoIconsPlayHoverColor = addHash(data.nextVideoIconsPlayHoverColor) || '#ffffff'

    this.tooltipsTextColor = addHash(data.tooltipsTextColor) || '#ffffff'
    this.tooltipsBackroundColor = addHash(data.tooltipsBackroundColor) || '#000000'

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // LOCALIZATION
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    this.Autoplay = data.Autoplay || 'Autoplay'
    this.AutoplayOff = data.AutoplayOff || 'Off'
    this.AutoplayOn = data.AutoplayOn || 'On'
  }
}

export default VideoPlayer
