<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 7A1.6 1.6 0 015 5.4h14A1.6 1.6 0 0120.6 7v10a1.6 1.6 0 01-1.6 1.6H5A1.6 1.6 0 013.4 17V7zM5 6.6a.4.4 0 00-.4.4v10c0 .22.18.4.4.4h14a.4.4 0 00.4-.4V7a.4.4 0 00-.4-.4H5z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.4 13.5a.6.6 0 01.6-.6h10a.6.6 0 010 1.2H7a.6.6 0 01-.6-.6zM8.9 15.5a.6.6 0 01.6-.6h5a.6.6 0 010 1.2h-5a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>
