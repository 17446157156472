export default {
  // # COUNTRIES
  loadingTopCountries: true,
  topCountries: [],
  topCountriesGroupedData: {},

  // # DEVICES
  loadingTopDevices: true,
  topDevices: [],
  topDevicesGroupedData: {},

  // # TOP VIDEOS
  loadingTopVideos: true,
  topVideos: [],

  // # TOP PODCASTS
  loadingTopPodcasts: true,
  topPodcasts: [],

  // # TOP AUDIOS
  loadingTopAudios: true,
  topAudios: [],

  //  # TOP LIVE VIDEOS
  loadingTopLiveVideos: true,
  topLiveVideos: [],

  // # VIDEO LIVE CONSUMPTION
  loadingVideoLiveConsumption: false,
  videoLiveConsumption: [],
  videoLiveConsumptionGroupedData: {},
  videoLiveConsumptionTotal: 0,

  // # AUDIO LIVE CONSUMPTION
  loadingAudioLiveConsumption: false,
  audioLiveConsumption: [],
  audioLiveConsumptionGroupedData: {},
  audioLiveConsumptionTotal: 0,

  loadingVideos: true,
  videos: [],
  filters: {
    params: {
      folderView: false,
      folderId: null,
      insertedDate: null,
      key: null,
      value: null,
      page: 1,
      take: 3,
      search: '',
      order: 'desc',
      onlyReadyVideos: false,
      isLive: false,
    },
  },
}
