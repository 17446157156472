<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1834 10.8917C16.8667 10.8917 16.6 10.65 16.5584 10.3334C16.3584 8.45002 15.3417 6.74169 13.775 5.65836C13.4917 5.45836 13.425 5.07502 13.6167 4.79169C13.8167 4.50836 14.2084 4.44169 14.4834 4.63336C16.35 5.92502 17.55 7.95836 17.7917 10.2C17.825 10.5417 17.5834 10.85 17.2334 10.8917C17.225 10.8917 17.2 10.8917 17.1834 10.8917Z"
      fill="currentColor"
    />
    <path
      d="M2.90838 10.9333C2.88338 10.9333 2.86671 10.9333 2.84171 10.9333C2.50004 10.8917 2.25004 10.5833 2.28338 10.2417C2.50838 8.00001 3.70004 5.97501 5.54171 4.66668C5.82504 4.46668 6.21671 4.53334 6.41671 4.81668C6.61671 5.10001 6.55004 5.49168 6.26671 5.69168C4.71671 6.78334 3.71671 8.49168 3.52504 10.375C3.50004 10.6917 3.22504 10.9333 2.90838 10.9333Z"
      fill="currentColor"
    />
    <path
      d="M10.0501 18.8417C8.81672 18.8417 7.64172 18.5583 6.54172 18.0083C6.23339 17.85 6.10839 17.475 6.26672 17.1667C6.42505 16.8583 6.80005 16.7333 7.10839 16.8917C8.90839 17.8 11.0751 17.8167 12.8917 16.9417C13.2001 16.7917 13.5751 16.925 13.7251 17.2333C13.8751 17.5417 13.7417 17.9167 13.4334 18.0667C12.3667 18.5833 11.2334 18.8417 10.0501 18.8417Z"
      fill="currentColor"
    />
    <path
      d="M10.0499 7.03336C8.42494 7.03336 7.10828 5.71669 7.10828 4.09169C7.10828 2.46669 8.42494 1.15002 10.0499 1.15002C11.6749 1.15002 12.9916 2.46669 12.9916 4.09169C12.9916 5.71669 11.6666 7.03336 10.0499 7.03336ZM10.0499 2.40836C9.11661 2.40836 8.35828 3.16669 8.35828 4.10002C8.35828 5.03336 9.11661 5.79169 10.0499 5.79169C10.9833 5.79169 11.7416 5.03336 11.7416 4.10002C11.7416 3.16669 10.9749 2.40836 10.0499 2.40836Z"
      fill="currentColor"
    />
    <path
      d="M4.02504 17.225C2.40004 17.225 1.08337 15.9083 1.08337 14.2833C1.08337 12.6667 2.40004 11.3417 4.02504 11.3417C5.65004 11.3417 6.96671 12.6583 6.96671 14.2833C6.96671 15.9 5.65004 17.225 4.02504 17.225ZM4.02504 12.5917C3.09171 12.5917 2.33337 13.35 2.33337 14.2833C2.33337 15.2167 3.09171 15.975 4.02504 15.975C4.95837 15.975 5.71671 15.2167 5.71671 14.2833C5.71671 13.35 4.95837 12.5917 4.02504 12.5917Z"
      fill="currentColor"
    />
    <path
      d="M15.975 17.225C14.35 17.225 13.0333 15.9083 13.0333 14.2833C13.0333 12.6667 14.35 11.3417 15.975 11.3417C17.6 11.3417 18.9167 12.6583 18.9167 14.2833C18.9083 15.9 17.5917 17.225 15.975 17.225ZM15.975 12.5917C15.0417 12.5917 14.2833 13.35 14.2833 14.2833C14.2833 15.2167 15.0417 15.975 15.975 15.975C16.9083 15.975 17.6667 15.2167 17.6667 14.2833C17.6583 13.35 16.9083 12.5917 15.975 12.5917Z"
      fill="currentColor"
    />
  </svg>

</template>
