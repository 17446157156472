<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4 6.286a2.886 2.886 0 11.937 2.129l-3.406 2.432c.154.353.24.743.24 1.153 0 .41-.086.8-.24 1.153l3.406 2.432a2.886 2.886 0 11-.698.976l-3.406-2.432a2.886 2.886 0 110-4.258L13.64 7.44a2.877 2.877 0 01-.24-1.153z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
