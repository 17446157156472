import Channel from '@/models/channels/channel'

export default {
  SET_ISLOADING: (state, data) => { state.isLoading = data },
  SET_CHANNEL: (state, data) => {
    const channel = new Channel()
    channel.fromData(data)
    state.channel = channel
  },
  SET_CHANNELS: (state, data) => { state.channels = data || [] },
  SET_DEFAULT_CHANNELS: (state, data) => { state.defaultChannels = data || [] },
  SET_LIVE_CHANNELS: (state, data) => { state.liveChannels = data || [] },
  SET_CHANNEL_TITLE: (state, data) => { state.channel.title = data },
  SET_CHANNEL_LOGO: (state, data) => { state.channel.logo = data },
  SET_CHANNEL_LOGOFILE: (state, data) => { state.channel.logoFile = data },
  SET_CHANNEL_TYPE: (state, data) => { state.channel.typeId = data },
  SET_CHANNEL_LATENCY_TYPE: (state, data) => { state.channel.latencyTypeId = data },
  SET_CHANNEL_SELECT_LIST: (state, data) => { state.channelSelectList = data },
  SET_LIVESTREAM_PROVIDERS: (state, data) => { state.livestreamProviders = data },
  SET_OPTIONS: (state, data) => { state.options = data },
  SET_INPUT_TYPES: (state, data) => { state.inputTypes = data },
  CLEAR_CHANNEL_SELECT_LIST(state) {
    state.channelSelectList = []
  },
  CLEAR_CHANNEL: state => { state.channel = new Channel() },
  CLEAR_OPTIONS: state => {
    state.options = {
      types: [],
      latencyTypes: [],
    }
  },

  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },
  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'search') {
      state.filters.params.search = data.value
      state.filters.params.page = 1
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        flagTypeId: null,
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
}
