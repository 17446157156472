<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 9.692a2.6 2.6 0 012.6-2.6h.462a.6.6 0 010 1.2H6a1.4 1.4 0 00-1.4 1.4v6c0 .689.558 1.247 1.246 1.247a2.446 2.446 0 012.446 2.446V20a.6.6 0 11-1.2 0v-.615c0-.689-.558-1.247-1.246-1.247A2.446 2.446 0 013.4 15.693v-6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3 18.139a2.4 2.4 0 00-1.752.758L8.13 20.41a.6.6 0 11-.875-.82l1.417-1.513a3.6 3.6 0 012.627-1.139h2.547a.6.6 0 010 1.2H11.3z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.462 4.6a1.4 1.4 0 00-1.4 1.4v7.077a1.4 1.4 0 001.4 1.4h3.909a3.6 3.6 0 012.372.892l.965.846v-.138a1.6 1.6 0 011.6-1.6h.693a1.4 1.4 0 001.4-1.4V6A1.4 1.4 0 0018 4.6H8.462zM5.862 6a2.6 2.6 0 012.6-2.6h9.539A2.6 2.6 0 0120.6 6v7.077a2.6 2.6 0 01-2.6 2.6h-.693a.4.4 0 00-.4.4v1.24a.7.7 0 01-1.161.527l-1.794-1.572a2.4 2.4 0 00-1.582-.595H8.462a2.6 2.6 0 01-2.6-2.6V6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.17 8.308a.6.6 0 01.6-.6h4.923a.6.6 0 110 1.2H10.77a.6.6 0 01-.6-.6zM10.17 10.77a.6.6 0 01.6-.6h4.923a.6.6 0 110 1.2H10.77a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
