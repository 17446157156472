<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.92 19.7501C10.63 19.7501 10.35 19.7101 10.12 19.6201C6.7 18.4501 1.25 14.2701 1.25 8.09009C1.25 4.92009 3.80999 2.3501 6.95999 2.3501C8.46999 2.3501 9.87999 2.93008 10.93 3.96008C11.99 2.93008 13.39 2.3501 14.9 2.3501C18.05 2.3501 20.61 4.92009 20.61 8.09009C20.61 8.52009 20.58 8.9601 20.52 9.4101C20.49 9.6401 20.35 9.85009 20.15 9.97009C19.95 10.0901 19.7 10.1001 19.48 10.0101C18.25 9.4801 16.72 9.8801 15.92 10.9501C15.78 11.1401 15.51 11.2401 15.32 11.2501C15.08 11.2501 14.86 11.1401 14.72 10.9501C14.15 10.1901 13.28 9.7601 12.34 9.7601C10.71 9.7601 9.38 11.1001 9.38 12.7501C9.38 15.3501 10.87 17.2001 12.13 18.3101C12.31 18.4701 12.4 18.7001 12.38 18.9401C12.36 19.1801 12.23 19.3901 12.02 19.5201C11.91 19.5801 11.82 19.6201 11.77 19.6301C11.51 19.7101 11.22 19.7501 10.92 19.7501ZM6.95999 3.8501C4.63999 3.8501 2.75 5.75009 2.75 8.09009C2.75 13.3201 7.10001 16.5401 9.60001 17.7801C8.65001 16.5101 7.87 14.8301 7.87 12.7401C7.87 10.2601 9.86999 8.25009 12.33 8.25009C13.45 8.25009 14.5 8.66011 15.31 9.39011C16.32 8.49011 17.76 8.08008 19.1 8.33008C19.1 8.25008 19.1 8.1701 19.1 8.1001C19.1 5.7601 17.21 3.86008 14.89 3.86008C13.56 3.86008 12.33 4.48008 11.52 5.55008C11.38 5.74008 11.16 5.8501 10.92 5.8501C10.68 5.8501 10.46 5.74008 10.32 5.55008C9.53001 4.47008 8.29999 3.8501 6.95999 3.8501Z"
      fill="currentColor"
    />
    <path
      d="M15.3201 21.65C15.0901 21.65 14.8501 21.62 14.6601 21.55C13.8001 21.26 12.4101 20.55 11.1401 19.42C9.66014 18.11 7.89014 15.89 7.89014 12.74C7.89014 10.26 9.89013 8.25 12.3501 8.25C13.4701 8.25 14.5201 8.66001 15.3301 9.39001C16.5901 8.26001 18.5101 7.94 20.0901 8.63C21.7101 9.35 22.7601 10.97 22.7601 12.75C22.7601 17.49 18.6001 20.67 15.9801 21.56C15.7801 21.62 15.5501 21.65 15.3201 21.65ZM12.3401 9.75C10.7101 9.75 9.38013 11.09 9.38013 12.74C9.38013 15.34 10.8701 17.19 12.1301 18.3C13.2401 19.28 14.4101 19.89 15.1401 20.13C15.2201 20.16 15.4101 20.16 15.4801 20.13C16.9801 19.62 21.2501 17.17 21.2501 12.74C21.2501 11.55 20.5501 10.47 19.4801 10C18.2701 9.47 16.7201 9.87 15.9201 10.94C15.7801 11.13 15.5101 11.23 15.3201 11.24C15.0801 11.24 14.8601 11.13 14.7201 10.94C14.1501 10.18 13.2801 9.75 12.3401 9.75Z"
      fill="currentColor"
    />
  </svg>

</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
