<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.264 11.45A4.1 4.1 0 0013.904 4H6.6a.6.6 0 000 1.2h1v13.6h-1a.6.6 0 000 1.2h7.704a4.502 4.502 0 001.96-8.55zm-2.36.75H8.8v6.6h5.504a3.3 3.3 0 000-6.6h-.4zm.007-1.2a2.9 2.9 0 00-.007-5.8H8.8V11h5.111z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
