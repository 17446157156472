<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.299805 4.9998C0.299805 2.40407 2.40407 0.299805 4.9998 0.299805H12.9998V1.6998H4.9998C3.17727 1.6998 1.6998 3.17726 1.6998 4.9998V12.9998H0.299805V4.9998Z"
      fill="currentColor"
    />
  </svg>
</template>
