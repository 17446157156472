<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.383 12.505l.199-.199a14.88 14.88 0 003.147-5.541l.054-.176h1.85a.616.616 0 100-1.232h-5.43V4.244a.614.614 0 10-1.228 0v1.113H3.54a.61.61 0 100 1.219h9.14l-.116.332a13.892 13.892 0 01-2.788 4.714l-.186.207-.186-.207a13.905 13.905 0 01-1.796-2.506.581.581 0 00-.503-.304c-.409 0-.646.41-.466.735a15.082 15.082 0 001.988 2.786l.16.177-3.945 3.898a.612.612 0 10.862.868L9.59 13.39l1.926 1.926a.602.602 0 10.848-.854l-1.98-1.957zM14.706 17.812h4.448l.836 2.228a.559.559 0 101.046-.392l-3.173-8.463a.996.996 0 00-1.866 0l-3.173 8.463a.559.559 0 101.046.392l.836-2.228zm.46-1.228l1.764-4.706 1.764 4.706h-3.528z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
