class FileObject {
  constructor() {
    this.file = null
    this.fileType = ''
    this.mediaId = ''
    this.name = ''
    this.size = 0
    this.type = ''
    this.uploadType = ''
    this.progress = 0
    this.requestKey = null
    this.downloadUrl = ''
    this.status = null
    this.failedStatus = null
    this.urls = []
    this.uploadedParts = []
  }

  fromData(data) {
    this.file = data.file ? data.file : data
    this.fileType = data.fileType ? data.fileType : ''
    this.name = data.name || ''
    this.size = data.size || ''
    this.type = data.type || ''
    this.mediaId = data.mediaId || ''
    this.uploadType = data.uploadType || ''
    this.progress = data.progress || 0
    this.requestKey = data.requestKey || null
    this.downloadUrl = data.downloadUrl || ''
    this.status = data.status || null
    this.failedStatus = data.failedStatus || null
    this.urls = data.urls || []
    this.uploadedParts = data.uploadedParts || []
  }
}

export default FileObject
