<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.39059 6.60677C8.56073 5.7769 8.56073 4.43143 9.39059 3.60156L10.8932 2.09896C11.7231 1.2691 13.0685 1.2691 13.8984 2.09896L15.401 3.60156C16.2309 4.43143 16.2309 5.7769 15.401 6.60677L13.8984 8.10937C13.0685 8.93923 11.7231 8.93923 10.8932 8.10937L9.39059 6.60677ZM10.1419 5.85546C9.72696 5.44053 9.72696 4.7678 10.1419 4.35286L11.6445 2.85026C12.0594 2.43533 12.7322 2.43533 13.1471 2.85026L14.6497 4.35286C15.0646 4.7678 15.0646 5.44053 14.6497 5.85547L13.1471 7.35807C12.7322 7.773 12.0594 7.773 11.6445 7.35807L10.1419 5.85546Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.41666 4.04166C1.41666 2.86806 2.36806 1.91666 3.54166 1.91666H5.66666C6.84027 1.91666 7.79166 2.86806 7.79166 4.04166V6.16666C7.79166 7.34027 6.84027 8.29166 5.66666 8.29166H3.54166C2.36806 8.29166 1.41666 7.34027 1.41666 6.16666V4.04166ZM3.54166 2.97916H5.66666C6.25347 2.97916 6.72916 3.45486 6.72916 4.04166V6.16666C6.72916 6.75347 6.25347 7.22916 5.66666 7.22916H3.54166C2.95486 7.22916 2.47916 6.75347 2.47916 6.16666V4.04166C2.47916 3.45486 2.95486 2.97916 3.54166 2.97916Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.54166 9.70833C2.36806 9.70833 1.41666 10.6597 1.41666 11.8333V13.9583C1.41666 15.1319 2.36806 16.0833 3.54166 16.0833H5.66666C6.84027 16.0833 7.79166 15.1319 7.79166 13.9583V11.8333C7.79166 10.6597 6.84027 9.70833 5.66666 9.70833H3.54166ZM5.66666 10.7708H3.54166C2.95486 10.7708 2.47916 11.2465 2.47916 11.8333V13.9583C2.47916 14.5451 2.95486 15.0208 3.54166 15.0208H5.66666C6.25347 15.0208 6.72916 14.5451 6.72916 13.9583V11.8333C6.72916 11.2465 6.25347 10.7708 5.66666 10.7708Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.20833 11.8333C9.20833 10.6597 10.1597 9.70833 11.3333 9.70833H13.4583C14.6319 9.70833 15.5833 10.6597 15.5833 11.8333V13.9583C15.5833 15.1319 14.6319 16.0833 13.4583 16.0833H11.3333C10.1597 16.0833 9.20833 15.1319 9.20833 13.9583V11.8333ZM11.3333 10.7708H13.4583C14.0451 10.7708 14.5208 11.2465 14.5208 11.8333V13.9583C14.5208 14.5451 14.0451 15.0208 13.4583 15.0208H11.3333C10.7465 15.0208 10.2708 14.5451 10.2708 13.9583V11.8333C10.2708 11.2465 10.7465 10.7708 11.3333 10.7708Z"
      fill="currentColor"
    />
  </svg>
</template>
