import AdBreaks from '@/models/ad-schedules/AdBreaks'

export default {
  adBreaks: [],
  adBreak: new AdBreaks(),
  isLoading: true,
  isAdd: true,
  options: {
    mediationOptions: undefined,
    bidderOptions: undefined,
  },
}
