export default {
  // # COUNTRIES
  SET_TOP_COUNTRIES(state, data) {
    state.topCountries = data
    state.topCountriesGroupedData = data.map(entry => ({
      names: entry?.countryName,
      values: entry?.countryNr,
    }))
  },

  LOADING_TOP_COUNTRIES: (state, value) => { state.loadingTopCountries = value },

  // # DEVICES
  SET_TOP_DEVICES(state, data) {
    state.topDevices = data
    state.topDevicesGroupedData = {
      deviceType: data.map(entry => entry?.deviceType),
      deviceTypeNr: data.map(entry => entry?.deviceTypeNr),
    }
  },

  LOADING_TOP_DEVICES: (state, value) => { state.loadingTopDevices = value },

  // # TOP VIDEOS
  SET_TOP_VIDEOS(state, data) {
    state.topVideos = data
  },

  LOADING_TOP_VIDEOS: (state, value) => { state.loadingTopVideos = value },

  // # TOP PODCASTS
  SET_TOP_PODCASTS(state, data) {
    state.topPodcasts = data
  },

  LOADING_TOP_PODCASTS: (state, value) => { state.loadingTopPodcasts = value },

  // # TOP AUDIOS
  SET_TOP_AUDIOS(state, data) {
    state.topAudios = data
  },

  LOADING_TOP_AUDIOS: (state, value) => { state.loadingTopAudios = value },

  // # TOP LIVE VIDEOS
  SET_TOP_LIVE_VIDEOS(state, data) {
    state.topLiveVideos = data
  },

  LOADING_TOP_LIVE_VIDEOS: (state, value) => { state.loadingTopLiveVideos = value },

  // # VIDEO LIVE CONSUMPTION
  SET_VIDEO_LIVE_CONSUMPTION(state, data) {
    state.videoLiveConsumption = data?.liveViewers

    if (Array.isArray(data?.liveViewers)) {
      state.videoLiveConsumptionGroupedData = {
        data: data.liveViewers.map(entry => entry?.uniqueViewers).reverse(),
        date: data.liveViewers.map(entry => entry?.date).reverse(),
      }
    } else {
      state.videoLiveConsumptionGroupedData = {
        data: [],
        date: [],
      }
    }

    state.videoLiveConsumptionTotal = data?.total
  },

  LOADING_VIDEO_LIVE_CONSUMPTION: (state, value) => { state.loadingVideoLiveConsumption = value },

  // # AUDIO LIVE CONSUMPTION
  SET_AUDIO_LIVE_CONSUMPTION(state, data) {
    state.audioLiveConsumption = data?.liveViewers

    if (Array.isArray(data?.liveViewers)) {
      state.audioLiveConsumptionGroupedData = {
        data: data.liveViewers.map(entry => entry?.uniqueViewers).reverse(),
        date: data.liveViewers.map(entry => entry?.date).reverse(),
      }
    } else {
      state.audioLiveConsumptionGroupedData = {
        data: [],
        date: [],
      }
    }

    state.audioLiveConsumptionTotal = data?.total
  },

  LOADING_AUDIO_LIVE_CONSUMPTION: (state, value) => { state.loadingAudioLiveConsumption = value },

  // DESTROY DATA
  DESTROY_DATA(state) {
    state.topCountriesGroupedData = {}
    state.topCountries = []
    state.topDevicesGroupedData = {}
    state.topDevices = []
    state.topVideos = []
    state.topPodcasts = []
    state.topAudios = []
    state.topLiveVideos = []
    state.videoLiveConsumption = []
    state.videoLiveConsumptionGroupedData = {}
    state.videoLiveConsumptionTotal = 0
    state.audioLiveConsumption = []
    state.audioLiveConsumptionGroupedData = {}
    state.audioLiveConsumptionTotal = 0
  },

  LOADING_VIDEOS(state, value) {
    state.loadingVideos = value
  },
  SET_VIDEOS: (state, data) => {
    state.videos = data
  },
}
