<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.187 10.834a2.807 2.807 0 115.614 0 2.807 2.807 0 01-5.614 0zm2.807-1.607a1.607 1.607 0 100 3.214 1.607 1.607 0 000-3.214z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.081 5.385a.4.4 0 00-.587.244l-.031.116a3.6 3.6 0 01-2.558 2.558l-.116.03a.4.4 0 00-.244.588l.06.105a3.6 3.6 0 010 3.617l-.06.104a.4.4 0 00.244.588l.116.03c.68.181 1.28.552 1.738 1.055a.599.599 0 01.358.464c.206.315.363.665.462 1.04l.03.116a.4.4 0 00.588.243l.105-.06a3.6 3.6 0 013.617 0l.104.06a.4.4 0 00.588-.243l.03-.116c.1-.375.257-.725.462-1.04a.599.599 0 01.358-.464 3.598 3.598 0 011.739-1.054l.116-.031a.4.4 0 00.243-.588l-.06-.104a3.6 3.6 0 010-3.617l.06-.105a.4.4 0 00-.243-.587l-.116-.031a3.6 3.6 0 01-2.558-2.558l-.031-.117a.4.4 0 00-.588-.243l-.104.06a3.6 3.6 0 01-3.617 0l-.105-.06zm6.287 9.706a2.399 2.399 0 011.023-.565l.116-.031a1.6 1.6 0 00.974-2.35l-.06-.105a2.4 2.4 0 010-2.411l.06-.105a1.6 1.6 0 00-.974-2.35l-.116-.031a2.4 2.4 0 01-1.705-1.705l-.031-.117a1.6 1.6 0 00-2.35-.973l-.105.06a2.4 2.4 0 01-2.411 0l-.105-.06a1.6 1.6 0 00-2.35.973l-.031.117a2.4 2.4 0 01-1.705 1.705l-.117.03a1.6 1.6 0 00-.973 2.351l.06.105a2.4 2.4 0 010 2.411l-.06.104a1.6 1.6 0 00.973 2.35l.117.032c.39.103.739.3 1.023.566l-1.099 1.726a1.6 1.6 0 00.527 2.231l.985.591a1.6 1.6 0 002.227-.606l1.099-2.013a2.402 2.402 0 011.269 0l1.098 2.013a1.6 1.6 0 002.228.606l.985-.59a1.6 1.6 0 00.527-2.232l-1.1-1.727zm-.687 1.157l-.026.1a1.599 1.599 0 01-1.382 1.182l.508.93a.4.4 0 00.557.151l.985-.59a.4.4 0 00.131-.558l-.773-1.215zm-8.146 1.215l.772-1.215.027.1c.179.675.75 1.115 1.381 1.182l-.507.93a.4.4 0 01-.557.151l-.985-.59a.4.4 0 01-.131-.558z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
