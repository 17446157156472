<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    :width="size"
    :height="size"
    style="fill: currentColor"
    viewBox="0,0,256,256"
  >
    <g transform="translate(-44.8,-44.8) scale(1.35,1.35)"><g
      fill="none"
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    ><g transform="scale(5.33333,5.33333)"><path
      d="M37,42h-26c-2.761,0 -5,-2.239 -5,-5v-26c0,-2.761 2.239,-5 5,-5h26c2.761,0 5,2.239 5,5v26c0,2.761 -2.239,5 -5,5z"
      fill="#ff6f00"
    /><path
      d="M33.5,22h-1c-0.828,0 -1.5,-0.672 -1.5,-1.5v-0.5c0,-3.866 -3.134,-7 -7,-7h-4c-3.866,0 -7,3.134 -7,7v8c0,3.866 3.134,7 7,7h8c3.866,0 7,-3.134 7,-7v-4.5c0,-0.828 -0.672,-1.5 -1.5,-1.5zM20,19h5c0.553,0 1,0.448 1,1c0,0.552 -0.447,1 -1,1h-5c-0.553,0 -1,-0.448 -1,-1c0,-0.552 0.447,-1 1,-1zM28,29h-8c-0.553,0 -1,-0.448 -1,-1c0,-0.552 0.447,-1 1,-1h8c0.553,0 1,0.448 1,1c0,0.552 -0.447,1 -1,1z"
      fill="#ffffff"
    /></g></g></g>
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
