<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.771 4.454A3.6 3.6 0 0115.317 3.4H18A2.6 2.6 0 0120.6 6v2.683a3.6 3.6 0 01-1.054 2.546l-7.782 7.781a2.6 2.6 0 01-3.677 0L4.99 15.913a2.6 2.6 0 010-3.677l7.781-7.782zm2.546.146a2.4 2.4 0 00-1.697.703l-7.781 7.781a1.4 1.4 0 000 1.98l3.097 3.098a1.4 1.4 0 001.98 0l7.781-7.782a2.4 2.4 0 00.703-1.697V6A1.4 1.4 0 0018 4.6h-2.683z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.445 6.97a.585.585 0 100 1.17.585.585 0 000-1.17zm-1.786.586a1.785 1.785 0 113.57 0 1.785 1.785 0 01-3.57 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
