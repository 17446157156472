<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.922 6.942a2.411 2.411 0 10-3.41 3.41 2.411 2.411 0 003.41-3.41zm-2.561.848a1.211 1.211 0 111.713 1.713 1.211 1.211 0 01-1.713-1.713z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.003 3.5a3.6 3.6 0 00-2.546 1.054L6.87 10.142H5.307c-.98 0-1.47 1.184-.778 1.877l7.35 7.35c.693.693 1.878.202 1.878-.778v-1.563l5.587-5.587a3.6 3.6 0 001.054-2.546V7.1a3.6 3.6 0 00-3.6-3.6h-1.795zm-1.697 1.903a2.4 2.4 0 011.697-.703h1.795a2.4 2.4 0 012.4 2.4v1.795a2.4 2.4 0 01-.703 1.697l-5.188 5.188-5.189-5.19 5.188-5.187zm-6.134 5.939H5.55l7.008 7.008v-1.624l-5.385-5.384z"
    />
    <path
      d="M7.244 16.66a.6.6 0 01-.006.848l-2.513 2.48a.6.6 0 01-.843-.854l2.514-2.48a.6.6 0 01.848.006zM5.43 15.694a.6.6 0 10-.849-.848l-.905.905a.6.6 0 10.848.849l.906-.906zM9.053 18.468a.6.6 0 010 .849l-.906.905a.6.6 0 01-.849-.848l.906-.906a.6.6 0 01.849 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
