<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.054 10.149a.6.6 0 01.82-.218 2.885 2.885 0 01-.002 4.997.6.6 0 11-.6-1.039 1.685 1.685 0 000-2.92.6.6 0 01-.218-.82z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.193 8.167a.6.6 0 01.82-.219 5.17 5.17 0 012.587 4.48 5.17 5.17 0 01-2.588 4.481.6.6 0 11-.6-1.039A3.97 3.97 0 0019.4 12.43a3.97 3.97 0 00-1.988-3.442.6.6 0 01-.219-.82zM8.571 8.4a.6.6 0 01.6.6v6.857a.6.6 0 01-1.2 0V9a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.795 6.711c1.723-1.206 4.09.027 4.09 2.13v7.175c0 2.103-2.367 3.336-4.09 2.13l-2.413-1.689H7.43a4.029 4.029 0 010-8.057h.953l2.413-1.689zm2.89 2.13c0-1.132-1.274-1.796-2.202-1.147L8.76 9.6H7.429a2.829 2.829 0 100 5.657H8.76l2.722 1.906c.928.65 2.203-.015 2.203-1.147V8.84z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
