<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.136 4.948a2.6 2.6 0 00-3.51 1.454l-.27.72a.4.4 0 01-.234.233l-.72.27a2.6 2.6 0 00-1.454 3.51l.318.7a.4.4 0 010 .33l-.318.7a2.6 2.6 0 001.454 3.51l.72.27a.4.4 0 01.234.234l.27.719a2.6 2.6 0 003.51 1.454l.285-.13a1.4 1.4 0 011.158 0l.286.13a2.6 2.6 0 003.51-1.454l.27-.72a.4.4 0 01.234-.233l.719-.27a2.6 2.6 0 001.454-3.51l-.318-.7a.4.4 0 010-.33l.318-.7a2.6 2.6 0 00-1.454-3.51l-.72-.27a.4.4 0 01-.233-.234l-.27-.719a2.6 2.6 0 00-3.51-1.454l-.7.318a.4.4 0 01-.33 0l-.7-.318zm.33 5.452a1.067 1.067 0 11-2.132 0 1.067 1.067 0 012.133 0zm3.2 3.2a1.067 1.067 0 11-2.132 0 1.067 1.067 0 012.133 0zm-1.443-3.577a.533.533 0 11.754.754l-3.2 3.2a.533.533 0 01-.754-.754l3.2-3.2z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
