export default {
  SET_BILLINGS: (state, data) => { state.billings = data },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },
  UPDATE_FILTER: (state, data) => {
    state.filters.params[data.propKey] = data.value
  },
  UPDATE_PAGINATION: (state, data) => {
    state.pagination[data.propKey] = data.value
  },

  SET_INVOICE_DATA(state, data) {
    state.invoiceData = data
  },

}
