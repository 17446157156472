<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.39 5.753c-.85-.568-1.719-.937-2.609-1.086-.872-.146-2.278-.23-4.19-.26v-.001L7.988 4.4a1.984 1.984 0 00-1.943 1.505c-.094.376-.04.754.123 1.074-.33.252-.575.617-.674 1.048-.085.376-.027.752.14 1.07a1.824 1.824 0 00-.665 1.04c-.085.376-.027.75.139 1.068-.327.251-.57.614-.667 1.042a1.663 1.663 0 001.25 1.991l.028.007 3.358.704-.591 2.31a1.875 1.875 0 001.446 2.304 1.904 1.904 0 002.24-1.496v-.002c.362-1.815.877-3.105 1.507-3.928.477-.624 1.016-.98 1.63-1.104.157.43.57.739 1.057.739h2.106c.623 0 1.127-.506 1.127-1.128v-6.33c0-.621-.504-1.127-1.127-1.127h-2.106c-.418 0-.783.228-.977.566z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
