<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.832 6a1.6 1.6 0 113.2 0v1.2a1.602 1.602 0 011.913 1.026 1.6 1.6 0 012.064 1.247c.154-.047.319-.073.49-.073.83 0 1.559.622 1.62 1.488.065.938.114 2.46-.092 3.945-.175 1.256-.52 2.4-.808 3.204-.354.989-1.302 1.563-2.295 1.563h-3.569c-1.11 0-2.08-.703-2.479-1.712-.292-.74-.652-1.575-.957-2.076-.435-.717-1.808-2.335-2.624-3.273a1.607 1.607 0 01-.023-2.083 1.598 1.598 0 012.357-.107l1.203 1.203V6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
