<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.7 4.155C9.7 2.925 10.778 2 12.006 2c1.228 0 2.298.926 2.298 2.155 0 .709-.36 1.323-.898 1.71v1.106h3.586c1.606 0 3.002 1.203 3.002 2.787v.285h.106c1.018 0 1.901.782 1.901 1.802v2.788c0 1.024-.887 1.797-1.901 1.797h-.107v.29c0 1.587-1.396 2.781-3.001 2.781h-.674v.708c0 1.027-.891 1.791-1.902 1.791H9.59c-1.007 0-1.908-.76-1.908-1.79v-.71h-.667c-1.611 0-2.98-1.199-2.98-2.78v-.29h-.129C2.895 16.43 2 15.659 2 14.633v-2.788c0-1.024.893-1.802 1.907-1.802h.129v-.285c0-1.579 1.367-2.787 2.98-2.787h3.579V5.866a2.107 2.107 0 01-.894-1.71zM12.006 3c-.76 0-1.305.557-1.305 1.155 0 .41.238.784.63.993a.5.5 0 01.265.442V7.47a.5.5 0 01-.5.5h-4.08c-1.137 0-1.98.834-1.98 1.787v.785a.5.5 0 01-.5.5h-.628c-.534 0-.907.4-.907.802v2.788c0 .399.37.797.907.797h.629a.5.5 0 01.5.5v.79c0 .951.84 1.78 1.98 1.78h1.167a.5.5 0 01.5.5v1.21c0 .395.367.79.908.79h4.824c.538 0 .902-.392.902-.79V19a.5.5 0 01.5-.5h1.174c1.145 0 2.002-.835 2.002-1.78v-.79a.5.5 0 01.5-.5h.606c.534 0 .901-.396.901-.798v-2.788c0-.405-.37-.802-.901-.802h-.607a.5.5 0 01-.5-.5v-.785c0-.947-.858-1.787-2.001-1.787h-4.086a.5.5 0 01-.5-.5V5.59a.5.5 0 01.265-.442c.394-.21.633-.586.633-.993 0-.6-.542-1.155-1.298-1.155z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.76 15.2c-.509 0-.846.368-.846.736 0 .37.337.736.846.736h4.487c.508 0 .846-.368.846-.736s-.337-.736-.846-.736H9.76zm-1.846.736c0-1 .868-1.736 1.846-1.736h4.487c.978 0 1.846.735 1.846 1.736 0 1-.868 1.736-1.846 1.736H9.76c-.978 0-1.846-.735-1.846-1.736zM15.67 10.397a1 1 0 100 2 1 1 0 000-2zm-2 1a2 2 0 114 0 2 2 0 01-4 0zM8.67 10.397a1 1 0 100 2 1 1 0 000-2zm-2 1a2 2 0 114 0 2 2 0 01-4 0z"
    />
  </svg>
</template>
