<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 6.86A2.6 2.6 0 016 4.26h12a2.6 2.6 0 012.6 2.6v8.571a2.6 2.6 0 01-2.6 2.6H6a2.6 2.6 0 01-2.6-2.6V6.86zM6 5.46a1.4 1.4 0 00-1.4 1.4v8.571a1.4 1.4 0 001.4 1.4h12a1.4 1.4 0 001.4-1.4V6.86a1.4 1.4 0 00-1.4-1.4H6zM8.543 19.146a.6.6 0 01.6-.6h5.714a.6.6 0 010 1.2H9.143a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.297 7.479a.6.6 0 01.224.818l-1.956 3.43a1.6 1.6 0 01-2.162.609l-1.468-.81a.4.4 0 00-.519.119L8.49 14.348a.6.6 0 01-.978-.696l1.928-2.704a1.6 1.6 0 012.075-.472l1.468.809a.4.4 0 00.54-.152l1.957-3.43a.6.6 0 01.818-.224z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
