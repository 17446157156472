<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 5.246a2.6 2.6 0 00-2.6 2.6v1.708h17.2V7.846a2.6 2.6 0 00-2.6-2.6H6zM20.6 10.754H3.4V18A2.6 2.6 0 006 20.6h12a2.6 2.6 0 002.6-2.6v-7.246z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.692 3.4a.6.6 0 01.6.6v3.692a.6.6 0 01-1.2 0V4a.6.6 0 01.6-.6zM16.308 3.4a.6.6 0 01.6.6v3.692a.6.6 0 11-1.2 0V4a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.309 13.538a.923.923 0 11-1.846 0 .923.923 0 011.846 0zm2.155 4a.923.923 0 100-1.846.923.923 0 000 1.846zm-3.078 0a.923.923 0 100-1.846.923.923 0 000 1.846zm7.077-.923a.923.923 0 11-1.846 0 .923.923 0 011.846 0zm2.154.924a.923.923 0 100-1.847.923.923 0 000 1.846zm-6.153-3.078a.923.923 0 100-1.846.923.923 0 000 1.846zm4-.923a.923.923 0 11-1.847 0 .923.923 0 011.846 0zm2.152.923a.923.923 0 100-1.846.923.923 0 000 1.846z"
    />
  </svg>
</template>
