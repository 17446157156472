<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.456 5.44A.75.75 0 018.7 4.875l8.927 7.8c.64.56.28 1.613-.567 1.664l-2.412.146 1.357 2.938a.75.75 0 01-.393 1.006l-2.409 1.005a.75.75 0 01-.97-.378l-1.385-3.002-1.808 1.644c-.61.555-1.59.121-1.59-.704L7.456 5.44zm1.1.77l-.005 10.445 2.666-2.424 1.874 4.057 1.763-.734-1.88-4.07 3.654-.221L8.556 6.21z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
