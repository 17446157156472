<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.886 8.161c0-2.18-2.522-3.392-4.224-2.03l-2.3 1.84h-.933a4.029 4.029 0 000 8.058h.932l2.3 1.84c1.703 1.362 4.225.15 4.225-2.03V8.16z"
    />
    <path
      d="M17.568 10.147a.6.6 0 00-.849.849L17.724 12l-1.005 1.004a.6.6 0 00.849.849l1.004-1.005 1.004 1.005a.6.6 0 00.849-.849L19.42 12l1.004-1.004a.6.6 0 10-.849-.849l-1.004 1.004-1.004-1.004z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
