<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.85355 1.64645C5.04882 1.84171 5.04882 2.15829 4.85355 2.35355L2.85355 4.35355C2.65829 4.54882 2.34171 4.54882 2.14645 4.35355C1.95118 4.15829 1.95118 3.84171 2.14645 3.64645L4.14645 1.64645C4.34171 1.45118 4.65829 1.45118 4.85355 1.64645Z"
      fill="currentColor"
    />
    <path
      d="M6 8.66667C6 8.39052 6.22386 8.16667 6.5 8.16667H8V6.66667C8 6.39052 8.22386 6.16667 8.5 6.16667C8.77614 6.16667 9 6.39052 9 6.66667V8.16667H10.5C10.7761 8.16667 11 8.39052 11 8.66667C11 8.94281 10.7761 9.16667 10.5 9.16667H9V10.6667C9 10.9428 8.77614 11.1667 8.5 11.1667C8.22386 11.1667 8 10.9428 8 10.6667V9.16667H6.5C6.22386 9.16667 6 8.94281 6 8.66667Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 14.6667C11.8137 14.6667 14.5 11.9804 14.5 8.66667C14.5 5.35296 11.8137 2.66667 8.5 2.66667C5.18629 2.66667 2.5 5.35296 2.5 8.66667C2.5 11.9804 5.18629 14.6667 8.5 14.6667ZM8.5 13.6667C11.2614 13.6667 13.5 11.4281 13.5 8.66667C13.5 5.90524 11.2614 3.66667 8.5 3.66667C5.73858 3.66667 3.5 5.90524 3.5 8.66667C3.5 11.4281 5.73858 13.6667 8.5 13.6667Z"
      fill="currentColor"
    />
    <path
      d="M12.1464 2.35355C11.9512 2.15829 11.9512 1.84171 12.1464 1.64645C12.3417 1.45118 12.6583 1.45118 12.8536 1.64645L14.8536 3.64645C15.0488 3.84171 15.0488 4.15829 14.8536 4.35355C14.6583 4.54882 14.3417 4.54882 14.1464 4.35355L12.1464 2.35355Z"
      fill="currentColor"
    />
  </svg>
</template>
