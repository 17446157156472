<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.98011 9.64648C5.17537 9.45121 5.49195 9.45121 5.68721 9.64648L6.83365 10.7929V7.33353C6.83365 7.05738 7.05751 6.83353 7.33365 6.83353C7.60979 6.83353 7.83365 7.05738 7.83365 7.33353V10.7929L8.9801 9.64648C9.17537 9.45121 9.49195 9.45121 9.68721 9.64648C9.88247 9.84174 9.88247 10.1583 9.68721 10.3536L7.92291 12.1179C7.59748 12.4433 7.06984 12.4433 6.7444 12.1179L4.98011 10.3536C4.78484 10.1583 4.78484 9.84174 4.98011 9.64648Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3337 12.6666C13.3337 13.7712 12.4382 14.6666 11.3337 14.6666H4.66699C3.56242 14.6666 2.66699 13.7712 2.66699 12.6666V3.33331C2.66699 2.22874 3.56242 1.33331 4.66699 1.33331H7.83856C8.369 1.33331 8.87771 1.54403 9.25278 1.9191L12.7479 5.41419C13.1229 5.78927 13.3337 6.29797 13.3337 6.82841V12.6666ZM12.3337 12.6666C12.3337 13.2189 11.8859 13.6666 11.3337 13.6666H4.66699C4.11471 13.6666 3.66699 13.2189 3.66699 12.6666V3.33331C3.66699 2.78103 4.11471 2.33331 4.66699 2.33331H7.33366C7.51775 2.33331 7.66699 2.48255 7.66699 2.66665V4.66665C7.66699 5.95531 8.71166 6.99998 10.0003 6.99998H12.0003C12.1844 6.99998 12.3337 7.14922 12.3337 7.33331V12.6666ZM8.66699 2.74753L11.9194 5.99998H10.0003C9.26395 5.99998 8.66699 5.40303 8.66699 4.66665V2.74753Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
