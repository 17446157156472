<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.872 14.406c-1.85-1.271-1.85-4.002 0-5.274l2.27-1.56c-.69-1.287-2.42-1.84-3.769-.912L5.057 9.627a2.6 2.6 0 000 4.285l4.316 2.967c1.35.927 3.079.374 3.77-.912l-2.271-1.56z"
    />
    <path
      d="M11.552 10.121a2 2 0 000 3.296l4.316 2.967c1.327.913 3.133-.037 3.133-1.648V8.802c0-1.61-1.806-2.56-3.133-1.648l-4.316 2.967z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
