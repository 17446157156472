<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <!-- image -->
        <b-img
          fluid
          :src="downImg"
          alt="Error page"
        />

        <h2 class="mb-1 text-secondary">
          Bad Request
        </h2>

        <p>{{ pageName }} isn’t working at the moment.</p>

        <div class="mt-3">
          <b-button
            variant="bg-white"
            class="mb-2 mr-1 btn-sm-block"
            @click="$router.go()"
          >
            Refresh Page
          </b-button>

          <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{name:'dashboard', params: {projectId: activeProjectId}}"
          >
            <span class="text-main">
              Return to Homepage
            </span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
import fetchUserData from '@/services/mixins/fetchUserData'
import { logoutUser } from '@/auth'
/* eslint-disable global-require */

export default {
  data() {
    return {
      downImg: require('@/assets/images/pages/error-400.svg'),
    }
  },
  computed: {
    pageName() {
      return this.$route.meta.pageTitle || 'This page'
    },
    activeOrganizationId() {
      const activeOrganization = this.$store.getters['organizationModule/getActiveOrganizationId']
      return activeOrganization || null
    },
    activeProjectId() {
      return this.$store.state.projectModule.activeProjectId || null
    },
  },
  async mounted() {
    await fetchUserData(this.activeOrganizationId, this.activeProjectId).catch(error => { this.logout(); console.error(error) })
  },
  methods: {
    logout() {
      logoutUser('auth-login')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-misc.scss';
</style>
