/* eslint-disable no-unused-vars */
export default [
  {
    path: '/:projectId/video-players',
    name: 'video-players',
    component: () => import('@/views/players/video/Players.vue'),
    meta: {
      titleText: 'Video Players',
      parentText: 'Dashboard',
      contentClass: 'players',
      navActiveLink: 'video-players',
      gjIcon: 'Player',
      action: 'read',
      resource: 'player',
      breadcrumb: [
        {
          text: 'Video Players',
          to: 'video-players',
          active: true,
        },
      ],
    },
  },
  {
    path: '',
    component: () => import('@/views/players/video/Add.vue'),
    children: [
      {
        path: '/:projectId/video-players/add/customize',
        name: 'video-players-customize-add',
        component: () => import('@/views/players/components/Customize.vue'),
        props: {
          module: 'videoPlayersModule',
          colorsSectionIcons: ['Filters', 'Settings', 'IconparkCarouselVideo', 'Forward', 'CommentAlt'],
        },
        meta: {
          titleText: 'Video Players',
          parentText: 'Dashboard',
          contentClass: 'player',
          navActiveLink: 'video-players',
          gjIcon: 'Player',
          action: 'write',
          resource: 'player',
          breadcrumb: [
            {
              text: 'Video Players',
              to: 'video-players',
            },
            {
              text: 'New video player',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/video-players/add/advertising',
        name: 'video-players-advertising-add',
        component: () => import('@/views/players/video/tabs/Advertising.vue'),
        meta: {
          titleText: 'Video Players',
          parentText: 'Dashboard',
          contentClass: 'player',
          navActiveLink: 'video-players',
          gjIcon: 'Player',
          action: 'write',
          resource: 'player',
          breadcrumb: [
            {
              text: 'Video Players',
              to: 'video-players',
            },
            {
              text: 'New video player',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/video-players/add/localization',
        name: 'video-players-localization-add',
        component: () => import('@/views/players/components/Localization.vue'),
        props: {
          moduleName: 'videoPlayersModule',
        },
        meta: {
          titleText: 'Video Players',
          parentText: 'Dashboard',
          contentClass: 'player',
          navActiveLink: 'video-players',
          gjIcon: 'Player',
          action: 'write',
          resource: 'player',
          breadcrumb: [
            {
              text: 'Video Players',
              to: 'video-players',
            },
            {
              text: 'New video player',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/video-players/add/:tab?',
        name: 'video-players-add',
        component: () => import('@/views/players/video/tabs/SetupTab.vue'),
        beforeEnter: (to, from, next) => {
          const { tab } = to.params
          const validGeneralValues = ['setup', undefined]

          if (!validGeneralValues.includes(tab)) {
            const errorPath = '/error-404'
            next(errorPath)
          } else {
            next()
          }
        },
        meta: {
          titleText: 'Video Players',
          parentText: 'Dashboard',
          contentClass: 'player',
          navActiveLink: 'video-players',
          gjIcon: 'Player',
          action: 'write',
          resource: 'player',
          breadcrumb: [
            {
              text: 'Video Players',
              to: 'video-players',
            },
            {
              text: 'New video player',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/video-players/:id/customize',
        name: 'video-players-customize-edit',
        component: () => import('@/views/players/components/Customize.vue'),
        props: {
          module: 'videoPlayersModule',
          colorsSectionIcons: ['Filters', 'Settings', 'IconparkCarouselVideo', 'Forward', 'CommentAlt'],
        },
        meta: {
          titleText: 'Video Players',
          parentText: 'Dashboard',
          contentClass: 'player',
          navActiveLink: 'video-players',
          gjIcon: 'Player',
          action: 'write',
          resource: 'player',
          breadcrumb: [
            {
              text: 'Video Players',
              to: 'video-players',
            },
            {
              dynamic: true,
              text: 'Edit Video Player',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/video-players/:id/advertising',
        name: 'video-players-advertising-edit',
        component: () => import('@/views/players/video/tabs/Advertising.vue'),
        meta: {
          titleText: 'Video Players',
          parentText: 'Dashboard',
          contentClass: 'player',
          navActiveLink: 'video-players',
          gjIcon: 'Player',
          action: 'write',
          resource: 'player',
          breadcrumb: [
            {
              text: 'Video Players',
              to: 'video-players',
            },
            {
              dynamic: true,
              text: 'Edit Video Player',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/video-players/:id/localization',
        name: 'video-players-localization-edit',
        component: () => import('@/views/players/components/Localization.vue'),
        props: {
          moduleName: 'videoPlayersModule',
        },
        meta: {
          titleText: 'Video Players',
          parentText: 'Dashboard',
          contentClass: 'player',
          navActiveLink: 'video-players',
          gjIcon: 'Player',
          action: 'write',
          resource: 'player',
          breadcrumb: [
            {
              text: 'Video Players',
              to: 'video-players',
            },
            {
              dynamic: true,
              text: 'Edit Video Player',
              active: true,
            },
          ],
        },
      },
      {
        path: '/:projectId/video-players/:id/:tab?',
        name: 'video-players-edit',
        component: () => import('@/views/players/video/tabs/SetupTab.vue'),
        beforeEnter: (to, from, next) => {
          const { tab } = to.params
          const validGeneralValues = ['setup', undefined]

          if (!validGeneralValues.includes(tab)) {
            const errorPath = '/error-404'
            next(errorPath)
          } else {
            next()
          }
        },
        meta: {
          titleText: 'Video Players',
          parentText: 'Dashboard',
          contentClass: 'player',
          navActiveLink: 'video-players',
          gjIcon: 'Player',
          action: 'write',
          resource: 'player',
          breadcrumb: [
            {
              text: 'Video Players',
              to: 'video-players',
            },
            {
              dynamic: true,
              text: 'Edit Video Player',
              active: true,
            },
          ],
        },
      },
    ],
  },

  // Vertical Players Routing
  {
    path: '/:projectId/vertical-players',
    component: () => import('@/views/players/vertical/Player.vue'),
    children: [
      {
        path: '',
        name: 'vertical-players-list',
        component: () => import('@/views/players/vertical/Players.vue'),
        meta: {
          titleText: 'Vertical Players',
          parentText: 'Dashboard',
          contentClass: 'vertical-players',
          navActiveLink: 'vertical-players-list',
          gjIcon: 'Player',
          action: 'read',
          resource: 'player',
          breadcrumb: [
            {
              text: 'Vertical Players',
              active: true,
            },
          ],
        },
      },
      {
        path: '',
        component: () => import('@/views/players/vertical/Add.vue'),
        children: [
          {
            path: '/:projectId/vertical-players/add/customize',
            name: 'vertical-players-customize-add',
            component: () => import('@/views/players/components/Customize.vue'),
            props: {
              module: 'verticalPlayersModule',
              colorsSectionIcons: ['Filters', 'Settings', 'IconparkCarouselVideo', 'Forward', 'CommentAlt'],
              hasLogo: false,
            },
            meta: {
              title: '',
              titleText: 'Vertical Players',
              parentText: 'Dashboard',
              contentClass: 'vertical-player',
              navActiveLink: 'vertical-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Vertical Players',
                  to: 'vertical-players-list',
                },
                {
                  text: 'New vertical player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/vertical-players/add/localization',
            name: 'vertical-players-localization-add',
            component: () => import('@/views/players/components/Localization.vue'),
            props: {
              moduleName: 'verticalPlayersModule',
            },
            meta: {
              title: '',
              titleText: 'Vertical Players',
              parentText: 'Dashboard',
              contentClass: 'vertical-player',
              navActiveLink: 'vertical-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Vertical Players',
                  to: 'vertical-players-list',
                },
                {
                  text: 'New vertical player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/vertical-players/add/:tab?',
            name: 'vertical-players-add',
            component: () => import('@/views/players/vertical/tabs/SetupTab.vue'),
            beforeEnter: (to, from, next) => {
              const { tab } = to.params
              const validGeneralValues = ['setup', undefined]

              if (!validGeneralValues.includes(tab)) {
                const errorPath = '/error-404'
                next(errorPath)
              } else {
                next()
              }
            },
            meta: {
              title: '',
              titleText: 'Vertical Players',
              parentText: 'Dashboard',
              contentClass: 'vertical-player',
              navActiveLink: 'vertical-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Vertical Players',
                  to: 'vertical-players-list',
                },
                {
                  text: 'New vertical player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/vertical-players/:id/customize',
            name: 'vertical-players-customize-edit',
            component: () => import('@/views/players/components/Customize.vue'),
            props: {
              module: 'verticalPlayersModule',
              colorsSectionIcons: ['Filters', 'Settings', 'IconparkCarouselVideo', 'Forward', 'CommentAlt'],
              hasLogo: false,
            },
            meta: {
              title: '',
              titleText: 'Vertical Players',
              parentText: 'Dashboard',
              contentClass: 'vertical-player',
              navActiveLink: 'vertical-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Vertical Players',
                  to: 'vertical-players-list',
                },
                {
                  dynamic: true,
                  text: 'Edit Vertical Player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/vertical-players/:id/localization',
            name: 'vertical-players-localization-edit',
            component: () => import('@/views/players/components/Localization.vue'),
            props: {
              moduleName: 'verticalPlayersModule',
            },
            meta: {
              title: '',
              titleText: 'Vertical Players',
              parentText: 'Dashboard',
              contentClass: 'vertical-player',
              navActiveLink: 'vertical-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Vertical Players',
                  to: 'vertical-players-list',
                },
                {
                  dynamic: true,
                  text: 'Edit Vertical Player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/vertical-players/:id/:tab?',
            name: 'vertical-players-edit',
            component: () => import('@/views/players/vertical/tabs/SetupTab.vue'),
            beforeEnter: (to, from, next) => {
              const { tab } = to.params
              const validGeneralValues = ['setup', undefined]

              if (!validGeneralValues.includes(tab)) {
                const errorPath = '/error-404'
                next(errorPath)
              } else {
                next()
              }
            },
            meta: {
              title: '',
              titleText: 'Vertical Players',
              parentText: 'Dashboard',
              contentClass: 'vertical-player',
              navActiveLink: 'vertical-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Vertical Players',
                  to: 'vertical-players-list',
                },
                {
                  dynamic: true,
                  text: 'Edit Vertical Player',
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },

  // Audio Players Routing
  {
    path: '/:projectId/audio-players',
    component: () => import('@/views/players/audio/Player.vue'),
    children: [
      {
        path: '',
        name: 'audio-players-list',
        component: () => import('@/views/players/audio/Players.vue'),
        meta: {
          titleText: 'Audio Players',
          parentText: 'Dashboard',
          contentClass: 'audio-players',
          navActiveLink: 'audio-players-list',
          gjIcon: 'Player',
          action: 'read',
          resource: 'player',
          breadcrumb: [
            {
              text: 'Audio Players',
              active: true,
            },
          ],
        },
      },
      {
        path: '',
        component: () => import('@/views/players/audio/Add.vue'),
        children: [
          {
            path: '/:projectId/audio-players/add/customize',
            name: 'audio-players-customize-add',
            component: () => import('@/views/players/components/Customize.vue'),
            props: {
              module: 'audioPlayersModule',
              colorsSectionIcons: ['Settings', 'Filters'],
            },
            meta: {
              title: '',
              titleText: 'Audio Players',
              parentText: 'Dashboard',
              contentClass: 'audio-player',
              navActiveLink: 'audio-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Audio Players',
                  to: 'audio-players-list',
                },
                {
                  text: 'New audio player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/audio-players/add/advertising',
            name: 'audio-players-advertising-add',
            component: () => import('@/views/players/audio/tabs/Advertising.vue'),
            meta: {
              title: '',
              titleText: 'Audio Players',
              parentText: 'Dashboard',
              contentClass: 'audio-player',
              navActiveLink: 'audio-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Audio Players',
                  to: 'audio-players-list',
                },
                {
                  text: 'New audio player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/audio-players/add/localization',
            name: 'audio-players-localization-add',
            component: () => import('@/views/players/components/Localization.vue'),
            props: {
              moduleName: 'audioPlayersModule',
            },
            meta: {
              title: '',
              titleText: 'Audio Players',
              parentText: 'Dashboard',
              contentClass: 'audio-player',
              navActiveLink: 'audio-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Audio Players',
                  to: 'audio-players-list',
                },
                {
                  text: 'New audio player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/audio-players/add/:tab?',
            name: 'audio-players-add',
            component: () => import('@/views/players/audio/tabs/SetupTab.vue'),
            beforeEnter: (to, from, next) => {
              const { tab } = to.params
              const validGeneralValues = ['setup', undefined]

              if (!validGeneralValues.includes(tab)) {
                const errorPath = '/error-404'
                next(errorPath)
              } else {
                next()
              }
            },
            meta: {
              title: '',
              titleText: 'Audio Players',
              parentText: 'Dashboard',
              contentClass: 'audio-player',
              navActiveLink: 'audio-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Audio Players',
                  to: 'audio-players-list',
                },
                {
                  text: 'New audio player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/audio-players/:id/customize',
            name: 'audio-players-customize-edit',
            component: () => import('@/views/players/components/Customize.vue'),
            props: {
              module: 'audioPlayersModule',
              colorsSectionIcons: ['Settings', 'Filters'],
            },
            meta: {
              title: '',
              titleText: 'Audio Players',
              parentText: 'Dashboard',
              contentClass: 'audio-player',
              navActiveLink: 'audio-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Audio Players',
                  to: 'audio-players-list',
                },
                {
                  dynamic: true,
                  text: 'Edit Audio Player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/audio-players/:id/advertising',
            name: 'audio-players-advertising-edit',
            component: () => import('@/views/players/audio/tabs/Advertising.vue'),
            meta: {
              title: '',
              titleText: 'Audio Players',
              parentText: 'Dashboard',
              contentClass: 'audio-player',
              navActiveLink: 'audio-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Audio Players',
                  to: 'audio-players-list',
                },
                {
                  dynamic: true,
                  text: 'Edit Audio Player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/audio-players/:id/localization',
            name: 'audio-players-localization-edit',
            component: () => import('@/views/players/components/Localization.vue'),
            props: {
              moduleName: 'audioPlayersModule',
            },
            meta: {
              title: '',
              titleText: 'Audio Players',
              parentText: 'Dashboard',
              contentClass: 'audio-player',
              navActiveLink: 'audio-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Audio Players',
                  to: 'audio-players-list',
                },
                {
                  dynamic: true,
                  text: 'Edit Audio Player',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/:projectId/audio-players/:id/:tab?',
            name: 'audio-players-edit',
            component: () => import('@/views/players/audio/tabs/SetupTab.vue'),
            beforeEnter: (to, from, next) => {
              const { tab } = to.params
              const validGeneralValues = ['setup', undefined]

              if (!validGeneralValues.includes(tab)) {
                const errorPath = '/error-404'
                next(errorPath)
              } else {
                next()
              }
            },
            meta: {
              title: '',
              titleText: 'Audio Players',
              parentText: 'Dashboard',
              contentClass: 'audio-player',
              navActiveLink: 'audio-players-list',
              gjIcon: 'Player',
              action: 'execute',
              resource: 'player',
              breadcrumb: [
                {
                  text: 'Audio Players',
                  to: 'audio-players-list',
                },
                {
                  dynamic: true,
                  text: 'Edit Audio Player',
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },

]
