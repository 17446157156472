<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.6 12a.6.6 0 01-.6.6H4a.6.6 0 110-1.2h16a.6.6 0 01.6.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.547 5.216a1.6 1.6 0 011.6 1.6V17.18a1.6 1.6 0 01-1.6 1.6H7.184a1.6 1.6 0 01-1.6-1.6V6.816a1.6 1.6 0 011.6-1.6h2.363zm.4 1.6a.4.4 0 00-.4-.4H7.184a.4.4 0 00-.4.4V17.18c0 .22.179.4.4.4h2.363a.4.4 0 00.4-.4V6.816zM16.82 8.216a1.6 1.6 0 011.6 1.6v4.546a1.6 1.6 0 01-1.6 1.6h-2.364a1.6 1.6 0 01-1.6-1.6V9.816a1.6 1.6 0 011.6-1.6h2.364zm.4 1.6a.4.4 0 00-.4-.4h-2.364a.4.4 0 00-.4.4v4.546c0 .22.18.4.4.4h2.364a.4.4 0 00.4-.4V9.816z"
    />
  </svg>
</template>
