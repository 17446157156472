<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.168 4.74a.4.4 0 00-.336 0L4.726 8.038l7.084 3.796a.4.4 0 00.378 0l7.084-3.796-7.105-3.298zm-.842-1.089a1.6 1.6 0 011.348 0l7.286 3.383a1.1 1.1 0 01.056 1.968l-7.26 3.89a1.6 1.6 0 01-1.512 0l-7.26-3.89a1.1 1.1 0 01.056-1.968l7.286-3.383z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.856 11.409a.6.6 0 01.812-.246l8.143 4.363a.4.4 0 00.378 0l8.143-4.363a.6.6 0 01.567 1.058l-8.144 4.363a1.6 1.6 0 01-1.51 0L3.1 12.22a.6.6 0 01-.245-.812z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.856 15.101a.6.6 0 01.812-.245l8.143 4.362a.4.4 0 00.378 0l8.143-4.362a.6.6 0 01.567 1.058l-8.144 4.362a1.6 1.6 0 01-1.51 0L3.1 15.914a.6.6 0 01-.245-.813z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
