<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.32067 7.73805L7.29439 2.38536C7.42928 2.14255 7.65372 1.94743 7.92749 1.86804C8.21578 1.78443 8.54374 1.83741 8.80036 2.07253C9.21167 2.44936 9.63118 3.08343 9.82379 4.04647C10.0329 5.09193 9.66715 6.07013 9.41292 6.75011C9.39169 6.80687 9.37125 6.86156 9.35198 6.91403C9.34535 6.93207 9.34692 6.93988 9.34696 6.94013C9.34713 6.94121 9.3476 6.94357 9.35075 6.94766C9.35668 6.95537 9.38049 6.97786 9.4355 6.97786H12.8748C14.1029 6.97786 14.9837 8.16171 14.6308 9.338L13.4308 13.338C13.1982 14.1135 12.4844 14.6445 11.6748 14.6445H4.13403C3.8889 14.7517 3.61814 14.8112 3.3335 14.8112C2.22893 14.8112 1.3335 13.9157 1.3335 12.8112V9.47784C1.3335 8.37327 2.22893 7.47784 3.3335 7.47784C3.6925 7.47784 4.02941 7.57243 4.32067 7.73805ZM8.16854 2.87101C8.17096 2.86665 8.17347 2.86263 8.17602 2.85893C8.41405 3.0973 8.70046 3.52885 8.84321 4.24259C8.99407 4.99688 8.72782 5.7176 8.46161 6.43819L8.41327 6.56931C8.13525 7.32638 8.73706 7.97786 9.4355 7.97786H12.8748C13.433 7.97786 13.8334 8.51597 13.673 9.05065L12.473 13.0507C12.3672 13.4031 12.0428 13.6445 11.6748 13.6445H5.15214C5.26858 13.3908 5.3335 13.1086 5.3335 12.8112V9.47784C5.3335 9.10598 5.23201 8.75782 5.05522 8.45955C5.07321 8.43731 5.08954 8.41322 5.10391 8.38735L8.16854 2.87101ZM3.85055 13.6673C3.79898 13.6834 3.751 13.7077 3.70811 13.7386C3.59241 13.7854 3.46596 13.8112 3.3335 13.8112C2.78121 13.8112 2.3335 13.3635 2.3335 12.8112V9.47784C2.3335 8.92556 2.78121 8.47784 3.3335 8.47784C3.88578 8.47784 4.3335 8.92556 4.3335 9.47784V12.8112C4.3335 13.1742 4.14002 13.4921 3.85055 13.6673Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
