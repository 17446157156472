/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'

export default {
  postLanguage({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/language/post', data)
        .then(response => {
          commit('ADD_LANGUAGE', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getLanguages({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/languages/select-list')
        .then(response => {
          commit('SET_LANGUAGES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getLanguageResourceByKey({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/language/languageResources/${data.languageId}/${data.key}`)
        .then(response => {
          // commit('SET_LANGUAGES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getLanguageResourceByFilter({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/language/languageResources/${data.languageId}/${data.filter}`)
        .then(response => {
          // commit('SET_LANGUAGES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

}
