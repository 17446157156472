/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import organization from '@/store/account/organization/moduleOrganization'
import project from '@/store/account/project/moduleProject'

export default {

  // get Users By Organization Id
  getUsers({ commit, state }) {
    // state.filters.params.organizationId = organization.state.activeOrganizationId
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/users`, state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_USERS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // get Users By Project Id
  getUsersOfProject({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/users`, state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_USERS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // get Users Of Organization Not in Project
  getUsersForProject({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/users/user-organization`, state.filters)
        .then(response => {
          // commit('SET_PAGINATION', response.data.result)
          commit('SET_USERS_FOR_PROJECT', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // get Users By Id
  getUserById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/users/${id}`)
        .then(response => {
          commit('SET_USER', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // get Users By Id
  getUserData({ commit, state, rootState }, data) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/accounts/users/data?organizationId=${data.organizationId}&projectId=${data.projectId}`)
        .then(response => {
          commit('SET_USER', response.data.result.user)
          commit('SET_USER_DEFAULT_ORGANIZATION', response?.data?.result?.defaultOrganization)
          commit('SET_USER_DEFAULT_PROJECT', response?.data?.result?.defaultProject)
          // const responseUserPermissions = response.data.result.permissions.length > 0 ? response.data.result.permissions : null
          // commit('userRolesModule/SET_PERMISSIONS', { data: responseUserPermissions, property: 'ofUser' }, { root: true })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // get Users By Id
  getUserEditedById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/users/${id}`)
        .then(response => {
          commit('SET_USER_EDITED', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // get Users By Email
  getUserByEmail({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/users/user-info`)
        .then(response => {
          commit('SET_USER', response.data.result.user)
          resolve(response)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  // get Projects User not in
  getOrganizationProjectsUserNotIn({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/users/${userId}/projects`)
        .then(response => {
          commit('SET_USERS_ORGANIZATION_PROJECTS', response.data.result)
          resolve(response)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  // Add User Project Mapping
  addUser({ commit, state }, user) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/users`, user, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          },
        })
        .then(response => {
          // commit('ADD_USER', Object.assign(user, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // Add User To Project
  addUsersToProject({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/users/add-users-to-project`, data)
        .then(response => {
          // commit('ADD_USER', Object.assign(user, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  addUsersToProjects({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/organizations/${organization.state.activeOrganizationId}/users/projects`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  //
  updateUser({ state }, user) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/users`, user)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateUserProjectRole({ state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/users/update-user-role`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateUserOrganizationRole({ state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/users/update-user-role`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removeUserFromOrganization({ state }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/organizations/${organization.state.activeOrganizationId}/users/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removeUserFromProject({ state }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${project.state.activeProjectId}/users/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removeProjectFromUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${data.projectId}/users/${data.publicId}`)
        .then(response => {
          commit('REMOVE_PROJECT_FROM_USER', {
            userIndex: data.userIndex,
            projectIndex: data.projectIndex,
          })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // Resend Invitation
  resendInvitation({ commit }, email) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/users/resend-invitation?email=${email}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
