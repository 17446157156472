import AdUnit from './bidding/adUnit'

class Wrapper {
  constructor() {
    this.name = ''
    this.description = ''
    this.bidderTimeOut = 0
    this.serverTimeOut = 0
    this.coopaInventory = false
    this.adUnits = []
  }

  async fromData(data) {
    this.publicId = data.publicId
    this.name = data.name
    this.description = data.description
    this.coopaInvertory = data.coopaInvertory
    this.bidderTimeOut = data.bidderTimeOut
    this.serverTimeOut = data.serverTimeOut
    this.coopaInventory = data.coopaInventory
    this.adUnits = data.adUnits ? data.adUnits.map(x => {
      const adUnits = new AdUnit()
      adUnits.fromData(x)
      return adUnits
    }) : null
  }
}

export default Wrapper
