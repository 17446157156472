<template>

  <!-- EMBED MODAL -->
  <b-modal
    :id="embedRef"
    :ref="embedRef"
    close-title="Close modal"
    :ok-title="toUpperCase(okButton)"
    :cancel-title="toUpperCase(cancelButton)"
    centered
    no-close-on-backdrop
    ok-variant="flat-danger"
    header-bg-variant="white"
    cancel-variant="primary"
    title-class="text-main"
    header-text-variant="primary text-main bg-white"
    body-class="d-flex flex-column"
    @ok="okMethod()"
  >
    <template #modal-title>
      <p class="p-0 m-0 pb-25 h6 text-main">
        {{ toUpperCase(title) }}
      </p>
    </template>
    <div class="d-block pb-2">
      <span class="d-flex flex-column align-items-center">
        <GjIcon
          name="InfoAlt"
          color="#ff0000"
          size="20"
          class="col-1 p-0"
        />
        <p class="text-sm text-primary text-secondary m-0">
          {{ description }}
        </p>
      </span>
    </div>
  </b-modal>
</template>

<script>

export default {
  props: {
    embedRef: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: [String, Number],
      required: true,
    },
    description: {
      type: [String, Number],
      required: true,
    },
    okButton: {
      type: [String, Number],
      required: true,
    },
    cancelButton: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {

  },
  methods: {
    okMethod() {
      this.$emit('stopUploads', true)
    },
    toUpperCase(text) {
      return text.toUpperCase()
    },
  },
}
</script>
