<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 11.714A6.314 6.314 0 0110.314 5.4h.034l9.429 1.048a1.6 1.6 0 011.423 1.59V9.79a1.6 1.6 0 01-1.212 1.552l-2.586.646c-.52.13-.958.564-1.172 1.143-.625 1.694-2.277 4.897-5.916 4.897A6.314 6.314 0 014 11.714zM10.282 6.6a5.114 5.114 0 00.032 10.229c2.793 0 4.178-2.453 4.79-4.112.322-.872 1.027-1.647 2.007-1.892l2.586-.646A.4.4 0 0020 9.79V8.038a.4.4 0 00-.356-.398l-5.301-.589v1.235a.6.6 0 01-1.2 0V6.918L10.282 6.6zM8 11.714a2.314 2.314 0 114.629 0 2.314 2.314 0 01-4.629 0zm2.314-1.114a1.114 1.114 0 100 2.229 1.114 1.114 0 000-2.229z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
