<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.398 4.182a.6.6 0 00.6.6h16a.6.6 0 100-1.2h-16a.6.6 0 00-.6.6zM7.18 6.855a1.6 1.6 0 00-1.6 1.6V13a1.6 1.6 0 001.6 1.6h2.364a1.6 1.6 0 001.6-1.6V8.455a1.6 1.6 0 00-1.6-1.6H7.18zm-.4 1.6c0-.221.18-.4.4-.4h2.364c.22 0 .4.179.4.4V13a.4.4 0 01-.4.4H7.18a.4.4 0 01-.4-.4V8.455zM14.453 6.855a1.6 1.6 0 00-1.6 1.6v10.363a1.6 1.6 0 001.6 1.6h2.363a1.6 1.6 0 001.6-1.6V8.455a1.6 1.6 0 00-1.6-1.6h-2.363zm-.4 1.6a.4.4 0 01.4-.4h2.363c.221 0 .4.179.4.4v10.363a.4.4 0 01-.4.4h-2.363a.4.4 0 01-.4-.4V8.455z"
    />
  </svg>
</template>
