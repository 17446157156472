<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 7.733C7.5 5.315 9.54 3.4 12 3.4s4.5 1.915 4.5 4.333-2.04 4.333-4.5 4.333-4.5-1.915-4.5-4.333zM4.9 17.867c0-2.419 2.04-4.335 4.5-4.335h5.2c2.46 0 4.5 1.916 4.5 4.335V18a2.6 2.6 0 01-2.6 2.6h-9A2.6 2.6 0 014.9 18v-.133z"
    />
  </svg>
</template>
