<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66666 12.2916C6.50832 12.2916 6.34999 12.2333 6.22499 12.1083L4.55833 10.4416C4.31666 10.2 4.31666 9.79991 4.55833 9.55824L6.22499 7.89158C6.46666 7.64991 6.86666 7.64991 7.10832 7.89158C7.34999 8.13324 7.34999 8.53329 7.10832 8.77496L5.88332 9.99993L7.10832 11.2249C7.34999 11.4666 7.34999 11.8666 7.10832 12.1083C6.98332 12.2333 6.82499 12.2916 6.66666 12.2916Z"
      fill="currentColor"
    />
    <path
      d="M13.3332 12.2916C13.1749 12.2916 13.0166 12.2333 12.8916 12.1083C12.6499 11.8666 12.6499 11.4666 12.8916 11.2249L14.1166 9.99993L12.8916 8.77496C12.6499 8.53329 12.6499 8.13324 12.8916 7.89158C13.1332 7.64991 13.5332 7.64991 13.7749 7.89158L15.4416 9.55824C15.6832 9.79991 15.6832 10.2 15.4416 10.4416L13.7749 12.1083C13.6499 12.2333 13.4916 12.2916 13.3332 12.2916Z"
      fill="currentColor"
    />
    <path
      d="M9.99996 18.9583C5.05829 18.9583 1.04163 14.9416 1.04163 9.99996C1.04163 5.05829 5.05829 1.04163 9.99996 1.04163C14.9416 1.04163 18.9583 5.05829 18.9583 9.99996C18.9583 14.9416 14.9416 18.9583 9.99996 18.9583ZM9.99996 2.29163C5.74996 2.29163 2.29163 5.74996 2.29163 9.99996C2.29163 14.25 5.74996 17.7083 9.99996 17.7083C14.25 17.7083 17.7083 14.25 17.7083 9.99996C17.7083 5.74996 14.25 2.29163 9.99996 2.29163Z"
      fill="currentColor"
    />
    <path
      d="M9.16672 12.5668C9.08339 12.5668 9.00006 12.5501 8.92506 12.5168C8.6084 12.3834 8.4584 12.0168 8.60006 11.6918L10.2667 7.80007C10.4001 7.48341 10.7667 7.33339 11.0834 7.47506C11.4001 7.60839 11.5501 7.9751 11.4084 8.3001L9.74173 12.1918C9.64173 12.4251 9.40839 12.5668 9.16672 12.5668Z"
      fill="currentColor"
    />
  </svg>

</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
