import Vue from 'vue'
import VueRouter from 'vue-router'

// auth imports
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import {
  authRoutes,
  authRoutesGuard,
} from '@/auth'
// eslint-disable-next-line import/extensions
import { routerGuards } from '@/services/routerGuards.js'
import { handleCancelRequest } from '@/libs/axios'
import store from '@/store'

// Our routes
// import axios from '@axios'
import pages from './routes/pages'
import errorPages from './routes/errorPages'
import account from './routes/account'
import dashboard from './routes/dashboard'
import videos from './routes/videos'
import audios from './routes/audios'
import live from './routes/live'
import players from './routes/players'
import adSchedules from './routes/ad-schedules'
import analytics from './routes/analytics'
import properties from './routes/properties'
import development from './routes/development-test/development'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'set-user' } },
    ...pages,
    ...errorPages,
    ...authRoutes,
    ...account,
    ...dashboard,
    ...videos,
    ...audios,
    ...live,
    ...players,
    ...adSchedules,
    ...analytics,
    ...properties,
    // PROJECT routes, for development only
    ...(process.env.NODE_ENV === 'development' ? [
      ...development,
    ] : []),
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

routerGuards(router)

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  let hiddenSidebar
  if (to.meta.hiddenSidebar || to.meta.layout === 'full' || (!from.name && to.meta.errorPage)) hiddenSidebar = true
  else hiddenSidebar = false
  store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', hiddenSidebar)

  vuexOidcCreateRouterMiddleware(store, 'oidcStore')

  if (from.matched.length > 0 && !(from.name === 'organization-select'
      || from.name === 'projects-select'
      || from.name === 'set-user') && from.path !== to.path) { handleCancelRequest() }

  window.biskoQueue = window.biskoQueue || []
  function bsk() {
    // eslint-disable-next-line prefer-rest-params
    window.biskoQueue.push(arguments)
  }
  const userPublicId = store.state.userModule?.user?.publicId

  if ((process.env.VUE_APP_ENV).toLowerCase() === 'production' || !process.env.VUE_APP_ENV) {
    bsk('init', '181978')
    bsk('time')
    bsk('event', 'pageview')
    bsk('identify', userPublicId)
  }

  return next()
})

authRoutesGuard(router)

export default router
