<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.537 5.124a1.07 1.07 0 00-1.81-.051l-.765 1.132-.471-.32.47.32a2.108 2.108 0 00-.237 1.893c.28.78.658 1.562 1.136 2.347L7.2 9.1c.996-1 1.187-2.553.462-3.767l-.125-.209zm-1 6.342l1.513-1.52a4.293 4.293 0 00.642-5.228l-.515.307.515-.307-.125-.21A2.271 2.271 0 004.734 4.4l-.766 1.132a3.308 3.308 0 00-.373 2.972c.86 2.392 2.524 4.73 4.948 7.022 2.367 2.236 4.66 3.847 6.882 4.806a3.279 3.279 0 003.148-.303l1.032-.704a2.286 2.286 0 00-.109-3.842l-.208-.126a4.256 4.256 0 00-5.214.645l-1.388 1.394a29.908 29.908 0 01-3.318-2.743c-1.13-1.067-2.071-2.13-2.83-3.187zm7.17 6.606c.746.463 1.477.849 2.194 1.158.654.282 1.406.21 1.996-.192l1.031-.704a1.086 1.086 0 00-.05-1.823l-.209-.126a3.056 3.056 0 00-3.745.463l-1.218 1.224z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
