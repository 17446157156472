<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      d="M14.9697 22.75H8.96973C3.53973 22.75 1.21973 20.43 1.21973 15V9C1.21973 3.57 3.53973 1.25 8.96973 1.25H14.9697C20.3997 1.25 22.7197 3.57 22.7197 9V15C22.7197 20.43 20.4097 22.75 14.9697 22.75ZM8.96973 2.75C4.35973 2.75 2.71973 4.39 2.71973 9V15C2.71973 19.61 4.35973 21.25 8.96973 21.25H14.9697C19.5797 21.25 21.2197 19.61 21.2197 15V9C21.2197 4.39 19.5797 2.75 14.9697 2.75H8.96973Z"
      fill="currentColor"
    />
    <path
      d="M7.96973 22.75C7.55973 22.75 7.21973 22.41 7.21973 22V2C7.21973 1.59 7.55973 1.25 7.96973 1.25C8.37973 1.25 8.71973 1.59 8.71973 2V22C8.71973 22.41 8.38973 22.75 7.96973 22.75Z"
      fill="currentColor"
    />
    <path
      d="M14.9696 15.31C14.7796 15.31 14.5896 15.24 14.4396 15.09L11.8796 12.53C11.5896 12.24 11.5896 11.76 11.8796 11.47L14.4396 8.91C14.7296 8.62 15.2096 8.62 15.4996 8.91C15.7896 9.2 15.7896 9.68001 15.4996 9.97001L13.4796 12L15.5096 14.03C15.7996 14.32 15.7996 14.8 15.5096 15.09C15.3596 15.24 15.1696 15.31 14.9696 15.31Z"
      fill="currentColor"
    />
  </svg>
</template>
