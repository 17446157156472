<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.639 6.04a1.4 1.4 0 00-1.89.784l-.27.719a1.6 1.6 0 01-.936.936l-.72.27a1.4 1.4 0 00-.782 1.89l.318.699a1.6 1.6 0 010 1.324l-.318.7a1.4 1.4 0 00.783 1.89l.719.269a1.6 1.6 0 01.936.936l.27.72a1.4 1.4 0 001.89.782l.285-.13a2.6 2.6 0 012.152 0l.285.13a1.4 1.4 0 001.89-.783l.27-.719a1.6 1.6 0 01.936-.936l.72-.27a1.4 1.4 0 00.782-1.89l-.317-.699a1.6 1.6 0 010-1.324l.317-.7a1.4 1.4 0 00-.783-1.89l-.719-.269a1.6 1.6 0 01-.936-.936l-.27-.72a1.4 1.4 0 00-1.89-.782l-.699.318a1.6 1.6 0 01-1.324 0l-.7-.318zm-3.014.362a2.6 2.6 0 013.51-1.454l.7.318a.4.4 0 00.33 0l.7-.318a2.6 2.6 0 013.51 1.454l.27.72a.4.4 0 00.234.233l.719.27a2.6 2.6 0 011.454 3.51l-.318.7a.4.4 0 000 .33l.318.7a2.6 2.6 0 01-1.454 3.51l-.72.27a.4.4 0 00-.233.234l-.27.719a2.6 2.6 0 01-3.51 1.454l-.286-.13a1.4 1.4 0 00-1.158 0l-.286.13a2.6 2.6 0 01-3.51-1.454l-.27-.72a.4.4 0 00-.234-.233l-.719-.27a2.6 2.6 0 01-1.454-3.51l.318-.7a.4.4 0 000-.33l-.318-.7a2.6 2.6 0 011.454-3.51l.72-.27a.4.4 0 00.233-.234l.27-.719z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4 11.467a1.067 1.067 0 100-2.134 1.067 1.067 0 000 2.134zm3.2 3.2a1.067 1.067 0 100-2.134 1.067 1.067 0 000 2.134zm.377-4.644a.533.533 0 00-.754 0l-3.2 3.2a.533.533 0 10.754.754l3.2-3.2a.533.533 0 000-.754z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
