<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.714 5.171a.6.6 0 00-.6.6v12.572a.6.6 0 001.2 0V5.77a.6.6 0 00-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 7.247a.6.6 0 00.833.166l1.381-.92 1.382.92a.6.6 0 00.665-.998L5.714 5.05 3.667 6.415a.6.6 0 00-.166.832zM10.257 8.628a.6.6 0 00.6.6H20a.6.6 0 100-1.2h-9.143a.6.6 0 00-.6.6zM10.257 12.057a.6.6 0 00.6.6h6.857a.6.6 0 000-1.2h-6.857a.6.6 0 00-.6.6zM10.257 15.485a.6.6 0 00.6.6h4.572a.6.6 0 100-1.2h-4.572a.6.6 0 00-.6.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
