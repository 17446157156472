class Role {
  constructor() {
    // this.id =
    this.name = ''
    this.description = null
    this.isActive = false
    // this.organizationId = null
  }

  fromData(data) {
    this.id = data.id ? data.id : null
    this.name = data.name ? data.name : ''
    this.description = data.description ? data.description : ''
    this.isActive = data.isActive ? data.isActive : false
    // this.organizationId = data.organizationId ? data.organizationId : null
  }
}

export default Role
