/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'
import organization from '@/store/account/organization/moduleOrganization'

export default {
  getBillings({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/organizations/${organization.state.activeOrganizationId}/billing`,
          state.filters,
        )
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_BILLINGS', response.data.result.items)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getPresingnedUrl({ commit, state }, filename) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/organizations/${organization.state.activeOrganizationId}/billing/invoice?requestKey=${filename}`,
        )
        .then(async response => {
          commit('SET_INVOICE_DATA', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

}
