<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.462 3.4a2.6 2.6 0 00-2.6 2.6v1.096A2.6 2.6 0 003.4 9.692v6a2.446 2.446 0 002.446 2.447c.688 0 1.246.557 1.246 1.246V20a.6.6 0 001.038.41l1.418-1.513a2.4 2.4 0 011.751-.758h2.547a.6.6 0 000-1.2H11.3a3.6 3.6 0 00-2.627 1.138l-.503.537a2.447 2.447 0 00-2.323-1.675A1.246 1.246 0 014.6 15.692v-6A1.4 1.4 0 015.862 8.3v4.778a2.6 2.6 0 002.6 2.6h3.909a2.4 2.4 0 011.582.595l1.794 1.572a.7.7 0 001.161-.526v-1.241c0-.221.18-.4.4-.4h.693a2.6 2.6 0 002.6-2.6V6A2.6 2.6 0 0018 3.4H8.462zM9.77 7.708a.6.6 0 000 1.2h4.923a.6.6 0 000-1.2H9.77zm1.4 3.061a.6.6 0 01.6-.6h4.923a.6.6 0 010 1.2H11.77a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
