import {
  actions, mutations, state, getters,
} from './state/store'
import FileObject from './state/file'
import singlePartUpload from './singlePartUpload'
import multiPartUpload from './multiPartUpload'
import encode from './encode'
import reencode from './reencode'
import multipartComplete from './multipartComplete'
import { handleCancelRequest } from './libs/axios'

export {
  actions, mutations, state, getters,
}

export default {
  upload: async (file, {
    reupload = false, index = null, fileType = '', videoId, isForReencode,
  } = {}) => {
    const CHUNK_SIZE = state.FILE_CHUNK_SIZE
    if (file.size > CHUNK_SIZE * 1000) {
      throw new Error('File larger than 50GB!')
    } else if (!fileType) {
      throw new Error('No file type provided!')
    } else if (reupload && index === null) {
      throw new Error('No index provided!')
    } else {
      const FILE = new FileObject()
      FILE.fromData(file)
      FILE.fileType = fileType

      if (reupload) {
        mutations.ADD_REUPLOADING_FILE({ FILE, index })
      } else mutations.ADD_NEW_UPLOADING_FILE(FILE)

      state.eventBus.$emit(state.eventBusName, state.uploads)

      if (file.size > CHUNK_SIZE) {
        let alreadyUploadedParts = []
        if (reupload) {
          const aup = await actions.getMultipartUploadedParts(FILE.requestKey)
          if (aup.data.result) alreadyUploadedParts = aup.data.result.map(p => p.partNumber)
        }
        await multiPartUpload(FILE, alreadyUploadedParts, videoId, isForReencode)
      } else {
        await singlePartUpload(FILE, FILE.requestKey, videoId, isForReencode)
      }
    }
  },
  uploadFromURL: async (url, fileType, isForReencode, videoId) => {
    const FILE = new FileObject()
    FILE.status = 'uploading'
    FILE.name = url
    FILE.requestKey = url
    FILE.uploadType = 'url'
    FILE.fileType = fileType
    mutations.ADD_NEW_UPLOADING_FILE(FILE)
    if (!isForReencode) {
      encode(FILE, FILE.requestKey)
    } else {
      reencode(videoId, FILE, FILE.requestKey)
    }
  },
  async retryUpload(file, index) {
    if (file.failedStatus === 'encode') {
      file.failedStatus = null
      encode(file, file.requestKey)
    } else if (file.failedStatus === 'complete') {
      file.failedStatus = null
      multipartComplete(file, file.requestKey)
    } else {
      await this.upload(file, { reupload: true, index, fileType: file.fileType })
    }
  },

  setProjectId(projectId) {
    mutations.SET_PROJECT_ID(projectId)
  },
  async destroyUploads() {
    handleCancelRequest()
    await state.uploads.forEach(u => {
      u.cancelToken.cancel()
      // mutations.DELETE_OBJECT_FROM_LIST(i)
    })
    mutations.CLEAR_UPLOADS()
  },
}
