import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'

import store from '@/store/index'
// import { logoutUser } from '@/services/auth'
// import { initialAbility } from '@/libs/acl/config'
import fetchPermissions from '@/services/mixins/fetchPermissions'
import fetchUserData from '@/services/mixins/fetchUserData'

export const projectIdKey = 'projectId'
export const organizationIdKey = 'organizationId'

export function setLocalStorage(key, value) {
  localStorage.setItem(key, value)
}

export function getCurrentProjectId() {
  return store.state.projectModule.activeProjectId
}

export function setCurrentProjectId(id) {
  store.commit('projectModule/SET_ACTIVE_PROJECT_ID', id)
}

export function getCurrentOrganizationId() {
  return store.state.organizationModule.activeOrganizationId
}

export function setCurrentOrganizationId(id) {
  store.commit('organizationModule/SET_ACTIVE_ORGANIZATION_ID', id)
}

export async function fetchProject(id) {
  return new Promise((resolve, reject) => {
    store.dispatch('projectModule/getProjectWithProjectId', id)
      .then(response => {
        if (response.data.result.publicProjectId && response.data.result.publicOrganizationId) {
          resolve(response.data.result)
        }
        resolve(null)
      }).catch(err => reject(err))
  })
}

export async function fetchOrganization(id) {
  return new Promise((resolve, reject) => {
    store.dispatch('organizationModule/getOrganizationWithOrganizationId', id)
      .then(response => {
        if (response.data.result.publicId) {
          resolve(response.data.result)
        }
        resolve(null)
      }).catch(err => reject(err))
  })
}

export function routerGuards(router) {
  router.beforeEach(async (to, from, next) => {
    const isLoggedIn = isUserLoggedIn()
    const organizationId = getCurrentOrganizationId()
    const projectId = getCurrentProjectId()
    // const resolved = router.resolve({name:to.name});
    // if(resolved.route.name === to.name){
    //   return next
    // }
    // continue if route is public
    if (to.meta.isPublic) { return next() }

    if (to.params.projectId && from.params.projectId !== to.params.projectId && !from.meta.errorPage) {
      store.commit('authModule/SET_ISLOADING', true)
    }

    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({
        name: 'auth-login',
        query: {
          redirectTo: `${window.location.pathname}${window.location.search}`,
        },
      })
    }

    if (to.meta.errorPage) {
      return next()
    }

    // Continue if route is going to profile
    if (to.meta.profile) {
      if (!store.state.userModule.user?.email) {
        await fetchUserData(organizationId, null)
          .catch(err => {
            console.error(err)
          })
      }
      return next()
    }

    // Continue if no organization is needed
    if (to.meta.noOrganizationAccess) {
      return next()
    }

    if (to.name === 'organization' || to.name === 'project') {
      from.meta.updatingOrganization = true
    }

    if (to.meta.settingUser) {
      return next()
    }

    if (organizationId && to.meta.organizationAccess) {
      return next()
    }

    if (!organizationId && to.meta.organizationAccess && to.params.organizationId) {
      try {
        const organization = await fetchOrganization(to.params.organizationId)
        setLocalStorage(organizationIdKey, organization.publicId)
        setCurrentOrganizationId(organization.publicId)
        localStorage.removeItem('projectId')
        await fetchUserData(to.params.organizationId, null)
          .catch(err => {
            console.error(err)
          })
        await fetchPermissions(organization.publicId, null)
          .catch(err => {
            console.error(err)
          })
        if (!canNavigate(to)) {
          if (!isLoggedIn) {
            return next({
              name: 'auth-login',
            })
          }
          return next({ name: 'error-403' })
        }
        return next()
      } catch {
        return next({ name: 'organization-select' })
      }
    }

    if (to.params.projectId && from.params.projectId !== to.params.projectId) {
      try {
        // const result =
        await fetchProject(to.params.projectId)
          .then(async result => {
            setLocalStorage(projectIdKey, result.publicProjectId)
            const projectPage = Math.ceil(result.orderNumber / 5)
            store.commit('projectModule/SET_ACTIVE_PROJECT_PAGE', projectPage)
            setCurrentProjectId(to.params.projectId)
            store.commit('projectModule/UPDATE_IS_DROPDOWN_LOADING', false)
            if (result.publicOrganizationId !== organizationId) {
              setCurrentOrganizationId(result.publicOrganizationId)
              setLocalStorage(organizationIdKey, result.publicOrganizationId)
            }
            await fetchUserData(result.publicOrganizationId, to.params.projectId)
              .catch(err => {
                console.error(err)
              })
            await fetchPermissions(result.publicOrganizationId, to.params.projectId)
              .catch(err => {
                console.error(err)
              })
            if (!canNavigate(to)) {
              if (!isLoggedIn) {
                return next({
                  name: 'auth-login',
                })
              }
              return next({ name: 'error-403' })
            }

            return next()
          })
          .catch(err => {
            console.error(err)
            if (store.state.app.isIframe) {
              return next({ name: 'error-403' })
            }
            return next({ name: 'organization-select' })
          })
      } catch {
        return next({ name: 'error-403' })
      }
    }

    // Can navigate check
    if (!canNavigate(to)) {
      if (!isLoggedIn) {
        return next({
          name: 'auth-login',
        })
      }
      return next({ name: 'error-403' })
    }

    if (!to.params.projectId && to.meta.projectRequired) {
      if (!projectId) {
        return next({ name: 'set-user', params: { nextRoute: to.name } })
      }
      return next({ ...to, params: { ...to.params, projectId } })
    }

    if (!organizationId && to.meta.organizationAccess && !to.meta.errorPage) {
      return next({ name: 'organization-select' })
    }

    if (organizationId && !projectId && to.name !== 'project' && to.name !== 'project-add') {
      return next({ name: 'projects-select' })
    }

    if (organizationId && !projectId && to.name !== 'project') {
      return next()
    }

    // // Redirect if logged in
    if (to.meta.redirectIfLoggedIn) {
      const userData = getUserData()
      return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
    return next()
  })
  // eslint-disable-next-line no-unused-vars
  router.afterEach((to, _) => {
    store.commit('authModule/SET_ISLOADING', false)
  })
}
