<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 6A2.6 2.6 0 016 3.4h9a2.6 2.6 0 012.596 2.462h.558A2.446 2.446 0 0120.6 8.308V17a3.6 3.6 0 01-3.6 3.6H8.308a2.446 2.446 0 01-2.447-2.446v-.558A2.6 2.6 0 013.4 15V6zM15 17.6H7.06v.554c0 .688.558 1.246 1.247 1.246H17a2.4 2.4 0 002.4-2.4V8.308c0-.689-.558-1.246-1.246-1.246H17.6V15a2.6 2.6 0 01-2.6 2.6zM12.38 9.3a.9.9 0 100-1.8.9.9 0 000 1.8zm-2.68.8a.5.5 0 01.796-.026l2.122 2.61a.5.5 0 01-.388.816H8.25a.5.5 0 01-.407-.79L9.7 10.1z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
