<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7639 5.76443L11.902 3.9025C11.692 3.69251 11.333 3.84123 11.333 4.1382V5.50013L6.66629 5.50013C6.39015 5.50013 6.16629 5.72399 6.16629 6.00013C6.16629 6.27627 6.39015 6.50013 6.66629 6.50013L11.333 6.50013V7.86206C11.333 8.15903 11.692 8.30775 11.902 8.09776L13.7639 6.23583C13.8941 6.10566 13.8941 5.8946 13.7639 5.76443Z"
      fill="currentColor"
    />
    <path
      d="M4.09726 7.9025L2.23533 9.76443C2.10515 9.8946 2.10515 10.1057 2.23533 10.2358L4.09726 12.0978C4.30724 12.3077 4.66629 12.159 4.66629 11.8621V10.5001L9.33296 10.5001C9.6091 10.5001 9.83296 10.2763 9.83296 10.0001C9.83296 9.72399 9.6091 9.50013 9.33296 9.50013L4.66629 9.50013V8.1382C4.66629 7.84123 4.30724 7.69251 4.09726 7.9025Z"
      fill="currentColor"
    />
  </svg>
</template>
