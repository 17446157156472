<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.164 4.96a1.4 1.4 0 00-1.328 0l-5.5 2.962A1.4 1.4 0 004.6 9.155v5.688c0 .514.283.988.736 1.232l5.5 2.962a1.4 1.4 0 001.328 0l5.5-2.962a1.4 1.4 0 00.736-1.232V9.154a1.4 1.4 0 00-.736-1.233l-5.5-2.961zm-1.897-1.056a2.6 2.6 0 012.466 0l5.5 2.962A2.6 2.6 0 0119.6 9.155v5.688a2.6 2.6 0 01-1.367 2.289l-5.5 2.961a2.6 2.6 0 01-2.466 0l-5.5-2.961a2.6 2.6 0 01-1.367-2.29V9.155a2.6 2.6 0 011.367-2.29l5.5-2.961z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.04 8.269A.6.6 0 014.845 8l6.476 3.238a.4.4 0 00.358 0L18.155 8a.6.6 0 11.537 1.074l-6.476 3.238a1.6 1.6 0 01-1.431 0L4.309 9.074a.6.6 0 01-.269-.805z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5 11.399a.6.6 0 01.6.6v7.211a.6.6 0 01-1.2 0V12a.6.6 0 01.6-.6zM12.319 7.678a.6.6 0 01-.247.812l-4.01 2.138v3.967a.6.6 0 11-1.2 0V9.908l4.645-2.477a.6.6 0 01.812.247zM17.225 14.607a.6.6 0 01-.255.809l-2.885 1.5a.6.6 0 11-.554-1.065l2.885-1.5a.6.6 0 01.81.255z"
    />
  </svg>
</template>
