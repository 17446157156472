<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.083 9.01a4.787 4.787 0 019.552-.249l.233 2.613a7.312 7.312 0 001.812 4.179c.558.629.22 1.694-.69 1.776A68.766 68.766 0 0112 17.6c-2.61 0-4.957-.17-6.176-.277-.89-.078-1.224-1.133-.65-1.74a6.688 6.688 0 001.827-4.332l.082-2.24zM14.6 19a.6.6 0 01-.6.6h-4a.6.6 0 110-1.2h4a.6.6 0 01.6.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
