/* eslint-disable no-plusplus */
import AdBreaks from '@/models/ad-schedules/AdBreaks'
import AdBreakSettings from '@/models/ad-schedules/AdBreakSettings'
import WaterfallTagUrl from '@/models/ad-schedules/AdBreakTagURL'

const mutations = {
  SET_AD_BREAKS: (state, { adScheduleId, data }) => {
    let { adBreaks } = state
    adBreaks = []
    let adBreak
    data = data.sort((a, b) => a.adBreakTypeId - b.adBreakTypeId)
    const rolls = [data.filter(y => y.adBreakTypeId === 1), data.filter(y => y.adBreakTypeId === 2), data.filter(y => y.adBreakTypeId === 3)]
    const rollsTypeNames = ['Preroll', 'Midroll', 'Postroll']
    const rollsTypes = [1, 2, 3]
    // const startingIndex = adBreaks.findIndex(y => y.adBreakTypeId === rollsTypes[index])
    let startingIndex = 0
    rolls.forEach((roll, index) => {
      if (!roll.length < 1) {
        roll.forEach((m, i) => {
          adBreak = new AdBreaks()
          adBreak.fromData(m)
          adBreaks.splice(i + startingIndex, 0, adBreak)
          startingIndex++
        })
      } else {
        adBreak = new AdBreaks(adScheduleId, rollsTypeNames[index], rollsTypes[index])
        adBreaks.splice(startingIndex, 0, adBreak)
        startingIndex++
      }
    })
    state.adBreaks = adBreaks
  },
  SET_AD_BREAK: (state, data) => {
    const adBreak = new AdBreaks()
    adBreak.fromData(data)
    state.adBreak = adBreak
  },
  ADD_AD_BREAK: (state, { adScheduleId, type, id }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === id) || 0
    const rollsLength = adBreaks.filter(x => x.adBreakTypeId === id).length
    if (adBreaks.length) {
      adBreaks.splice(rollsStartingIndex + rollsLength, 0, new AdBreaks(adScheduleId, type, id))
    } else {
      adBreaks.push(new AdBreaks(adScheduleId, type, id))
    }
  },
  SET_EXISTING_AD_BREAK: (state, { data }) => {
    const { adBreaks } = state
    const adBreakIndex = adBreaks.findIndex(y => y.id === data.id) || 0
    const nab = new AdBreaks()
    nab.fromData(data)
    adBreaks.splice(adBreakIndex, 1, nab)
  },
  SET_CREATED_AD_BREAK: (state, {
    data, adBreakType, adBreakIndex,
  }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType) || 0
    const nab = new AdBreaks()
    nab.fromData(data)
    adBreaks.splice(adBreakIndex + rollsStartingIndex, 1, nab)
  },
  SET_SECONDS_TO_REPEAT: (state, {
    data, adBreakType, adBreakIndex,
  }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType) || 0
    adBreaks[adBreakIndex + rollsStartingIndex].secondsToRepeat = data
  },
  SET_PROTECT_FIRST_SECONDS: (state, {
    data, adBreakType, adBreakIndex,
  }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType) || 0
    adBreaks[adBreakIndex + rollsStartingIndex].protectFirstSeconds = data
  },
  SET_PROTECT_LAST_SECONDS: (state, {
    data, adBreakType, adBreakIndex,
  }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType) || 0
    adBreaks[adBreakIndex + rollsStartingIndex].protectLastSeconds = data
  },
  SET_AD_BREAK_TEMPLATE_ON_LIST: (state, data) => {
    state.adBreaks.unshift(data)
  },
  SET_OPTIONS: (state, data) => { state.options = data.data.result },

  CLEAR_AD_BREAK_ON_INDEX: (state, {
    adBreakType, adBreakIndex, adScheduleId, adBreakTypeName,
  }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType) || 0
    state.adBreaks.splice(adBreakIndex + rollsStartingIndex, 1, new AdBreaks(adScheduleId, adBreakTypeName, adBreakType))
  },
  REMOVE_AD_BREAK: (state, {
    adBreakTypeId, adBreakIndex, adBreakTypeName, adScheduleId,
  }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakTypeId) || 0
    const filtered = adBreaks.filter(y => y.adBreakTypeId === adBreakTypeId)
    if (filtered.length <= 1) adBreaks.splice(adBreakIndex + rollsStartingIndex, 1, new AdBreaks(adScheduleId, adBreakTypeName, adBreakTypeId))
    else adBreaks.splice(adBreakIndex + rollsStartingIndex, 1)
  },
  CLEAR_AD_BREAK: state => { state.adBreak = new AdBreaks() },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  UPDATE_IS_AD_BREAK_ADD: (state, data) => { state.isAdd = data },
  UPDATE_NAME: (state, data) => { state.adBreak.name = data },
  UPDATE_MEDIATION_OPTION_ID: (state, data) => { state.adBreak.mediationOptionId = data },
  SET_AD_BREAK_DISTRIBUTION_TYPE_ID: (state, { value, adBreakType, adBreakIndex }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType) || 0
    state.adBreaks[rollsStartingIndex + adBreakIndex].distributionMethodTypeId = value
  },
  REMOVE_SEGMENT: (state, data) => {
    state.adBreak.adBreakTemplateBidders[data].isDeleted = true
  },
  UPDATE_AD_PARTNER_INPUT_VALUE: (state, data) => {
    state.adBreak.adBreakTemplateBidders[data.partnerIndex].bidParamValues[data.inputIndex].value = data.value
  },

  // ####################### //
  //  #   WATERFALL TAG URLS
  // ####################### //
  ADD_TAG_URL(state, data) {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === data.typeId) || 0
    state.adBreaks[rollsStartingIndex + data.adBreakIndex].waterfallTags.push(new WaterfallTagUrl())
  },
  REMOVE_TAG_URL(state, data) {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === data.typeId) || 0
    state.adBreaks[data.adBreakIndex + rollsStartingIndex].waterfallTags[data.tagUrlIndex].isDeleted = true
  },
  UPDATE_WATERFALL_TAG_URL: (state, data) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === data.typeId) || 0
    state.adBreaks[data.adBreakIndex + rollsStartingIndex].waterfallTags[data.tagUrlIndex].waterfallTagUrl = data.url
  },

  // ####################### //
  //  #   SETTINGS
  // ####################### //
  ADD_NEW_SETTINGS_ROW: (state, { adBreakType, adBreakIndex, index }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType) || 0
    const { toSecond } = adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index]
    const { fromSecond } = adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index]
    const nabs = new AdBreakSettings(toSecond)
    adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings.splice(index + 1, 0, nabs)
    adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index].toSecond = fromSecond + 1
    adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index + 1].fromSecond = fromSecond + 1
  },
  DELETE_SETTINGS_ROW: (state, { adBreakType, adBreakIndex, index }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType) || 0
    adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings.splice(index, 1)
    if (index < 1) adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index].fromSecond = 0
    else adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index].fromSecond = adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index - 1].toSecond
    if (index + 1 > adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings.length) {
      adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index - 1].toSecond = null
    }
  },
  UPDATE_NEXT_FROM_SETTINGS_ROW: (state, {
    adBreakType, adBreakIndex, index, e,
  }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType) || 0
    e = e || adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index].toSecond
    if (adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index + 1]) { adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index + 1].fromSecond = e }
  },
  UPDATE_NEXT_TO_SETTINGS_ROW: (state, {
    adBreakType, adBreakIndex, index, e,
  }) => {
    index += 1
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType) || 0
    if (adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index]) {
      if (adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings.length - 1 <= index) { adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index].toSecond = null; return }
      e += 1
      adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index].toSecond = e
      adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index + 1].fromSecond = e
    }
  },
  CALCULATE_ADSNR_SETTINGS_ROW: (state, {
    adBreakType, adBreakIndex, index, e,
  }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType) || 0
    const step = 100 / (e + 1)
    const steps = []
    let i = 0
    while (i < e) {
      i += 1
      const s = parseFloat((i * step).toFixed(2))
      steps.push(s)
    }
    adBreaks[adBreakIndex + rollsStartingIndex].adBreakSettings[index].percentages = steps
  },

}

export default mutations
