export default {
  getIsLoading: state => state.isLoading,
  getIsAdBreakAdd: state => state.isAdd,
  // getAdBreak: state => state.adBreak,
  getAdBreaks: state => state.adBreaks,
  getAdBreak: state => (typeId, index) => {
    const { adBreaks } = state
    const arrayOfTypeName = adBreaks.filter(x => x.adBreakTypeId === typeId)
    const singleAdBreak = arrayOfTypeName[index]
    return singleAdBreak
  },
  getName: state => state.adBreak.name,
  getSecondsToRepeat: state => ({ adBreakType, adBreakIndex }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType)
    return adBreaks[adBreakIndex + rollsStartingIndex]?.secondsToRepeat
  },
  getProtectFirstSeconds: state => ({ adBreakType, adBreakIndex }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType)
    return adBreaks[adBreakIndex + rollsStartingIndex]?.protectFirstSeconds
  },
  getProtectLastSeconds: state => ({ adBreakType, adBreakIndex }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType)
    return adBreaks[adBreakIndex + rollsStartingIndex]?.protectLastSeconds
  },
  getAdBreakTagUrl: state => (typeId, index) => {
    const { adBreaks } = state
    const arrayOfTypeName = adBreaks.filter(x => x.adBreakTypeId === typeId)
    return arrayOfTypeName[index]?.waterfallTags
  },
  getAdBreakByType: state => type => state.adBreaks.filter(y => y.adBreakTypeId === type && y.isDeleted === false),
  getFromByIndex: state => ({ adBreakType, adBreakIndex, index }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType)
    return adBreaks[adBreakIndex + rollsStartingIndex]?.adBreakSettings[index]?.fromSecond
  },
  getToByIndex: state => ({ adBreakType, adBreakIndex, index }) => {
    const { adBreaks } = state
    const rollsStartingIndex = adBreaks.findIndex(y => y.adBreakTypeId === adBreakType)
    return adBreaks[adBreakIndex + rollsStartingIndex]?.adBreakSettings[index]?.toSecond
  },

  getatLeastOneVastBool: state => state.adBreaks.filter(adb => !adb.isDeleted && adb.isVerified).length > 0,
  getatLeastOneTagUrlBool: state => state.adBreaks.filter(adb => adb.waterfallTags.filter(wt => wt.waterfallTagUrl !== '' && !wt.isDeleted).length > 0).length > 0,
}
