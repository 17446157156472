import { replaceHash } from '@/services/mixins/playerMixins'

export default {
  getIsLoading: state => state.isLoading,
  getPlayers: state => state.players.options,
  getDefaultPlayers: state => state.defaultPlayers,
  getPlayersSelectList: state => state.playersSelectList,
  getPlayer: state => state.player,
  getOptions: state => state.options,
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,

  getPlayerSharing: state => state.player.sharing,

  getPlayerReleaseChannel: state => state.player.releaseChannel,
  getAllPlayerReleaseChannels: state => state.player.releaseChannels,
  getPlayerTypeId: state => state.player.playerTypeId,
  getPlayerPlayerSocialNetworks: state => state.player.playerSocialNetworks,
  getPlayerPlayerSocialNetworkIds: state => state.player.socialNetworkIds,
  getPlayerPlayerSizeType: state => state.player.playerSizeType,
  getPlayerResponsiveSizeOption: state => state.player.responsiveSizeOption,
  getConfig(state) {
    return {
      // config: {
      size: {
        sizeType: state.player.playerSizeType.name.toUpperCase(),
        height: state.player.height,
      },
      autoplay: state.player.autoPlay,
      configId: state.player.publicId,
      autostartOnViewable: {
        state: state.player.autoStartOnViewableOn,
        onMobile: state.player.autoStartOnViewableMobile,
        onData: state.player.autoStartOnViewableOnData,
        percentageViewable: state.player.autoStartOnViewablePercentage,
      },
      autopauseOnViewable: {
        state: state.player.autoPauseOnViewableOn,
        percentageViewable: state.player.autoPauseOnViewablePercentage,
      },
      muted: state.player.muteOnStart,
      socialMedia: state.player?.playerSocialNetworks.map(x => x.name),
      controls: {
        play: state.player.playButton,
        seekbar: state.player.seekbarOn,
        text: state.player.textOn,
        fullscreen: state.player.fullScreenButton,
        share: state.player.sharing,
      },
      skin: {
        text: state.player.skinTextColor,
        buttons: {
          icon: state.player.elementsIconButtonColor,
          background: state.player.elementsBackgroundColor,
        },
        seekbar: {
          rail: state.player.elementsSeekBarRailColor,
          buffer: state.player.elementsSeekBarBufferColor,
          progress: state.player.elementsSeekBarProgressColor,
          dragger: state.player.elementsSeekBarDraggerColor,
        },
      },
    }
  },
  getEndpointObject(state) {
    const {
      skinTextColor,
      elementsSeekBarRailColor,
      elementsSeekBarProgressColor,
      elementsSeekBarDraggerColor,
      elementsSeekBarBufferColor,
      elementsBackgroundColor,
      elementsIconButtonColor,
      ...player
    } = state.player
    return {
      ...player,
      skinTextColor: replaceHash(skinTextColor),
      elementsSeekBarRailColor: replaceHash(elementsSeekBarRailColor),
      elementsSeekBarProgressColor: replaceHash(elementsSeekBarProgressColor),
      elementsSeekBarDraggerColor: replaceHash(elementsSeekBarDraggerColor),
      elementsSeekBarBufferColor: replaceHash(elementsSeekBarBufferColor),
      elementsBackgroundColor: replaceHash(elementsBackgroundColor),
      elementsIconButtonColor: replaceHash(elementsIconButtonColor),
    }
  },
  getCustomizeColorsObject() {
    return [
      {
        name: 'Elements',
        id: 'elements',
        elements: [
          {
            name: 'Colors',
            colors: [
              {
                label: 'Text Color',
                prop: 'skinTextColor',
              },
            ],
          },
          {
            name: 'Buttons',
            colors: [
              {
                label: 'Icon Color',
                prop: 'elementsIconButtonColor',
              },
              {
                label: 'Background Color',
                prop: 'elementsBackgroundColor',
              },
            ],
          },
          {
            name: 'Slider',
            colors: [
              {
                label: 'Rail',
                prop: 'elementsSeekBarRailColor',
              },
              {
                label: 'Progressbar',
                prop: 'elementsSeekBarProgressColor',
              },
              {
                label: 'Dragger',
                prop: 'elementsSeekBarDraggerColor',
              },
              {
                label: 'Buffer',
                prop: 'elementsSeekBarBufferColor',
              },
            ],
          },
        ],
      },
    ]
  },
  getCheckboxes() {
    return [
      {
        label: 'Show play',
        prop: 'playButton',
      },
      {
        label: 'Show Fullscreen Button',
        prop: 'fullScreenButton',
      },
      {
        label: 'Show Text',
        prop: 'textOn',
      },
      {
        label: 'Show Seekbar',
        prop: 'seekbarOn',
      },
    ]
  },
  getLocalizations() {
    return [
      {
        name: 'Player Controls',
        id: 'playerControls',
        items: [
          'Play',
          'Pause',
          'Mute',
          'UnMute',
          'Fullscreen',
          'Minimize',
          'Settings',
          'More',
          'Less',
          'Previous',
          'Next',
        ],
      },
      {
        name: 'More Player Options',
        id: 'morePlayerOptions',
        items: [
          'Share',
          'Copy',
          'CopyLink',
          'Off',
          'PlaybackRate',
          'Normal',
          'Quality',
          'Auto',
          'Live',
        ],
      },
    ]
  },
}
