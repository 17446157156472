/* eslint-disable no-unused-vars */
import axios from '@/libs/axios'
import project from '@/store/account/project/moduleProject'

const baseURL = process.env.VUE_APP_API_AUDIO
const hostURL = process.env.VUE_APP_API_HOST
const analyticsUrl = process.env.VUE_APP_API_ANALYTICS_AUDIO

export default {
  getPodcast({ commit, state }, id) {
    commit('LOADING_PODCAST', true)
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/${project.state.activeProjectId}/podcasts/${id}`)
        .then(async response => {
          commit('SET_PODCAST', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => {
          commit('LOADING_PODCAST', false)
        })
    })
  },
  getPodcasts({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/${project.state.activeProjectId}/podcasts`, state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_PODCASTS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updatePodcast({ commit }, player) {
    commit('UPADTING_PODCAST', true)
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseURL}api/v1/${project.state.activeProjectId}/podcasts`, player)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => {
          commit('UPADTING_PODCAST', false)
        })
    })
  },
  deletePodcasts({ commit }, items) {
    return new Promise((resolve, reject) => {
      const requestData = { ids: items }
      axios.delete(`${baseURL}api/v1/${project.state.activeProjectId}/podcasts`, { data: requestData })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getCategories({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/categories/select-list`)
        .then(async response => {
          commit('SET_CATEGORIES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getSubcategories({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/subcategories/select-list`)
        .then(async response => {
          commit('SET_SUBCATEGORIES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getSortTypes({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/sort-types/select-list`)
        .then(async response => {
          commit('SET_SORT_TYPES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAudios({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/${project.state.activeProjectId}/audios`, state.filtersOfAudio)
        .then(async response => {
          commit('SET_AUDIOS', response.data.result.items)
          commit('SET_AUDIOS_PAGINATION', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getPodcastAudios({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/${project.state.activeProjectId}/podcasts/${id}/audios`)
        .then(response => {
          commit('SET_PODCAST_AUDIOS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  postPodcastAudios({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}api/v1/${project.state.activeProjectId}/podcasts/audios`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putPodcastAudios({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseURL}api/v1/${project.state.activeProjectId}/podcasts/audios`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deletePodcastAudios({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${baseURL}api/v1/${project.state.activeProjectId}/podcasts/audios`, { data })
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  addPodcast({ commit }, podcast) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}api/v1/${project.state.activeProjectId}/podcasts`, podcast)
        .then(response => {
          commit('ADD_PODCAST', Object.assign(podcast, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getEmbed({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${hostURL}podcast/embed/${data.player}/${data.id}/${data.seo}`)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  uploadImage({ commit }, file) {
    return new Promise((resolve, reject) => {
      axios
        .post(`api/projects/${project.state.activeProjectId}/uploads/image`, file,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  uploadFromRssLink({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}api/v1/${project.state.activeProjectId}/podcasts/upload`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getPodcastSelectList({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v1/${project.state.activeProjectId}/podcasts/select-list`)
        .then(response => {
          commit('SET_PODCASTS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // Analytics
  getPodcastAnalytics({ commit, state }, query) {
    const { podcastId, data } = query
    let endpoint = ''
    if (podcastId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/graph?metric=${data.metric}&intervalType=${data.intervalType}&isPodcast=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/graph?metric=${data.metric}&podcastId=${podcastId}&intervalType=${data.intervalType}&isPodcast=${true}`
    }
    let differenceInDays = null
    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      commit('LOADING_CUSTOM_ANALYTICS', true)
      const [from, to] = state.dateStr.split(' to ')
      const utcDates = data.customDate.map(dateString => new Date(dateString))
      const startDate = utcDates[0]
      const endDate = utcDates[1]
      const differenceinTime = endDate.getTime() - startDate.getTime()
      differenceInDays = differenceinTime / (1000 * 3600 * 24)
      differenceInDays = 5
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS', true)

    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(response => {
          const result = response?.data?.result
          commit('SET_ANALYTICS', { result, intervalType: query.data.intervalType, differenceInDays })
          resolve(response)
        })
        .catch(error => {
          commit('SET_ANALYTICS', [])
          commit('LOADING_ANALYTICS', false)
          commit('LOADING_CUSTOM_ANALYTICS', false)
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS', false)
          commit('LOADING_CUSTOM_ANALYTICS', false)
        })
    })
  },

  getPodcastAnalyticsTopCountries({ commit, state }, query) {
    const { podcastId, data } = query
    let endpoint = ''

    if (podcastId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topCountries?intervalType=${data.intervalType}&isPodcast=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topCountries?podcastId=${podcastId}&intervalType=${data.intervalType}&isPodcast=${true}`
    }

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_COUNTRIES', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_COUNTRIES', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_COUNTRIES', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_COUNTRIES', false)
        })
    })
  },

  getPodcastAnalyticsTopBrowsers({ commit, state }, query) {
    const { podcastId, data } = query

    let endpoint = ''

    if (podcastId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topBrowsers?intervalType=${data.intervalType}&isPodcast=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topBrowsers?podcastId=${podcastId}&intervalType=${data.intervalType}&isPodcast=${true}`
    }

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_BROWSERS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_BROWSERS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_BROWSERS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_BROWSERS', false)
        })
    })
  },

  getPodcastAnalyticsTopDevices({ commit, state }, query) {
    const { podcastId, data } = query

    let endpoint = ''

    if (podcastId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topDevices?intervalType=${data.intervalType}&isPodcast=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/topDevices?podcastId=${podcastId}&intervalType=${data.intervalType}&isPodcast=${true}`
    }

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_TOP_DEVICES', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_TOP_DEVICES', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_TOP_DEVICES', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_TOP_DEVICES', false)
        })
    })
  },

  getPodcastAnalyticsLiveViewrs({ commit, state }, query) {
    const { podcastId, data } = query
    let endpoint = ''

    if (podcastId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/liveViewers?isPodcast=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/liveViewers?podcastId=${podcastId}&isPodcast=${true}`
    }

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_LIVE_VIEWERS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_LIVE_VIEWERS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_LIVE_VIEWERS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_LIVE_VIEWERS', false)
        })
    })
  },

  getPodcastAnalyticsKeyMoments({ commit, state }, query) {
    const { podcastId, data } = query

    let endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/keyMoments/graph?podcastId=${podcastId}&metric=${data.metric}&intervalType=${data.intervalType}&isPodcast=${true}`

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_KEY_MOMENTS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_KEY_MOMENTS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_KEY_MOMENTS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_KEY_MOMENTS', false)
        })
    })
  },

  getPodcastAnalyticsKeyMomentsCards({ commit, state }, query) {
    const { podcastId, data } = query

    let endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/keyMoments/cards/${podcastId}?metric=${data.metric}&intervalType=${data.intervalType}&isPodcast=${true}`

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_KEY_MOMENTS_CARDS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_KEY_MOMENTS_CARDS', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_KEY_MOMENTS_CARDS', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_KEY_MOMENTS_CARDS', false)
        })
    })
  },

  getPodcastAnalyticsEpisodes({ commit, state }, query) {
    const { podcastId, data } = query

    let endpoint = ''

    if (podcastId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/podcastAudios?metric=${data.metric}&intervalType=${data.intervalType}&isPodcast=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/podcastAudios?podcastId=${podcastId}&metric=${data.metric}&intervalType=${data.intervalType}&isPodcast=${true}`
    }

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_EPISODES', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_EPISODES', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_EPISODES', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_EPISODES', false)
        })
    })
  },

  getPodcastAnalyticsListenEpisode({ commit, state }, query) {
    const { podcastId, data } = query

    let endpoint = ''

    if (podcastId === undefined) {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/graph/listenPerAudio?metric=${data.metric}&intervalType=${data.intervalType}&isPodcast=${true}`
    } else {
      endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/graph/listenPerAudio?podcastId=${podcastId}&metric=${data.metric}&intervalType=${data.intervalType}&isPodcast=${true}`
    }

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_LISTEN_EPISODE', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_LISTEN_EPISODE', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_LISTEN_EPISODE', [])
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_LISTEN_EPISODE', false)
        })
    })
  },

  getPodcastAnalyticsReport({ commit, state }, query) {
    const { podcastId, data } = query
    let endpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/audio/download/xls/podcast?podcastId=${podcastId}&intervalType=${data.intervalType}`

    if (data.intervalType === 5 && Array.isArray(data.customDate)) {
      const [from, to] = state.dateStr.split(' to ')
      endpoint += `&startDate=${from}&endDate=${to || from}`
    }

    commit('LOADING_ANALYTICS_REPORT', true)
    return new Promise((resolve, reject) => {
      axios
        .get(endpoint)
        .then(async response => {
          commit('SET_ANALYTICS_REPORT', response?.data?.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_ANALYTICS_REPORT', null)
          reject(error)
        })
        .finally(() => {
          commit('LOADING_ANALYTICS_REPORT', false)
        })
    })
  },
  getEmbedPreselected({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${hostURL}podcast/embed/${data.player}/${data.id}/${data.seo}?startEpisode=${data.startEpisode}`)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

}
