class SocialDistribution {
  constructor() {
    this.name = ''
    this.socialMedia = ''
    this.socialMediaId = 0
    this.isActive = false
    this.socialMediaHubs = []
  }

  fromData(data) {
    this.name = data.name || ''
    this.socialMedia = data.socialMedia || ''
    this.socialMediaId = data.socialMediaId || 0
    this.isActive = data.isActive || false
    this.socialMediaHubs = data.socialMediaHubs || []
  }
}

export default SocialDistribution
