<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.57 7.971a.6.6 0 01.6.6v6.858a.6.6 0 01-1.2 0V8.57a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.662 6.131c1.702-1.362 4.224-.15 4.224 2.03v7.678c0 2.18-2.522 3.392-4.224 2.03l-2.301-1.84h-.932a4.029 4.029 0 010-8.058h.932l2.3-1.84zm3.024 2.03c0-1.174-1.358-1.826-2.275-1.093l-2.63 2.103H7.43a2.829 2.829 0 000 5.658h1.353l2.63 2.103c.916.733 2.274.08 2.274-1.093V8.16zM16.72 10.147a.6.6 0 01.848 0l2.857 2.857a.6.6 0 01-.849.849l-2.857-2.857a.6.6 0 010-.849z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.425 10.147a.6.6 0 010 .849l-2.857 2.857a.6.6 0 11-.849-.849l2.857-2.857a.6.6 0 01.849 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
