<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.558 9.442a.6.6 0 010 .849l-4.267 4.267a.6.6 0 01-.849-.849l4.267-4.267a.6.6 0 01.849 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.442 6.558a2.417 2.417 0 00-3.418 0l-2.666 2.666a.6.6 0 01-.849-.848l2.667-2.667a3.617 3.617 0 015.115 5.115l-2.667 2.667a.6.6 0 01-.848-.849l2.666-2.666a2.417 2.417 0 000-3.418zM6.558 17.442a2.417 2.417 0 003.418 0l2.666-2.666a.6.6 0 01.849.848l-2.667 2.667a3.617 3.617 0 01-5.115-5.115l2.667-2.667a.6.6 0 11.848.849l-2.666 2.666a2.417 2.417 0 000 3.418z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
