<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 6.1a1.4 1.4 0 00-1.4 1.4v9A1.4 1.4 0 006 17.9h12a1.4 1.4 0 001.4-1.4v-6.174a1.4 1.4 0 00-1.4-1.4h-5.13a.6.6 0 01-.027 0H6.857a.6.6 0 010-1.2h5.191l-.192-.632a1.4 1.4 0 00-1.34-.994H6zm7.302 1.626l-.297-.98A2.6 2.6 0 0010.517 4.9H6a2.6 2.6 0 00-2.6 2.6v9A2.6 2.6 0 006 19.1h12a2.6 2.6 0 002.6-2.6v-6.174a2.6 2.6 0 00-2.6-2.6h-4.698z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
