<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.6 8.266C5.6 6.126 7.352 4.4 9.5 4.4c2.148 0 3.9 1.725 3.9 3.866 0 2.142-1.752 3.867-3.9 3.867-2.148 0-3.9-1.725-3.9-3.867zm-2.2 8.867c0-2.142 1.752-3.867 3.9-3.867h4.4c2.148 0 3.9 1.725 3.9 3.867a2.467 2.467 0 01-2.467 2.467H5.867A2.467 2.467 0 013.4 17.133zM17.5 8.4a.6.6 0 01.6.6v1.9H20a.6.6 0 010 1.2h-1.9V14a.6.6 0 11-1.2 0v-1.9H15a.6.6 0 110-1.2h1.9V9a.6.6 0 01.6-.6z"
    />
  </svg>
</template>
