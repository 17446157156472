export const initialAbility = [
  // {
  //   action: 'read',
  //   subject: 'dashboard',
  // },
  {
    id: -2,
    action: 'read',
    subject: 'Auth',
    key: 'Auth.read',
  },
]

export const _ = undefined
