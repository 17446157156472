<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 6.86A2.6 2.6 0 016 4.26h12a2.6 2.6 0 012.6 2.6v8.571a2.6 2.6 0 01-2.6 2.6H6a2.6 2.6 0 01-2.6-2.6V6.86zM6 5.46a1.4 1.4 0 00-1.4 1.4v8.571a1.4 1.4 0 001.4 1.4h12a1.4 1.4 0 001.4-1.4V6.86a1.4 1.4 0 00-1.4-1.4H6zM8.543 19.146a.6.6 0 01.6-.6h5.714a.6.6 0 110 1.2H9.143a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
