export default {
  getScene: state => state.scene,
  getScenes: state => state.scenes,
  getFetchingScenes: state => state.fetchingScenes,
  getGettingScene: state => state.gettingScene,
  getAddingScene: state => state.addingScene,
  getUpdatingScene: state => state.updatingScene,
  getReplacingScene: state => state.replacingScene,
  getDeletingScene: state => state.deletingScene,
}
