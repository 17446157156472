import state from './ moduleRestreamLiveState'
import mutations from './ moduleRestreamLiveMutations'
import actions from './ moduleRestreamLiveActions'
import getters from './ moduleRestreamLiveGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
