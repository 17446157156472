import Notification from '@/models/notifications/Notification'

export default {
  SET_NOTIFICATIONS: (state, data) => { state.notifications = data || [] },
  SET_NOTIFICATION: (state, data) => {
    const notification = new Notification()
    notification.fromData(data)
    state.notification = notification
  },
  SET_NOTIFICATION_EVENTS: (state, data) => { state.notificationEvents = data },
  SET_TITLE: (state, data) => { state.notification.title = data },
  SET_USAGETYPE: (state, id) => {
    state.notification.usageVolumeTypes[0].usageNotificationVolumeId = id
  },
  SET_SELECTED_TYPE: (state, data) => { state.notification.userNotificationTypeId = data },
  SET_TYPES_LIST: (state, data) => { state.notificationTypes = data },
  SET_USAGEVOLUMES: (state, data) => { state.usageVolumes = data },
  SET_DATES: (state, data) => { state.dateOptions = data },
  ADD_USAGETYPE: (state, id) => { state.notification.usageVolumeTypes.push({ usageNotificationVolumeId: id }) },
  REMOVE_USAGETYPE: (state, id) => {
    state.notification.usageVolumeTypes
      .splice(state.notification.usageVolumeTypes
        .findIndex(x => x.usageNotificationVolumeId === id),
      1)
  },
  SET_NOTIFICATION_THRESHOLD: (state, v) => { state.notification.usageVolumeTypes[0].threshold = v },
  UPDATE_NOTIFICATION_STATUS: (state, { index, active }) => { state.notifications[index].active = active },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  CLEAR_NOTIFICATIONS: state => { state.notifications = [] },
  CLEAR_NOTIFICATION: state => { state.notification = new Notification() },
  CLEAR_DATES: state => { state.dateOptions = [] },

  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },

  UPDATE_FILTER: (state, data) => {
    state.filters.params[data.propKey] = data.value
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
}
