<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.207 15.7c0 2.371 2.148 4.3 4.79 4.3 2.641 0 4.79-1.929 4.79-4.299 0-1.218-.567-2.319-1.477-3.101h2.474a.599.599 0 000-1.198h-5.797c-1.976-.004-3.583-1.394-3.583-3.102 0-1.71 1.612-3.102 3.593-3.102 1.98 0 3.592 1.392 3.592 3.102a.598.598 0 101.198-.001c0-2.37-2.149-4.299-4.79-4.299-2.642 0-4.79 1.929-4.79 4.299 0 1.218.567 2.32 1.477 3.103H6.209a.599.599 0 000 1.198h5.866c1.944.035 3.514 1.413 3.514 3.1 0 1.711-1.612 3.102-3.592 3.102-1.981 0-3.593-1.39-3.593-3.102a.599.599 0 00-1.197 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
