<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.285 4.6a1.686 1.686 0 100 3.371 1.686 1.686 0 000-3.371zM13.4 6.286a2.886 2.886 0 115.772 0 2.886 2.886 0 01-5.772 0zM16.285 16.029a1.686 1.686 0 100 3.371 1.686 1.686 0 000-3.371zM13.4 17.714a2.886 2.886 0 115.772 0 2.886 2.886 0 01-5.772 0zM8.285 10.314a1.686 1.686 0 100 3.372 1.686 1.686 0 000-3.372zM5.4 12a2.886 2.886 0 115.772 0 2.886 2.886 0 01-5.772 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.773 7.366a.6.6 0 01-.14.837l-4 2.857a.6.6 0 01-.697-.977l4-2.857a.6.6 0 01.837.14zM9.797 13.08a.6.6 0 01.837-.14l4 2.857a.6.6 0 11-.698.977l-4-2.857a.6.6 0 01-.14-.837z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
