<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.686 7a2.6 2.6 0 012.6-2.6h7.428a2.6 2.6 0 012.6 2.6v.505a3.601 3.601 0 012.286 3.352v5a2.314 2.314 0 01-2.314 2.314h-.126a2.601 2.601 0 01-2.446 1.715H8.286a2.601 2.601 0 01-2.447-1.72A2.6 2.6 0 013.4 15.571v-4.714c0-1.524.948-2.827 2.286-3.352V7zm11.428.257H6.886V7a1.4 1.4 0 011.4-1.4h7.428a1.4 1.4 0 011.4 1.4v.257zM6.886 15.571a1.4 1.4 0 011.4-1.4h7.428a1.4 1.4 0 011.4 1.4v1.715a1.4 1.4 0 01-1.4 1.4H8.286a1.4 1.4 0 01-1.4-1.4V15.57zm-.6-4.285a.571.571 0 100-1.143.571.571 0 000 1.143zm1.714 0a.571.571 0 100-1.143.571.571 0 000 1.143z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
