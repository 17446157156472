/* eslint-disable no-unused-vars */
export default [
  {
    path: '/:projectId/videos/',
    component: () => import('@/views/videos/video/Videos.vue'),
    name: 'videos',
    meta: {
      titleText: 'Videos',
      parentText: 'Dashboard',
      contentClass: 'videos',
      navActiveLink: 'videos',
      gjIcon: 'VideoPlayer',
      action: 'read',
      resource: 'video',
      projectRequired: true,
      breadcrumb: [{ text: 'Videos', to: 'videos', active: true }],
    },
  },
  {
    path: '',
    component: () => import('@/views/videos/video/Edit.vue'),
    children: [
      // the logic is handled the case where user can use the path like below
      // '/:projectId/videos/:id
      // '/:projectId/videos/:id/:general?
      // general is optional but in case the user mismatch it redirect to 404 page

      {
        path: '/:projectId/videos/:id/qualities',
        name: 'videos-edit-assets',
        component: () => import('@/views/videos/video/components/video-files-tab/FilesTab.vue'),
        meta: {
          title: 'Edit Video',
          titleText: 'Videos',
          parentText: 'Dashboard',
          contentClass: 'video',
          navActiveLink: 'videos',
          gjIcon: 'VideoPlayer',
          action: 'execute',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            { text: 'Videos', to: 'videos' },
            { dynamic: true, text: 'Edit Video', active: true },
          ],
        },
      },
      {
        path: '/:projectId/videos/:id/subtitles',
        name: 'videos-edit-subtitles',
        component: () => import('@/views/videos/video/components/subtitles-tab/Subtitles.vue'),
        meta: {
          title: 'Edit Video',
          titleText: 'Videos',
          parentText: 'Dashboard',
          contentClass: 'video',
          navActiveLink: 'videos',
          gjIcon: 'VideoPlayer',
          action: 'execute',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            { text: 'Videos', to: 'videos' },
            { dynamic: true, text: 'Edit Video', active: true },
          ],
        },
      },
      {
        path: '/:projectId/videos/:id/analytics',
        name: 'analytics',
        component: () => import('@/views/videos/video/components/analytics-tab/Analytics.vue'),
        meta: {
          title: 'Edit Video',
          titleText: 'Videos',
          parentText: 'Dashboard',
          contentClass: 'video',
          navActiveLink: 'videos',
          gjIcon: 'VideoPlayer',
          action: 'execute',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            { text: 'Videos', to: 'videos' },
            { dynamic: true, text: 'Edit Video', active: true },
          ],
        },
        // children: [
        //   {
        //     path: 'embeds',
        //     component: () => import('@/views/videos/video/components/analytics-tab/tabs/Embeds.vue'),
        //     name: 'embeds',
        //     meta: {
        //       title: 'Edit Video',
        //       titleText: 'Videos',
        //       parentText: 'Dashboard',
        //       contentClass: 'video',
        //       navActiveLink: 'videos',
        //       gjIcon: 'VideoPlayer',
        //       action: 'execute',
        //       resource: 'video',
        //       projectRequired: true,
        //       breadcrumb: [
        //         { text: 'Videos', to: 'videos' },
        //         { dynamic: true, text: 'Edit Video', active: true },
        //       ],
        //     },
        //   },
        //   {
        //     path: 'unique-views',
        //     component: () => import('@/views/videos/video/components/analytics-tab/tabs/UniqueViews.vue'),
        //     name: 'unique-views',
        //     meta: {
        //       title: 'Edit Video',
        //       titleText: 'Videos',
        //       parentText: 'Dashboard',
        //       contentClass: 'video',
        //       navActiveLink: 'videos',
        //       gjIcon: 'VideoPlayer',
        //       action: 'execute',
        //       resource: 'video',
        //       projectRequired: true,
        //       breadcrumb: [
        //         { text: 'Videos', to: 'videos' },
        //         { dynamic: true, text: 'Edit Video', active: true },
        //       ],
        //     },
        //   },
        //   {
        //     path: 'views',
        //     component: () => import('@/views/videos/video/components/analytics-tab/tabs/Views.vue'),
        //     name: 'views',
        //     meta: {
        //       title: 'Edit Video',
        //       titleText: 'Videos',
        //       parentText: 'Dashboard',
        //       contentClass: 'video',
        //       navActiveLink: 'videos',
        //       gjIcon: 'VideoPlayer',
        //       action: 'execute',
        //       resource: 'video',
        //       projectRequired: true,
        //       breadcrumb: [
        //         { text: 'Videos', to: 'videos' },
        //         { dynamic: true, text: 'Edit Video', active: true },
        //       ],
        //     },
        //   },
        //   {
        //     path: 'watch-time-hours',
        //     component: () => import('@/views/videos/video/components/analytics-tab/tabs/WatchTimeHours.vue'),
        //     name: 'watch-time-hours',
        //     meta: {
        //       title: 'Edit Video',
        //       titleText: 'Videos',
        //       parentText: 'Dashboard',
        //       contentClass: 'video',
        //       navActiveLink: 'videos',
        //       gjIcon: 'VideoPlayer',
        //       action: 'execute',
        //       resource: 'video',
        //       projectRequired: true,
        //       breadcrumb: [
        //         { text: 'Videos', to: 'videos' },
        //         { dynamic: true, text: 'Edit Video', active: true },
        //       ],
        //     },
        //   },
        //   {
        //     path: 'completes',
        //     component: () => import('@/views/videos/video/components/analytics-tab/tabs/Completes.vue'),
        //     name: 'completes',
        //     meta: {
        //       title: 'Edit Video',
        //       titleText: 'Videos',
        //       parentText: 'Dashboard',
        //       contentClass: 'video',
        //       navActiveLink: 'videos',
        //       gjIcon: 'VideoPlayer',
        //       action: 'execute',
        //       resource: 'video',
        //       projectRequired: true,
        //       breadcrumb: [
        //         { text: 'Videos', to: 'videos' },
        //         { dynamic: true, text: 'Edit Video', active: true },
        //       ],
        //     },
        //   },
        //   {
        //     path: 'average-view-duration',
        //     component: () => import('@/views/videos/video/components/analytics-tab/tabs/AverageViewDuration.vue'),
        //     name: 'average-view-duration',
        //     meta: {
        //       title: 'Edit Video',
        //       titleText: 'Videos',
        //       parentText: 'Dashboard',
        //       contentClass: 'video',
        //       navActiveLink: 'videos',
        //       gjIcon: 'VideoPlayer',
        //       action: 'execute',
        //       resource: 'video',
        //       projectRequired: true,
        //       breadcrumb: [
        //         { text: 'Videos', to: 'videos' },
        //         { dynamic: true, text: 'Edit Video', active: true },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        path: '/:projectId/videos/:id/:general?',
        name: 'videos-edit-general-information',
        component: () => import('@/views/videos/video/components/general-information-tab/GeneralInformationTab.vue'),
        beforeEnter: (to, from, next) => {
          const { general } = to.params
          const validGeneralValues = ['general', undefined]

          if (!validGeneralValues.includes(general)) {
            const errorPath = '/error-404'
            next(errorPath)
          } else {
            next()
          }
        },
        meta: {
          title: 'Edit Video',
          titleText: 'Videos',
          parentText: 'Dashboard',
          contentClass: 'video',
          navActiveLink: 'videos',
          gjIcon: 'VideoPlayer',
          action: 'execute',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            { text: 'Videos', to: 'videos' },
            { dynamic: true, text: 'Edit Video', active: true },
          ],
        },
      },
    ],
  },

  // Playlist Routing
  {
    path: '/:projectId/playlists',
    component: () => import('@/views/videos/playlists/Playlist.vue'),
    meta: {
      titleText: 'Playlists',
      parentText: 'Dashboard',
      contentClass: 'playlists',
      navActiveLink: 'playlists',
      gjIcon: 'IconparkCarouselVideo',
      action: 'read',
      resource: 'video',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Playlists',
          to: 'playlists',
          active: true,
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'playlists',
        component: () => import('@/views/videos/playlists/Playlists.vue'),
        meta: {
          titleText: 'Playlists',
          parentText: 'Dashboard',
          contentClass: 'playlists',
          navActiveLink: 'playlists',
          gjIcon: 'IconparkCarouselVideo',
          action: 'read',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Playlists',
              to: 'playlists',
              active: true,
            },
          ],
        },
      },
      {
        path: ':id',
        name: 'playlists-edit',
        component: () => import('@/views/videos/playlists/Edit.vue'),
        meta: {
          title: '',
          titleText: 'Playlists',
          parentText: 'Dashboard',
          contentClass: 'playlist',
          navActiveLink: 'playlists',
          gjIcon: 'IconparkCarouselVideo',
          action: 'execute',
          resource: 'video',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Playlists',
              to: 'playlists',
            },
            {
              dynamic: true,
              text: 'Edit Playlists',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
