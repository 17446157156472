<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5754 14.2525H12.9177C12.5725 14.2525 12.2927 14.5323 12.2927 14.8775C12.2927 15.2226 12.5725 15.5025 12.9177 15.5025H16.6677C17.243 15.5025 17.7093 15.0361 17.7093 14.4608V10.7108C17.7093 10.3656 17.4295 10.0858 17.0843 10.0858C16.7392 10.0858 16.4593 10.3656 16.4593 10.7108V13.3687L11.692 8.60136C11.4594 8.36869 11.0861 8.35627 10.8385 8.57296L7.94554 11.1045L3.77487 6.93388C3.53079 6.6898 3.13506 6.6898 2.89098 6.93388C2.64691 7.17796 2.64691 7.57369 2.89098 7.81777L7.47513 12.4019C7.70781 12.6346 8.08103 12.647 8.32866 12.4303L11.2216 9.89872L15.5754 14.2525Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
