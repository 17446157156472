<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3333 10C13.3333 11.841 11.8409 13.3333 10 13.3333C8.15905 13.3333 6.66667 11.841 6.66667 10C6.66667 8.15906 8.15905 6.66668 10 6.66668C11.8409 6.66668 13.3333 8.15906 13.3333 10ZM12.0833 10C12.0833 11.1506 11.1506 12.0833 10 12.0833C8.84941 12.0833 7.91667 11.1506 7.91667 10C7.91667 8.84942 8.84941 7.91668 10 7.91668C11.1506 7.91668 12.0833 8.84942 12.0833 10Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.7859 2.34119C9.54095 1.92171 10.4591 1.92171 11.2141 2.34119L16.2141 5.11896C17.0078 5.55989 17.5 6.39644 17.5 7.30436V12.6957C17.5 13.6036 17.0078 14.4401 16.2141 14.8811L11.2141 17.6588C10.4591 18.0783 9.54095 18.0783 8.7859 17.6588L3.7859 14.8811C2.99223 14.4401 2.5 13.6036 2.5 12.6957V7.30436C2.5 6.39644 2.99223 5.55989 3.78589 5.11897L8.7859 2.34119ZM10.6071 3.43388L15.6071 6.21166C16.0039 6.43212 16.25 6.8504 16.25 7.30436V12.6957C16.25 13.1496 16.0039 13.5679 15.6071 13.7884L10.6071 16.5661C10.2295 16.7759 9.77048 16.7759 9.39295 16.5662L4.39295 13.7884C3.99612 13.5679 3.75 13.1496 3.75 12.6957V7.30436C3.75 6.8504 3.99612 6.43212 4.39295 6.21166L9.39295 3.43388C9.77048 3.22415 10.2295 3.22415 10.6071 3.43388Z"
      fill="currentColor"
    />
  </svg>
</template>
