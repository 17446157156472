<template>
  <Transition name="fade-in">
    <div
      v-if="onScreen"
      class="flex flex-row p-0 m-0 bg-grey rounded col-12"
    >
      <b-col
        cols="12"
        xl="12"
        class="m-0 d-flex justify-content-between p-0 m-0"
      >
        <!-- Left col: New Button -->

        <b-col class="d-flex flex-column flex-md-row  w-100 p-0 ">
          <slot name="AddInPage" />
        </b-col>
      </b-col>
    </div>
  </Transition>
</template>

<script>
export default {
  data() {
    return {
      onScreen: false,
      headerBgVariant: 'black',
      headerTextVariant: 'white',
      bodyBgVariant: 'light',
      bodyTextVariant: 'black',
      footerBgVariant: '',
      footerTextVariant: '',
    }
  },
  beforeMount() {
    this.onScreen = true
  },
  beforeDestroy() {
    this.onScreen = false
  },
}
</script>

<style lang="scss" scoped>
  .right-buttons{
    width: 300px;
    height: auto;

    @media screen and (max-width: 991.99px) {
      // display: none;
      position: absolute;
      top: 0;
      right: 24px;
    }

    @media screen and (min-width: 992px) {
      // display: none;
      position: relative;
    }
  }

  .position-unset {
    position: unset !important;
  }
  .fade-in-enter-active {
    -webkit-animation: slide-rotate-hor-t-bck 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) reverse both;
    animation: slide-rotate-hor-t-bck 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) reverse both;
  }
  .fade-in-leave-active {
    -webkit-animation: slide-rotate-hor-t-bck 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: slide-rotate-hor-t-bck 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  }

  // .fade-in-enter-from,
  // .fade-in-leave-to {
  //   opacity: 0;
  // }

  /* ----------------------------------------------
 * Generated by Animista on 2022-2-16 17:35:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-rotate-hor-t-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-hor-t-bck {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
  100% {
    -webkit-transform: translateY(-150px) translateZ(-230px) rotateX(-90deg);
            transform: translateY(-150px) translateZ(-230px) rotateX(-90deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
}
@keyframes slide-rotate-hor-t-bck {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
  100% {
    -webkit-transform: translateY(-150px) translateZ(-230px) rotateX(-90deg);
            transform: translateY(-150px) translateZ(-230px) rotateX(-90deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
}
</style>
