import SocialDistribution from '@/models/social-distribution/social-distribution'
import SocialDistributionCreate from '@/models/social-distribution/social-distribution-create'

export default {
  SET_SOCIAL_DISTRIBUTION: (state, data) => { const fd = new SocialDistribution(); fd.fromData(data); state.socialDistribution = fd },
  SET_SOCIAL_DISTRIBUTIONS: (state, data) => { state.socialDistributions = data },
  SET_AUTHORIZE_URL: (state, data) => { state.authorizeUrl = data },
  SET_CATEGORIES: (state, data) => { state.categories = data },
  SET_PRICACY_STATUSES: (state, data) => { state.privacyStatuses = data },
  SET_FACEBOOK_TAGS: (state, data) => { state.facebookTags = data },
  SET_SOCIAL_MEDIAS: (state, data) => { state.socialMedias = data },
  SET_SELECT_LIST: (state, data) => { state.selectList = data },
  SET_MEDIA_HUBS: (state, data) => { state.mediaHubs = data },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },
  UPDATE_FILTER: (state, data) => {
    state.filters.params[data.propKey] = data.value
  },
  UPDATE_PAGINATION: (state, data) => {
    state.pagination[data.propKey] = data.value
  },
  SET_SOCIAL_DISTRIBUTION_CREATE: (state, data) => {
    state.socialDistributionCreate = new SocialDistributionCreate()
    state.socialDistributionCreate.fromData(data)
  },
  SET_SOCIAL_DISTRIBUTION_SOCIAL_MEDIA_PLATFORM: (state, socialMediaPlatform) => {
    state.socialDistributionCreate.socialMediaPlatform = socialMediaPlatform
  },
  SET_SOCIAL_DISTRIBUTION_SOCIAL_MEDIA_HUB_ID: (state, socialMediaHubId) => {
    state.socialDistributionCreate.socialMediaHubId = socialMediaHubId
  },
  SET_SOCIAL_DISTRIBUTION_TITLE: (state, title) => {
    state.socialDistributionCreate.title = title
  },
  SET_SOCIAL_DISTRIBUTION_DESCRIPTION: (state, description) => {
    state.socialDistributionCreate.description = description
  },
  SET_SOCIAL_DISTRIBUTION_CATEGORY_ID: (state, categoryId) => {
    state.socialDistributionCreate.categoryId = categoryId
  },
  SET_SOCIAL_DISTRIBUTION_SCHEDULE_POST_DATE_TIME: (state, schedulePostDateTime) => {
    state.socialDistributionCreate.schedulePostDateTime = schedulePostDateTime
  },
  SET_SOCIAL_DISTRIBUTION_TAGS: (state, tags) => {
    state.socialDistributionCreate.tags = tags
  },
  SET_SOCIAL_DISTRIBUTION_IS_SCHEDULED: (state, isScheduled) => {
    state.socialDistributionCreate.isScheduled = isScheduled
  },
  SET_SOCIAL_DISTRIBUTION_PRIVACY_STATUS: (state, privacyStatus) => {
    state.socialDistributionCreate.privacyStatus = privacyStatus
  },
}
