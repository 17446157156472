<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.877 6.67023C14.1055 6.44978 14.476 6.44978 14.7045 6.67023L14.2908 7.06941C14.7045 6.67023 14.7043 6.67006 14.7045 6.67023L14.7052 6.67094L14.7061 6.67175L14.708 6.67369L14.7133 6.67886L14.7287 6.6943C14.741 6.70686 14.7574 6.72393 14.7773 6.74544C14.8171 6.78843 14.8711 6.84929 14.9344 6.9273C15.0607 7.08308 15.2256 7.30884 15.3895 7.59882C15.7173 8.1786 16.0461 9.02386 16.0461 10.0802C16.0461 11.1365 15.7173 11.9817 15.3895 12.5615C15.2256 12.8515 15.0607 13.0772 14.9344 13.233C14.8711 13.311 14.8171 13.3719 14.7773 13.4149C14.7574 13.4364 14.741 13.4535 14.7287 13.466L14.7133 13.4815L14.708 13.4866L14.7061 13.4886L14.7052 13.4894C14.705 13.4896 14.7045 13.4901 14.3069 13.1065L14.7045 13.4901C14.476 13.7105 14.1055 13.7105 13.877 13.4901C13.6492 13.2703 13.6485 12.9143 13.875 12.6937C13.8757 12.693 13.8764 12.6924 13.877 12.6917L14.2908 13.0909C13.877 12.6917 13.8772 12.6916 13.877 12.6917L13.8764 12.6923L13.875 12.6937L13.8791 12.6895C13.884 12.6846 13.8927 12.6756 13.9048 12.6626C13.9289 12.6365 13.9664 12.5944 14.0128 12.5372C14.1059 12.4225 14.2336 12.2484 14.3622 12.0209C14.6195 11.5657 14.8759 10.9056 14.8759 10.0802C14.8759 9.25474 14.6195 8.59462 14.3622 8.13946C14.2336 7.91197 14.1059 7.73786 14.0128 7.62314C13.9664 7.5659 13.9289 7.52385 13.9048 7.49775C13.8927 7.48471 13.884 7.47569 13.8791 7.47079L13.875 7.46663C13.6485 7.24602 13.6492 6.89004 13.877 6.67023Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2175 4.41217C16.4459 4.19171 16.8164 4.19171 17.0449 4.41217L16.6312 4.81134C17.0449 4.41217 17.0447 4.41198 17.0449 4.41217L17.0457 4.41296L17.0467 4.41393L17.0493 4.41639L17.0564 4.42342C17.0621 4.42905 17.0695 4.43655 17.0787 4.44592C17.097 4.46465 17.1221 4.49085 17.1529 4.52443C17.2146 4.59158 17.2995 4.68836 17.3997 4.81409C17.6 5.06537 17.8627 5.43347 18.1241 5.91267C18.6475 6.872 19.1666 8.2789 19.1666 10.0802C19.1666 11.8814 18.6475 13.2883 18.1241 14.2477C17.8627 14.7269 17.6 15.0949 17.3997 15.3462C17.2995 15.472 17.2146 15.5687 17.1529 15.6359C17.1221 15.6695 17.097 15.6957 17.0787 15.7144C17.0695 15.7238 17.0621 15.7313 17.0564 15.7369L17.0493 15.7439L17.0467 15.7464L17.0457 15.7474C17.0455 15.7475 17.0449 15.7481 16.6312 15.349L17.0449 15.7481C16.8164 15.9686 16.4459 15.9686 16.2175 15.7481C15.9892 15.5279 15.989 15.171 16.2167 14.9505C16.2166 14.9506 16.2168 14.9505 16.2167 14.9505L16.216 14.9512C16.2161 14.9511 16.216 14.9512 16.2167 14.9505L16.2275 14.9396C16.2377 14.9291 16.2546 14.9116 16.2771 14.8871C16.3221 14.8381 16.3896 14.7614 16.4722 14.6578C16.6375 14.4504 16.8624 14.1364 17.0886 13.7218C17.5404 12.8935 17.9964 11.666 17.9964 10.0802C17.9964 8.49433 17.5404 7.26682 17.0886 6.43851C16.8624 6.0239 16.6375 5.70987 16.4722 5.50248C16.3896 5.39887 16.3221 5.32219 16.2771 5.27319C16.2546 5.24871 16.2377 5.23117 16.2275 5.22068L16.2172 5.21026C16.2164 5.2095 16.216 5.2091 16.2159 5.20905L16.2172 5.21026C15.989 4.98978 15.9891 4.63252 16.2175 4.41217ZM16.2159 5.20905C16.2159 5.20905 16.2159 5.20905 16.2159 5.20905V5.20905Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.29547 6.67023C5.52396 6.44978 5.89443 6.44978 6.12293 6.67023C6.35076 6.89004 6.35143 7.24602 6.12494 7.46663L6.12082 7.47079C6.116 7.47569 6.10728 7.48471 6.0952 7.49775C6.07103 7.52385 6.03355 7.5659 5.98713 7.62314C5.89407 7.73786 5.76637 7.91197 5.63775 8.13946C5.38043 8.59462 5.12409 9.25474 5.12409 10.0802C5.12409 10.9056 5.38043 11.5657 5.63775 12.0209C5.76637 12.2484 5.89407 12.4225 5.98713 12.5372C6.03355 12.5944 6.07103 12.6365 6.0952 12.6626C6.10728 12.6756 6.116 12.6846 6.12082 12.6895L6.12479 12.6935L6.12407 12.6928M6.12479 12.6935C6.35128 12.9142 6.35076 13.2703 6.12293 13.4901C5.89443 13.7105 5.52396 13.7105 5.29547 13.4901L5.69518 13.1044C5.29546 13.4901 5.29564 13.4903 5.29547 13.4901L5.29474 13.4894L5.2939 13.4886L5.29191 13.4866L5.28666 13.4815L5.2713 13.466C5.25897 13.4535 5.24255 13.4364 5.22263 13.4149C5.18281 13.3719 5.12886 13.311 5.06558 13.233C4.93922 13.0772 4.77437 12.8515 4.61043 12.5615C4.28265 11.9817 3.95388 11.1365 3.95388 10.0802C3.95388 9.02386 4.28265 8.1786 4.61043 7.59882C4.77437 7.30884 4.93922 7.08308 5.06558 6.9273C5.12886 6.84929 5.18281 6.78843 5.22263 6.74544C5.24255 6.72393 5.25897 6.70686 5.2713 6.6943L5.28666 6.67886L5.29191 6.67369L5.2939 6.67175L5.29474 6.67094L5.29547 6.67023C5.29564 6.67006 5.29547 6.67023 5.7092 7.06941L5.29547 6.67023M6.12479 12.6935C6.12498 12.6937 6.12514 12.6939 6.12479 12.6935V12.6935ZM6.12479 12.6935C6.12423 12.693 6.12382 12.6926 6.12326 12.6921L6.12479 12.6935Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.95504 4.41217C3.18354 4.19171 3.55401 4.19171 3.78251 4.41217C4.011 4.63263 4.011 4.99006 3.78251 5.21052L3.36877 4.81134C3.78251 5.21052 3.78234 5.21068 3.78251 5.21052L3.78315 5.2099L3.78402 5.20905C3.78406 5.20901 3.784 5.20905 3.78315 5.2099L3.77247 5.22068C3.76222 5.23117 3.74538 5.24871 3.72289 5.27319C3.6779 5.32219 3.6104 5.39887 3.5278 5.50248C3.36245 5.70987 3.13755 6.0239 2.91137 6.43851C2.45952 7.26682 2.00353 8.49433 2.00353 10.0802C2.00353 11.666 2.45952 12.8935 2.91137 13.7218C3.13755 14.1364 3.36245 14.4504 3.5278 14.6578C3.6104 14.7614 3.6779 14.8381 3.72289 14.8871C3.74538 14.9116 3.76222 14.9291 3.77247 14.9396L3.78279 14.9501C3.78349 14.9507 3.78389 14.9511 3.78399 14.9512L3.78279 14.9501C4.01095 15.1705 4.01089 15.5278 3.78251 15.7481C3.55401 15.9686 3.18354 15.9686 2.95504 15.7481L3.36867 15.3491C2.95504 15.7481 2.95524 15.7483 2.95504 15.7481L2.95422 15.7474L2.95323 15.7464L2.9507 15.7439L2.94357 15.7369C2.9379 15.7313 2.93043 15.7238 2.92127 15.7144C2.90295 15.6957 2.87789 15.6695 2.84704 15.6359C2.78538 15.5687 2.7005 15.472 2.60026 15.3462C2.39992 15.0949 2.13724 14.7269 1.87583 14.2477C1.3525 13.2883 0.833313 11.8814 0.833313 10.0802C0.833313 8.2789 1.3525 6.872 1.87583 5.91267C2.13724 5.43347 2.39992 5.06537 2.60026 4.81409C2.7005 4.68836 2.78538 4.59158 2.84704 4.52443C2.87789 4.49085 2.90295 4.46465 2.92127 4.44592C2.93043 4.43655 2.9379 4.42905 2.94357 4.42342L2.9507 4.41639L2.95323 4.41393L2.95422 4.41296L2.95504 4.41217C2.95524 4.41198 2.95504 4.41217 3.36877 4.81134L2.95504 4.41217ZM3.78399 14.9512C3.784 14.9513 3.78402 14.9513 3.78399 14.9512V14.9512Z"
      fill="currentColor"
    />
    <path
      d="M9.99998 10.8328C10.2069 10.8328 10.4053 10.7535 10.5516 10.6124C10.6979 10.4712 10.7801 10.2798 10.7801 10.0802C10.7801 9.88053 10.6979 9.68908 10.5516 9.54793C10.4053 9.40677 10.2069 9.32747 9.99998 9.32747C9.79307 9.32747 9.59464 9.40677 9.44834 9.54793C9.30203 9.68908 9.21984 9.88053 9.21984 10.0802C9.21984 10.2798 9.30203 10.4712 9.44834 10.6124C9.59464 10.7535 9.79307 10.8328 9.99998 10.8328Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.0346 9.14876C9.29064 8.90173 9.63789 8.76295 9.99998 8.76295C10.3621 8.76295 10.7093 8.90173 10.9654 9.14876C11.2214 9.39578 11.3652 9.73081 11.3652 10.0802C11.3652 10.4295 11.2214 10.7645 10.9654 11.0116C10.7093 11.2586 10.3621 11.3974 9.99998 11.3974C9.63789 11.3974 9.29064 11.2586 9.0346 11.0116C8.77857 10.7645 8.63473 10.4295 8.63473 10.0802C8.63473 9.73081 8.77857 9.39578 9.0346 9.14876ZM9.99998 9.89199C9.94825 9.89199 9.89864 9.91181 9.86207 9.9471C9.82549 9.98239 9.80494 10.0303 9.80494 10.0802C9.80494 10.1301 9.82549 10.1779 9.86207 10.2132C9.89864 10.2485 9.94825 10.2683 9.99998 10.2683C10.0517 10.2683 10.1013 10.2485 10.1379 10.2132C10.1745 10.1779 10.195 10.1301 10.195 10.0802C10.195 10.0303 10.1745 9.98239 10.1379 9.9471C10.1013 9.91181 10.0517 9.89199 9.99998 9.89199Z"
      fill="currentColor"
    />
  </svg>

</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
