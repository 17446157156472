<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.71 7.1a2.6 2.6 0 012.6-2.6h11.373a2.6 2.6 0 012.6 2.6v6.98a2.6 2.6 0 01-2.6 2.6H15.21a1.4 1.4 0 00-1.133.577l-1.435 1.973a.8.8 0 01-1.294 0l-1.435-1.973a1.4 1.4 0 00-1.132-.577H6.31a2.6 2.6 0 01-2.6-2.6V7.1zm3.941 2.392a.6.6 0 01.6-.6h5.49a.6.6 0 110 1.2h-5.49a.6.6 0 01-.6-.6zm2.6 1.596a.6.6 0 100 1.2h5.49a.6.6 0 000-1.2h-5.49z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
