<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.242 5.136c3.744 3.442 9.547 3.448 13.298.017C17.758 3.913 15.075 3 11.885 3c-3.175 0-5.847.905-6.643 2.136z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.771 6.532C14.758 9.726 9.005 9.718 5 6.51v4.878a13.576 13.576 0 0013.771.018V6.532zm-2.314 3.925a.571.571 0 11-1.143 0 .571.571 0 011.143 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.771 12.778A14.778 14.778 0 015 12.762v4.552c0 .556.312 1.009.703 1.345.393.338.926.615 1.533.835 1.219.444 2.863.706 4.65.706 1.787 0 3.43-.262 4.65-.706.606-.22 1.14-.497 1.533-.835.39-.336.702-.788.702-1.345v-4.536zm-2.314 3.394a.571.571 0 11-1.143 0 .571.571 0 011.143 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
