/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

const analyticsUrl = process.env.VUE_APP_API_ANALYTICS_URL

export default {

  getVideoAndAudioLiveConsumption({ commit }) {
    commit('LOADING_VIDEO_LIVE_CONSUMPTION', true)
    commit('LOADING_AUDIO_LIVE_CONSUMPTION', true)

    const videoPromise = new Promise((resolve, reject) => {
      axios.get(`${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/dashboard/videoLiveConsumption?intervalLength=1`)
        .then(response => {
          commit('SET_VIDEO_LIVE_CONSUMPTION', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
          commit('SET_VIDEO_LIVE_CONSUMPTION', [])
        })
        .finally(() => {
          commit('LOADING_VIDEO_LIVE_CONSUMPTION', false)
        })
    })

    const audioPromise = new Promise((resolve, reject) => {
      axios.get(`${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/dashboard/audioLiveConsumption?intervalLength=1`)
        .then(response => {
          commit('SET_AUDIO_LIVE_CONSUMPTION', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
          commit('SET_AUDIO_LIVE_CONSUMPTION', [])
        })
        .finally(() => {
          commit('LOADING_AUDIO_LIVE_CONSUMPTION', false)
        })
    })

    return Promise.all([videoPromise, audioPromise])
  },

  getTopCountries({ commit }) {
    commit('LOADING_TOP_COUNTRIES', true)
    return new Promise((resolve, reject) => {
      axios.get(`${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/dashboard/topCountries`)
        .then(response => {
          commit('SET_TOP_COUNTRIES', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
          commit('SET_TOP_COUNTRIES', [])
        })
        .finally(() => {
          commit('LOADING_TOP_COUNTRIES', false)
        })
    })
  },

  getTopDevices({ commit }) {
    commit('LOADING_TOP_DEVICES', true)
    return new Promise((resolve, reject) => {
      axios.get(`${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/dashboard/topDevices`)
        .then(response => {
          commit('SET_TOP_DEVICES', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
          commit('SET_TOP_DEVICES', [])
        })
        .finally(() => {
          commit('LOADING_TOP_DEVICES', false)
        })
    })
  },

  getTopVideos({ commit }, query) {
    const { itemsToShow, intervalType } = query.data

    const baseEndpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/dashboard/topVideos?items=${itemsToShow}`

    const endpoint = itemsToShow > 3 && intervalType >= 0
      ? `${baseEndpoint}&intervalType=${intervalType}`
      : baseEndpoint

    commit('LOADING_TOP_VIDEOS', true)

    return new Promise((resolve, reject) => {
      axios.get(endpoint)
        .then(response => {
          const { result } = response.data
          const topVideos = result === null ? [] : result
          commit('SET_TOP_VIDEOS', topVideos)
          resolve(response)
        })
        .catch(error => {
          reject(error)
          commit('SET_TOP_VIDEOS', [])
        })
        .finally(() => {
          commit('LOADING_TOP_VIDEOS', false)
        })
    })
  },
  getVideos({ commit, state }) {
    commit('LOADING_VIDEOS', true)
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/videos`, state.filters)
        .then(async response => {
          commit('SET_VIDEOS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => {
          commit('LOADING_VIDEOS', false)
        })
    })
  },

  getTopPodcasts({ commit }, query) {
    const { itemsToShow, intervalType } = query.data

    const baseEndpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/dashboard/topPodcasts?items=${itemsToShow}`

    const endpoint = itemsToShow > 3 && intervalType >= 0
      ? `${baseEndpoint}&intervalType=${intervalType}`
      : baseEndpoint
    commit('LOADING_TOP_PODCASTS', true)

    return new Promise((resolve, reject) => {
      axios.get(endpoint)
        .then(response => {
          const { result } = response.data
          const topPodcasts = result === null ? [] : result
          commit('SET_TOP_PODCASTS', topPodcasts)
          resolve(response)
        })
        .catch(error => {
          reject(error)
          commit('SET_TOP_PODCASTS', [])
        })
        .finally(() => {
          commit('LOADING_TOP_PODCASTS', false)
        })
    })
  },

  getTopAudios({ commit }, query) {
    const { itemsToShow, intervalType } = query.data

    const baseEndpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/dashboard/topAudios?items=${itemsToShow}`

    const endpoint = itemsToShow > 3 && intervalType >= 0
      ? `${baseEndpoint}&intervalType=${intervalType}`
      : baseEndpoint
    commit('LOADING_TOP_AUDIOS', true)

    return new Promise((resolve, reject) => {
      axios.get(endpoint)
        .then(response => {
          const { result } = response.data
          const topAudios = result === null ? [] : result
          commit('SET_TOP_AUDIOS', topAudios)
          resolve(response)
        })
        .catch(error => {
          reject(error)
          commit('SET_TOP_AUDIOS', [])
        })
        .finally(() => {
          commit('LOADING_TOP_AUDIOS', false)
        })
    })
  },

  getTopLiveVideos({ commit }, query) {
    const { itemsToShow, intervalType } = query.data

    const baseEndpoint = `${analyticsUrl}api/v1/projects/${project.state.activeProjectId}/dashboard/topLiveVideos?items=${itemsToShow}`

    const endpoint = itemsToShow > 3 && intervalType >= 0
      ? `${baseEndpoint}&intervalType=${intervalType}`
      : baseEndpoint
    commit('LOADING_TOP_LIVE_VIDEOS', true)

    return new Promise((resolve, reject) => {
      axios.get(endpoint)
        .then(response => {
          const { result } = response.data
          const topLiveVideos = result === null ? [] : result
          commit('SET_TOP_LIVE_VIDEOS', topLiveVideos)
          resolve(response)
        })
        .catch(error => {
          reject(error)
          commit('SET_TOP_LIVE_VIDEOS', [])
        })
        .finally(() => {
          commit('LOADING_TOP_LIVE_VIDEOS', false)
        })
    })
  },
}
