<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 8.148a4.748 4.748 0 017.053-4.152.608.608 0 01.065-.003h3.333a2.6 2.6 0 012.6 2.6v2.14h1.01a2.6 2.6 0 012.413 1.635l.54 1.348a2.6 2.6 0 01.185.966v3.17a2.379 2.379 0 01-1.778 2.301l.001.07a2.378 2.378 0 01-4.755.007h-3.54a2.378 2.378 0 11-4.756-.076 2.379 2.379 0 01.599-4.68h7.481a1.4 1.4 0 001.4-1.4V6.593a1.4 1.4 0 00-1.4-1.4h-1.987A4.748 4.748 0 113.4 8.149zM8.149 5.77a.6.6 0 01.6.6v1.178h1.178a.6.6 0 110 1.2H8.249a.7.7 0 01-.7-.7V6.37a.6.6 0 01.6-.6zm6.238 11.26h-4.181A2.377 2.377 0 006.108 17a1.178 1.178 0 01.262-2.326h7.481a2.6 2.6 0 002.6-2.6v-2.14h1.01a1.4 1.4 0 011.299.88l.54 1.348c.065.165.1.341.1.52v3.17c0 .56-.392 1.029-.915 1.148a2.377 2.377 0 00-4.098.03zM6.97 18.222a1.178 1.178 0 112.355 0 1.178 1.178 0 01-2.355 0zm8.296 0a1.178 1.178 0 112.355 0 1.178 1.178 0 01-2.355 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
