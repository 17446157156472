export default {
  isLoading: true,
  isUploading: false,
  flags: [],
  flag: null,
  flagTypes: [],

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

  filters: {
    params: {
      flagTypeId: null,
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },
}
