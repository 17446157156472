import axios from '@axios'
import project from '@/store/account/project/moduleProject'

const baseURL = process.env.VUE_APP_API_LIVESTREAM

const version = 1

export default {
  getScenes({ commit }, videoId) {
    commit('SET_FETCHING_SCENES', true)
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v${version}/${project.state.activeProjectId}/live-videos/${videoId}/scenes`)
        .then(response => {
          commit('SET_SCENES', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => { commit('SET_FETCHING_SCENES', false) })
    })
  },
  addScene({ commit }, { videoId, data }) {
    commit('SET_ADDING_SCENE', true)
    return new Promise((resolve, reject) => {
      axios.post(`${baseURL}api/v${version}/${project.state.activeProjectId}/live-videos/${videoId}/scenes`, data)
        .then(response => {
          commit('ADD_SCENE', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => { commit('SET_ADDING_SCENE', false) })
    })
  },
  updateScenePosition({ commit }, { videoId, data }) {
    commit('SET_UPDATING_SCENE', true)
    return new Promise((resolve, reject) => {
      axios.put(`${baseURL}api/v${version}/${project.state.activeProjectId}/live-videos/${videoId}/scenes/position`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => { commit('SET_UPDATING_SCENE', false) })
    })
  },
  replaceScene({ commit }, { videoId, data }) {
    commit('SET_REPLACING_SCENE', true)
    return new Promise((resolve, reject) => {
      axios.put(`${baseURL}api/v${version}/${project.state.activeProjectId}/live-videos/${videoId}/scenes/replace`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => { commit('SET_REPLACING_SCENE', false) })
    })
  },
  getScene({ commit }, { videoId, id }) {
    commit('SET_GETTING_SCENE', true)
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}api/v${version}/${project.state.activeProjectId}/live-videos/${videoId}/scenes/${id}`)
        .then(response => {
          commit('SET_SCENE', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => { commit('SET_GETTING_SCENE', false) })
    })
  },
  deleteSceneById({ commit }, { videoId, data }) {
    commit('SET_DELETING_SCENE', true)
    return new Promise((resolve, reject) => {
      axios
        .delete(`${baseURL}api/v${version}/${project.state.activeProjectId}/live-videos/${videoId}/scenes`, {
          data,
        })
        .then(response => {
          commit('DELETE_SCENE', data.channelId)
          resolve(response)
        })
        .catch(error => { reject(error) })
        .finally(() => { commit('SET_DELETING_SCENE', false) })
    })
  },
}
