import AdSchedule from '@/models/ad-schedules/adSchedules'
import project from '@/store/account/project/moduleProject'

export default {
  adSchedules: [],
  defaultAdSchedules: [],
  adSchedule: new AdSchedule(),
  adFunctions: [],
  isLoading: true,
  filters: {
    params: {
      ProjectId: project.state.activeProjectId,
      // page: 1,
      // take: 10,
      search: '',
      order: 'desc',
    },
  },
  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

  options: {
    adManagerOptions: null,
    vpaidModeOptions: [],
    adSeenOnSubsequentVisitOptions: [],
    adBreakTypeOptions: [],
    breakTimingTypeOptions: [],
  },
}
