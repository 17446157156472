<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.472 5.348c.215-.167.473-.286.76-.34l1.984-.374a1.825 1.825 0 012.122 1.431L20.54 17.05a1.775 1.775 0 01-1.415 2.098l-1.983.374a1.825 1.825 0 01-2.123-1.431l-1.125-5.615v5.174a1.8 1.8 0 01-1.8 1.8H9.979c-.46 0-.881-.174-1.2-.459a1.793 1.793 0 01-1.2.459H5.465a1.8 1.8 0 01-1.8-1.8V6.506a1.8 1.8 0 011.8-1.8h2.114c.461 0 .882.174 1.2.459a1.793 1.793 0 011.2-.459h2.115c.553 0 1.047.25 1.378.642zm4.29 12.274a.555.555 0 00.436-.661.574.574 0 00-.662-.456.555.555 0 00-.436.661.574.574 0 00.661.456zm-10.676-.465a.564.564 0 11-1.128 0 .564.564 0 011.128 0zm4.515 0a.564.564 0 11-1.129 0 .564.564 0 011.129 0z"
    />
  </svg>
</template>
