<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.23 15.563v-2.079a2.971 2.971 0 000-5.82V5.544c0-1.25-1.368-2.017-2.434-1.366l-3.973 2.427a1.2 1.2 0 01-.625.176H7.11a3.711 3.711 0 00-1.264 7.202l.91 4.552a1.903 1.903 0 003.769-.373v-3.959h.653c.228 0 .451.066.644.188l3.945 2.522c1.065.68 2.462-.084 2.462-1.348zm-1.2-10.02a.4.4 0 00-.609-.342l-3.973 2.427a2.4 2.4 0 01-1.25.352H7.11a2.511 2.511 0 000 5.022h4.068c.457 0 .905.131 1.29.377l3.945 2.522a.4.4 0 00.616-.338V5.543zm1.2 6.698V8.908a1.771 1.771 0 010 3.333zm-8.904 1.961h-2.21l.818 4.097a.703.703 0 001.392-.138v-3.959z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
