<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.17 5a.6.6 0 01.6-.6h3.83v3.83a.6.6 0 11-1.2 0V5.6h-2.63a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.424 4.576a.6.6 0 010 .848l-3.23 3.231a.6.6 0 01-.849-.848l3.23-3.231a.6.6 0 01.85 0zM5 15.17a.6.6 0 01.6.6v2.63h2.63a.6.6 0 110 1.2H4.4v-3.83a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.655 15.345a.6.6 0 010 .848l-3.23 3.231a.6.6 0 01-.85-.848l3.232-3.231a.6.6 0 01.848 0zM4.4 4.4h3.83a.6.6 0 010 1.2H5.6v2.63a.6.6 0 11-1.2 0V4.4z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.576 4.576a.6.6 0 01.848 0l3.231 3.23a.6.6 0 01-.848.85L4.576 5.423a.6.6 0 010-.848zM19 15.17a.6.6 0 01.6.6v3.83h-3.83a.6.6 0 110-1.2h2.63v-2.63a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.345 15.345a.6.6 0 01.849 0l3.23 3.23a.6.6 0 11-.848.85l-3.231-3.232a.6.6 0 010-.848z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
