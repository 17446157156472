<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 18 18"
    style="fill: currentColor"
  >
    <rect
      width="18"
      height="18"
      rx="3"
      fill="#1DA1F2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9966 6.80712C14.0009 6.91954 14.0024 7.03192 14.0024 7.14435C14.0024 10.5529 11.6046 14.4879 7.21996 14.4879C5.87307 14.4879 4.62066 14.0586 3.56543 13.3278C3.75196 13.3483 3.9414 13.3636 4.13374 13.3636C5.2505 13.3636 6.27908 12.9496 7.09496 12.2546C6.05185 12.2393 5.17105 11.4881 4.86727 10.466C5.0131 10.4967 5.16328 10.5121 5.31687 10.5121C5.53344 10.5121 5.7437 10.4814 5.94525 10.4201C4.85369 10.185 4.03151 9.14258 4.03151 7.89056C4.03151 7.87523 4.03151 7.87009 4.03151 7.85986C4.35322 8.04895 4.72143 8.16642 5.11241 8.18175C4.47191 7.71672 4.05088 6.92464 4.05088 6.03034C4.05088 5.5602 4.16813 5.11558 4.37453 4.73231C5.54991 6.29606 7.30718 7.32324 9.28827 7.43056C9.24757 7.24148 9.22673 7.04222 9.22673 6.84292C9.22673 5.41716 10.2941 4.26221 11.6109 4.26221C12.2965 4.26221 12.9157 4.57398 13.3502 5.07479C13.8943 4.96236 14.404 4.74774 14.8653 4.45135C14.6865 5.05436 14.3091 5.56017 13.8158 5.87701C14.2984 5.81569 14.7587 5.67781 15.1855 5.4734C14.8653 5.98954 14.4622 6.44429 13.9966 6.80712Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
