import BiddingTemplate from './bidders'

class AdUnit {
  constructor() {
    this.adServerCurrency = null
    this.bidOptionType = ''
    this.auctionDelay = 0
    this.priceGranularities = []
    this.bidders = []
  }

  fromData(data) {
    this.adServerCurrency = data.adServerCurrency ? data.adServerCurrency : null
    this.bidOptionType = data.bidOptionType ? data.bidOptionType : ''
    this.auctionDelay = data.auctionDelay ? data.auctionDelay : 0
    this.priceGranularities = data.priceGranularities ? data.priceGranularities : []
    this.bidders = data.bidders ? data.bidders.map(x => {
      const bidders = new BiddingTemplate()
      bidders.fromData(x)
      return bidders
    }) : null
  }
}

export default AdUnit
