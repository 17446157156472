<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0 0 24 24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.4a8.6 8.6 0 100 17.2 8.6 8.6 0 000-17.2zm3.853 6.739a.6.6 0 10-.849-.849l-4.147 4.147-1.861-1.861a.6.6 0 00-.849.848l2.215 2.215a.7.7 0 00.99 0l4.5-4.5z"
    />
  </svg>
</template>
