<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.052 6.86a.6.6 0 00-.96-.72L9.107 8.786l1.985 2.645a.6.6 0 00.96-.72l-.995-1.325h6.086a2.257 2.257 0 01.52 4.454c-.282.066-.52.295-.52.585 0 .373.305.682.67.61a3.458 3.458 0 00-.67-6.85h-6.086l.995-1.325z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.943 13.9l-.995-1.326a.6.6 0 01.96-.72l1.985 2.646-1.985 2.646a.6.6 0 11-.96-.72l.995-1.326H6.857a3.457 3.457 0 01-.67-6.85c.365-.071.67.238.67.61 0 .29-.238.52-.52.586a2.258 2.258 0 00.52 4.454h6.086z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
