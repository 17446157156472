<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.7998 4.59941C3.80569 4.59941 2.9998 5.4053 2.9998 6.39941V9.59941C2.9998 10.5935 3.80569 11.3994 4.7998 11.3994H8.7998C9.57923 11.3994 10.2444 10.9037 10.4943 10.2084L10.746 9.50826L12.6938 10.7256C12.827 10.8089 12.9998 10.7131 12.9998 10.556V5.44281C12.9998 5.28573 12.827 5.18996 12.6938 5.27321L10.746 6.49057L10.4943 5.7904C10.2444 5.09514 9.57923 4.59941 8.7998 4.59941H4.7998ZM1.7998 6.39941C1.7998 4.74256 3.14295 3.39941 4.7998 3.39941H8.7998C9.84053 3.39941 10.7566 3.92915 11.2944 4.73272L12.0578 4.25561C12.9903 3.67282 14.1998 4.3432 14.1998 5.44281V10.556C14.1998 11.6556 12.9903 12.326 12.0578 11.7432L11.2944 11.2661C10.7566 12.0697 9.84053 12.5994 8.7998 12.5994H4.7998C3.14295 12.5994 1.7998 11.2563 1.7998 9.59941V6.39941Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
