<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.53 8.42a.6.6 0 01.6.6v1.91h1.91a.6.6 0 010 1.2h-1.91v1.91a.6.6 0 11-1.2 0v-1.91H9.02a.6.6 0 110-1.2h1.91V9.02a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.53 3.4a8.13 8.13 0 105.308 14.287l2.738 2.737a.6.6 0 00.848-.848l-2.738-2.738A8.13 8.13 0 0011.53 3.4zM4.6 11.53a6.93 6.93 0 1113.859 0 6.93 6.93 0 01-13.859 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
