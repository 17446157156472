class CustomOverlays {
  constructor() {
    this.position = null
    this.caption = ''
    this.secondsFrom = null
    this.secondsTo = null
  }

  fromData(data) {
    this.position = data.position || null
    this.caption = data.caption || null
    this.secondsFrom = data.secondsFrom || null
    this.secondsTo = data.secondsTo || null
  }
}

export default CustomOverlays
