<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.983 4.51a3.6 3.6 0 00-3.853 0L4.135 8.307c-.93.589-.513 2.029.589 2.029h1.299v7.253h-.91a1.506 1.506 0 100 3.011H19a1.506 1.506 0 100-3.011h-.91v-7.253h1.3c1.101 0 1.519-1.44.588-2.03L13.983 4.51zm-6.76 13.079h2.422v-7.253H7.223v7.253zM6.63 9.136h12.414l-5.703-3.613a2.4 2.4 0 00-2.568 0L5.069 9.136H6.63zm10.26 1.2v7.253h-2.422v-7.253h2.423zm-3.622 0v7.253h-2.423v-7.253h2.423zm-8.46 8.758c0-.169.136-.305.305-.305H19a.306.306 0 110 .611H5.113a.306.306 0 01-.305-.306z"
    />
  </svg>
</template>
