<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6 5a6.87 6.87 0 00-5.086 2.244.6.6 0 00.886.81 5.686 5.686 0 019.87 3.395l-.714-.596a.6.6 0 10-.769.922l1.715 1.429.384.32.384-.32 1.714-1.429a.6.6 0 10-.768-.922l-.742.619A6.886 6.886 0 0011.6 5zM3.216 11.996a.6.6 0 00.768.922l.743-.619a6.886 6.886 0 0011.959 4.229.6.6 0 00-.886-.81 5.686 5.686 0 01-9.869-3.395l.713.595a.6.6 0 00.769-.922l-1.715-1.428-.384-.32-.384.32-1.714 1.428z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
