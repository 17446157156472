<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.773 4.732a.6.6 0 00.269.805L17.968 12 5.042 18.463a.6.6 0 00.536 1.074l13.106-6.553a1.1 1.1 0 000-1.968L5.578 4.463a.6.6 0 00-.805.269z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
