<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.405 14.398a2.644 2.644 0 00-.005.17v1.713a2.6 2.6 0 002.6 2.6h12a2.6 2.6 0 002.6-2.6v-1.714a19.16 19.16 0 00-.572-4.363l-.592-2.367a3.6 3.6 0 00-3.493-2.727H8.057a3.6 3.6 0 00-3.493 2.727l-.592 2.367a19.167 19.167 0 00-.567 4.194zm2.323-6.27A2.4 2.4 0 018.057 6.31h7.886a2.4 2.4 0 012.329 1.818l.592 2.367c.146.585.262 1.176.349 1.772a2.588 2.588 0 00-1.213-.3H6c-.438 0-.85.108-1.213.3.087-.596.203-1.187.35-1.772l.591-2.367zM4.6 14.853c0-.133.001-.265.004-.398A1.4 1.4 0 016 13.167h12a1.4 1.4 0 011.396 1.288c.003.133.004.265.004.398v1.428a1.4 1.4 0 01-1.4 1.4H6a1.4 1.4 0 01-1.4-1.4v-1.428z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.686 15.424a.6.6 0 01.6-.6H9a.6.6 0 110 1.2H7.286a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
