/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
// import axiosAnalytics from '@/libs/axiosAnalytics'
import project from '@/store/account/project/moduleProject'
import organization from '@/store/account/organization/moduleOrganization'

const analyticsBaseUrl = {
  baseURL: process.env.VUE_APP_API_ANALYTICS_URL,
}

const audioAnalyticsBaseUrl = {
  baseURL: process.env.VUE_APP_API_ANALYTICS_AUDIO,
}
export default {

  getLiveViewers({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/analytics/liveViewers`, { baseURL: analyticsBaseUrl.baseURL, params: state.liveViewersFilters.params })
        .then(response => {
          // commit('SET_PAGINATION', response.data.result)
          commit('SET_LIVE_VIEWERS', response.data.result)
          resolve(response.data.result)
        })
        .catch(error => { reject(error) })
    })
  },

  getUsageData({ commit }) {
    commit('LOADING_USAGE_DATA', true)

    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/usage`)
        .then(response => {
          // commit('SET_PAGINATION', response.data.result)
          commit('SET_USAGE_DATA', response.data.result)
          resolve(response.data.result)
        })
        .catch(error => {
          reject(error)
          commit('SET_USAGE_DATA', {})
        })
        .finally(() => {
          commit('LOADING_USAGE_DATA', false)
        })
    })
  },

  getTopContent({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/analytics/topContent`, { baseURL: analyticsBaseUrl.baseURL, params: state.topContentFilters.params })
        .then(response => {
          // commit('SET_PAGINATION', response.data.result)
          commit('SET_TOP_CONTENT', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getVideoAnalytics({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/analytics/videos/${id}`, analyticsBaseUrl)
        .then(response => {
          commit('SET_VIDEO_ANALYTICS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getTimeWatchedPerViewer({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/analytics/videos/${id}/time-watched-per-viewer`, analyticsBaseUrl)
        .then(response => {
          commit('SET_TIME_WATCHED_PER_VIEWER', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getTopDevices({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/analytics/topDevices`, analyticsBaseUrl)
        .then(response => {
          // commit('SET_PAGINATION', response.data.result)
          commit('SET_TOP_DEVICES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getTopOperatingSystems({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/analytics/topOperatingSystems`, analyticsBaseUrl)
        .then(response => {
          // commit('SET_PAGINATION', response.data.result)
          commit('SET_TOP_OPERATING_SYSTEMS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getTopDomains({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/analytics/topDomains`, analyticsBaseUrl)
        .then(response => {
          // commit('SET_PAGINATION', response.data.result)
          commit('SET_TOP_DOMAINS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getTopBrowsers({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/analytics/topBrowsers`, analyticsBaseUrl)
        .then(response => {
          // commit('SET_PAGINATION', response.data.result)
          commit('SET_TOP_BROWSERS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getTopCountries({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/analytics/topCountries`, analyticsBaseUrl)
        .then(response => {
          // commit('SET_PAGINATION', response.data.result)
          commit('SET_TOP_COUNTRIES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  //
  //
  // ANALYTICS CONTENT ACTIONS
  //
  //
  getContent({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/custom-reports/fields`)
        .then(response => {
          commit('SET_CUSTOMREPORTS_OPTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  //
  //
  // CUSTOM REPORTS
  //
  //
  getCustomReportsFields({ commit, state }, filterId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/custom-reports/fields?reportEntityType=${filterId}`)
        .then(response => {
          commit('SET_CUSTOMREPORTS_OPTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getCustomReportsFilterOperators({ commit, state }, filterId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/report-filter-operators/operators/select-list/${filterId}`)
        .then(response => {
          // commit('SET_CUSTOMREPORTS_FILTEROPERATORS', response.data.result)
          resolve(response.data.result)
        })
        .catch(error => { reject(error) })
    })
  },
  getCustomReports({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/custom-reports`, state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_CUSTOMREPORTS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getCustomReportById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/custom-reports/${id}`)
        .then(response => {
          commit('SET_CUSTOMREPORT', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getCustomReportByQuery({ commit, state }, { data, type }) {
    const obj = (data === 'table' || data === 'table-all') ? state.customReportViewTableQuery : state.customReportViewChartQuery
    obj.take ??= state.customReportViewTableFilters.params.take
    obj.page ??= state.customReportViewTableFilters.params.page
    if (data === 'table') { obj.page = state.customReportViewTableFilters.params.page; obj.take = 10; obj.takeAll = false } else { obj.takeAll = true }
    let url = analyticsBaseUrl
    if (type !== 1) {
      url = audioAnalyticsBaseUrl
    }
    return new Promise((resolve, reject) => {
      // axios.get(`/api/projects/${project.state.activeProjectId}/reports/query?${Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&')}`, analyticsBaseUrl)
      axios.get(`/api/projects/${project.state.activeProjectId}/reports?query=${JSON.stringify(obj)}`, url)
        .then(async response => {
          if (data === 'table') {
            await commit('SET_CUSTOMREPORTS_TABLE_PAGINATION', response.data.result)
            await commit('SET_CUSTOMREPORT_VIEW_TABLE', response.data.result)
          }
          if (data === 'chart') await commit('SET_CUSTOMREPORT_VIEW_CHART', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  postCustomReport({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/projects/${project.state.activeProjectId}/custom-reports`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putCustomReport({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/projects/${project.state.activeProjectId}/custom-reports`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeCustomReport({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${project.state.activeProjectId}/custom-reports/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  //
  //
  // ANALYTICS USAGE HISTORY ACTIONS
  //
  //
  getOrganizationUsageHistory({ commit, state }) {
    // state.filters.params.organizationId = organization.state.activeOrganizationId
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/usage-history`, state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_USAGEHISTORY_TOTAL', response.data.result.total)
          commit('SET_USAGEHISTORY', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getProjectUsageHistory({ commit, state }, projectId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${projectId}/usage-history`, state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_USAGEHISTORY_TOTAL', response.data.result.total)
          commit('SET_USAGEHISTORY', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAllOrganizationUsageHistory({ commit, state }) {
    state.filters.params.takeAll = true
    // state.filters.params.organizationId = organization.state.activeOrganizationId
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/usage-history`, state.filters)
        .then(response => {
          state.filters.params.takeAll = false
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAllProjectUsageHistory({ commit, state }, projectId) {
    state.filters.params.takeAll = true
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${projectId}/usage-history`, state.filters)
        .then(response => {
          state.filters.params.takeAll = false
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getOrganizationUsageContract({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${organization.state.activeOrganizationId}/contract`)
        .then(response => {
          commit('SET_CONTRACT_TOTAL', response.data.result.contractModel)
          commit('SET_CONTRACT_USAGE', response.data.result.usageModel)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
