<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.172 13a2.829 2.829 0 115.657 0 2.829 2.829 0 01-5.657 0z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 6.686h1.419A2.6 2.6 0 019.999 4.4h4a2.6 2.6 0 012.582 2.286H18a2.6 2.6 0 012.6 2.6v7.428a2.6 2.6 0 01-2.6 2.6H6a2.6 2.6 0 01-2.6-2.6V9.286a2.6 2.6 0 012.6-2.6zm6 2.285a4.029 4.029 0 100 8.058 4.029 4.029 0 000-8.058z"
    />
  </svg>
</template>
