class SocialDistributionCreate {
  constructor() {
    this.socialMediaHubId = null
    this.videoId = ''
    this.title = ''
    this.description = ''
    this.socialMediaPlatform = null
    this.categoryId = ''
    this.tags = []
    this.privacyStatus = null
    this.isScheduled = false
    this.schedulePostDateTime = ''
  }
}

export default SocialDistributionCreate
