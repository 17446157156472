<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 6.5a3.1 3.1 0 013.1-3.1h2.53a3.1 3.1 0 013.1 3.1v2.53a3.1 3.1 0 01-3.1 3.1H6.5a3.1 3.1 0 01-3.1-3.1V6.5zm3.1-1.9a1.9 1.9 0 00-1.9 1.9v2.53c0 1.049.85 1.9 1.9 1.9h2.53a1.9 1.9 0 001.9-1.9V6.5a1.9 1.9 0 00-1.9-1.9H6.5zM13.282 6.941a2.6 2.6 0 012.6-2.6h2.165a2.6 2.6 0 012.6 2.6v2.165a2.6 2.6 0 01-2.6 2.6h-2.165a2.6 2.6 0 01-2.6-2.6V6.94zm2.6-1.4a1.4 1.4 0 00-1.4 1.4v2.165a1.4 1.4 0 001.4 1.4h2.165a1.4 1.4 0 001.4-1.4V6.94a1.4 1.4 0 00-1.4-1.4h-2.165zM13.282 15.853c0-1.16.94-2.1 2.1-2.1h1.706c1.16 0 2.1.94 2.1 2.1v1.706a2.1 2.1 0 01-2.1 2.1h-1.706a2.1 2.1 0 01-2.1-2.1v-1.706zm2.1-.9a.9.9 0 00-.9.9v1.706a.9.9 0 00.9.9h1.706a.9.9 0 00.9-.9v-1.706a.9.9 0 00-.9-.9h-1.706zM4.812 15.84a2.6 2.6 0 012.6-2.6h2.16a2.6 2.6 0 012.6 2.6V18a2.6 2.6 0 01-2.6 2.6h-2.16a2.6 2.6 0 01-2.6-2.6v-2.16zm2.6-1.4a1.4 1.4 0 00-1.4 1.4V18a1.4 1.4 0 001.4 1.4h2.16a1.4 1.4 0 001.4-1.4v-2.16a1.4 1.4 0 00-1.4-1.4h-2.16z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
