<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.9 6a2.6 2.6 0 012.6-2.6h10.77a2.6 2.6 0 012.6 2.6v2.77a2.6 2.6 0 01-2.6 2.6H5.5a2.6 2.6 0 01-2.6-2.6V6zM5.13 15.23a2.6 2.6 0 012.6-2.6H18.5a2.6 2.6 0 012.6 2.6V18a2.6 2.6 0 01-2.6 2.6H7.73a2.6 2.6 0 01-2.6-2.6v-2.77z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
