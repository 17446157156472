<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 7.5A2.5 2.5 0 014.5 5h15A2.5 2.5 0 0122 7.5v9a2.5 2.5 0 01-2.5 2.5h-15A2.5 2.5 0 012 16.5v-9zM4.5 6A1.5 1.5 0 003 7.5v9A1.5 1.5 0 004.5 18h15a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0019.5 6h-15z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.82 8.5a3.5 3.5 0 102.178 6.24 3.5 3.5 0 100-5.48A3.485 3.485 0 009.82 8.5zm2.867 1.492c.399.568.633 1.26.633 2.008 0 .747-.234 1.44-.633 2.008a2.5 2.5 0 100-4.017zM7.32 12a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
