<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4 8A2.6 2.6 0 016 5.4h12A2.6 2.6 0 0120.6 8v8a2.6 2.6 0 01-2.6 2.6H6A2.6 2.6 0 013.4 16V8zM6 6.6A1.4 1.4 0 004.6 8v8A1.4 1.4 0 006 17.4h12a1.4 1.4 0 001.4-1.4V8A1.4 1.4 0 0018 6.6H6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.491 7.682a.6.6 0 01.827-.19l6.94 4.337a1.4 1.4 0 001.484 0l6.94-4.338a.6.6 0 01.636 1.018l-6.94 4.337a2.6 2.6 0 01-2.756 0L3.682 8.51a.6.6 0 01-.19-.827z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
