class ApiKeyUser {
  constructor() {
    this.apiKeyId = 0
    this.apiName = ''
    this.hasExpireTime = true
    this.startDateTime = null
    this.expireDateTime = null
  }

  fromData(data, toGet = false) {
    this.apiKeyId = data?.apiKeyId || data?.id || 0
    this.apiName = data?.apiName || data?.name || ''

    if (toGet) {
      this.startDateTime = data?.startDateTime || data?.startDate || null
      this.expireDateTime = data?.expireDateTime || data?.expireDate || null
    } else {
      this.expireDateTime = data.expireDateTime ? data.expireDateTime.split(' ').join('T') : null
      this.startDateTime = data.startDateTime ? data.startDateTime.split(' ').join('T') : null
      // this.expireDateTime = '2021-07-05T16:27:03.894Z'
      // this.startDateTime = '2021-07-05T16:27:03.894Z'
    }
    this.hasExpireTime = data.hasExpireTime
  }
}

export default ApiKeyUser
