<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 4.6A1.4 1.4 0 004.6 6v3.23a.6.6 0 11-1.2 0V6A2.6 2.6 0 016 3.4h3.23a.6.6 0 010 1.2H6zM14.17 4a.6.6 0 01.6-.6H18A2.6 2.6 0 0120.6 6v3.23a.6.6 0 11-1.2 0V6A1.4 1.4 0 0018 4.6h-3.23a.6.6 0 01-.6-.6zM4 14.17a.6.6 0 01.6.6V18A1.4 1.4 0 006 19.4h3.23a.6.6 0 010 1.2H6A2.6 2.6 0 013.4 18v-3.23a.6.6 0 01.6-.6zM20 14.17a.6.6 0 01.6.6V18a2.6 2.6 0 01-2.6 2.6h-3.23a.6.6 0 110-1.2H18a1.4 1.4 0 001.4-1.4v-3.23a.6.6 0 01.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
