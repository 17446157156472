import ApiKey from '@/models/api-keys/ApiKey'

export default {
  isLoading: true,

  apiKey: new ApiKey(),
  apiKeys: [],

  apiKeyRoleOptions: [],
  apiKeyProjectsOptions: [],

  activeRoleId: null,

  filters: {
    params: {
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },
}
