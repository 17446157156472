<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 10.733a.6.6 0 01.6.6V18A1.4 1.4 0 006 19.4h12a1.4 1.4 0 001.4-1.4v-6.667a.6.6 0 011.2 0V18a2.6 2.6 0 01-2.6 2.6H6A2.6 2.6 0 013.4 18v-6.667a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.167 17.499L7.41 13.108a.6.6 0 01.814-.882l3.326 3.07V4a.6.6 0 111.2 0v11.218l2.976-2.976a.6.6 0 01.848.849l-4.407 4.408z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
