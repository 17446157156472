<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 5.6A2.4 2.4 0 005.6 8v8A2.4 2.4 0 008 18.4h8a2.4 2.4 0 002.4-2.4v-2.056a.6.6 0 011.2 0V16a3.6 3.6 0 01-3.6 3.6H8A3.6 3.6 0 014.4 16V8A3.6 3.6 0 018 4.4h2.25a.6.6 0 110 1.2H8zm5.733-.6a.6.6 0 01.6-.6H19.6V9.667a.6.6 0 11-1.2 0V6.449l-3.642 3.642a.6.6 0 11-.849-.849L17.552 5.6h-3.219a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
