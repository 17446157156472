<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0 0 17 18"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.62434 7.6493C5.44423 7.4897 5.44423 7.22104 5.62434 7.06145L8.49967 4.51367L11.375 7.06145C11.5551 7.22104 11.5551 7.4897 11.375 7.64929C11.2074 7.79782 10.9435 7.79782 10.7759 7.6493L8.49967 5.63241L6.22349 7.6493C6.05587 7.79782 5.79196 7.79782 5.62434 7.6493Z"
      fill="currentColor"
    />
    <path
      d="M5.62434 10.3507C5.44423 10.5102 5.44423 10.7789 5.62434 10.9385L8.49967 13.4863L11.375 10.9385C11.5551 10.7789 11.5551 10.5102 11.375 10.3507C11.2074 10.2021 10.9435 10.2021 10.7759 10.3507L8.49967 12.3675L6.22349 10.3507C6.05587 10.2021 5.79196 10.2021 5.62434 10.3507Z"
      fill="currentColor"
    />
  </svg>
</template>
