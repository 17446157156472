<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 5.60833C17.4833 5.60833 17.4583 5.60833 17.4333 5.60833C13.025 5.16667 8.62501 5 4.26667 5.44167L2.56667 5.60833C2.21667 5.64167 1.90834 5.39167 1.87501 5.04167C1.84167 4.69167 2.09167 4.39167 2.43334 4.35833L4.13334 4.19167C8.56667 3.74167 13.0583 3.91667 17.5583 4.35833C17.9 4.39167 18.15 4.7 18.1167 5.04167C18.0917 5.36667 17.8167 5.60833 17.5 5.60833Z"
      fill="currentColor"
    />
    <path
      d="M7.08334 4.76669C7.05 4.76669 7.01667 4.76669 6.975 4.75835C6.64167 4.70002 6.40834 4.37502 6.46667 4.04169L6.65 2.95002C6.78334 2.15002 6.96667 1.04169 8.90834 1.04169H11.0917C13.0417 1.04169 13.225 2.19169 13.35 2.95835L13.5333 4.04169C13.5917 4.38335 13.3583 4.70835 13.025 4.75835C12.6833 4.81669 12.3583 4.58335 12.3083 4.25002L12.125 3.16669C12.0083 2.44169 11.9833 2.30002 11.1 2.30002H8.91667C8.03334 2.30002 8.01667 2.41669 7.89167 3.15835L7.7 4.24169C7.65 4.55002 7.38334 4.76669 7.08334 4.76669Z"
      fill="currentColor"
    />
    <path
      d="M12.675 18.9583H7.325C4.41666 18.9583 4.3 17.35 4.20833 16.05L3.66666 7.65833C3.64166 7.31667 3.90833 7.01667 4.25 6.99167C4.6 6.975 4.89166 7.23334 4.91666 7.575L5.45833 15.9667C5.55 17.2333 5.58333 17.7083 7.325 17.7083H12.675C14.425 17.7083 14.4583 17.2333 14.5417 15.9667L15.0833 7.575C15.1083 7.23334 15.4083 6.975 15.75 6.99167C16.0917 7.01667 16.3583 7.30833 16.3333 7.65833L15.7917 16.05C15.7 17.35 15.5833 18.9583 12.675 18.9583Z"
      fill="currentColor"
    />
    <path
      d="M11.3833 14.375H8.60834C8.26667 14.375 7.98334 14.0917 7.98334 13.75C7.98334 13.4083 8.26667 13.125 8.60834 13.125H11.3833C11.725 13.125 12.0083 13.4083 12.0083 13.75C12.0083 14.0917 11.725 14.375 11.3833 14.375Z"
      fill="currentColor"
    />
    <path
      d="M12.0833 11.0417H7.91667C7.57501 11.0417 7.29167 10.7584 7.29167 10.4167C7.29167 10.075 7.57501 9.79169 7.91667 9.79169H12.0833C12.425 9.79169 12.7083 10.075 12.7083 10.4167C12.7083 10.7584 12.425 11.0417 12.0833 11.0417Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
