import Bidding from '@/models/ad-schedules/bidding/bidding'
import BiddingTemplateBidders from '@/models/ad-schedules/bidding/biddingTemplateBidders'
import BidParamValues from '@/models/ad-schedules/bidding/bidParamValues'

export default {
  SET_BIDDING: (state, data) => {
    const bidding = new Bidding()
    bidding.fromData(data)
    state.bidding = bidding
  },
  SET_OPTIONS: (state, data) => { state.options = data.data.result },
  SET_BIDDINGS: (state, data) => {
    state.biddings = data.map(x => ({
      name: x.name,
      id: x.value,
    }))
  },
  SET_BIDDING_TEMPLATE_ON_LIST: (state, data) => {
    state.biddings.unshift(data)
  },
  CLEAR_BIDDING: state => { state.bidding = new Bidding() },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  UPDATE_IS_BIDDING_ADD: (state, data) => { state.isAdd = data },
  UPDATE_NAME: (state, data) => { state.bidding.name = data },
  UPDATE_MEDIATION_OPTION_ID: (state, data) => { state.bidding.mediationOptionId = data },
  ADD_BID_PARAM_VALUES: state => state.bidding.biddingTemplateBidders.bidParamValues.push(new BidParamValues()),
  ADD_BIDDING_TEMPLATE_BIDDERS: (state, data) => {
    const biddingTemplateBidders = new BiddingTemplateBidders()
    biddingTemplateBidders.fromData(data)
    state.bidding.biddingTemplateBidders.push(biddingTemplateBidders)
  },
  REMOVE_SEGMENT: (state, data) => {
    state.bidding.biddingTemplateBidders[data].isDeleted = true
  },
  UPDATE_AD_PARTNER_INPUT_VALUE: (state, data) => {
    state.bidding.biddingTemplateBidders[data.partnerIndex].bidParamValues[data.inputIndex].value = data.value
  },

}
