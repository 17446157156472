<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.125 1.6875C3.81434 1.6875 3.5625 1.93934 3.5625 2.25C3.5625 2.56066 3.81434 2.8125 4.125 2.8125C4.84987 2.8125 5.4375 3.40013 5.4375 4.125C5.4375 4.84987 4.84987 5.4375 4.125 5.4375C3.40013 5.4375 2.8125 4.84987 2.8125 4.125C2.8125 3.81434 2.56066 3.5625 2.25 3.5625C1.93934 3.5625 1.6875 3.81434 1.6875 4.125C1.6875 5.47119 2.77881 6.5625 4.125 6.5625C4.59431 6.5625 5.03264 6.42987 5.40455 6.20004L14.6023 15.3977C14.8219 15.6174 15.1781 15.6174 15.3977 15.3977C15.6174 15.1781 15.6174 14.8219 15.3977 14.6023L6.20004 5.40455C6.42987 5.03264 6.5625 4.59431 6.5625 4.125C6.5625 2.77881 5.47119 1.6875 4.125 1.6875Z"
      fill="#36435E"
    />
    <path
      d="M14.7347 2.50385L13.3925 2.83939C13.1098 2.91009 13.0122 3.26225 13.2183 3.46836L13.4773 3.72728L10.1023 7.10228C9.88258 7.32195 9.88258 7.67811 10.1023 7.89778C10.3219 8.11745 10.6781 8.11745 10.8977 7.89778L14.2727 4.52278L14.5317 4.7817C14.7378 4.98781 15.0899 4.89026 15.1606 4.60748L15.4962 3.26533C15.5404 3.18271 15.5625 3.09137 15.5625 3.00003L15.5984 2.85637C15.6671 2.58172 15.4183 2.33295 15.1437 2.40161L15 2.43753C14.9087 2.43753 14.8173 2.45964 14.7347 2.50385Z"
      fill="#36435E"
    />
    <path
      d="M7.67808 10.322C8.19064 10.8345 8.19064 11.6655 7.67808 12.1781L3.92808 15.9281C3.41551 16.4407 2.58449 16.4407 2.07192 15.9281C1.55936 15.4155 1.55936 14.5845 2.07192 14.072L5.82192 10.322C6.33449 9.80939 7.16551 9.80939 7.67808 10.322Z"
      fill="#36435E"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
