<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.248 18.065A2.447 2.447 0 013.4 15.692V6A2.6 2.6 0 016 3.4h7.693a2.6 2.6 0 012.6 2.6v2.323h1.122a2.6 2.6 0 012.414 1.634l.585 1.462a2.6 2.6 0 01.186.966v3.307a2.447 2.447 0 01-1.848 2.373l.002.089a2.446 2.446 0 01-4.892 0v-.015h-3.724v.015a2.446 2.446 0 11-4.89-.09zM4.6 14.446h9.093a2.6 2.6 0 002.6-2.6V9.523h1.122a1.4 1.4 0 011.3.88l.585 1.462c.066.166.1.342.1.52v3.307c0 .599-.422 1.099-.985 1.219a2.445 2.445 0 00-4.23.027h-4.37a2.445 2.445 0 00-4.23-.027 1.247 1.247 0 01-.985-1.219v-1.246zm7.802-7.815a.6.6 0 01.044.847l-2.699 2.999a.7.7 0 01-1.015.027l-.848-.849a.6.6 0 01.848-.849l.476.476 2.346-2.607a.6.6 0 01.848-.044zm-4.71 10.277a1.246 1.246 0 100 2.492 1.246 1.246 0 000-2.492zm8.616 0a1.246 1.246 0 100 2.492 1.246 1.246 0 000-2.492z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
