class Channel {
  constructor() {
    this.publicId = ''
    this.name = ''
    this.title = ''
    this.typeId = 1
    this.type = ''
    this.latencyTypeId = 1
    this.latencyType = ''
    this.logo = ''
    this.logoFile = ''
    this.healthStatus = ''
    this.liveStatus = ''
    this.streamServer = ''
    this.streamKey = ''
    this.playbackUrl = ''
    this.defaultScreens = []
    this.livestreamProvider = ''
    this.source = ''
    this.inputType = ''
    this.filePath = ''
  }

  fromData(data) {
    this.publicId = data.publicId || ''
    this.name = data.name || ''
    this.title = data.title || ''
    this.typeId = data.typeId || 1
    this.type = data.type || ''
    this.latencyTypeId = data.latencyTypeId || 1
    this.latencyType = data.latencyType || ''
    this.logo = data.logo || ''
    this.logoFile = data.logoFile || ''
    this.healthStatus = data.healthStatus || ''
    this.liveStatus = data.liveStatus || ''
    this.streamServer = data.streamServer || ''
    this.streamKey = data.streamKey || ''
    this.playbackUrl = data.playbackUrl || ''
    this.defaultScreens = data.defaultScreens || []
    this.livestreamProvider = data.livestreamProvider || ''
    this.source = data.source || ''
    this.inputType = data.inputType || ''
    this.filePath = data.filePath || ''
  }
}

export default Channel
