<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7 18.5583H12.5917C8.8917 18.5583 7.10837 17.1 6.80003 13.8333C6.7667 13.4917 7.0167 13.1833 7.3667 13.15C7.70003 13.1166 8.0167 13.375 8.05003 13.7167C8.2917 16.3333 9.52503 17.3083 12.6 17.3083H12.7084C16.1 17.3083 17.3 16.1083 17.3 12.7167V7.28332C17.3 3.89165 16.1 2.69165 12.7084 2.69165H12.6C9.50836 2.69165 8.27503 3.68332 8.05003 6.34998C8.00837 6.69165 7.7167 6.94998 7.3667 6.91665C7.0167 6.89165 6.7667 6.58332 6.7917 6.24165C7.07503 2.92498 8.8667 1.44165 12.5917 1.44165H12.7C16.7917 1.44165 18.5417 3.19165 18.5417 7.28332V12.7167C18.5417 16.8083 16.7917 18.5583 12.7 18.5583Z"
      fill="currentColor"
    />
    <path
      d="M12.5 10.625H3.0167C2.67503 10.625 2.3917 10.3417 2.3917 9.99998C2.3917 9.65832 2.67503 9.37498 3.0167 9.37498H12.5C12.8417 9.37498 13.125 9.65832 13.125 9.99998C13.125 10.3417 12.8417 10.625 12.5 10.625Z"
      fill="currentColor"
    />
    <path
      d="M4.87503 13.4166C4.7167 13.4166 4.55837 13.3583 4.43337 13.2333L1.6417 10.4416C1.40003 10.2 1.40003 9.79998 1.6417 9.55832L4.43337 6.76665C4.67503 6.52498 5.07503 6.52498 5.3167 6.76665C5.55837 7.00832 5.55837 7.40832 5.3167 7.64998L2.9667 9.99998L5.3167 12.35C5.55837 12.5917 5.55837 12.9916 5.3167 13.2333C5.20003 13.3583 5.03337 13.4166 4.87503 13.4166Z"
      fill="currentColor"
    />
  </svg>

</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
