<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.082 4.55a.6.6 0 01.6.6v13.714a.6.6 0 01-1.2 0V5.15a.6.6 0 01.6-.6zM15.082 4.55a.6.6 0 01.6.6v13.714a.6.6 0 01-1.2 0V5.15a.6.6 0 01.6-.6zM12.682 5.15a.6.6 0 00-1.2 0v13.714a.6.6 0 001.2 0V5.15zM6.199 10.293a.66.66 0 00-.094-.343c-.155-.252-.448-.304-.654-.114L2.957 12.12a.533.533 0 00-.093.115c-.155.252-.114.61.093.8l2.494 2.285c.08.075.179.115.28.115.258 0 .468-.256.468-.572v-4.571zM17.77 10.293v4.571c0 .316.21.572.468.572.1 0 .2-.04.28-.115l2.494-2.285c.206-.19.248-.548.093-.8a.532.532 0 00-.093-.115l-2.494-2.285c-.207-.19-.5-.138-.655.114a.659.659 0 00-.093.343z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
