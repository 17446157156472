<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.375 18.75H4.625a.625.625 0 100 1.25h14.75a.625.625 0 100-1.25zM16.563 4a.625.625 0 00-.625.625v7.453C15.938 14.241 14.17 16 12 16c-2.171 0-3.938-1.76-3.938-3.922V4.625a.625.625 0 10-1.25 0v7.453c0 2.852 2.328 5.172 5.188 5.172s5.188-2.32 5.188-5.172V4.625A.625.625 0 0016.563 4z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
