/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {

  // ////////// //
  /* GeoBlockingRule CRUD */
  getGeoBlockingRules({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/geo-blocking-rules`, state.filters)
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_GEOBLOCKINGRULES', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getGeoBlockingRulesSelectList({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/geo-blocking-rules/options/geo-blocking-rule`)
        .then(async response => {
          commit('SET_GEOBLOCKINGRULES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  postGeoBlockingRule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/projects/${project.state.activeProjectId}/geo-blocking-rules`, data)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putGeoBlockingRules({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/geo-blocking-rules`, data)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getGeoBlockingRuleById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/geo-blocking-rules/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteGeoBlockingRuleById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${project.state.activeProjectId}/geo-blocking-rules/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  /* GeoBlockingRule Restriction Type GET */
  getGeoBlockingRuleTypes({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/restriction-types`)
        .then(response => {
          commit('SET_GEOBLOCKINGRULE_TYPES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
