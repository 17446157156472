/* eslint-disable no-unused-vars */
export default [
  // Ad Schedules Routing
  {
    path: '/:projectId/ad-schedules',
    component: () => import('@/views/adSchedules/AdSchedule.vue'),
    meta: {
      titleText: 'Ad Schedules',
      parentText: 'Dashboard',
      contentClass: 'ad-schedules',
      navActiveLink: 'ad-schedules',
      gjIcon: 'Money',
      breadcrumb: [
        {
          text: 'Ad schedules',
          to: 'ad-schedules',
          active: true,
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'ad-schedules',
        component: () => import('@/views/adSchedules/AdSchedules.vue'),
        meta: {
          titleText: 'Ad Schedules',
          parentText: 'Dashboard',
          contentClass: 'ad-schedules',
          navActiveLink: 'ad-schedules',
          gjIcon: 'Money',
          action: 'read',
          resource: 'ads',
          breadcrumb: [
            {
              text: 'Ad schedules',
              to: 'ad-schedules',
              active: true,
            },
          ],
        },
      },
      {
        path: ':id',
        name: 'ad-schedules-edit',
        component: () => import('@/views/adSchedules/Edit.vue'),
        meta: {
          titleText: 'Ad Schedules',
          parentText: 'Dashboard',
          contentClass: 'ad-schedule',
          navActiveLink: 'ad-schedules',
          gjIcon: 'Money',
          action: 'execute',
          resource: 'ads',
          projectRequired: true,
          breadcrumb: [
            {
              text: 'Ad schedules',
              to: 'ad-schedules',
            },
            {
              dynamic: true,
              text: 'Edit Ad Schedule',
              active: true,
            },
          ],
        },
      },
    ],
  },

  {
    path: '/:projectId/demand-manager',
    name: 'demand-manager',
    component: () => import('@/views/adSchedules/wrappers/Wrappers.vue'),
    meta: {
      titleText: 'Wrappers',
      parentText: 'Dashboard',
      contentClass: 'demand-manager',
      navActiveLink: 'demand-manager',
      gjIcon: 'Money',
      action: 'read',
      resource: 'ads',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Demand Manager',
          to: 'demand-manager',
          active: true,
        },
      ],
    },

  },
  {
    path: '/:projectId/demand-manager/add',
    name: 'wrappers-add',
    component: () => import('@/views/adSchedules/wrappers/Add.vue'),
    meta: {
      titleText: 'Demand Manager',
      parentText: 'Dashboard',
      contentClass: 'wrapper',
      navActiveLink: 'demand-manager',
      gjIcon: 'Money',
      action: 'execute',
      resource: 'ads',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Demand Manager',
          to: 'demand-manager',
        },
        {
          dynamic: false,
          text: 'Create Wrapper',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:projectId/view/demand-manager/:id',
    name: 'wrappers-view',
    component: () => import('@/views/adSchedules/wrappers/View.vue'),
    meta: {
      titleText: 'Demand Manager',
      parentText: 'Dashboard',
      contentClass: 'demand-manager',
      navActiveLink: 'demand-manager',
      gjIcon: 'Money',
      action: 'execute',
      resource: 'ads',
      projectRequired: true,
      breadcrumb: [
        {
          text: 'Demand Manager',
          to: 'demand-manager',
        },
        {
          dynamic: false,
          text: 'View Wrapper',
          active: true,
        },
      ],
    },
  },

]
