<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.327 7.154a1.077 1.077 0 100-2.154 1.077 1.077 0 000 2.154zM16.663 7.154a1.077 1.077 0 100-2.154 1.077 1.077 0 000 2.154zM11.993 7.154a1.077 1.077 0 100-2.154 1.077 1.077 0 000 2.154zM7.327 19a1.077 1.077 0 100-2.154 1.077 1.077 0 000 2.154zM16.663 19a1.077 1.077 0 100-2.154 1.077 1.077 0 000 2.154zM11.993 19a1.077 1.077 0 100-2.154 1.077 1.077 0 000 2.154zM7.327 13.077a1.077 1.077 0 100-2.154 1.077 1.077 0 000 2.154zM16.663 13.077a1.077 1.077 0 100-2.154 1.077 1.077 0 000 2.154zM11.993 13.077a1.077 1.077 0 100-2.154 1.077 1.077 0 000 2.154z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
