<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 6.65a6.1 6.1 0 00-2.6 11.62v.98a1.6 1.6 0 001.6 1.6h2a1.6 1.6 0 001.6-1.6v-.98A6.1 6.1 0 0012 6.65zM6.924 7.74a.6.6 0 000-.849l-.565-.565a.6.6 0 00-.849.848l.566.566a.6.6 0 00.848 0zM5 11.65a.6.6 0 00-.6-.6h-.8a.6.6 0 000 1.2h.8a.6.6 0 00.6-.6zM11.9 5.75a.6.6 0 01-.6-.6v-.8a.6.6 0 111.2 0v.8a.6.6 0 01-.6.6zM19 11.65a.6.6 0 01.6-.6h.8a.6.6 0 010 1.2h-.8a.6.6 0 01-.6-.6zM17.076 7.74a.6.6 0 010-.849l.565-.565a.6.6 0 01.849.848l-.566.566a.6.6 0 01-.848 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
