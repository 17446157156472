<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.968 6.91c0-.332.227-.6.508-.6H16.59c.281 0 .509.268.509.6 0 .33-.228.6-.509.6H6.476c-.28 0-.508-.27-.508-.6zM7.968 12c0-.331.227-.6.508-.6H18.59c.281 0 .509.269.509.6 0 .331-.228.6-.509.6H8.476c-.28 0-.508-.269-.508-.6zM10.968 17.09c0-.33.227-.6.508-.6H21.59c.281 0 .509.27.509.6 0 .332-.228.6-.509.6H11.476c-.28 0-.508-.268-.508-.6zM1.9 6.91a.6.6 0 01.6-.6h.814a.6.6 0 010 1.2H2.5a.6.6 0 01-.6-.6zM3.9 12a.6.6 0 01.6-.6h.814a.6.6 0 110 1.2H4.5a.6.6 0 01-.6-.6zM6.9 17.09a.6.6 0 01.6-.6h.814a.6.6 0 110 1.2H7.5a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
