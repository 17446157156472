import state from './moduleFlagsState'
import mutations from './moduleFlagsMutations'
import actions from './moduleFlagsActions'
import getters from './moduleFlagsGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
