<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.015 4.998C5.27 3.188 7.05 2 9.077 2H15c3.866 0 7 4.477 7 10s-3.134 10-7 10a5.65 5.65 0 01-.077 0H9.077c-2.027 0-3.808-1.187-5.062-2.998C2.76 17.19 2 14.712 2 12c0-2.712.76-5.19 2.015-7.002zM11.945 21C9.61 19.379 8 15.958 8 12s1.61-7.379 3.945-9c-1.585.022-3.07.958-4.184 2.567C6.635 7.193 5.923 9.465 5.923 12c0 2.534.712 4.807 1.838 6.433 1.113 1.609 2.599 2.545 4.184 2.567zM4.837 5.567c1.085-1.566 2.521-2.495 4.059-2.563-.74.509-1.401 1.19-1.958 1.994C5.683 6.81 4.923 9.288 4.923 12c0 2.712.76 5.19 2.015 7.002.557.804 1.218 1.485 1.958 1.994-1.538-.068-2.974-.997-4.059-2.563C3.712 16.807 3 14.534 3 12c0-2.535.712-4.807 1.837-6.433zM21 12c0 2.581-.734 4.875-1.87 6.498C17.994 20.123 16.518 21 15 21s-2.993-.877-4.13-2.502C9.734 16.875 9 14.58 9 12c0-2.581.734-4.876 1.87-6.498C12.007 3.877 13.482 3 15 3s2.993.877 4.13 2.502C20.267 7.124 21 9.42 21 12z"
    />
    <path
      d="M14.98 16.276c.157 0 .274-.107.274-.278v-.615c1.245-.088 2.222-.762 2.222-1.963 0-1.04-.703-1.568-1.988-1.865l-.234-.059V9.46c.542.068.894.361 1.098.87.103.233.264.375.518.375.283 0 .479-.18.479-.444a.853.853 0 00-.035-.25c-.229-.805-1.02-1.357-2.06-1.44v-.62c0-.17-.117-.278-.274-.278-.16 0-.278.107-.278.278v.62c-1.23.093-2.11.81-2.11 1.91 0 .99.67 1.547 1.91 1.835l.2.05v2.128c-.698-.073-1.045-.415-1.206-.874-.102-.259-.273-.38-.508-.38-.298 0-.488.19-.488.468 0 .078.015.166.04.249.233.816 1.083 1.348 2.162 1.426v.615c0 .17.117.278.278.278zm-1.352-5.878c0-.45.371-.855 1.074-.938v1.91c-.781-.201-1.074-.528-1.074-.972zm2.812 3.12c0 .522-.351.917-1.186.981v-2.002c.908.215 1.186.513 1.186 1.02z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
