<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.95 6a2.6 2.6 0 012.6-2.6h6.286a2.6 2.6 0 012.6 2.6v12a2.6 2.6 0 01-2.6 2.6H11.55a2.6 2.6 0 01-2.6-2.6V6zm2.6-1.4a1.4 1.4 0 00-1.4 1.4v12a1.4 1.4 0 001.4 1.4h6.286a1.4 1.4 0 001.4-1.4V6a1.4 1.4 0 00-1.4-1.4H11.55z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.379 6.286a.6.6 0 01.6-.6h3.428a.6.6 0 110 1.2H12.98a.6.6 0 01-.6-.6zM3.55 12a2.314 2.314 0 014.55-.6h6.086c.47 0 .85.38.85.85v2.036a.6.6 0 11-1.2 0V12.6h-1.657v1.114a.6.6 0 11-1.2 0V12.6H8.1a2.315 2.315 0 01-4.55-.6zm2.314-1.114a1.114 1.114 0 100 2.228 1.114 1.114 0 000-2.228z"
    />
  </svg>
</template>
