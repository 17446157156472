<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.971 5.905A2.486 2.486 0 0110.476 3.4a2.486 2.486 0 012.505 2.505v.543h2.448c1.169 0 2.123.954 2.123 2.123v2.448h.543a2.486 2.486 0 012.505 2.505 2.486 2.486 0 01-2.505 2.505h-.543v2.447c0 1.17-.954 2.124-2.123 2.124h-3.496v-1.743c0-.811-.645-1.457-1.457-1.457-.811 0-1.457.646-1.457 1.457V20.6H5.524A2.128 2.128 0 013.4 18.476v-3.495h1.743c.811 0 1.457-.646 1.457-1.457 0-.812-.646-1.457-1.457-1.457H3.4V8.57c0-1.169.954-2.123 2.124-2.123H7.97v-.543zM10.476 4.6c-.735 0-1.305.57-1.305 1.305v1.743H5.524a.928.928 0 00-.924.923v2.296h.543A2.649 2.649 0 017.8 13.524a2.649 2.649 0 01-2.657 2.657H4.6v2.295c0 .507.417.924.924.924h2.295v-.543a2.649 2.649 0 012.657-2.657 2.649 2.649 0 012.657 2.657v.543h2.296a.928.928 0 00.923-.924V14.83h1.743c.736 0 1.305-.57 1.305-1.305s-.57-1.305-1.305-1.305h-1.743V8.571a.928.928 0 00-.923-.923H11.78V5.905c0-.736-.57-1.305-1.305-1.305z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
