import state from './moduleBiddingState'
import mutations from './moduleBiddingMutations'
import actions from './moduleBiddingActions'
import getters from './moduleBiddingGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
