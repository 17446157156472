<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.246 7.104V6a2.6 2.6 0 012.6-2.6h8.308a2.6 2.6 0 012.6 2.6v1.104l1.27 1.058a1.6 1.6 0 01.576 1.23V18a2.6 2.6 0 01-2.6 2.6H6A2.6 2.6 0 013.4 18V9.39c0-.474.21-.925.576-1.229l1.27-1.059zM6.446 6a1.4 1.4 0 011.4-1.4h8.308a1.4 1.4 0 011.4 1.4V10.565L12.2 13.653a.4.4 0 01-.4 0l-5.354-3.088V6zm-1.2 3.872V8.666l-.502.418a.4.4 0 00-.144.307V9.5l.646.372zm13.508-1.206v1.206L19.4 9.5V9.39a.4.4 0 00-.144-.307l-.502-.418z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.938 7.077a.6.6 0 01.6-.6h4.923a.6.6 0 110 1.2H9.538a.6.6 0 01-.6-.6zM8.938 9.538a.6.6 0 01.6-.6h4.923a.6.6 0 110 1.2H9.538a.6.6 0 01-.6-.6z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
