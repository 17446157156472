import state from './moduleLanguageState'
import mutations from './moduleLanguageMutations'
import actions from './moduleLanguageActions'
import getters from './moduleLanguageGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
