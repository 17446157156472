<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.537 11.466l1.513-1.52a4.293 4.293 0 00.642-5.228l-.125-.21A2.27 2.27 0 004.734 4.4l-.766 1.132a3.308 3.308 0 00-.373 2.972c.859 2.392 2.524 4.73 4.948 7.022 2.367 2.236 4.66 3.847 6.882 4.806 1.032.445 2.22.33 3.148-.303l1.032-.704a2.286 2.286 0 00-.109-3.842l-.208-.126a4.256 4.256 0 00-5.214.645l-1.389 1.394a29.907 29.907 0 01-3.318-2.743c-1.129-1.067-2.07-2.13-2.83-3.187z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
