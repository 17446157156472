<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 3.4A2.6 2.6 0 003.4 6v9a2.6 2.6 0 002.462 2.596v.558A2.446 2.446 0 008.308 20.6H17a3.6 3.6 0 003.6-3.6V8.308a2.446 2.446 0 00-2.446-2.446h-.558A2.6 2.6 0 0015 3.4H6zm10.4 3.062V6A1.4 1.4 0 0015 4.6H6A1.4 1.4 0 004.6 6v9A1.4 1.4 0 006 16.4h9a1.4 1.4 0 001.4-1.4V6.462zM7.062 17.6H15a2.6 2.6 0 002.6-2.6V7.062h.554c.688 0 1.246.557 1.246 1.246V17a2.4 2.4 0 01-2.4 2.4H8.308a1.246 1.246 0 01-1.246-1.246V17.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.281 8.4a.9.9 0 11-1.8 0 .9.9 0 011.8 0zm-2.784 1.674a.5.5 0 00-.796.025L7.843 12.71a.5.5 0 00.408.79h3.98a.5.5 0 00.388-.815l-2.122-2.611z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
