<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.556 6.29V4.806c0-.715-.58-1.296-1.295-1.296H6.478c-.714 0-1.295.581-1.295 1.296V6.29h-.106C3.576 6.29 2.4 7.553 2.4 9.057v8.666c0 1.504 1.176 2.767 2.677 2.767h13.846c1.502 0 2.677-1.263 2.677-2.767V9.057c0-1.504-1.176-2.767-2.677-2.767h-.019V4.806c0-.715-.58-1.296-1.295-1.296h-2.783c-.715 0-1.296.581-1.296 1.296V6.29h-2.973zM6.478 4.71a.097.097 0 00-.095.096V6.29h2.973V4.806a.097.097 0 00-.095-.096H6.478zM5.183 7.49v.003h5.373V7.49h2.975v.003h5.373V7.49h.02c.79 0 1.476.677 1.476 1.567v8.666c0 .89-.685 1.567-1.477 1.567H5.077c-.792 0-1.477-.677-1.477-1.567V9.057c0-.89.685-1.567 1.477-1.567h.106zm12.521-1.2H14.73V4.806c0-.052.044-.096.096-.096h2.783c.052 0 .095.044.095.096V6.29z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
