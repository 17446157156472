import Playlist from '@/models/playlists/playlist'

export default {
  isLoading: true,
  videoArrayIsLoading: true,
  isUploading: false,
  playlist: new Playlist(),
  playlists: [],
  playlistDisplayTypes: [],
  videos: [],
  videosPagination: {},
  playlistVideos: [],
  aspectRatios: [],
  selectedType: 1,

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

  filters: {
    params: {
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },

  filtersOfVideo: {
    params: {
      page: 1,
      take: 15,
      search: '',
      order: 'desc',
      onlyReadyVideos: true,
      onlyPublishedVideos: true,
      visibilityOptionIds: 1,
    },
  },

  options: {},
}
