<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.4 15.77a.6.6 0 01.6-.6h3.83V19a.6.6 0 11-1.2 0v-2.63H5a.6.6 0 01-.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.655 15.345a.6.6 0 010 .848l-3.23 3.231a.6.6 0 01-.85-.848l3.231-3.231a.6.6 0 01.849 0zM15.77 4.4a.6.6 0 01.6.6v2.63H19a.6.6 0 110 1.2h-3.83V5a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.424 4.576a.6.6 0 010 .848l-3.23 3.231a.6.6 0 11-.849-.848l3.23-3.231a.6.6 0 01.85 0zM15.17 15.17H19a.6.6 0 110 1.2h-2.63V19a.6.6 0 11-1.2 0v-3.83z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.345 15.345a.6.6 0 01.848 0l3.231 3.23a.6.6 0 11-.848.85l-3.231-3.232a.6.6 0 010-.848zM8.23 4.4a.6.6 0 01.6.6v3.83H5a.6.6 0 010-1.2h2.63V5a.6.6 0 01.6-.6z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.576 4.576a.6.6 0 01.848 0l3.231 3.23a.6.6 0 01-.848.85L4.575 5.423a.6.6 0 010-.848z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
