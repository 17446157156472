import state from './moduleVideosState'
import mutations from './moduleVideosMutations'
import actions from './moduleVideosActions'
import getters from './moduleVideosGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
