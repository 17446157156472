<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
    style="fill: currentcolor;"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.585 7.524a3 3 0 10-1.06 1.06L10.938 12l-3.415 3.415a3 3 0 101.06 1.06L12 13.062l7.47 7.47a.75.75 0 101.06-1.061L8.585 7.524zM6 7.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 12a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
    />
    <path d="M13.47 10.53a.75.75 0 001.06 0l6-6a.75.75 0 00-1.06-1.06l-6 6a.75.75 0 000 1.06z" />
    <path d="M2.25 12a.75.75 0 01.75-.75h1a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75z" />
    <path d="M7 11.25a.75.75 0 000 1.5h1a.75.75 0 000-1.5H7z" />
    <path d="M15.25 12a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75z" />
    <path d="M21 11.25h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5z" />
  </svg>
</template>
