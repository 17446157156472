<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 22.75L2.5 22.75C2.09 22.75 1.75 22.41 1.75 22C1.75 21.59 2.09 21.25 2.5 21.25L22.5 21.25C22.91 21.25 23.25 21.59 23.25 22C23.25 22.41 22.91 22.75 22.5 22.75Z"
      fill="currentColor"
    />
    <path
      d="M14.75 22.75H10.25C9.84 22.75 9.5 22.41 9.5 22L9.5 4C9.5 2.28 10.45 1.25 12.05 1.25H12.95C14.55 1.25 15.5 2.28 15.5 4L15.5 22C15.5 22.41 15.16 22.75 14.75 22.75ZM11 21.25H14L14 4C14 2.85 13.46 2.75 12.95 2.75H12.05C11.54 2.75 11 2.85 11 4L11 21.25Z"
      fill="currentColor"
    />
    <path
      d="M7.5 22.75H3.5C3.09 22.75 2.75 22.41 2.75 22L2.75 10C2.75 8.28 3.63 7.25 5.1 7.25L5.9 7.25C7.37 7.25 8.25 8.28 8.25 10L8.25 22C8.25 22.41 7.91 22.75 7.5 22.75ZM4.25 21.25H6.75L6.75 10C6.75 8.75 6.2 8.75 5.9 8.75H5.1C4.8 8.75 4.25 8.75 4.25 10L4.25 21.25Z"
      fill="currentColor"
    />
    <path
      d="M21.5 22.75H17.5C17.09 22.75 16.75 22.41 16.75 22V15C16.75 13.28 17.63 12.25 19.1 12.25H19.9C21.37 12.25 22.25 13.28 22.25 15V22C22.25 22.41 21.91 22.75 21.5 22.75ZM18.25 21.25H20.75V15C20.75 13.75 20.2 13.75 19.9 13.75H19.1C18.8 13.75 18.25 13.75 18.25 15V21.25Z"
      fill="currentColor"
    />
  </svg>

</template>
