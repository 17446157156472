<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.245 4.573a1.802 1.802 0 012.547-.089l2.635 2.458c.728.678.768 1.818.089 2.546l-.185.199-5.386-4.792.3-.322zM13.125 5.774l-6.763 7.252c.558 1.03 1.537 2.584 2.098 3.107.632.59 2.322 1.298 3.295 1.678l6.756-7.246-5.386-4.791zM7.64 17.012c.656.611 2.14 1.292 3.237 1.74l-1.043 1.119c-.192.205-.45.337-.728.372l-4.295.542A.72.72 0 014 20.03l.241-4.322c.016-.28.13-.547.321-.752l.938-1.006c.626 1.103 1.544 2.506 2.14 3.062z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
