<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4993 24.9582H13.4993C8.97435 24.9582 7.04102 23.0248 7.04102 18.4998V13.4998C7.04102 8.97484 8.97435 7.0415 13.4993 7.0415H15.166C15.5077 7.0415 15.791 7.32484 15.791 7.6665C15.791 8.00817 15.5077 8.2915 15.166 8.2915H13.4993C9.65768 8.2915 8.29102 9.65817 8.29102 13.4998V18.4998C8.29102 22.3415 9.65768 23.7082 13.4993 23.7082H18.4993C22.341 23.7082 23.7077 22.3415 23.7077 18.4998V16.8332C23.7077 16.4915 23.991 16.2082 24.3327 16.2082C24.6744 16.2082 24.9577 16.4915 24.9577 16.8332V18.4998C24.9577 23.0248 23.0243 24.9582 18.4993 24.9582Z"
      fill="currentColor"
    />
    <path
      d="M13.0841 20.7415C12.5758 20.7415 12.1091 20.5581 11.7674 20.2248C11.3591 19.8165 11.1841 19.2248 11.2758 18.5998L11.6341 16.0915C11.7008 15.6081 12.0174 14.9831 12.3591 14.6415L18.9258 8.0748C20.5841 6.41647 22.2674 6.41647 23.9258 8.0748C24.8341 8.98314 25.2424 9.90814 25.1591 10.8331C25.0841 11.5831 24.6841 12.3165 23.9258 13.0665L17.3591 19.6331C17.0174 19.9748 16.3924 20.2915 15.9091 20.3581L13.4008 20.7165C13.2924 20.7415 13.1841 20.7415 13.0841 20.7415ZM19.8091 8.95814L13.2424 15.5248C13.0841 15.6831 12.9008 16.0498 12.8674 16.2665L12.5091 18.7748C12.4758 19.0165 12.5258 19.2165 12.6508 19.3415C12.7758 19.4665 12.9758 19.5165 13.2174 19.4831L15.7258 19.1248C15.9424 19.0915 16.3174 18.9081 16.4674 18.7498L23.0341 12.1831C23.5758 11.6415 23.8591 11.1581 23.9008 10.7081C23.9508 10.1665 23.6674 9.59147 23.0341 8.9498C21.7008 7.61647 20.7841 7.99147 19.8091 8.95814Z"
      fill="currentColor"
    />
    <path
      d="M22.5423 14.1917C22.484 14.1917 22.4256 14.1834 22.3756 14.1667C20.1839 13.5501 18.4423 11.8084 17.8256 9.61673C17.7339 9.2834 17.9256 8.94173 18.2589 8.84173C18.5923 8.75006 18.934 8.94173 19.0256 9.27506C19.5256 11.0501 20.9339 12.4584 22.7089 12.9584C23.0423 13.0501 23.2339 13.4001 23.1423 13.7334C23.0673 14.0167 22.8173 14.1917 22.5423 14.1917Z"
      fill="currentColor"
    />
  </svg>

</template>

<script>
export default {
  name: 'EditIcon',
  props: {
    size: {
      type: String,
      default: '21',
    },
  },
}
</script>
