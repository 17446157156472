import Audio from '@/models/audios/audio'
import CustomParameter from '@/models/audios/CustomParameters'
import Chapters from '@/models/audios/Chapters'

export default {
  SET_AUDIO: (state, data) => { const fd = new Audio(); fd.fromData(data); state.audio = fd },
  SET_AUDIOS: (state, data) => { state.audios = data },
  ADD_AUDIO: (state, data) => { state.audios.unshift(data) },
  ADD_AUDIOS: (state, data) => { [...state.audios] = [...state.audios, ...data] },
  SET_AUDIO_STREAMS: (state, data) => {
    state.streams = data ? data.streamResponse?.streams.map(d => ({
      mode: 'span', // span means this header will span all columns
      label: d.streamType, // this is the label that'll be used for the header
      labelType: d.assetType, // this is the label type
      html: false, // if this is true, label will be rendered as html
      root: d.root,
      children: d.qualityTypes,
    })) : null
  },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },
  SET_OPTIONS: (state, data) => { state.options = data.data.result },
  SET_STREAMS: (state, data) => { state.streams = data },
  SET_PUBLIC_ID: (state, data) => { state.audio.publicId = data },
  SET_TITLE: (state, data) => { state.audio.title = data },
  SET_DESCRIPTION: (state, data) => { state.audio.description = data },
  SET_AUTHOR: (state, data) => { state.audio.author = data },
  SET_SOURCE: (state, data) => { state.audio.source = data },
  SET_LINK: (state, data) => { state.audio.link = data },
  SET_PUBLISH_START_DATE: (state, data) => { state.audio.publishStartDate = data },
  SET_PUBLISH_END_DATE: (state, data) => { state.audio.publishEndDate = data },
  ADD_CUSTOM_PARAMETER: state => { const ncp = new CustomParameter(); state.audio.customParameters.push(ncp) },
  SET_AUDIO_THUMBNAIL: (state, data) => {
    state.audio.thumbnail = data
  },
  CLEAR_AUDIO: state => { state.audio = new Audio() },
  CLEAR_AUDIOS: state => { state.audios = [] },
  REMOVE_AUDIOS_BY_ID: (state, data) => {
    data.forEach(d => {
      state.audios.splice(state.audios.findIndex(x => x.mediaId === d), 1)
    })
  },
  REMOVE_CUSTOM_PARAMETER: (state, index) => { state.audio.customParameters.splice(index, 1) },

  UPDATE_PROJECT_ID: (state, data) => { state.filters.params.ProjectId = data },
  LOADING_AUDIO: (state, value) => { state.loadingAudio = value },
  LOADING_AUDIOS: (state, value) => { state.loadingAudios = value },
  UPDATE_ISUPLOADING: (state, data) => { state.isUploading = data },
  NEW_AUDIO_UPLOADED: (state, data) => { state.newAudio = data },
  UPDATE_CUSTOM_PARAMETER_KEY: (state, data) => { state.audio.customParameters[data.index].key = data.key },
  UPDATE_CUSTOM_PARAMETER_VALUE: (state, data) => { state.audio.customParameters[data.index].value = data.value },
  UPDATE_AUDIO: (state, data) => {
    const audioIndex = state.audios.findIndex(p => p.id === data.id)
    Object.assign(state.audios[audioIndex], data)
  },
  UPDATE_STATUS_BY_ID: (state, data) => {
    const audioIndex = state.audios.findIndex(v => v.publicId === data.id)
    state.audios[audioIndex].status = data.status
  },
  UPDATE_FILTER: (state, data) => {
    state.filters.params[data.propKey] = data.value
  },
  UPDATE_PAGINATION: (state, data) => {
    state.pagination[data.propKey] = data.value
  },

  RESET_FILTER: state => {
    const filteri = {
      params: {
        folderView: false,
        folderId: null,
        insertedDate: null,
        key: null,
        value: null,
        podcastId: null,
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
  RESET_FILTER_LIVE: state => {
    const filteri = {
      params: {
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
  SET_AUDIO_PODCASTS: (state, data) => { state.podcasts = data },
  // Audio Analytics
  SET_ANALYTICS(state, payload) {
    const { result, intervalType, differenceInDays } = payload
    state.intervalType = intervalType
    state.diffInTimeCustomDate = differenceInDays
    state.audioAnalytics = result
    state.audioAnalyticsGropuedData = {
      result: result?.graphResult?.map(entry => entry?.result),
      date: result?.graphResult?.map(entry => entry?.date),
    }
    state.audioAnalyticsTotal = result?.total
  },
  SET_LIVE_VIEWERS(state, data) {
    state.liveViewers = data?.liveViewers
    state.liveViewersGroupedData = {
      uniqueViewers: data?.liveViewers?.map(entry => entry?.uniqueViewers),
      date: data?.liveViewers?.map(entry => entry?.date),
    }
    state.liveViewersTotal = data?.total
  },
  SET_KEY_MOMENTS(state, data) {
    state.keyMoments = data
    state.keyMomentsGroupedData = {
      audioSeconds: data.map(entry => entry?.audioSeconds),
      listens: data.map(entry => entry?.listens),
    }
  },
  LOADING_CUSTOM_ANALYTICS(state, value) {
    state.loadingCustomAnalytics = value
  },

  SET_KEY_MOMENTS_CARDS(state, data) {
    state.keyMomentsCards = data
  },
  SET_CUSTOM_DATE(state, { e, dateStr }) {
    state.customDate = e
    state.dateStr = dateStr
  },
  DESTROY_CUSTOM_DATE(state) {
    state.customDate = []
    state.dateStr = ''
  },
  LOADING_VIDEOS(state, value) {
    state.loadingVideos = value
  },
  LOADING_ANALYTICS(state, value) {
    state.loadingAnalytics = value
  },
  LOADING_ANALYTICS_TOP_COUNTRIES(state, value) {
    state.loadingTopCountries = value
  },
  LOADING_ANALYTICS_TOP_DEVICES(state, value) {
    state.loadingTopDevices = value
  },
  LOADING_ANALYTICS_TOP_PLATFORMS(state, value) {
    state.loadingTopPlatforms = value
  },
  LOADING_ANALYTICS_KEY_MOMENTS(state, value) {
    state.loadingKeyMoments = value
  },
  LOADING_ANALYTICS_KEY_MOMENTS_CARDS(state, value) {
    state.loadingKeyMomentsCards = value
  },
  LOADING_ANALYTICS_LIVE_VIEWERS(state, value) {
    state.loadingLiveViewers = value
  },
  SET_TOP_PLATFORMS(state, data) {
    state.topPlatforms = data
    state.topPlatformsGroupedData = data.map(entry => ({
      names: entry?.platform,
      values: entry?.plays,
    }))
  },
  SET_TOP_COUNTRIES(state, data) {
    state.topCountries = data
    state.topCountriesGroupedData = data.map(entry => ({
      names: entry?.countryName,
      values: entry?.countryNr,
    }))
  },
  SET_TOP_DEVICES(state, data) {
    state.topDevices = data
    state.topDevicesGroupedData = {
      deviceType: data.map(entry => entry?.deviceType),
      deviceTypeNr: data.map(entry => entry?.deviceTypeNr),
    }
  },
  SET_TOP_BROWSERS(state, data) {
    state.topBrowsers = data
    state.topBrowsersGroupedData = data.map(entry => ({
      names: entry?.browser,
      values: entry?.browserTypeNr,
    }))
  },
  LOADING_ANALYTICS_TOP_BROWSERS(state, value) {
    state.loadingTopBrowsers = value
  },
  DESTROY_AUDIO_ANALYTICS(state) {
    state.audioAnalyticsTotal = 0
    state.liveViewersTotal = 0
    state.audioAnalytics = []
    state.keyMoments = []
    state.keyMomentsCards = []
    state.topCountries = []
    state.topDevices = []
    state.topPlatforms = []
    state.keyMomentsGroupedData = {}
    state.audioAnalyticsGropuedData = {}
    state.liveViewersGroupedData = {}
    state.topCountriesGroupedData = {}
    state.topDevicesGroupedData = {}
    state.topPlatformsGroupedData = {}
  },
  LOADING_ANALYTICS_REPORT(state, value) {
    state.loadingAnalyticsReport = value
  },
  SET_ANALYTICS_REPORT(state, data) {
    state.analyticsReport = data
  },
  SET_CHAPTER_STATE: (state, data) => {
    state.audio.chapterState = data
  },
  ADD_CHAPTER: (state, time = null) => {
    const ncp = new Chapters('', time)
    state.audio.chapters.push(ncp)
  },
  REMOVE_CHAPTER: (state, index) => {
    state.audio.chapters.splice(index, 1)
  },
  REMOVE_CHAPTERS: state => {
    state.audio.chapters.length = 0
  },
  UPDATE_CHAPTER_TITLE: (state, data) => {
    state.audio.chapters[data.index].title = data.title
  },
  UPDATE_CHAPTER_TIME: (state, data) => {
    state.audio.chapters[data.index].time = data.time
  },
}
