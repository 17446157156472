<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.176 7.424a.6.6 0 01.848-.848l5 5 .424.424-.424.424-5 5a.6.6 0 01-.848-.848L17.15 12.6H5.1a.6.6 0 110-1.2h12.051l-3.975-3.976z"
    />
  </svg>
</template>
