<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 4.4H7C5.56406 4.4 4.4 5.56406 4.4 7V17C4.4 18.4359 5.56406 19.6 7 19.6H17C18.4359 19.6 19.6 18.4359 19.6 17V7C19.6 5.56406 18.4359 4.4 17 4.4ZM7 3C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3H7Z"
      fill="currentColor"
    />
  </svg>
</template>
