<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.4 7A2.6 2.6 0 017 4.4h10A2.6 2.6 0 0119.6 7v10a2.6 2.6 0 01-2.6 2.6H7A2.6 2.6 0 014.4 17V7zM7 5.6A1.4 1.4 0 005.6 7v10A1.4 1.4 0 007 18.4h10a1.4 1.4 0 001.4-1.4V7A1.4 1.4 0 0017 5.6H7z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
