// import FlagModel from '@/models/geoBlockingRules/geoBlockingRule'

export default {
  SET_GEOBLOCKINGRULES: (state, data) => { state.geoBlockingRules = data || [] },
  SET_GEOBLOCKINGRULE: (state, data) => {
    // const geoBlockingRule = new FlagModel()
    // geoBlockingRule.fromData(data)
    state.geoBlockingRule = data
  },
  SET_GEOBLOCKINGRULE_CUSTOMSETTING: (state, data) => { state.geoBlockingRule.geoBlockingRuleCustomSetting = data },
  SET_NAME: (state, data) => { state.geoBlockingRule.name = data },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
  CLEAR_GEOBLOCKINGRULES: state => { state.geoBlockingRules = [] },

  SET_GEOBLOCKINGRULE_TYPES: (state, data) => { data.map(x => { x.value = x.id; return x }); state.geoBlockingRuleTypes = data },
  SET_PAGINATION: (state, data) => { const { items, ...p } = data; state.pagination = p },

  UPDATE_FILTER: (state, data) => {
    if (data.propKey === 'geoBlockingRuleTypeId') {
      state.filters.params[data.propKey] = data.value
      state.filters.params.page = '1'
    } else {
      state.filters.params[data.propKey] = data.value
    }
  },
  RESET_FILTER: state => {
    const filteri = {
      params: {
        geoBlockingRuleTypeId: null,
        page: 1,
        take: 10,
        search: '',
        order: 'desc',
      },
    }
    state.filters = filteri
  },
}
