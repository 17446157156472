<template>
  <FullHeightLayout
    class="gj-table full-height-layout--min w-100 p-0 d-flex flex-column justify-content-center align-items-center"
  >
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <!-- image -->
        <b-img
          fluid
          :src="downImg"
          alt="Error page"
        />

        <h2 class="mb-1 text-secondary">
          Page Not Found
        </h2>

        <p>The requested URL was not found on this project.</p>

        <div class="mt-3">
          <!-- <b-button
            variant="bg-white"
            class="mb-2 mr-1"
            @click="$router.push($route.path)"
          >
            Refresh Page
          </b-button> -->

          <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{ name:'dashboard', params: { projectId: activeProjectId } }"
          >
            <span class="text-main">
              Return to {{ isFullScreen ? 'Project' : 'Dashboard' }}
            </span>
          </b-button>
        </div>
      </div>
    </div>
  </FullHeightLayout>
</template>

<script>
import fetchUserData from '@/services/mixins/fetchUserData'
import { logoutUser } from '@/auth'
/* eslint-disable global-require */

export default {
  data() {
    return {
      downImg: require('@/assets/images/pages/error-404.svg'),
    }
  },
  computed: {
    activeOrganizationId() {
      const activeOrganization = this.$store.getters['organizationModule/getActiveOrganizationId']
      return activeOrganization || null
    },
    activeProjectId() {
      return this.$store.state.projectModule.activeProjectId || null
    },
    isFullScreen() {
      return this.$store.state.appConfig.layout.menu.hidden
    },
  },
  async mounted() {
    await fetchUserData(this.activeOrganizationId, this.activeProjectId).catch(error => { this.logout(); console.error(error) })
  },
  methods: {
    logout() {
      logoutUser('auth-login')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-misc.scss';
</style>
