import state from './moduleSearchState'
import mutations from './moduleSearchMutations'
import actions from './moduleSearchActions'
import getters from './moduleSearchGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
