<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.25 18.343c0-.309.251-.56.56-.56h13.064c.721 0 1.306-.584 1.306-1.306v-8.93c0-.722-.585-1.307-1.306-1.307H5.81a.56.56 0 010-1.12h13.064A2.426 2.426 0 0121.3 7.546v8.931a2.426 2.426 0 01-2.426 2.426H5.81a.56.56 0 01-.56-.56z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.782 12.012c0-.31.251-.56.56-.56h4.666a.56.56 0 010 1.12h-4.666a.56.56 0 01-.56-.56zM11.782 14.345c0-.31.251-.56.56-.56h4.666a.56.56 0 110 1.12h-4.666a.56.56 0 01-.56-.56zM11.782 9.679c0-.31.251-.56.56-.56h1.866a.56.56 0 110 1.12h-1.866a.56.56 0 01-.56-.56zM5.053 13.199c-.87 0-1.555.666-1.555 1.462v.063c0 .36.296.653.66.653h4.238c.364 0 .66-.293.66-.654v-.062c0-.796-.685-1.462-1.555-1.462H5.053zm-2.12 1.462c0-1.128.96-2.022 2.12-2.022H7.5c1.16 0 2.12.894 2.12 2.022v.063c0 .67-.549 1.213-1.225 1.213H4.158a1.219 1.219 0 01-1.225-1.213v-.063z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.7 14.661c0-1.269 1.077-2.255 2.353-2.255H7.5c1.276 0 2.353.986 2.353 2.255v.063c0 .8-.655 1.446-1.458 1.446H4.158A1.452 1.452 0 012.7 14.723v-.062zm2.353-1.789c-1.043 0-1.886.802-1.886 1.79v.061c0 .54.441.98.99.98h4.24c.549 0 .99-.44.99-.98v-.062c0-.987-.844-1.789-1.886-1.789H5.053zm0 .56c-.753 0-1.321.572-1.321 1.23v.061c0 .23.188.42.426.42h4.238c.238 0 .426-.19.426-.42v-.062c0-.656-.568-1.229-1.32-1.229h-2.45zm-1.788 1.23c0-.937.8-1.697 1.788-1.697H7.5c.988 0 1.788.76 1.788 1.696v.063a.89.89 0 01-.893.886H4.158a.89.89 0 01-.893-.886v-.063zM6.41 8.845a1.005 1.005 0 100 2.01 1.005 1.005 0 000-2.01zM4.474 9.85a1.938 1.938 0 113.876 0 1.938 1.938 0 01-3.876 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
