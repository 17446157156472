<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4.6a7.4 7.4 0 100 14.8 7.4 7.4 0 000-14.8zM3.4 12a8.6 8.6 0 1117.2 0 8.6 8.6 0 01-17.2 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.853 9.29a.6.6 0 010 .849l-4.5 4.5a.7.7 0 01-.99 0l-2.216-2.215a.6.6 0 11.849-.848l1.861 1.861 4.147-4.147a.6.6 0 01.849 0z"
    />
  </svg>
</template>
