<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.57 3.97a8.03 8.03 0 11-5.794 13.587.605.605 0 01.873-.838 6.82 6.82 0 10-1.773-6.023l.05-.038a.605.605 0 11.726.968l-1.13.847a.627.627 0 01-.027.02l-.348.261-.335-.251a.612.612 0 01-.055-.041l-1.115-.836a.605.605 0 01.726-.968l.255.192a8.031 8.031 0 017.948-6.88z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 6.54c.334 0 .605.27.605.604v4.604l3.252 3.25a.605.605 0 11-.855.855l-3.606-3.604V7.144c0-.334.27-.605.604-.605z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
