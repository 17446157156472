export default [
  {
    path: '/:projectId/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      titleText: 'Dashboard',
      parentText: 'Dashboard',
      resource: 'dashboard',
      projectRequired: true,
      contentClass: 'dashboard',
      navActiveLink: 'dashboard',
      gjIcon: 'Home',
      breadcrumb: [
        {
          text: 'Dashboard',
          to: 'dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/',
    name: 'set-user',
    component: () => import('@/views/dashboard/SetUser.vue'),
    meta: {
      layout: 'full',
      hiddenSidebar: true,
      noOrganizationAccess: true,
      resource: 'Auth',
      contentClass: '',
      navActiveLink: '',
    },
  },
]
