export default {
  getWebhooks: state => state.webhooks,
  getWebhook: state => state.webhook,
  getWebhookEvents: state => state.webhookEvents,
  getWebhookFiltersSelected: state => state.webhookFiltersSelected,
  getSelectedWebhookType: state => state.filters.params.webhookTypeId,
  getPagination: state => state.pagination,
  getPageLength: state => state.filters.params.take,
  getCurrentPage: state => state.filters.params.page,
}
