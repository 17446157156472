/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {

  getUserRoles({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/roles', state.filters)
        .then(response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_ROLES', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getUserRoleById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/roles/${id}`)
        .then(response => {
          commit('SET_ROLE', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  addUserRole({ commit }, role) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/roles', role)
        .then(response => {
          commit('ADD_ROLE', Object.assign(role, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateUserRole({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .put('/api/roles', state.role)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateUserRolePermissions({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/projects/${project.state.activeProjectId}/role-permissions/add-or-remove-per-role`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  removeUserRoleById({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/roles/${itemId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  activateOrDeactivate({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/roles/activate?id=${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // ---= PERMISSIONS =--- //
  getAllPermissions({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/permissions`)
        .then(response => {
          commit('SET_PERMISSIONS', { data: response.data.result, property: 'all' })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getUserRolePermissions({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/role-permissions/${id}`)
        .then(response => {
          commit('SET_ACTIVE_ROLE_ID', id)
          commit('SET_PERMISSIONS', { data: response.data.result, property: 'ofUser' })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getUserPermissions({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/accounts/users/${data.organizationId}/permissions?projectId=${data.projectId}`)
        .then(response => {
          commit('SET_PERMISSIONS', { data: response.data.result, property: 'ofUser' })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getRolePermissions({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/role-permissions/${id}`)
        .then(response => {
          commit('SET_PERMISSIONS', { data: response.data.result, property: 'ofRole' })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateRolePermissions({ commit }, permission) {
    // return new Promise((resolve, reject) => {
    //   axios
    //     .post(`api/projects/${project.state.activeProjectId}/role-permissions`, permission)
    //     .then(response => {
    //       commit('UPDATE_PERMISSIONS_OF_ROLE', Object.assign(permission, { id: response.data.id }))
    //       resolve(response)
    //     })
    //     .catch(error => { reject(error) })
    // })
    console.log('Update this api', permission)
  },
}
