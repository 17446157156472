<template>
  <svg
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.82813 4.25C8.09878 4.25 7.39931 4.53973 6.88358 5.05546L3.88358 8.05546C2.80964 9.1294 2.80964 10.8706 3.88358 11.9445L10.0552 18.1161C11.1291 19.1901 12.8703 19.1901 13.9442 18.1161L20.1158 11.9445C21.1898 10.8706 21.1898 9.1294 20.1158 8.05546L17.1158 5.05546C16.6001 4.53973 15.9006 4.25 15.1713 4.25H8.82813ZM11.7917 10.336L8.73662 5.75335C8.76697 5.75112 8.79749 5.75 8.82813 5.75H15.1713C15.2019 5.75 15.2324 5.75113 15.2628 5.75335L12.2077 10.336C12.1088 10.4844 11.8907 10.4844 11.7917 10.336ZM13.6997 10.8021L16.5163 6.57727L19.0552 9.11612C19.1439 9.20487 19.2165 9.30364 19.273 9.4088L13.6997 10.8021ZM7.48311 6.57725L10.2996 10.8021L4.72642 9.40875C4.7829 9.30361 4.8555 9.20485 4.94424 9.11612L7.48311 6.57725ZM5.11155 11.0512L11.1158 17.0555C11.1583 17.0979 11.2031 17.1367 11.2498 17.1718V12.5857L5.11155 11.0512ZM12.7498 12.5858V17.1717C12.7964 17.1367 12.8411 17.0979 12.8836 17.0555L18.8878 11.0513L12.7498 12.5858Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
