/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'
import organization from '@/store/account/organization/moduleOrganization'

const baseURL = process.env.VUE_APP_API_LIVESTREAM

const version = 1

export default {
  // ////////// //
  /* Channels CRUD */
  getChannels({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/${project.state.activeProjectId}/channels`, { baseURL, params: state.filters.params })
        .then(async response => {
          commit('SET_PAGINATION', response.data.result)
          commit('SET_CHANNELS', response.data.result.items)
          commit('SET_DEFAULT_CHANNELS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getLiveChannels({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v${version}/${project.state.activeProjectId}/channels`, { baseURL, params: state.liveFilters.params })
        .then(async response => {
          commit('SET_LIVE_CHANNELS', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  postChannel({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/channels`,
        baseURL,
        method: 'POST',
        data,
      })

        .then(async response => {
          commit('SET_CHANNEL', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateChannel({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/v${version}/${project.state.activeProjectId}/channels`,
        baseURL,
        method: 'PUT',
        data,
      })

        .then(async response => {
          // commit('SET_CHANNEL', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getChannel({ commit, state }, publicId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v${version}/${project.state.activeProjectId}/channels/${publicId}`, { baseURL })
        .then(async response => {
          commit('SET_CHANNEL', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  deleteChannelById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${baseURL}api/v${version}/${project.state.activeProjectId}/channels/${id}`)
        .then(async response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getOptions({ commit, state }, payload) {
    const endpoint = `/api/v${version}/${project.state.activeProjectId}/channels/options`
    const queryParams = payload && payload.livestreamProviderId ? { livestreamProviderId: payload.livestreamProviderId } : {}
    return new Promise((resolve, reject) => {
      axios.get(endpoint, { baseURL, params: queryParams })
        .then(response => {
          commit('SET_OPTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  channelSelectList({ commit, state }, payload) {
    const { activeProjectId } = project.state
    return new Promise((resolve, reject) => {
      let endpoint = `${baseURL}api/v${version}/${activeProjectId}/channels/select-list?livestreamProviderId=${payload.livestreamProviderId}`
      if (payload.videoId) {
        endpoint += `&videoId=${payload.videoId}`
      }
      axios
        .get(endpoint)
        .then(async response => {
          commit('CLEAR_CHANNEL_SELECT_LIST')
          commit('SET_CHANNEL_SELECT_LIST', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getLiveStreamProviders({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}api/v${version}/organizations/${organization.state.activeOrganizationId}/livestreamproviders`)
        .then(async response => {
          commit('SET_LIVESTREAM_PROVIDERS', response.data.result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getInputTypes({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v${version}/input-types/select-list`, { baseURL })
        .then(async response => {
          commit('SET_INPUT_TYPES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateChannelsOrder({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseURL}api/v${version}/${project.state.activeProjectId}/channels/order`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
