import Notification from '@/models/notifications/Notification'

export default {
  isLoading: true,
  notifications: [],
  notification: new Notification(),
  usageVolumes: [],
  dateOptions: [],
  notificationTypes: [],

  pagination: {
    totalPages: null,
    totalCount: null,
    currentPage: null,
  },

  filters: {
    params: {
      page: 1,
      take: 10,
      search: '',
      order: 'desc',
    },
  },
}
