<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.894 17.65v-5.174l1.125 5.615a1.825 1.825 0 002.123 1.431l1.983-.374a1.775 1.775 0 001.415-2.098L18.338 6.065a1.825 1.825 0 00-2.123-1.431l-1.983.374a1.78 1.78 0 00-.76.34 1.796 1.796 0 00-1.378-.642H9.979c-.46 0-.881.174-1.2.459a1.793 1.793 0 00-1.2-.459H5.465a1.8 1.8 0 00-1.8 1.8V17.65a1.8 1.8 0 001.8 1.8h2.114c.461 0 .882-.174 1.2-.459.319.285.74.459 1.2.459h2.115a1.8 1.8 0 001.8-1.8zm.574-11.466a.592.592 0 00-.472.7l2.202 10.985a.608.608 0 00.708.477l1.983-.374a.592.592 0 00.472-.7L17.159 6.288a.608.608 0 00-.708-.478l-1.983.374zm-9.603.322a.6.6 0 01.6-.6h2.114a.6.6 0 01.6.6V17.65a.6.6 0 01-.6.6H5.465a.6.6 0 01-.6-.6V6.506zm4.514 0a.6.6 0 01.6-.6h2.115a.6.6 0 01.6.6V17.65a.6.6 0 01-.6.6H9.979a.6.6 0 01-.6-.6V6.506zm-2.293 10.65a.564.564 0 11-1.128 0 .564.564 0 011.128 0zm3.95.565a.564.564 0 100-1.129.564.564 0 000 1.13zm7.162-.76a.555.555 0 01-.437.661.574.574 0 01-.661-.456.555.555 0 01.436-.661c.303-.057.6.147.662.456z"
    />
  </svg>
</template>
