<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 20.6a2.1 2.1 0 01-2.1-2.1v-13c0-1.16.94-2.1 2.1-2.1h1c1.16 0 2.1.94 2.1 2.1V11a.6.6 0 11-1.2 0V5.5a.9.9 0 00-.9-.9h-1a.9.9 0 00-.9.9v13a.9.9 0 00.9.9h.5a.6.6 0 010 1.2h-.5z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.323 14.025a.6.6 0 00-.849.848L13.1 16.5l-1.626 1.626a.6.6 0 00.849.849l1.626-1.626 1.626 1.626a.6.6 0 00.848-.849L14.797 16.5l1.626-1.625a.6.6 0 00-.848-.849l-1.626 1.626-1.626-1.626z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
