import state from './moduleUploadState'
import mutations from './moduleUploadMutations'
import actions from './moduleUploadActions'
import getters from './moduleUploadGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
