<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.57 6.1a3.972 3.972 0 013.967 3.968v.013l.003.036c.078 1.387-.16 4.984-3.764 9.548a.62.62 0 01-1.06-.158.618.618 0 01-.01-.423c0-.004.06-.18.138-.483a12.7 12.7 0 00.255-1.255c.145-.958.216-2.17-.024-3.34A3.968 3.968 0 017.57 6.1z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.675 6.1a3.972 3.972 0 013.967 3.968v.03l.002.019c.079 1.387-.16 4.984-3.764 9.548a.62.62 0 01-1.06-.158.618.618 0 01-.01-.423c.001-.004.06-.18.138-.483.08-.307.177-.741.255-1.255.145-.958.216-2.17-.023-3.34a3.968 3.968 0 01.495-7.906z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
