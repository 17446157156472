<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="3"
      width="18"
      height="18"
      rx="4"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.8071 7.92108C16.9888 8.09065 16.9986 8.37541 16.829 8.55709L9.88385 15.9983C9.6763 16.2207 9.3238 16.2207 9.11625 15.9983L7.17108 13.9142C7.0015 13.7325 7.01132 13.4478 7.19301 13.2782C7.3747 13.1086 7.65945 13.1185 7.82903 13.3001L9.50005 15.0905L16.1711 7.94301C16.3407 7.76132 16.6254 7.7515 16.8071 7.92108Z"
      fill="white"
    />
  </svg>

</template>
