<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4.22c-3.373 0-6.253 1.389-8.428 3.607a.6.6 0 10.856.84C6.401 6.657 8.98 5.42 12 5.42c3.02 0 5.599 1.236 7.572 3.247a.6.6 0 00.856-.84C18.253 5.608 15.373 4.22 12 4.22zM4.993 9.55C6.764 7.68 9.209 6.506 12 6.506c2.79 0 5.235 1.173 7.007 3.043a.6.6 0 01-.871.825C16.575 8.726 14.438 7.706 12 7.706s-4.575 1.02-6.136 2.669a.6.6 0 11-.871-.825zM12 8.79a7.512 7.512 0 00-5.59 2.484.6.6 0 00.894.8A6.312 6.312 0 0112 9.992c1.855 0 3.552.807 4.696 2.084a.6.6 0 10.894-.8A7.513 7.513 0 0012 8.79zm-4.04 4.224A5.17 5.17 0 0112 11.077c1.633 0 3.09.758 4.037 1.939a.6.6 0 01-.936.75A3.963 3.963 0 0012 12.277a3.97 3.97 0 00-3.103 1.489.6.6 0 11-.936-.75zm2.297 3.233a1.743 1.743 0 113.486 0 1.743 1.743 0 01-3.486 0zM12 15.707a.543.543 0 100 1.085.543.543 0 000-1.085zm8.424-.453a.6.6 0 010 .848l-1.29 1.29 1.29 1.29a.6.6 0 11-.848.849l-1.29-1.29-1.29 1.29a.6.6 0 01-.849-.849l1.29-1.29-1.29-1.29a.6.6 0 01.849-.848l1.29 1.29 1.29-1.29a.6.6 0 01.848 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
