export default {
  getIsLoading: state => state.isLoading,
  getName: state => state.role.name,
  getDescription: state => state.role.description,
  getRoles: state => state.roles,
  getActiveRoleId: state => state.activeRoleId,
  getPermissionsAll: state => state.permissions.all,
  getPermissionsOfRoleTest: state => state.permissions.ofUser,
  getPermissionsDefault: state => state.permissions.default,
  getPermissionsOfRole: state => state.permissions.ofRole,
  getPermissionsOfUser: state => state.permissions.ofUser.map(x => ({ subject: x.key.split('.')[0], action: x.key.split('.')[1] })),
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,
}
