import AdFunction from '@/models/ad-schedules/AdFunction'

export default {
  SET_AD_FUNCTION: (state, data) => {
    const adFunction = new AdFunction()
    adFunction.fromData(data)
    state.adFunction = adFunction
  },
  SET_AD_FUNCTIONS: (state, data) => { state.adFunctions = data },
  CLEAR_AD_FUNCTION: state => { state.adFunction = new AdFunction() },
  CLEAR_AD_FUNCTIONS: state => { state.adFunctions = [] },
  UPDATE_ISLOADING: (state, data) => { state.isLoading = data },
}
