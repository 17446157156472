import Vue from 'vue'

// axios
import axios from 'axios'

let controller = new AbortController()
const { signal } = controller

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_BASE_URL,
  signal,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

const reInitAbortController = () => {
  const newController = new AbortController()
  const newSignal = newController.signal
  axiosIns.defaults.signal = newSignal

  return newController
}

export const handleCancelRequest = () => {
  controller.abort('Request was canceled')
  controller = reInitAbortController()
}

Vue.prototype.$http = axiosIns

export default axiosIns
