export default {
  getIsLoading: state => state.isLoading,
  getWrapper: state => state.wrapper,
  getWrappers: state => state.wrappers,
  getCurrencies: state => state.currencies,
  getBidOptions: state => state.bidOptions,
  getWrapperOptions: state => state.wrapperOptions,
  getPagination: state => state.pagination,
  getCurrentPage: state => state.filters.params.page,
  getPageLength: state => state.filters.params.take,

}
