<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.55 3.4A2.6 2.6 0 008.95 6v12a2.6 2.6 0 002.6 2.6h6.286a2.6 2.6 0 002.6-2.6V6a2.6 2.6 0 00-2.6-2.6H11.55zm1.429 2.286a.6.6 0 100 1.2h3.428a.6.6 0 100-1.2H12.98zM3.55 12a2.314 2.314 0 014.55-.6h6.086c.47 0 .85.38.85.85v2.036a.6.6 0 11-1.2 0V12.6h-1.657v1.114a.6.6 0 11-1.2 0V12.6H8.1a2.315 2.315 0 01-4.55-.6zm2.314-1.114a1.114 1.114 0 100 2.228 1.114 1.114 0 000-2.228z"
    />
  </svg>
</template>
