<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.4a.6.6 0 01.6.6v2.857a.6.6 0 11-1.2 0V4a.6.6 0 01.6-.6zM12 16.543a.6.6 0 01.6.6V20a.6.6 0 01-1.2 0v-2.857a.6.6 0 01.6-.6zM16.3 4.552a.6.6 0 01.22.82L15.09 7.846a.6.6 0 01-1.04-.6l1.43-2.474a.6.6 0 01.819-.22zM19.448 7.7a.6.6 0 01-.22.82l-2.474 1.428a.6.6 0 11-.6-1.039l2.474-1.429a.6.6 0 01.82.22zM8.066 14.271a.6.6 0 01-.22.82L5.372 16.52a.6.6 0 11-.6-1.04l2.474-1.428a.6.6 0 01.82.22zM20.6 12a.6.6 0 01-.6.6h-2.857a.6.6 0 010-1.2H20a.6.6 0 01.6.6zM7.457 12a.6.6 0 01-.6.6H4a.6.6 0 110-1.2h2.857a.6.6 0 01.6.6zM19.448 16.3a.6.6 0 01-.82.22l-2.474-1.429a.6.6 0 11.6-1.04l2.474 1.43a.6.6 0 01.22.82zM8.066 9.729a.6.6 0 01-.82.22l-2.474-1.43a.6.6 0 01.6-1.039L7.846 8.91a.6.6 0 01.22.82zM16.3 19.448a.6.6 0 01-.82-.22l-1.428-2.474a.6.6 0 111.039-.6l1.429 2.474a.6.6 0 01-.22.82zM9.729 8.066a.6.6 0 01-.82-.22L7.48 5.372a.6.6 0 111.04-.6l1.428 2.474a.6.6 0 01-.22.82z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
