class Player {
  constructor() {
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // SETUP
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.publicId = ''
    this.cloudHostedPlayerLibrary = ''
    this.releaseChannelId = null

    this.name = 'Untitled Player'

    this.loopContent = false
    this.muteOnStart = true
    this.floatOnScroll = false
    this.autoPauseWhenNotViewable = true
    this.enablePlayBackRateControls = true

    this.autoPlay = true
    this.focusOnAutoStart = false
    this.pauseWhenRelatedIsShown = false
    this.readQualities = false
    this.showPlaylist = false
    this.enableGravityChange = false
    this.forceSound = false
    this.enableHapticFeedbackOnButtonClick = false
    this.enableHapticFeedbackOnPinchToZoom = false
    this.enableHapticFeedbackOnPinchToZoom = false
    this.allowSeekOnLive = true
    this.seekLiveAfterPause = false
    this.onHoverPlay = false

    this.autoStartOnViewableOn = false
    this.autoStartOnViewableMobile = false
    this.autoStartOnViewableOnData = false
    this.autoStartOnViewablePercentage = 0.5

    this.autoPauseOnViewableOn = false
    this.autoPauseOnViewablePercentage = 0.5

    this.autoPauseOnCuePointOn = false

    this.autoPauseOnCuePointState = false
    this.autoPauseOnCuePointOnDesktop = false
    this.autoPauseOnCuePointOnMobile = false
    this.autoPauseOnCuePointContinueUnMuted = false
    this.autoPauseOnCuePointTimeToPause = 0
    this.autoPauseOnCuePointPlayButtonUrl = ''
    this.autoPauseOnCuePointPlayButtonPath = ''

    this.volume = 0.5

    this.adsRequireInteraction = false

    this.floatOnMobile = false
    this.floatRequiresInteraction = false
    // TODO : add `floatBorder`
    this.floatBorderWidth = 0
    this.floatBorderColor = '#ffffff'

    this.preloadOptionId = 2
    this.preloadAds = false

    this.sharing = false
    this.casting = false
    this.googleAnalytics = false

    this.recommendation = false
    this.playlistId = null
    this.showControlsOnVPAID = false
    this.outstreamPlayer = false
    this.dispAdverisementAbove = false
    this.autoCollapseAfterAds = false
    this.companionAds = false
    this.playAdsMuted = true
    this.debug = false

    this.floatPositionOptionId = 1
    this.floatDismissible = false
    this.floatWidth = 340

    this.defaultBandwidthEstimateOptionId = 1

    // this.sharingTypeId = null
    this.countDownMessage = 'string'
    this.cueText = 'string'
    this.skipCountDown = 'string'
    this.companionAdsDivID = 'string'
    this.companionAdsWidth = 0
    this.companionAdsHeight = 0
    // this.volume = 0

    this.skipAmount = 1

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // CUSTOMIZE
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.showContentTitle = true
    this.showContentDescription = true
    this.showControls = true
    this.theaterButton = true
    this.settingsButton = true
    this.chromeCastButton = true
    this.nextButton = true
    this.airplayButton = true
    this.bigPlayButton = true
    this.fullScreenButton = true
    this.pictureInPictureButton = false
    this.volumeButton = true
    this.shareButton = false
    this.audioOnlyButton = false
    this.showLiveIcon = false
    this.afterMidrollBacktrackTime = 3
    this.afterMidrollBacktrackState = false
    this.jumpBackAfterMidrollOn = false
    this.jumpBackAfterMidrollTime = 3
    this.adAnnouncementTimeBeforeAd = 5
    this.showAutoPlay = false
    this.adAnnouncementState = false
    this.midrollSentenceAlignment = false
    this.showQualities = false
    this.showSubtitles = false
    this.showHelperButton = false
    this.helperButtonImageUrl = ''

    this.logoOn = false
    this.logoUrl = ''
    this.logoPath = ''
    this.logoOnClickUrl = ''
    this.logoDefaultOpacity = 1
    this.logoInactiveOpacity = 0.5
    this.logoPositionOptionId = 1
    this.channelUrl = ''

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // ADVERTISING
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.adScheduleId = null
    this.advertising = false

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // LOCALIZATION
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.Back = 'Back'
    this.Play = 'Play'
    this.Pause = 'Pause'
    this.Next = 'Next'
    this.Replay = 'Replay'
    this.VolumeText = 'Volume'
    this.Mute = 'Mute'
    this.UnMute = 'Unmute'
    this.Live = 'Live'
    this.Settings = 'Settings'
    this.FullScreen = 'Fullscreen'
    this.Chromecast = 'Chromecast'
    this.Airplay = 'Airplay'
    this.Quality = 'Quality'
    this.Subtitles = 'Subtitles'
    this.Theater = 'Theater'
    this.Skip = 'Skip'
    this.SkipIn = 'Skip in'
    this.Visit = 'Visit'
    this.Info = 'Info'
    this.PictureInPicture = 'Picture in Picture'
    this.SkipIntro = 'Skip Intro'
    this.ContinueWatching = 'Continue Watching'
    this.Playlist = 'Related'
    this.PlaybackSpeed = 'Speed'
    this.ContinueAfterPause = 'Continue after pause'
    this.NextVideoCancel = 'Cancel next video'
    this.PlayNow = 'Play now'
    this.PlaybackRate = 'Playback Rate'
    this.Auto = 'Auto'
    this.Off = 'Off'
    this.On = 'On'
    this.Normal = 'Normal'
    this.Share = 'Share'
    this.SimultaneousAds = 'Simultaneous Ads'
    this.ZoomIn = 'Zoom In'
    this.ZoomOut = 'Zoom Out'
    this.OriginalZoom = 'Original Zoom'
    this.ApiErrorOverlay = null
    this.SourceErrorOverlay = null
    this.PlayerErrorOverlay = null
    this.CaptionsOff = null
    this.Live = null
    this.ChromeCastPlayingOn = null
    this.Dismiss = null
    this.AdAnnouncement = 'Ad starting in '
    this.UpNext = 'Up Next'
    this.GeoBlockedTitle = 'Video not available in your country.'
    this.GeoBlockedDescription = 'Unfortunately, the publisher has not made this video available in your country.'
    this.StreamInterruptedTitle = 'Livestream is interrupted'
    this.StreamInterruptedDescription = 'Something went wrong. Try refreshing the page.'
    this.PlaybackErrorTitle = 'There was a problem playing this video.'
    this.PlaybackErrorDescription = 'Unfortunately, this video could not be played at this moment. Please try refreshing your browser.'
    this.StreamStartingTitle = 'Livestream is starting soon'
    this.StreamStartingDescription = 'Please wait, livestream is about to start soon'
    this.UnpublishedTitle = ''
    this.UnpublishedDescription = 'The publish date of this video is set in the future.'
    this.PrivateTitle = ''
    this.PrivateDescription = 'This video cannot be played because it is set as private.'
    this.AutoGeneratedSubtitles = ''
    this.PaywallRestrictedHover = ''
    // this.includeCompatibilityScript = false
    // this.keepVisibleWhenControlsHidden = false
    // this.sharingTooltip = 'string'
    // this.rightClickText = 'string'
    // this.rightClickUrl = 'string'

    this.releaseChannel = {
      id: 0,
      name: 'string',
      originalPath: 'string',
      templateOriginalPath: 'string',
    }
    this.preloadOption = {
      id: 2,
      name: 'Quick Start (default)',
      value: 'metadata',
      tooltipText: 'For the fastest startup time. When the player becomes viewable or if it\'s the only player on the embedded page, the player will load the media\'s metadata and up to one segment.',
    }
    this.defaultBandwidthEstimateOption = {
      id: 1,
      name: 'None',
      value: '0',
    }
    this.sharingType = {
      id: 0,
      name: 'string',
      value: 'string',
    }
    this.logoPositionOption = {
      id: 1,
      name: 'Top right (default)',
      value: 'TOP_RIGHT',
    }
    this.floatPositionOption = {
      id: 1,
      name: 'Top right (default)',
      value: 'TOP_RIGHT',
    }
    this.playerSocialNetworks = []
  }

  fromData(data) {
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // SETUP
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.publicId = data.publicId
    this.cloudHostedPlayerLibrary = data.cloudHostedPlayerLibrary || ''
    this.releaseChannelId = data.releaseChannelId || null

    this.name = data.name || '16:9 Example Player'

    this.loopContent = data.loopContent
    this.muteOnStart = data.muteOnStart
    this.floatOnScroll = data.floatOnScroll
    this.autoPauseWhenNotViewable = data.autoPauseWhenNotViewable
    this.enablePlayBackRateControls = data.enablePlayBackRateControls

    this.autoPlay = data.autoPlay
    this.showPlaylist = data.showPlaylist

    this.autoStartOnViewableOn = data.autoStartOnViewableOn
    this.autoStartOnViewableMobile = data.autoStartOnViewableMobile
    this.autoStartOnViewableOnData = data.autoStartOnViewableOnData
    this.autoStartOnViewablePercentage = data.autoStartOnViewablePercentage || 0.5

    this.autoPauseOnViewableOn = data.autoPauseOnViewableOn
    this.autoPauseOnViewablePercentage = data.autoPauseOnViewablePercentage || 0.5

    this.autoPauseOnCuePointOn = data.autoPauseOnCuePointOn || false

    this.autoPauseOnCuePointState = data.autoPauseOnCuePointState
    this.autoPauseOnCuePointOnDesktop = data.autoPauseOnCuePointOnDesktop
    this.autoPauseOnCuePointOnMobile = data.autoPauseOnCuePointOnMobile
    this.autoPauseOnCuePointContinueUnMuted = data.autoPauseOnCuePointContinueUnMuted
    this.autoPauseOnCuePointTimeToPause = data.autoPauseOnCuePointTimeToPause || 0
    this.autoPauseOnCuePointPlayButtonUrl = data.autoPauseOnCuePointPlayButtonUrl || ''
    this.autoPauseOnCuePointPlayButtonPath = data.autoPauseOnCuePointPlayButtonPath || ''

    this.volume = data.volume || 0.5

    this.adsRequireInteraction = data.adsRequireInteraction
    this.focusOnAutoStart = data.focusOnAutoStart
    this.pauseWhenRelatedIsShown = data.pauseWhenRelatedIsShown
    this.readQualities = data.readQualities
    this.enableGravityChange = data.enableGravityChange
    this.forceSound = data.forceSound
    this.enableHapticFeedbackOnButtonClick = data.enableHapticFeedbackOnButtonClick
    this.enableHapticFeedbackOnPinchToZoom = data.enableHapticFeedbackOnPinchToZoom
    this.allowSeekOnLive = data.allowSeekOnLive
    this.seekLiveAfterPause = data.seekLiveAfterPause
    this.onHoverPlay = data.onHoverPlay

    this.floatOnMobile = data.floatOnMobile
    this.floatRequiresInteraction = data.floatRequiresInteraction
    this.floatBorder = data.floatBorder

    this.preloadOptionId = data.preloadOptionId || 2
    this.preloadAds = data.preloadAds

    this.sharing = data.sharing
    this.casting = data.casting
    this.googleAnalytics = data.googleAnalytics

    this.recommendation = data.recommendation
    this.playlistId = data.playlistId || null
    this.showControlsOnVPAID = data.showControlsOnVPAID
    this.outstreamPlayer = data.outstreamPlayer
    this.dispAdverisementAbove = data.dispAdverisementAbove
    this.autoCollapseAfterAds = data.autoCollapseAfterAds
    this.companionAds = data.companionAds
    this.playAdsMuted = data.playAdsMuted
    this.debug = data.debug

    this.floatPositionOptionId = data.floatPositionOptionId || 1
    this.floatDismissible = data.floatDismissible
    this.floatWidth = Number(data.floatWidth) || 340

    this.defaultBandwidthEstimateOptionId = data.defaultBandwidthEstimateOptionId || 1

    this.countDownMessage = data.countDownMessage || 'string'
    this.cueText = data.cueText || 'string'
    this.skipCountDown = data.skipCountDown || 'string'
    this.companionAdsDivID = data.companionAdsDivID || 'string'
    this.companionAdsWidth = data.companionAdsWidth || 0
    this.companionAdsHeight = data.companionAdsHeight || 0
    // this.volume = data.volume || 0

    this.skipAmount = data.skipAmount || 1

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // CUSTOMIZE
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.showContentTitle = data.showContentTitle
    this.showContentDescription = data.showContentDescription
    this.showControls = data.showControls
    this.theaterButton = data.theaterButton
    this.settingsButton = data.settingsButton
    this.chromeCastButton = data.chromeCastButton
    this.nextButton = data.nextButton
    this.airplayButton = data.airplayButton
    this.bigPlayButton = data.bigPlayButton
    this.fullScreenButton = data.fullScreenButton
    this.pictureInPictureButton = data.pictureInPictureButton
    this.volumeButton = data.volumeButton
    this.shareButton = data.shareButton
    this.audioOnlyButton = data.audioOnlyButton
    this.showLiveIcon = data.showLiveIcon
    this.afterMidrollBacktrackState = data.afterMidrollBacktrackState
    this.afterMidrollBacktrackTime = data.afterMidrollBacktrackTime || 3
    this.jumpBackAfterMidrollOn = data.jumpBackAfterMidrollOn || false
    this.jumpBackAfterMidrollTime = data.jumpBackAfterMidrollTime || 3
    this.adAnnouncementState = data.adAnnouncementState
    this.adAnnouncementTimeBeforeAd = data.adAnnouncementTimeBeforeAd || 5
    this.midrollSentenceAlignment = data.midrollSentenceAlignment

    this.showAutoPlay = data.showAutoPlay
    this.showQualities = data.showQualities
    this.showSubtitles = data.showSubtitles
    this.showHelperButton = data.showHelperButton
    this.helperButtonImageUrl = data.helperButtonImageUrl || ''

    this.logoOn = data.logoOn
    this.logoUrl = data.logoUrl
    this.logoPath = data.logoPath
    this.logoOnClickUrl = data.logoOnClickUrl
    this.logoDefaultOpacity = data.logoDefaultOpacity || 1
    this.logoInactiveOpacity = data.logoInactiveOpacity || 0.5
    this.logoPositionOptionId = data.logoPositionOptionId || 1

    this.channelUrl = data.channelUrl || ''

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // ADVERTISING
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.adScheduleId = data.adScheduleId || null
    this.advertising = data.advertising

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //
    // LOCALIZATION
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~ //

    this.Back = data.Back || 'Back'
    this.Play = data.Play || 'Play'
    this.Pause = data.Pause || 'Pause'
    this.Next = data.Next || 'Next'
    this.Replay = data.Replay || 'Replay'
    this.VolumeText = data.VolumeText || 'Volume'
    this.Mute = data.Mute || 'Mute'
    this.UnMute = data.UnMute || 'Unmute'
    this.Live = data.Live || 'Live'
    this.Settings = data.Settings || 'Settings'
    this.FullScreen = data.FullScreen || 'FullScreen'
    this.Chromecast = data.Chromecast || 'Chromecast'
    this.Airplay = data.Airplay || 'Airplay'
    this.Quality = data.Quality || 'Quality'
    this.Subtitles = data.Subtitles || 'Subtitles'
    this.Theater = data.Theater || 'Theater'
    this.Skip = data.Skip || 'Skip'
    this.SkipIn = data.SkipIn || 'Skip in'
    this.Visit = data.Visit || 'Visit'
    this.Info = data.Info || 'Info'
    this.PictureInPicture = data.PictureInPicture || 'Picture in Picture'
    this.SkipIntro = data.SkipIntro || 'Skip Intro'
    this.ContinueWatching = data.ContinueWatching || 'Continue Watching'
    this.Playlist = data.Playlist || 'Related'
    this.PlaybackSpeed = data.PlaybackSpeed || 'Speed'
    this.ContinueAfterPause = data.ContinueAfterPause || 'Continue after pause'
    this.NextVideoCancel = data.NextVideoCancel || 'Cancel next video'
    this.PlayNow = data.PlayNow || 'Play now'
    this.PlaybackRate = data.PlaybackRate || 'Playback Rate'
    this.Auto = data.Auto || 'Auto'
    this.On = data.On || 'On'
    this.Off = data.Off || 'Off'
    this.Normal = data.Normal || 'Normal'
    this.Share = data.Share || 'Share'
    this.SimultaneousAds = data.SimultaneousAds || 'Simultaneous Ads'
    this.ZoomIn = data.ZoomIn || 'Zoom In'
    this.ZoomOut = data.ZoomOut || 'Zoom Out'
    this.OriginalZoom = data.OriginalZoom || 'Original Zoom'
    this.ApiErrorOverlay = data.ApiErrorOverlay || 'Api Error'
    this.SourceErrorOverlay = data.SourceErrorOverlay || 'Source Error'
    this.PlayerErrorOverlay = data.PlayerErrorOverlay || 'Player Error'
    this.CaptionsOff = data.CaptionsOff || 'Captions off'
    this.Live = data.Live || 'Live'
    this.ChromeCastPlayingOn = data.ChromeCastPlayingOn || 'ChromeCast playing'
    this.Dismiss = data.Dismiss || 'Dismiss'
    this.AdAnnouncement = data.AdAnnouncement || 'Ad starting in '
    this.UpNext = data.UpNext || 'Up Next'
    this.GeoBlockedTitle = data.GeoBlockedTitle || 'Video not available in your country.'
    this.GeoBlockedDescription = data.GeoBlockedDescription || 'Unfortunately, the publisher has not made this video available in your country.'
    this.StreamInterruptedTitle = data.StreamInterruptedTitle || 'Livestream is interrupted'
    this.StreamInterruptedDescription = data.StreamInterruptedDescription || 'Something went wrong. Try refreshing the page.'
    this.PlaybackErrorTitle = data.PlaybackErrorTitle || 'There was a problem playing this video.'
    this.PlaybackErrorDescription = data.PlaybackErrorDescription || 'Unfortunately, this video could not be played at this moment. Please try refreshing your browser.'
    this.StreamStartingTitle = data.StreamStartingTitle || 'Livestream is starting soon'
    this.StreamStartingDescription = data.StreamStartingDescription || 'Please wait, livestream is about to start soon'
    this.UnpublishedTitle = data.UnpublishedTitle || ''
    this.UnpublishedDescription = data.UnpublishedDescription || 'The publish date of this video is set in the future.'
    this.PrivateTitle = data.PrivateTitle || ''
    this.PrivateDescription = data.PrivateDescription || 'This video cannot be played because it is set as private.'
    this.PaywallRestrictedHover = data.PaywallRestrictedHover || 'Includes paid content'
    this.AutoGeneratedSubtitles = data.AutoGeneratedSubtitles || 'Auto-generated subtitles'

    this.includeCompatibilityScript = data.includeCompatibilityScript || false

    this.releaseChannel = data.releaseChannel || {
      id: 0,
      name: 'string',
      originalPath: 'string',
      templateOriginalPath: 'string',
    }
    this.preloadOption = data.preloadOption || {
      id: 2,
      name: 'Quick Start (default)',
      value: 'metadata',
      tooltipText: 'For the fastest startup time. When the player becomes viewable or if it\'s the only player on the embedded page, the player will load the media\'s metadata and up to one segment.',
    }
    this.defaultBandwidthEstimateOption = data.defaultBandwidthEstimateOption || {
      id: 1,
      name: 'None',
      value: '0',
    }
    this.sharingType = data.sharingType || {
      id: 0,
      name: 'string',
      value: 'string',
    }
    this.logoPositionOption = data.logoPositionOption || {
      id: 1,
      name: 'Top right (default)',
      value: 'TOP_RIGHT',
    }
    this.floatPositionOption = data.floatPositionOption || {
      id: 1,
      name: 'Top right (default)',
      value: 'TOP_RIGHT',
    }
    this.playerSocialNetworks = data.playerSocialNetworks || []
  }
  // [ ] : Check me to fix corrupted datas with default values
  // The logical nullish assignment (x ??= y) operator only
  // assigns if x is nullish (null or undefined).
  // config(data) {
  //   data.publicId ??= this.publicId
  //   data.cloudHostedPlayerLibrary ??= this.cloudHostedPlayerLibrary
  //   return data
  // }
}

export default Player
