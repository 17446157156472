<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.579 9.857a2.6 2.6 0 010 4.286l-6.106 4.197C8.748 19.526 6.4 18.291 6.4 16.198V7.802c0-2.093 2.348-3.328 4.073-2.142l6.106 4.197z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
