<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.63 6.6a2.2 2.2 0 012.2-2.2h3.57a2.2 2.2 0 012.2 2.2v10.8a2.2 2.2 0 01-2.2 2.2h-3.57a2.2 2.2 0 01-2.2-2.2V6.6zM3.4 5.6a2.2 2.2 0 012.2-2.2h3.57a2.2 2.2 0 012.2 2.2v3.57a2.2 2.2 0 01-2.2 2.2H5.6a2.2 2.2 0 01-2.2-2.2V5.6zM3.4 14.83a2.2 2.2 0 012.2-2.2h3.57a2.2 2.2 0 012.2 2.2v3.57a2.2 2.2 0 01-2.2 2.2H5.6a2.2 2.2 0 01-2.2-2.2v-3.57z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
