<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 4.4A2.6 2.6 0 004.4 7v10A2.6 2.6 0 007 19.6h10a2.6 2.6 0 002.6-2.6V7A2.6 2.6 0 0017 4.4H7zm6.884 6.523a.808.808 0 100-1.615.808.808 0 000 1.615zm-2.445.772a.5.5 0 01.796-.025l1.793 2.207a.5.5 0 01-.388.815h-3.364a.5.5 0 01-.407-.79l1.57-2.207z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
