import Vue from 'vue'
import {
  BootstrapVue,
  ToastPlugin,
  ModalPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import LoadScript from 'vue-plugin-load-script'
// import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/vue-select'
import 'vue-select/dist/vue-select.css'

Vue.use(LoadScript)

// Boostrap Vue
Vue.use(BootstrapVue)
// Vue.use(BootstrapVueIcons)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Vue.use(ApmVuePlugin, {
//   router,
//   config: {
//     serverUrl: process.env.VUE_APP_APM_SERVER_URL || 'https://apm.gjirafa.tech',
//     serviceName: 'vpplayer-admin-front',
//     environment: process.env.NODE_ENV === 'development' ? 'development' : 'production',
//   },
// })

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
