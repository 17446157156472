/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import axios from '@axios'
import project from '@/store/account/project/moduleProject'

export default {
  getAdSchedules({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v2/projects/${project.state.activeProjectId}/ad-schedules`, state.filters)
        .then(response => {
          // commit('SET_PAGINATION', response.data.result)
          commit('SET_AD_SCHEDULES', response.data.result.items)
          commit('SET_DEFAULT_AD_SCHEDULES', response.data.result.items)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAdSchedulesAll({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/ad-schedules/select-list`)
        .then(response => {
          commit('SET_AD_SCHEDULES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAdScheduleById({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/ad-schedules/${id}`)
        .then(response => {
          commit('SET_AD_SCHEDULE', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  postAdSchedule({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/projects/${project.state.activeProjectId}/ad-schedules`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putAdSchedule({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/projects/${project.state.activeProjectId}/ad-schedules`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  removeAdScheduleById({ state }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/projects/${project.state.activeProjectId}/ad-schedules/${itemId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getOptions({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/ad-schedules/select-options`)
        .then(response => {
          commit('SET_OPTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAdManagerOptions({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/ad-schedules/ad-manager-options`)
        .then(response => {
          commit('SET_AD_MANAGER_OPTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAdSchedulesTypeOptions({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/ad-schedule-types/select-list')
        .then(response => {
          commit('SET_AD_SCHEDULE_TYPES_OPTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  /*      AD FUNCTION CRUD      */
  getAdFunctions({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/projects/${project.state.activeProjectId}/ad-functions/select-list`)
        .then(response => {
          commit('SET_AD_FUNCTIONS', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getAdFunction({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/projects/${project.state.activeProjectId}/ad-functions/${id}`)
        .then(response => {
          commit('SET_AD_FUNCTION', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  postAdFunction({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/projects/${project.state.activeProjectId}/ad-functions`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  putAdFunction({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/projects/${project.state.activeProjectId}/ad-functions`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  duplicateAdSchedule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/projects/${project.state.activeProjectId}/ad-schedules/duplicate`, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  updateAdSchedulesOrder({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`api/projects/${project.state.activeProjectId}/ad-schedules/order`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
