<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.952 7.361a3.636 3.636 0 01-.848 7.17H9.035a3.634 3.634 0 01-1.98-6.683 4.462 4.462 0 018.897-.487z"
    />
    <path
      d="M12.07 19.448a.552.552 0 11-1.103 0 .552.552 0 011.103 0zM12.622 17.241a.552.552 0 11-1.103 0 .552.552 0 011.103 0zM8.76 19.448a.552.552 0 11-1.104 0 .552.552 0 011.103 0zM9.311 17.241a.552.552 0 11-1.103 0 .552.552 0 011.103 0zM15.38 19.448a.552.552 0 11-1.103 0 .552.552 0 011.103 0zM15.932 17.241a.552.552 0 11-1.103 0 .552.552 0 011.103 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
