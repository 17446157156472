import store from '@/store'
import { initialAbility } from '@/libs/acl/config'
import Ability from '@/libs/acl/ability'

export default function fetchPermissions(organizationId, projectId) {
  return new Promise((resolve, reject) => {
    store.dispatch('userRolesModule/getUserPermissions', { organizationId, projectId })
      .then(response => {
        const responseUserPermissions = response.data.result.length > 0 ? response.data.result : null
        store.commit('userRolesModule/SET_PERMISSIONS', { data: responseUserPermissions, property: 'ofUser' })
        // Get the user from localStorage and parse into object
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (responseUserPermissions.length > 0) {
          const responsePermissions = responseUserPermissions.map(x => ({
            id: x.id,
            subject: x.key.split('.')[0],
            action: x.key.split('.')[1],
            key: x.key,
          }))
          const concat = responsePermissions.concat(initialAbility)
          userData.ability = concat
        } else {
          userData.ability = initialAbility
        }
        Ability.update(userData.ability)
        localStorage.setItem('userData', JSON.stringify(userData))
        resolve(response)
      }).catch(err => { reject(err) })
  })
}
