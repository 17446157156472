/* eslint-disable no-useless-constructor */
import { newAddHash } from '@/services/mixins/playerMixins'
import Player from './player'

class VerticalPlayer extends Player {
  constructor() {
    super()

    this.playButton = true
    this.seekbarOn = true
    this.textOn = true
    this.fullScreenButton = true
    this.shareButton = false
    this.releaseChannelId = null

    this.height = 650
    this.playerSizeTypeId = 1
    this.responsiveSizeOptionId = 1
    this.responsiveSizeOption = {
      id: 2,
      name: '9:16',
      width: 9,
      height: 16,
    }
    this.playerSizeType = {
      id: 1,
      name: 'Responsive',
    }

    this.socialNetworkIds = []

    this.skinTextColor = '#FFFFFF'
    this.elementsIconButtonColor = '#FFFFFF'
    this.elementsBackgroundColor = '#FFFFFF'
    this.elementsSeekBarRailColor = '#FFFFFF'
    this.elementsSeekBarProgressColor = '#EA622B'
    this.elementsSeekBarDraggerColor = '#FFFFFF'
    this.elementsSeekBarBufferColor = '#FFFFFF'

    // LOCALIZATIONS

    this.CopyLink = 'CopyLink'
    this.Fullscreen = 'Fullscreen'
    this.Minimize = 'Minimize'
    this.More = 'More'
    this.Less = 'Less'
    this.Previous = 'Previous'
    this.Copy = 'Copy'
    this.Live = 'Live'
  }

  fromData(data) {
    super.fromData(data)
    this.height = data.height || 650
    this.playerSizeTypeId = data.playerSizeTypeId || 1
    this.responsiveSizeOptionId = data.responsiveSizeOptionId || null
    this.releaseChannelId = data.releaseChannelId || null
    this.responsiveSizeOption = data.responsiveSizeOption || {
      id: 2,
      name: '9:16',
    }
    this.playerSizeType = data.playerSizeType || {
      id: 1,
      name: 'Responsive',
    }

    this.playButton = data.playButton || false
    this.seekbarOn = data.seekbarOn || false
    this.textOn = data.textOn || false
    this.fullScreenButton = data.fullScreenButton || false
    this.shareButton = data.shareButton || false
    this.sharing = data.shareButton || false
    this.socialNetworkIds = data.socialNetworkIds ? data.socialNetworkIds : []

    this.skinTextColor = newAddHash(data.skinTextColor) ?? this.skinTextColor
    this.elementsIconButtonColor = newAddHash(data.elementsIconButtonColor) ?? this.elementsIconButtonColor
    this.elementsBackgroundColor = newAddHash(data.elementsBackgroundColor) ?? this.elementsBackgroundColor
    this.elementsSeekBarRailColor = newAddHash(data.elementsSeekBarRailColor) ?? this.elementsSeekBarRailColor
    this.elementsSeekBarProgressColor = newAddHash(data.elementsSeekBarProgressColor) ?? this.elementsSeekBarProgressColor
    this.elementsSeekBarDraggerColor = newAddHash(data.elementsSeekBarDraggerColor) ?? this.elementsSeekBarDraggerColor
    this.elementsSeekBarBufferColor = newAddHash(data.elementsSeekBarBufferColor) ?? this.elementsSeekBarBufferColor

    this.CopyLink = data.CopyLink || 'Copy Link'
    this.Fullscreen = data.Fullscreen || 'Fullscreen'
    this.Minimize = data.Minimize || 'Minimize'
    this.More = data.More || 'More'
    this.Less = data.Less || 'Less'
    this.Previous = data.Previous || 'Previous'
    this.Copy = data.Copy || 'Copy'
    this.Live = data.Live || 'Live'
  }
}

export default VerticalPlayer
