// import TagURL from './adSchedulesTagURL'

class BidParamValues {
  constructor() {
    this.id = 0
    this.name = ''
    this.value = ''
    // this.bidParamId = null
    this.biddingTemplateBidderId = null
    this.required = false
    this.type = 'string'
    this.placeholder = ''
  }

  fromData(data) {
    if (data.bidParamId) {
      this.bidParamId = data.bidParamId || 0
      this.id = data.id ? data.id : 0
    } else {
      this.id = 0
      this.bidParamId = data.id || 0
    }
    this.biddingTemplateBidderId = data.biddingTemplateBidderId || null
    this.name = data.name ? data.name : ''
    this.value = data.value ? data.value : ''
    this.required = data.required ? data.required : false
    this.type = data.type ? data.type : 'string'
    this.placeholder = data.placeholder ? data.placeholder : ''
  }
}

export default BidParamValues
