<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.4 5a.6.6 0 01.6-.6h13a.6.6 0 01.552.836l-1.757 4.109 1.757 4.109a.6.6 0 01-.552.836H6.6V19a.6.6 0 11-1.2 0V5zm1.2 8.09h11.491l-1.5-3.51a.6.6 0 010-.471l1.5-3.509H6.6v7.49z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
