<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.691 3.325c-.783 0-1.424.613-1.469 1.385h5.558a1.472 1.472 0 00-1.47-1.385h-2.619zM4.915 6.98c0-.786.615-1.427 1.39-1.47h11.39c.774.043 1.39.684 1.39 1.47v1.247c0 .664-.44 1.225-1.045 1.409v.004H5.96v-.004a1.473 1.473 0 01-1.045-1.409V6.98zM6.032 10.44l.737 8.102a2.345 2.345 0 002.335 2.133h5.791a2.345 2.345 0 002.336-2.133l.736-8.102H6.032z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
