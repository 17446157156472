class CustomParameter {
  constructor() {
    this.key = null
    this.value = null
  }

  fromData(data) {
    this.key = data.key || null
    this.value = data.value || null
  }
}

export default CustomParameter
