<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 6.419A2.419 2.419 0 016.419 4h2.367a2.419 2.419 0 012.42 2.419v2.367a2.419 2.419 0 01-2.42 2.42H6.42A2.419 2.419 0 014 8.785V6.42zm2.419-1.303c-.72 0-1.303.583-1.303 1.303v2.367c0 .72.583 1.303 1.303 1.303h2.367c.72 0 1.303-.583 1.303-1.303V6.42c0-.72-.583-1.303-1.303-1.303H6.42zM12.795 6.419A2.419 2.419 0 0115.213 4h2.368A2.419 2.419 0 0120 6.419v2.367a2.419 2.419 0 01-2.419 2.42h-2.367a2.419 2.419 0 01-2.42-2.42V6.42zm2.418-1.303c-.719 0-1.302.583-1.302 1.303v2.367c0 .72.583 1.303 1.303 1.303h2.367c.72 0 1.303-.583 1.303-1.303V6.42c0-.72-.583-1.303-1.303-1.303h-2.367zM12.795 15.213a2.419 2.419 0 012.418-2.418h2.368A2.419 2.419 0 0120 15.213v2.368A2.419 2.419 0 0117.581 20h-2.367a2.419 2.419 0 01-2.42-2.419v-2.367zm2.418-1.302c-.719 0-1.302.583-1.302 1.303v2.367c0 .72.583 1.303 1.303 1.303h2.367c.72 0 1.303-.583 1.303-1.303v-2.367c0-.72-.583-1.303-1.303-1.303h-2.367zM4 15.213a2.419 2.419 0 012.419-2.418h2.367a2.419 2.419 0 012.42 2.418v2.368A2.419 2.419 0 018.785 20H6.42A2.419 2.419 0 014 17.581v-2.367zm2.419-1.302c-.72 0-1.303.583-1.303 1.303v2.367c0 .72.583 1.303 1.303 1.303h2.367c.72 0 1.303-.583 1.303-1.303v-2.367c0-.72-.583-1.303-1.303-1.303H6.42z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
