<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.6 10.15a.6.6 0 00-1.2 0v3.7a.6.6 0 101.2 0v-3.7z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 3.4A3.6 3.6 0 003.4 7v10A3.6 3.6 0 007 20.6h10a3.6 3.6 0 003.6-3.6V7A3.6 3.6 0 0017 3.4H7zM4.6 7A2.4 2.4 0 017 4.6h4.4v1.85a.6.6 0 101.2 0V4.6H17A2.4 2.4 0 0119.4 7v10a2.4 2.4 0 01-2.4 2.4h-4.4v-1.85a.6.6 0 00-1.2 0v1.85H7A2.4 2.4 0 014.6 17V7z"
    />
  </svg>
</template>
