<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,20,20"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >

    <rect
      width="20"
      height="20"
      rx="10"
      fill="white"
    />
    <path
      d="M5.69332 15.0133C5.28666 15.0133 4.90666 14.8733 4.63332 14.6133C4.28666 14.2867 4.11999 13.7933 4.17999 13.26L4.42666 11.1C4.47332 10.6933 4.71999 10.1533 5.00666 9.85999L10.48 4.06666C11.8467 2.61999 13.2733 2.57999 14.72 3.94666C16.1667 5.31333 16.2067 6.73999 14.84 8.18666L9.36666 13.98C9.08666 14.28 8.56666 14.56 8.15999 14.6267L6.01332 14.9933C5.89999 15 5.79999 15.0133 5.69332 15.0133ZM12.62 3.93999C12.1067 3.93999 11.66 4.25999 11.2067 4.73999L5.73332 10.54C5.59999 10.68 5.44666 11.0133 5.41999 11.2067L5.17332 13.3667C5.14666 13.5867 5.19999 13.7667 5.31999 13.88C5.43999 13.9933 5.61999 14.0333 5.83999 14L7.98666 13.6333C8.17999 13.6 8.49999 13.4267 8.63332 13.2867L14.1067 7.49333C14.9333 6.61333 15.2333 5.79999 14.0267 4.66666C13.4933 4.15333 13.0333 3.93999 12.62 3.93999Z"
      fill="currentColor"
    />
    <path
      d="M13.56 9.29998C13.5467 9.29998 13.5267 9.29998 13.5133 9.29998C11.4333 9.09331 9.76 7.51331 9.44 5.44665C9.4 5.17331 9.58666 4.91998 9.86 4.87331C10.1333 4.83331 10.3867 5.01998 10.4333 5.29331C10.6867 6.90665 11.9933 8.14665 13.62 8.30665C13.8933 8.33331 14.0933 8.57998 14.0667 8.85331C14.0333 9.10665 13.8133 9.29998 13.56 9.29998Z"
      fill="currentColor"
    />
    <path
      d="M16 17.1666H4C3.72667 17.1666 3.5 16.94 3.5 16.6666C3.5 16.3933 3.72667 16.1666 4 16.1666H16C16.2733 16.1666 16.5 16.3933 16.5 16.6666C16.5 16.94 16.2733 17.1666 16 17.1666Z"
      fill="currentColor"
    />

  </svg>

</template>

<script>
export default {
  name: 'EditPenIcon',
  props: {
    size: {
      type: String,
      default: '21',
    },
  },
}
</script>
