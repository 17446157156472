/* eslint-disable no-unused-vars */
import axios from '@axios'

export default {
  getOrganizations({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v2/accounts/users/organizations')
        .then(response => {
          commit('SET_ORGANIZATIONS', response.data.result.items)
          commit('SET_PINNED_ORGANIZATIONS', response.data.result.items.filter(e => e.orderNumber === 0))
          commit('SET_UNPINNED_ORGANIZATIONS', response.data.result.items.filter(e => e.orderNumber !== 0))
          commit('SET_PAGINATION', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  getOrganizationWithOrganizationId({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/organizations/${id}`)
        .then(response => {
          commit('SET_ACTIVE_ORGANIZATION', response.data.result)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },

  addOrganization({ commit }, organization) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/organizations', organization)
        .then(response => {
          commit('ADD_ORGANIZATION', Object.assign(organization, { id: response.data.id }))
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateOrganization({ commit, state }, organization) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/organizations/${state.activeOrganizationId}`, organization)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  updateDefaultOrganization({ commit, state }, organizationId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/accounts/organizations/${organizationId}/default`)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  getCountryList({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/countries/select-list')
        .then(response => {
          commit('SET_COUNTRIES', response.data.result)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },

  // getVideoCatalogUrl({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`/api/organizations/${state.activeOrganizationId}/videos/catalog`)
  //       .then(response => {
  //         commit('SET_ORGANIZATION_VIDEOCATALOG_URL', response.data.result)
  //         resolve(response)
  //       })
  //       .catch(error => { reject(error) })
  //   })
  // },

  updateOrganizationsOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put('/api/organizations/order', payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
}
