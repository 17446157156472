<script>
export default {
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
<template>
  <svg
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  ><path d="M6 4.75A2.25 2.25 0 003.75 7v10A2.25 2.25 0 006 19.25h2a.75.75 0 010 1.5H6A3.75 3.75 0 012.25 17V7A3.75 3.75 0 016 3.25h2a3.751 3.751 0 013.675 3H21a.75.75 0 010 1.5H6a.75.75 0 010-1.5h4.122A2.251 2.251 0 008 4.75H6z" /><path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.572 13.145l-4.516 4.516a2.75 2.75 0 00-.806 1.945v2.143h2.144c.73 0 1.429-.29 1.944-.805l6.081-6.08a2.321 2.321 0 10-3.283-3.284l-1.546 1.546-.01.01-.008.01zm3.787-.504a.821.821 0 00-1.162 0l-1.025 1.025 1.161 1.162 1.026-1.025c.32-.321.32-.841 0-1.162zm-2.086 3.247l-1.162-1.161-3.995 3.995a1.25 1.25 0 00-.366.884v.643h.644c.331 0 .65-.131.884-.366l3.995-3.995z"
  /></svg>
</template>
