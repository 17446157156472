<template>
  <svg
    style="fill: currentColor"
    viewBox="0,0,24,24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4.6c-3.546 0-6.4 2.814-6.4 6.257 0 2.642 1.348 4.783 2.882 6.286.765.749 1.563 1.326 2.232 1.712.335.194.63.335.867.427.118.046.216.077.293.096a.965.965 0 00.117.021l.007.001h.011a.977.977 0 00.117-.022c.077-.02.175-.05.293-.096.237-.091.532-.233.867-.427.669-.386 1.467-.963 2.232-1.712 1.534-1.503 2.882-3.644 2.882-6.286C18.4 7.414 15.546 4.6 12 4.6zm-7.6 6.257C4.4 6.727 7.815 3.4 12 3.4s7.6 3.328 7.6 7.457c0 3.073-1.569 5.503-3.243 7.143-.839.822-1.718 1.46-2.47 1.895a7.6 7.6 0 01-1.035.506c-.288.112-.592.199-.852.199s-.563-.087-.852-.199a7.6 7.6 0 01-1.034-.506A12.673 12.673 0 017.643 18C5.969 16.36 4.4 13.93 4.4 10.857zm4.667 0c0-1.605 1.325-2.886 2.933-2.886 1.608 0 2.933 1.281 2.933 2.886 0 1.605-1.325 2.886-2.933 2.886-1.608 0-2.933-1.281-2.933-2.886zM12 9.171c-.968 0-1.733.767-1.733 1.686 0 .919.765 1.686 1.733 1.686.968 0 1.733-.767 1.733-1.686 0-.919-.765-1.686-1.733-1.686z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
