<template>
  <svg
    style="fill: currentColor"
    viewBox="0 0 24 24"
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 13.8334L16.5332 15.1549C17.1777 15.7105 17.5 15.9882 17.5 16.3334C17.5 16.6785 17.1777 16.9563 16.5332 17.5119L15 18.8334"
      stroke="#1C1C1C"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.6666 13.8334L10.1335 15.1549C9.48888 15.7105 9.16663 15.9882 9.16663 16.3334C9.16663 16.6785 9.48888 16.9563 10.1335 17.5119L11.6666 18.8334"
      stroke="#1C1C1C"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M16.6667 11.336V7.01654C16.6667 5.60509 16.6667 4.89937 16.4433 4.33572C16.0844 3.42958 15.3252 2.71483 14.3627 2.37686C13.764 2.16663 13.0144 2.16663 11.5152 2.16663C8.8915 2.16663 7.57969 2.16663 6.53197 2.53453C4.84762 3.12598 3.51901 4.37679 2.89078 5.96253C2.5 6.94892 2.5 8.18393 2.5 10.654V12.7758C2.5 15.3344 2.5 16.6136 3.20642 17.502C3.40882 17.7566 3.64885 17.9826 3.91923 18.1731C4.67922 18.7088 5.70849 18.813 7.5 18.8333"
      stroke="#1C1C1C"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M2.5 10.5C2.5 8.96579 3.74365 7.72218 5.27778 7.72218C5.8326 7.72218 6.4867 7.8194 7.02614 7.67486C7.50543 7.54643 7.8798 7.17206 8.00823 6.69276C8.15278 6.15333 8.05556 5.49923 8.05556 4.9444C8.05556 3.41028 9.29925 2.16663 10.8333 2.16663"
      stroke="#1C1C1C"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />

  </svg>
</template>

<script>
export default {
  name: 'TestIcon',
  props: {
    size: {
      type: String,
      default: '18',
    },
  },
}
</script>
